import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ValidationErrors } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-daily-savings',
  templateUrl: './view-daily-savings.component.html',
  styleUrls: ['./view-daily-savings.component.sass', './view-daily-savings.component.css']
})
export class ViewDailySavingsComponent implements OnInit {


  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  // Initialize Usable Variable
  responseApi : any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  singleAccount: boolean = true;
  notes: any = '';
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = [
    {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  ];
  

  // Initialize Options
  accountType     : Array<any> = [{'text' : 'Tabungan Nusantara', 'id' : 'tabungan-nusantara'}, {'text' : 'Tabungan Nusantara Bisnis', 'id' : 'tabungan-nusantara-bisnis'}, {'text' : 'Tabungan Nusantara Karyawan', 'id' : 'tabungan-nusantara-karyawan'}];
  accountStatus   : Array<any> = [{'text' : 'Tunggal', 'id' : 'tunggal'}, {'text' : 'Gabungan "OR"', 'id' : 'gabungan-or'}, {'text' : 'Gabungan "AND"', 'id' : 'gabungan-and'}, {'text' : 'Atas nama "QQ"', 'id' : 'atas-nama'}];
  accountFacility : Array<any> = [{'text' : 'Buku Tabungan', 'id' : 'buku-tabungan', 'checked' : false}, {'text' : 'Rekening Koran', 'id' : 'rekening-koran', 'checked' : false}];

  // Data Sample

  dummyDataStore    : any = [{'name' : 'Head Office', 'id' : '1'}, {'name' : 'Cabang 1', 'id' : '2'}];
  dummyDataCustomer : any;
  // dummyDataCustomer : any = [{'name' : 'Ivan', 'id' : '00000001'}, {'name' : 'Angga', 'id' : '00000002'}];

  // Initialize Select2 Component

  dataStore: any; tempStore: any;
  dataCustomer: any; tempCustomer: any;
  dataCoa: any; tempCoa: any;
  idParam: any;
  secondApproval : boolean = true;
  // Initialize Form

  accountForm         : FormGroup = new FormGroup({
    accountNumber     : new FormControl({value : '', disabled: true}, [Validators.required]),
    storeId           : new FormControl({value : '', disabled: true}, [Validators.required]),
    cifFirst          : new FormControl({value : '', disabled: true}, [Validators.required]),
    cifSecond         : new FormControl({value : '', disabled: true}, []),
    nameFirst         : new FormControl({value : '', disabled: true}, [Validators.required]),
    nameSecond        : new FormControl({value : '', disabled: true}, []),
    address           : new FormControl({value : '', disabled: true}, [Validators.required]),
    accountType       : new FormControl({value : '', disabled: true}, [Validators.required]),
    accountStatus     : new FormControl({value : '', disabled: true}, [Validators.required]),
    accountFacility   : new FormControl({value : [], disabled: true}, [Validators.required]),
    accountReferral   : new FormControl({value : 'none', disabled: true}, [Validators.required]),
    actionType        : new FormControl({value : '', disabled: true}, [Validators.required]),
    notes             : new FormControl({value : '', disabled: true}, [Validators.required]),
    createdBy         : new FormControl({value : '', disabled: true}, [Validators.required]),   
    monthly_interest  : new FormControl({value : '', disabled: true}, [Validators.required]),
    coaId             : new FormControl({value : '', disabled: true}, [Validators.required]),
  });

  ngOnInit(): void {
    this.getCIF();
    // this.getAllData();
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
    }
    this.getAccountData(this.idParam);
  }

  // Fill Data

  fillData(data){
    let cifSecond   = this.accountForm.get('cifSecond');
    let nameSecond  = this.accountForm.get('nameSecond');

    let valueReferral = data[0].account_number.slice(0,3) == '100' ? 'core' : (data[0].account_number.slice(0,3) == '101' ? 'sales' : 'none');
    var tempArray     = [];

    data.forEach(element => {
      this.accountFacility.forEach(elementFacility => {
        if (element.facility_code == elementFacility.id){
          elementFacility.checked = true;
        }
      });
      var obj = {'text' : element.facility_name, 'id' : element.facility_code, 'checked' : true};
      tempArray.push(obj);
    });

    this.accountForm.patchValue({
      accountNumber   : this.idParam,
      storeId         : data[0].id_store,
      cifFirst        : data[0].no_first_cif,
      cifSecond       : data[0].no_second_cif,
      address         : data[0].address,
      accountType     : data[0].account_type,
      accountStatus   : data[0].account_status,
      accountFacility : tempArray,
      accountReferral : valueReferral,
      createdBy       : this.ApiConfig.USER_DATA.id_user,
      monthly_interest : data[0].monthly_interest,
    });

    this.onFirstCustomerChange(data[0].no_first_cif); 

    if (data[0].account_status == 'gabungan-or' || data[0].account_status == 'gabungan-and'){
      this.singleAccount = false;
      cifSecond.setValidators(Validators.required);
      nameSecond.setValidators(Validators.required);
      this.onSecondCustomerChange(data[0].no_second_cif);
    } else {
      this.singleAccount = true;
      cifSecond.clearValidators();
      nameSecond.clearValidators();
    }

    cifSecond.updateValueAndValidity();
    nameSecond.updateValueAndValidity();
  }

  // Reusable Function

  getByValue(arr, value) {
    for (var i=0, iLen=arr.length; i<iLen; i++) {
      if (arr[i].id == value) return arr[i];
    }
  }

  // Function Initialize Data
  getAllData(){
    this.getStore();
    this.getCustomer();
    this.getCoa();
  }

  getAccountData(idParam){
    this.http.get(this.ApiConfig.API_URL + "/daily-savings/" + idParam, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      this.fillData(this.responseApi.data);
    });
  }

  // Get Data
  getStore(){
    // this.dataStore = this.responseApi.data;
    this.dataStore = this.dummyDataStore;
    this.tempStore = this.dataStore.map(store => ({
      "id": store.id,
      "text": store.name,
      "additional": store
    }));
  }

  getCustomer(){
    this.dataCustomer = this.dummyDataCustomer;
    this.tempCustomer = this.dataCustomer.map(customer => ({
      "id"          : customer.id,
      "text"        : customer.id,
      "additional"  : customer
    }));
  }

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  }


  // Ng-Select2 On Change

  onStoreChange(event: any){
    this.accountForm.patchValue({
      storeId : event
    });
  };

  onFirstCustomerChange(event: any){
    if(this.dummyDataCustomer!="" && this.dummyDataCustomer!=undefined){
      var tempArray = this.getByValue(this.dummyDataCustomer, event);
      
      this.accountForm.patchValue({
        cifFirst : event,
        nameFirst: tempArray.name
      });
    }
    
  }

  onSecondCustomerChange(event: any){
    if(this.dummyDataCustomer!="" && this.dummyDataCustomer!=undefined){
      var tempArray = this.getByValue(this.dummyDataCustomer, event);
      this.accountForm.patchValue({
        cifSecond : event,
        nameSecond: tempArray.name
      });
    }    
  }

  onAccountTypeChange(event: any){
    this.accountForm.patchValue({
      accountType : event
    });
  };

  onAccountStatusChange(event: any){
    this.accountForm.patchValue({
      accountStatus : event
    });
  };

  onCoaIdChange(event: any){
    this.accountForm.patchValue({
      coaId : event
    });
  };



  // Checkbox Value Change

  onAccountFacilityChange(event){
    var arrayTemp = this.accountForm.get('accountFacility').value;
    var countFlag = 0;
    var indexGet  = '';
    arrayTemp.forEach((element, index) => {
      if (element.id == event.id){
        countFlag++;
        indexGet = index;
      } 
    });

    if (countFlag == 0){
      arrayTemp.push(event);
    } else {
      arrayTemp.splice(indexGet, 1);
    }

    this.accountForm.patchValue({
      accountFacility : arrayTemp
    });
  }

  onReferralChange(event){
    this.accountForm.patchValue({
      accountReferral : event.value
    });
  }


  // Click Event
  addDailySavings(){
    this.http.post(this.ApiConfig.API_URL + "/daily-savings/", this.accountForm.value, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        Swal.fire("Success", "Your input has been saved.", "success");
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });
    // if(this.accountForm.valid){
    //   this.http.post(this.ApiConfig.API_URL + "/daily-savings/", this.accountForm.value, this.httpOptions).subscribe(res => {
    //     this.responseApi = res;
    //     if (this.responseApi.status == "200") {
    //       Swal.fire("Success", "Your input has been saved.", "success");
    //     } else {
    //       Swal.fire("Ooops", "There's something wrong happened", "error");
    //     }
    //   }, err => {
    //     Swal.fire("Ooops", "There's something wrong happened", "error");
    //   });
    //   Swal.fire("Success", "Your input has been saved.", "success");
    // } else {
    //     this.accountForm.markAllAsTouched();
    //     Swal.fire("Error", "Harap Lengkapi Form", "error");
    // }
  }

  reqDailySaving(actionType){
    this.accountForm.patchValue({
      actionType  : actionType,
      notes       : this.notes,
    });

    this.http.post(this.ApiConfig.API_URL + "/daily-savings/approval", this.accountForm.value, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      console.log(this.responseApi);
      if (this.responseApi.status == "200") {
        Swal.fire("Success", "Anda berhasil memberikan respon.", "success").then(() => {
          this.router.navigate(['/admin/daily-saving/']);
        })
      } else {
        Swal.fire("Ooops", "Anda telah memberikan respon.", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getApprovalList(){
    var html = '<ul>';
    this.http.get(this.ApiConfig.API_URL + "/daily-savings-list/add/" + this.idParam, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        this.responseApi.data.forEach(element => {
          html += '<li class="list-approval approved">'+ this.capitalizeFirstLetter(element.username) +' - ('+ element.created_date + ')</li>';
        });

        if (this.responseApi.data.length == 0){
          html += '<li>Tidak terdapat data.</li>'
        }
        html += '</ul>';
        Swal.fire({
          title: '<strong>List Approval</strong>',
          html: html,
          showCloseButton: true,
          showConfirmButton: false,
        })
      } else {
        Swal.fire("Ooops", "Anda telah memberikan respon.", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });
    // Swal.fire("List Approval", "", "success");
  };

  // Get CIF Data
  getCIF(){
    this.http.get(this.ApiConfig.API_URL + "/customer-individual-for-daily-saving", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dummyDataCustomer = this.responseApi.data;
          this.getAllData();
          // console.log(this.dummyDataCustomer);
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }


  // Function Panel

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

}
