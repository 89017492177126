import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-add-coa-subtype',
  templateUrl: './add-coa-subtype.component.html',
  styleUrls: ['./add-coa-subtype.component.sass']
})
export class AddCoaSubtypeComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  subtipeCoaForm = new FormGroup({
    coaType   : new FormControl('', [Validators.required]),
    coaCode   : new FormControl('', [Validators.required]),
    coaSubtype: new FormControl('', [Validators.required]),
  });

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempCoaType: any;

  ngOnInit(): void {
    this.getCoaType();
  }

  addSubtipe(){
    this.http.post(this.ApiConfig.API_URL + "/coa-subtype", this.subtipeCoaForm.value, this.httpOptions).subscribe(
      res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil menambahkan Subtipe COA", "success").then(() => {
              this.router.navigate(['/admin/coa-subtype']);
            });
          } else {
            Swal.fire("Maaf", "Anda gagal menambahkan Tipe COA", "error")
          }
      },
      err => {
        Swal.fire("Maaf", "Anda gagal menambahkan Tipe COA", "error")
      }
    );
  }

  getCoaType(){
    this.http.get(this.ApiConfig.API_URL + "/coa-type", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempCoaType = this.responseApi.data.map(coa => ({
            "id"    : coa.code,
            "text"  : coa.code + ' - ' + coa.coa_type_name,
            "additional"  : coa
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  onChangeCoaSubtype(event: any){
    this.subtipeCoaForm.patchValue({
      coaType : event
    });
  }

}
