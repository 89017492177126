import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getLocaleDateTimeFormat, Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import {MatTabsModule} from '@angular/material/tabs';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardRate : FormGroup = new FormGroup({
    goldRate        : new FormControl('', [Validators.required]),
    usdRate         : new FormControl('', [Validators.required]),
    todayDate       : new FormControl('', [Validators.required])
  });

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute
  ) {}

  value: any;
  displayValue = true;
  rateSysparam: any;
  responseApi: any;
  currDate : any;
  goldParam:any;
  usdParam:any;
  goldMintsoda:any;
  goldBbMintsoda:any;
  merchant:any;
  startStock:any;
  endStock:any;
  inSales: any;
  inVoid: any;
  mutIn: any;
  mutOut: any;

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  dateNow:any;

  ngOnInit(): void {
    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    const d = new Date();
    let month = months[d.getMonth()];

    this.dateNow = d.getDate() + " " + month + " " + d.getFullYear();
    console.log(this.ApiConfig.get("user-data"));
    this.merchant = this.ApiConfig.get("user-data").store_name;

    // this.getDailyInterest();


    // this.getRateSysParam();
    // this.getDailyCount();

    // this.dtOptions = {
    //   dom: 'Brtpl',
    //   ajax: {
    //       url: this.ApiConfig.API_URL + "/daily-recap/" + this.ApiConfig.USER_DATA.id_store,
    //       headers: { 'token': this.ApiConfig.USER_DATA.token },
    //       data: function (data) {
    //           setDatatableSearch();
    //           return data;
    //       }
    //   },
    //   columns: [
    //       {title: 'ID Customer',data:'id_customer'},
    //       {title: 'Nama Customer',data:'customer_name'},
    //       {title: 'No. Invoice',data:'no_sales_invoice'},
    //       {title: 'Nama Sales',data:'sales_name'},
    //       {title: 'Total',data:'total',render: $.fn.dataTable.render.number( '.', ',', 0, '' )},
    //   ],
    //   buttons:[
    //     {
    //       extend: 'pdf',
    //       title: 'Rekapitulasi Harian',
    //       text: 'Print',
    //       filename: 'Rekapitulasi-Harian',
    //       orientation: 'landscape',
    //       pageSize: 'A4',
    //       customize: function(doc) {
    //         doc.content[1].margin = [ 175, 0, 0, 0 ] //left, top, right, bottom
    //         doc.content[1].alignment='center'
    //       }
         
          

    //   }
    //   ]

    // };

  }

  ngAfterViewInit(){
    $('#daily-saving-header').text("Bunga Nasabah Per Hari Ini " + this.dateNow.toString());
  }

  getRateSysParam() {
    this.http.get(this.ApiConfig.API_URL + '/dashboard-rate' ,this.httpOptions).subscribe(
        (res) => {
          this.responseApi = res;
          if (this.responseApi.status == '200') {
            this.currDate = this.responseApi.data[0].sysdate;
            this.goldParam = this.responseApi.data[0].goldparam;
            this.usdParam = this.responseApi.data[0].usdparam;
            this.goldMintsoda = this.responseApi.data[0].goldMintsoda;
            this.goldBbMintsoda = this.responseApi.data[0].goldBbMintsoda;
            this.goldMintsoda = this.formatRupiah(this.goldMintsoda);
            this.goldBbMintsoda = this.formatRupiah(this.goldBbMintsoda);
            this.goldParam = this.formatRupiah(this.goldParam);
            this.usdParam = this.formatRupiah(this.usdParam);
          }
        },
        (err) => {
          Swal.fire("Error", err.error.message, "error");
        }
      );
  }

  getDailyCount(){
    this.http
      .get(this.ApiConfig.API_URL + '/daily-recap-count/' + this.ApiConfig.USER_DATA.id_store ,this.httpOptions)
      .subscribe(
        (res) => {
          this.responseApi = res;
          if (this.responseApi.status == '200') {
            this.inSales = this.responseApi.data[0].invoice_sales;
            this.inVoid = this.responseApi.data[0].invoice_void;
            this.mutIn = this.responseApi.data[0].mutation_in;
            this.mutOut = this.responseApi.data[0].mutation_out;
            this.endStock = (+this.responseApi.data[0].total_sku);
            this.startStock = (+this.endStock) + (+this.inSales) - (+this.responseApi.data[0].total_buyback);
            
          }
        },
        (err) => {
          Swal.fire("Error", err.error.message, "error");
        }
      );
  }    

  getDailyInterest(){
    this.http.get(this.ApiConfig.API_URL + "/calculate-daily-transaction", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          $('#container-table').remove();
          let strHTML = '<div id = "container-table" class = "mt-5" style="overflow: auto;">'
          strHTML+='<label style = "color:#ff5050">*** Nilai Bunga Yang Ditampilkan Di Bawah Ini Merupakan Nilai Bunga Sebelum Pukul 15.00 WIB</label>'
          strHTML+= '<table id = "tabel-data" class="row-border hover custom-table" style = "border-collapse: collapse;border-spacing: 0;width: 100%; border: 1px solid #ddd;">';
          strHTML += '<thead style = "display: table;width: 100%;table-layout: fixed;"><tr id = "header-table"><th>Nomor Akun</th><th>Nomor CIF</th><th>Bunga</th></tr></thead>';
          
          let arrData = this.responseApi.data;
          if(arrData.length >0){
            strHTML += '<tbody id = "body-table" style="display:block;padding:2rem;height: 50vh;overflow-x: auto;table-layout: fixed;width: 100%;">';
            
            for (let i = 0; i < arrData.length; i++){
              strHTML += "<tr id = 'row-table' class='row-table'><td>" + arrData[i].account_number + "</td><td>" + arrData[i].no_first_cif + "</td><td>Rp" + this.formatRupiah(parseFloat(arrData[i].last_amount)) + "</td></tr>";
            }
          }else{
            strHTML += '<tbody id = "body-table" style="display:block;padding:2rem;height: 50vh;overflow-x: auto;table-layout: fixed;width: 100%;"><tr><td>Tidak Ada Data<td><tr>';
          }

          strHTML += '</tbody></table></div>';
          $("#container-daily-saving").after(strHTML);
          $(".row-table").css("width", "100%");
          $(".row-table").css("display", "table");
          $(".row-table").css("table-layout", "fixed");
          $("td").css("padding", "2rem");
          $("tr:nth-child(even)").css("background-color", "#f2f2f2");
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }    

  formatRupiah(nilai) {
    let number_string = nilai.toString()
    let split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

    if (ribuan) {
      let separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
  };

  generateDepos(){
    // var dateStart = this.startDateGenerate;
    // var dateEnd = this.endDateGenerate;
    
    // if (dateEnd == undefined || dateStart == undefined){
    //   Swal.fire("Error",'Tanggal Kosong',"error");
    // } else if (dateEnd < dateStart){
    //   Swal.fire("Error",'Tanggal Akhir lebih besar dari Tanggal Awal',"error");
    // } else {
    //   window.open(this.ApiConfig.API_URL + "/test-xlsx", '_blank');
    // }
    window.open(this.ApiConfig.API_URL + "/test-xlsx", '_blank');
  }
  

}

