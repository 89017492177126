import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import {MatAccordion} from '@angular/material/expansion';
import { CurrencyPipe, DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import'moment/locale/id';

@Component({
  selector: 'app-demand-loan-payment',
  templateUrl: './demand-loan-payment.component.html',
  styleUrls: ['./demand-loan-payment.component.css']
})
export class DemandLoanPaymentComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private currencyPipe : CurrencyPipe
  ) { }

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  loanData: any;
  currentMonth: any;
  monthArr: any;
  coaId: any;
  tempCoa: any;

  ngOnInit(): void {
    this.monthArr = moment.months();
    this.currentMonth = moment().format('DD MMMM YYYY');
    this.getDemandLoan();
    this.getCoa();
  }

  onCoaIdChange(event: any){
    this.coaId = event;
  };

  getDemandLoan() {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-payment-data", this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200") {
          this.loanData = this.responseApi.data;
          this.loanData.forEach(e => {
            e.payment_type = "";
            e.t_payment_date = moment(e.payment_date).format('DD MMM YYYY');
          })
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }

  processDemandLoan() {
    let sendData = [];
    this.loanData.forEach((e,i,a) => {
      if(e.payment_type) {
        e.created_by = this.ApiConfig.USER_DATA.id_user;
        e.coaId      = this.coaId;
        sendData.push(e)
      }
    })
    this.http.post(this.ApiConfig.API_URL + "/demand-loan-process-payment", sendData, this.httpOptions).subscribe(
      (res) => {
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Proses Pembayaran Demand Loan Berhasil.", "success");
          this.location.back();
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      },
      (err) => {
        console.log(err);
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    )
  }

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  }

}
