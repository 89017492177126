import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-individual',
  templateUrl: './dialog-individual.component.html',
  styleUrls: ['./dialog-individual.component.sass']
})
export class DialogIndividualComponent implements OnInit {

  name = new FormControl('', [Validators.required]);
  responseApi:any;
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };

  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    public dialogRef: MatDialogRef<DialogIndividualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  getErrorMessage(){
    return "Nama Perlu Diisi";
  }

  doAction(){
    // console.log(this.name.value);
    if (this.name.valid){
      const objSend = {
        name : this.name.value,
        data : this.data
      };
      
      if (this.data == null){
        this.http.post(this.ApiConfig.API_URL + "/individual", objSend, this.httpOptions).subscribe(
          res => {
              this.responseApi = res;
              if (this.responseApi.status == "200") {
                this.dialogRef.close(true);
              } else {
                this.dialogRef.close(false);
              }
          },
          err => {
            this.dialogRef.close(false);
          }
        );
      } else {
        this.http.put(this.ApiConfig.API_URL + "/individual/" + this.data.id, objSend, this.httpOptions).subscribe(
          res => {
              this.responseApi = res;
              if (this.responseApi.status == "200") {
                this.dialogRef.close(true);
              } else {
                this.dialogRef.close(false);
              }
          },
          err => {
            this.dialogRef.close(false);
          }
        );
      }
    }

 
    // this.dialogRef.close();
  }

}
