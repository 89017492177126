import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-simpanan-berjangka',
  templateUrl: './view-simpanan-berjangka.component.html',
  styleUrls: ['./view-simpanan-berjangka.component.sass']
})
export class ViewSimpananBerjangkaComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    private router: Router,
    private globalService: ServicesComponent,
    private route: ActivatedRoute
  ) { }

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  simpananBerjangkaForm: FormGroup = new FormGroup({
    tipeCustomer: new FormControl('', [Validators.required]),
    cifNumber: new FormControl('', [Validators.required]),
    jenisNasabah: new FormControl('', [Validators.required]),

    mataUang: new FormControl('', [Validators.required]),
    statusRekening: new FormControl('', [Validators.required]),
    jangkaWaktu: new FormControl('', [Validators.required]),
    instruksiJatuhTempo: new FormControl('', [Validators.required]),

    noRekPembayaran: new FormControl('', [Validators.required]),
    namaRekPembayaran: new FormControl('', [Validators.required]),
    namaCdnPembayaran: new FormControl('', [Validators.required]),

    tanggalValuta: new FormControl('', [Validators.required]),
    sukuBunga: new FormControl('', [Validators.required]),
    nominalPenempatan: new FormControl(),
    terbilang: new FormControl('', [Validators.required]),

    noRekSumberDana: new FormControl('', [Validators.required]),
    namaRekSumberDana: new FormControl('', [Validators.required]),
    jenisRekSumberDana: new FormControl('', [Validators.required]),
    cabangSumberDana: new FormControl('', [Validators.required]),

    createdBy: new FormControl(),
  });

  approvalForm: FormGroup = new FormGroup({
    idSimpananBerjangka : new FormControl({value : ''}, [Validators.required]),
    actionType      : new FormControl({value : ''}, [Validators.required]),
    notes           : new FormControl({value : ''}, [Validators.required]),
    createdBy       : new FormControl({value : ''}, [Validators.required])
  });

  showIndividual: any = false;
  showIndividual2: any = false;
  showCompany: any = false;
  noSimpananBerjangka: any;

  notes: any = '';

  ngOnInit(): void {
    this.globalService.checkRole("Simpanan Berjangka"); 

    if (this.route.snapshot.params.id) {
      this.getSimpananBerjangkaData(this.route.snapshot.params.id);

      this.approvalForm.patchValue({
        idSimpananBerjangka  : this.route.snapshot.params.id,
        createdBy       : this.ApiConfig.USER_DATA.id_user
      });
    }
    
  }

  openAll(){
    this.myPanels.openAll();
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  tipeCustOnChange(e){
    if(e.target.value == "individual"){
      this.showIndividual = true; 
      this.showCompany = false;
    } 
    if(e.target.value == "company") {
      this.showCompany = true; 
      this.showIndividual = false;
    }
  }

  getSimpananBerjangkaData(idData){
    this.http.get(this.ApiConfig.API_URL + "/simpanan-berjangka/" + idData, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      console.log("asd", res);
      if (this.responseApi.status == "200") {
        this.noSimpananBerjangka = this.responseApi.data.no_simpanan_berjangka
        this.simpananBerjangkaForm.patchValue({
          tipeCustomer: this.responseApi.data.cif_type,
          cifNumber: this.responseApi.data.cif_id,
          jenisNasabah: this.responseApi.data.jenis_nasabah,

          mataUang: this.responseApi.data.mata_uang,
          statusRekening: this.responseApi.data.status_rekening,
          jangkaWaktu: this.responseApi.data.jangka_waktu,
          instruksiJatuhTempo: this.responseApi.data.instruksi_jatuh_tempo,

          noRekPembayaran: this.responseApi.data.no_rekening_pembayaran,
          namaRekPembayaran: this.responseApi.data.nama_rekening_pembayaran,
          namaCdnPembayaran: this.responseApi.data.nama_pada_cdn_pembayaran,

          tanggalValuta: this.responseApi.data.tanggal_valuta,
          sukuBunga: this.responseApi.data.suku_bunga,
          nominalPenempatan: this.responseApi.data.nominal_penempatan,
          terbilang: this.responseApi.data.terbilang,

          noRekSumberDana: this.responseApi.data.no_rekening_sumberdana,
          namaRekSumberDana: this.responseApi.data.nama_rekening_sumberdana,
          jenisRekSumberDana: this.responseApi.data.jenis_rekening_sumberdana,
          cabangSumberDana: this.responseApi.data.cabang_rekening_sumberdana,
        });
        if(this.responseApi.data.cif_type == "individual"){
          this.showIndividual = true; 
          this.showCompany = false;
        }
        else{
          this.showCompany = true; 
          this.showIndividual = false;
        }

        if(this.responseApi.data.status_rekening == "or" || this.responseApi.data.status_rekening == "and"){
          this.showIndividual2 = true;
        }
        else{
          this.showIndividual2 = false;
        }

      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, 
    err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });

  }

  reqSimpananBerjangka(actionType){
    this.approvalForm.patchValue({
      actionType  : actionType,
      notes       : this.notes,
    });

    this.http.post(this.ApiConfig.API_URL + "/simpanan-berjangka/approval", this.approvalForm.value, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        //if final approval only
        if(this.responseApi.data == 'FinalApproval'){
          //Input to mutasi transaction
          let jsonData = {
            cif_id : this.simpananBerjangkaForm.value.cifNumber,
            amount : this.simpananBerjangkaForm.value.nominalPenempatan,
            type : "D",
            note : "Pendebitan Simpanan Berjangka No. " + this.noSimpananBerjangka,
            create_operator : this.simpananBerjangkaForm.value.createdBy
          };

          this.http.post(this.ApiConfig.API_URL + "/insert-log-daily-savings-transactions", jsonData , this.httpOptions).subscribe(res => {
            this.responseApi = res;
            if (this.responseApi.status == "200") {
              Swal.fire("Success", "Anda berhasil memberikan respon.", "success").then(() => {
                this.router.navigate(['/admin/simpanan-berjangka']);
              })
            } else {
              Swal.fire("Ooops", this.responseApi.message, "error");
            }
          }, err => {
            Swal.fire("Ooops", this.responseApi.message, "error");
          });
          //End
        }
        //End

        Swal.fire("Success", "Anda berhasil memberikan respon.", "success").then(() => {
          this.router.navigate(['/admin/simpanan-berjangka']);
        })

      } else {
        Swal.fire("Ooops", "Anda telah memberikan respon.", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });
  }

  getApprovalList(){
    
  }

}
