import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import {identity, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ReplaySubject } from "rxjs"; 
import { CurrencyPipe, DatePipe, Location, NgLocaleLocalization } from '@angular/common';
import Swal from 'sweetalert2';

export interface arrAccountNumber {account_number: string}

@Component({
  selector: 'app-add-deposit',
  templateUrl: './add-deposit.component.html',
  styleUrls: ['./add-deposit.component.css']
})
export class AddDepositComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private currencyPipe : CurrencyPipe
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  depositForm: FormGroup = new FormGroup({
    // validation: new FormControl('', [Validators.required]),
    branch: new FormControl({value: '', disabled: true}, [Validators.required]),
    date: new FormControl('', [Validators.required]),
    deposit_type: new FormControl('Tunai', [Validators.required]),
    deposit_type_number: new FormControl(''),
    account_number: new FormControl('', [Validators.required]),
    account_type: new FormControl({value: '', disabled: true}),
    account_name: new FormControl({value: '', disabled: true}),
    depositor_information: new FormControl('Anggota', [Validators.required]),
    depositor_other_information: new FormControl(''),
    depositor_account_number: new FormControl('', [Validators.required]),
    depositor_identity_number: new FormControl(''),
    depositor_name: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    phone_number: new FormControl('', [Validators.required]),
    counted: new FormControl('', [Validators.required]),
    create_name: new FormControl('', [Validators.required]),
    coaId: new FormControl('', [Validators.required]),
  });

  dtDepositType = [
    {id : "Tunai"},
    {id : "Warkat Pemindahbukuan"}
  ];

  dtAccountType = [{'text' : 'Tabungan Nusantara', 'id' : 'tabungan-nusantara'}, {'text' : 'Tabungan Nusantara Bisnis', 'id' : 'tabungan-nusantara-bisnis'}, {'text' : 'Tabungan Nusantara Karyawan', 'id' : 'tabungan-nusantara-karyawan'}]

  dtDepositorInformation = [
    {id : "Anggota"},
    {id : "Calon Anggota"}
  ];

  tempAccountNumber:any;
  tempCoa: any;

  arrAccountNumber:any;
  options: arrAccountNumber[] = [];
  filteredOptions: Observable<arrAccountNumber[]>;

  tempStore:any;

  ngOnInit(): void {
    this.depositForm.get("create_name").setValue(this.ApiConfig.USER_DATA.id_user);
    this.getAccountNumber();
    this.getStoreData();
    this.getCoa();
    this.depositForm.get("branch").setValue(parseInt(this.ApiConfig.USER_DATA.id_store));
  }

  displayFn(user: arrAccountNumber): string {
    return user && user.account_number ? user.account_number : '';
  }

  private _filter(account_number: string): arrAccountNumber[] {
    const filterValue = account_number.toLowerCase();

    return this.options.filter(option => option.account_number.toLowerCase().includes(filterValue));
  }

  getAccountNumber(){
    this.http.get(this.ApiConfig.API_URL + "/daily-savings-table", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempAccountNumber = this.responseApi.data;
          this.filteredOptions =  new Observable<arrAccountNumber[]>();
          this.options = this.tempAccountNumber;
          this.filteredOptions = this.account_number.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.account_number),
            map(account_number => account_number ? this._filter(account_number) : this.options.slice())
          );
        }
      }, 
      err => {
        console.log(err);
      }
    )
  };

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  removeCurrency(event:any){
    let dataCurr = event.target.value.toString();
    dataCurr = dataCurr.replace("Rp ", "");
    dataCurr = dataCurr.replace(".00", "");
    dataCurr = dataCurr.replaceAll(",", "");
    event.target.value = dataCurr;
  }

  GetData(){
    this.depositForm.get('account_type').setValue(this.account_number.value.account_type);
    this.depositForm.get('account_name').setValue(this.account_number.value.full_name);
  }

  formattedAmount:any;
  transformAmount(element){
    this.formattedAmount = this.currencyPipe.transform(element.target.value, 'Rp ');    
    element.target.value = this.formattedAmount;
  }

  rupiahFormat(value){
    return this.currencyPipe.transform(value, 'Rp ');
  }

  testAPI(){
    // let jsonData = '{"cif_id" : "CDN-1210002", "amount" : "100000", "type" : "D", "note" : "testing uang keluar", "create_operator" : "1"}';
    // jsonData = JSON.parse(jsonData);
    // this.http.post(this.ApiConfig.API_URL + "/insert-log-daily-savings-transactions", jsonData , this.httpOptions).subscribe(res => {
    //   this.responseApi = res;
    //   // console.log(this.responseApi);
    //   if (this.responseApi.status == "200") {
    //     Swal.fire("Success", this.responseApi.message, "success");
    //   } else {
    //     Swal.fire("Ooops", this.responseApi.message, "error");
    //   }
    // }, err => {
    //   Swal.fire("Ooops", this.responseApi.message, "error");
    // });

    this.http.post(this.ApiConfig.API_URL + "/admin-cost", this.httpOptions).subscribe(res => {
      this.responseApi = res;
      // console.log(this.responseApi);
      if (this.responseApi.status == "200") {
        Swal.fire("Success", this.responseApi.message, "success");
      } else {
        Swal.fire("Ooops", this.responseApi.message, "error");
      }
    }, err => {
      Swal.fire("Ooops", this.responseApi.message, "error");
    });

    // let current = new Date();
    // let strTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    // let objAccNumber = this.depositForm.get('account_number').value;
    // let currencyFormat = this.rupiahFormat(this.depositForm.get('amount').value);
    
    // // console.log(currencyFormat);
    // if(currencyFormat != "" && currencyFormat != null){
    //   currencyFormat = currencyFormat.replace(".", "/");
    //   while(currencyFormat.indexOf(",") >= 0){
    //     currencyFormat = currencyFormat.replace(",", ".");
    //   }
    //   currencyFormat = currencyFormat.replace("/", ",");
    // }
    
    // $("#print-date").text(this.depositForm.get('date').value);
    // $("#print-time").text(strTime);
    // $("#print-amount").text(currencyFormat);
    // $("#print-account").text(objAccNumber.account_number);
    // $("#print-accountname").text(this.depositForm.get('account_name').value);

    // let currDate = new Date(this.depositForm.get('date').value);
    // console.log(currDate);
    // console.log(currDate.getUTCMonth());
    // let tempDate = currDate.getDate()+"-"+(currDate.getMonth()+1)+"-"+currDate.getFullYear();

    // let a = window.open();
    // a.document.write('<div id = "form-print" style = "display:block; font-size: 2mm; margin-top: 3cm; margin-left: 2cm;">');
    // a.document.write('<table style = "font-size: 2.5mm;">');
    // a.document.write('<tr><td>Tanggal</td><td style = "padding-left: 1mm"><p id = "print-date">: ' + tempDate + '</p></td>');
    // a.document.write('<tr><td>Jam</td><td style = "padding-left: 1mm"><p id = "print-time">: ' + strTime + '</p></td>');
    // a.document.write('<tr><td>Jumlah</td><td style = "padding-left: 1mm"><p id = "print-amount">: ' + currencyFormat + '</p></td>');
    // a.document.write('<tr><td>Nomor Akun</td><td style = "padding-left: 1mm"><p id = "print-account">: ' + objAccNumber.account_number + '</p></td>');
    // a.document.write('<tr><td>Nama Akun</td><td style = "padding-left: 1mm"><p id = "print-accountname">: ' + this.depositForm.get('account_name').value + '</p></td>');
    // a.document.write('</table>');
    // a.document.write('</div>');
    // a.document.close();
    // a.print();
    
  }

  printValidation(){
    let current = new Date();
    let strTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
    let objAccNumber = this.depositForm.get('account_number').value;
    let currencyFormat = this.rupiahFormat(this.depositForm.get('amount').value);
    
    if(currencyFormat != "" && currencyFormat != null){
      currencyFormat = currencyFormat.replace(".", "/");
      while(currencyFormat.indexOf(",") >= 0){
        currencyFormat = currencyFormat.replace(",", ".");
      }
      currencyFormat = currencyFormat.replace("/", ",");
    }

    let currDate = new Date(this.depositForm.get('date').value);
    let tempDate = currDate.getDate()+"-"+(currDate.getMonth()+1)+"-"+currDate.getFullYear();

    let a = window.open();
    a.document.write('<div id = "form-print" style = "display:block; font-size: 2mm; margin-top: 3cm; margin-left: 2cm;">');
    a.document.write('<table style = "font-size: 2.5mm;">');
    a.document.write('<tr><td>Tanggal</td><td style = "padding-left: 1mm"><p id = "print-date">: ' + tempDate + '</p></td>');
    a.document.write('<tr><td>Jam</td><td style = "padding-left: 1mm"><p id = "print-time">: ' + strTime + '</p></td>');
    a.document.write('<tr><td>Jumlah</td><td style = "padding-left: 1mm"><p id = "print-amount">: ' + currencyFormat + '</p></td>');
    a.document.write('<tr><td>Nomor Akun</td><td style = "padding-left: 1mm"><p id = "print-account">: ' + objAccNumber.account_number + '</p></td>');
    a.document.write('<tr><td>Nama Akun</td><td style = "padding-left: 1mm"><p id = "print-accountname">: ' + this.depositForm.get('account_name').value + '</p></td>');
    a.document.write('</table>');
    a.document.write('</div>');
    a.document.close();
    a.print();
  }

  validateForm(){
    const invalid = [];
    const controls = this.depositForm.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        // console.log(name);
        this.depositForm.markAllAsTouched();
        return false;
      }
    }
    return true;
  }

  onCoaIdChange(event: any){
    this.depositForm.patchValue({
      coaId : event
    });
  };

  addDeposit(){
    // console.log(this.depositForm.get("amount").value);
    // return true;

    if(this.validateForm()){
      if(this.depositForm.get("depositor_information").value == "Anggota"){
        this.depositForm.get("depositor_other_information").setValue(this.depositForm.get("depositor_account_number").value);
      }else if(this.depositForm.get("depositor_information").value == "Calon Anggota"){
        this.depositForm.get("depositor_other_information").setValue(this.depositForm.get("depositor_identity_number").value);
      }

      this.http.post(this.ApiConfig.API_URL + "/insert-deposit", this.depositForm.value , this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          let jsonData = {
            cif_id : this.depositForm.get("account_number").value.no_first_cif,
            amount : this.depositForm.get("amount").value,
            type : "K",
            note : "Setoran Dana",
            create_operator : this.depositForm.get("create_name").value 
          };

          this.http.post(this.ApiConfig.API_URL + "/insert-log-daily-savings-transactions", jsonData , this.httpOptions).subscribe(res => {
            this.responseApi = res;
            if (this.responseApi.status == "200") {
              // Swal.fire("Success", "Dana Berhasil Disetorkan.", "success");
              Swal.fire("Success", "Dana Berhasil Disetorkan.", "success").then(() => {
                this.router.navigate(['/admin']);
              })
            } else {
              Swal.fire("Ooops", this.responseApi.message, "error");
            }
          }, err => {
            Swal.fire("Ooops", this.responseApi.message, "error");
          });

          // Swal.fire("Success", "Dana Berhasil Disetorkan.", "success").then(() => {
          //   this.router.navigate(['/admin']);
          // })
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    }
  }

  changeDepositType(){
    let dataDT = this.depositForm.get("deposit_type").value;
    if (dataDT == "Warkat Pemindahbukuan"){
      this.depositForm.get('deposit_type_number').setValidators([Validators.required]);
      this.depositForm.get('deposit_type_number').updateValueAndValidity();
    }else{
      this.depositForm.get('deposit_type_number').clearValidators();
      this.depositForm.get('deposit_type_number').updateValueAndValidity();
      this.depositForm.get('deposit_type_number').setValue('');
    }
  }

  changeDepositorInformation(){
    let dataDT = this.depositForm.get("depositor_information").value;
    if (dataDT == "Anggota"){
      this.depositForm.get('depositor_account_number').setValidators([Validators.required]);
      this.depositForm.get('depositor_account_number').updateValueAndValidity();

      this.depositForm.get('depositor_identity_number').clearValidators();
      this.depositForm.get('depositor_identity_number').updateValueAndValidity();
      this.depositForm.get('depositor_identity_number').setValue('');
    }else{
      this.depositForm.get('depositor_identity_number').setValidators([Validators.required]);
      this.depositForm.get('depositor_identity_number').updateValueAndValidity();

      this.depositForm.get('depositor_account_number').clearValidators();
      this.depositForm.get('depositor_account_number').updateValueAndValidity();
      this.depositForm.get('depositor_account_number').setValue('');
    }
  }

  getStoreData(){
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          // console.log(this.responseApi);
          let tempData = this.responseApi.data;
          this.tempStore = tempData.map(city => ({
            "id_store": parseInt(city.id_store),
            "store_name": city.store_name,
            "additional": city
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  get account_number(){return this.depositForm.get("account_number")}
}
