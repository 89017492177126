import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'
import { DialogDemandLoanComponent } from './dialog-demand-loan/dialog-demand-loan.component';
import { DialogDemandLoanPerpanjangComponent } from './dialog-demand-loan-perpanjang/dialog-demand-loan-perpanjang.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


@Component({
  selector: 'app-add-demand-loan',
  templateUrl: './add-demand-loan.component.html',
  styleUrls: ['./add-demand-loan.component.css']
})
export class AddDemandLoanComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public dialog : MatDialog,
  ) { }

  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;
  loanData: any;
  demandLoanData: any;
  idParam: any; 
  sisaPinjaman: any;

  demandForm: FormGroup = new FormGroup ({
    loanId: new FormControl('', [Validators.required]),
    cif: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    tenor: new FormControl('', [Validators.required]),
  })

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
    }
    this.getLoan(this.idParam);
  }

  getDemandLoan(id) {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-payment/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          let totalPinjaman = 0
          this.demandLoanData = this.responseApi.data;
          console.log(this.demandLoanData);
          this.demandLoanData.forEach(e => {
            totalPinjaman += parseInt(e.principal);
          })
          this.getRemainingLoan(id)
          
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  getLoan(id) {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-parent-info/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.loanData = this.responseApi.data;
          this.loanData.request_date = moment(this.loanData.request_date).format('DD MMMM YYYY');
          this.getDemandLoan(this.idParam);

        }
      }, 
      err => {
        console.log(err);
      }
    )  
  }

  getRemainingLoan(id) {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-calculate-remaining/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.sisaPinjaman = this.responseApi.data
        }
      }, 
      err => {
        console.log(err);
      }
    )   
  }

  openDialog(itemData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      top: '0',
      left : '0'
    }
    this.dialog.open(DialogDemandLoanComponent , {
      data: itemData,
      position : {
        top : '0'
      },
      backdropClass: 'dialog-blur'
    });
  }

  openPerpanjang(itemData){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      top: '0',
      left : '0'
    }
    this.dialog.open(DialogDemandLoanPerpanjangComponent , {
      data: itemData,
      position : {
        top : '0'
      },
      backdropClass: 'dialog-blur'
    });
  }
}
