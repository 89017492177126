import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-demand-loan-perpanjang',
  templateUrl: './dialog-demand-loan-perpanjang.component.html',
  styleUrls: ['./dialog-demand-loan-perpanjang.component.sass']
})
export class DialogDemandLoanPerpanjangComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    public dialogRef: MatDialogRef<DialogDemandLoanPerpanjangComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  responseApi: any;
  loanData: any; loanMasterData: any;
  tenor: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  requestDate: any;
  paymentId: any; loanId: any;

  ngOnInit(): void {
    this.loanData = this.data;
    this.paymentId = this.loanData.id;
    this.loanId = this.loanData.demand_loan_id;
    this.getPaymentInfo();
    this.getLoan(this.loanId);
  }

  getPaymentInfo(){
    this.http.get(this.ApiConfig.API_URL + '/demand-loan-payment-info/' + this.paymentId + '/' + this.loanId, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          console.log(this.responseApi.data)
          this.loanMasterData = this.responseApi.data;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getLoan(id) {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-parent-info/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        console.log(this.responseApi);
        if(this.responseApi.status == "200"){
          // this.loanMasterData = this.responseApi.data;
          this.requestDate = moment(this.responseApi.data.request_date, 'YYYYMMDD');
        }
      }, 
      err => {
        console.log(err);
      }
    )  
  }

  submit(){
    if (this.loanMasterData.master_loan.status == 'INACTIVE'){
      Swal.fire('Error', 'Loan sudah tidak aktif', 'error');
    } else {
      var checkDate = this.checkDate();
      this.loanMasterData.loan_data.addTenor = this.tenor;
      if (checkDate){
        this.http.post(this.ApiConfig.API_URL + '/extend-demand-loan', this.loanMasterData, this.httpOptions).subscribe(
          (res) => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              Swal.fire("Success", "Your input has been saved. Demand Loan Successfully Processed", "success").then(() => {
                window.location.reload();
              }).catch((err) => {
                Swal.fire('Error', 'Something Went Wrong', 'error');
              })
              // this.location.back();
            }
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        Swal.fire('Error', 'Penambahan Jangka Waktu Melewati Usia Plafon (12 Bulan)', 'error');
      }
    }


  }

  checkDate(){
    const totalTenor = parseInt(this.tenor) + parseInt(this.loanData.tenor);
    const element = this.loanMasterData.loan_detail.find(x => x.tenor == '0').payment_date;
    
    const dateNow = moment(element, 'YYYYMMDD');
    const dateReq = moment(this.requestDate);
    const dateExp = moment(this.requestDate).add(1, 'years');
    const dateAcc = moment(element, 'YYYYMMDD').add(totalTenor, 'months');
    
    if (dateAcc.isBetween(dateReq, dateExp, null, '[]')){
      return true;
    } else {
      return false;
    }
  }
}


