import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  constructor(
    private http: HttpClient, 
    private location: Location, 
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };

  storeArray: any[];
  tempStoreData: Array<Select2OptionData>;

  roleArray: any[];
  tempRoleData: Array<Select2OptionData>;

  userDetailData: any;
  idParam: any;

  userForm = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    userPassword: new FormControl('', [Validators.required]),
    idStore: new FormControl('', [Validators.required]),
    idRole: new FormControl('', [Validators.required]),
    createdBy: new FormControl('', [Validators.required]),
    fullName: new FormControl('', [Validators.required]),
  });

  responseApi: any;

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      
      // this.getStore();
      this.getRole();
      this.getUserData();
    }
  }

  changeStore(event: any) {
    this.userForm.patchValue({
      idStore: event
    });
  }

  changeRole(event: any) {
    this.userForm.patchValue({
      idRole: event
    });
  }

  getStore() {
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.storeArray = this.responseApi.data;
          this.tempStoreData = this.responseApi.data.map(car => ({
            "id": car.id_store,
            "text": car.nama_store,
            "additional": car
          }));
        }

      },
      err => {
        
      }
    );
  }

  getRole() {
    this.http.get(this.ApiConfig.API_URL + "/role", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.roleArray = this.responseApi.data;
          this.tempRoleData = this.responseApi.data.map(car => ({
            "id": car.id_role,
            "text": car.role_name,
            "additional": car
          }));
        }

      },
      err => {
        
      }
    );
  }

  getUserData() {
    this.http.get(this.ApiConfig.API_URL + "/edit-user/" + this.idParam, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.userForm.patchValue({
            userName: this.responseApi.data[0].username,
            idStore: parseInt(this.responseApi.data[0].id_store),
            idRole: parseInt(this.responseApi.data[0].id_role),
            fullName: this.responseApi.data[0].fullname
          });
          console.log(this.userForm);
          
        }

      },
      err => {
        console.log(err);
        
      }
    );
  }

  editUser() {
    this.http.put(this.ApiConfig.API_URL + "/user/" + this.idParam, this.userForm.value, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Success Update User Data", "success").then(() => {
            this.router.navigate(['/admin/user']);
          });
        }
        else{
          Swal.fire("Failed", "Failed Update User Data", "error");
        }

      },
      err => {
        
      }
    );
  }

}
