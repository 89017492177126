import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';  
import { ApiConfig } from '../../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-add-plafon-demand-loan',
  templateUrl: './add-plafon-demand-loan.component.html',
  styleUrls: ['./add-plafon-demand-loan.component.sass']
})
export class AddPlafonDemandLoanComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private location: Location,
  ) { }

  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;
  idParam: any; loanData:any; formatAmount: any;
  cif: any; loanId: any; tenor: any; amount:any; name:any; interest:any;date:any;
  sisaPinjaman: any;
  firstLoan: any;
  coaId: any;
  tempCoa: any;
  requestDate: any;



  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
    };
    this.getLoan(this.idParam);
    this.getCoa();
  }

  onCoaIdChange(event: any){
    this.coaId = event;
  };
  

  getLoan(id) {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-parent-info/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        console.log(this.responseApi);
        if(this.responseApi.status == "200"){
          this.loanData = this.responseApi.data;
          this.cif = this.loanData.cif_id;
          this.loanId = this.loanData.id;
          this.name = this.loanData.request_name;
          this.requestDate = moment(this.loanData.request_date).format('YYYY-MM-DD');
          this.interest = this.loanData.loan_interest;
          this.getRemainingLoan(id);
        }
      }, 
      err => {
        console.log(err);
      }
    )  
  }

  getRemainingLoan(id) {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan-calculate-remaining/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.sisaPinjaman = this.responseApi.data
        }
      }, 
      err => {
        console.log(err);
      }
    )  
  }

  submit(){
    const checkDate: boolean = this.checkDate();
    let obj = {
      loanId: this.loanId,
      cif: this.cif,
      tenor: this.tenor,
      amount: this.amount.replace(',',''),
      interest: this.interest,
      createdBy : this.ApiConfig.USER_DATA.id_user,
      date: moment(this.date).format('YYYYMMDD'),
      coaId : this.coaId
    }

    if (checkDate){
      this.http.post(this.ApiConfig.API_URL + "/demand-loan-first-setup", obj, this.httpOptions).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200"){
            // console.log(this.responseApi.data)
            // Swal.fire("Success", "Your input has been saved.", "success");
            // this.location.back();
            this.firstLoan = this.responseApi.data
            this.prosesDana(obj);
          }
        }, 
        err => {
          console.log(err);
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      ) 
    } else {
      Swal.fire('Maaf', 'Tanggal Akseptasi tidak sesuai. Mohon melakukan pengecekan', 'error');
    }

  }

  prosesDana(obj) {
    let loanAmount = this.loanData.loan_amount;
    if (this.firstLoan) {
      let provisionFee = (this.loanData.provision_fee / 100) * loanAmount;
      let adminFee = (this.loanData.admin_fee / 100) * loanAmount
      let notaryFee = parseFloat(this.loanData.notary_fee);
      let insuranceFee = parseFloat(this.loanData.insurance_fee);
      let totalFee = provisionFee + adminFee + notaryFee + insuranceFee;

      let provisi = {
        cif_id : this.loanData.cif_id,
        amount : provisionFee,
        type : "D",
        note : "Biaya Provisi Demand Loan. Kode Pinjaman " + this.loanData.id,
        create_operator : this.ApiConfig.USER_DATA.id_user
      }
  
      let admin = {
        cif_id : this.loanData.cif_id,
        amount : adminFee,
        type : "D",
        note : "Biaya Admin Demand Loan. Kode Pinjaman " + this.loanData.id,
        create_operator : this.ApiConfig.USER_DATA.id_user
      }
  
      let notary = {
        cif_id : this.loanData.cif_id,
        amount : notaryFee,
        type : "D",
        note : "Biaya Notaris Demand Loan. Kode Pinjaman " + this.loanData.id,
        create_operator : this.ApiConfig.USER_DATA.id_user
      }
  
      let insurance = {
        cif_id : this.loanData.cif_id,
        amount : insuranceFee,
        type : "D",
        note : "Biaya Asuransi Demand Loan. Kode Pinjaman " + this.loanData.id,
        create_operator : this.ApiConfig.USER_DATA.id_user
      }
  
      let paymentArr = [provisi,admin,notary,insurance];
  
      paymentArr.forEach((e,i,a) => {
        setTimeout(() => {
  
          this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', e, this.httpOptions).subscribe(
            (res) => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                // this.sendLoan();
              }
            },
            (err) => {
              console.log(err);
            }
          );
  
        },500)
  
        if(i == paymentArr.length - 1) {
          this.sendLoan();
        }
      })
      // let obj_potong = {
      //   cif_id : this.loanData.cif_id,
      //   amount : totalFee,
      //   type : "D",
      //   note : "Biaya Registrasi Demand Loan. Kode Pinjaman " + this.loanData.id,
      //   create_operator : this.ApiConfig.USER_DATA.id_user
      // }

      // this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', obj_potong, this.httpOptions).subscribe(
      //   (res) => {
      //     this.responseApi = res;
      //     if(this.responseApi.status == "200"){
      //       this.sendLoan();
      //     }
      //   },
      //   (err) => {
      //     console.log(err);
      //   }
      // );
    } else {
      this.sendLoan();
    }
  }

  sendLoan() {
    let obj_cair = {
      cif_id : this.loanData.cif_id,
      amount : this.amount.replace(',',''),
      type : "K",
      note : "Pencairan Dana Pinjaman Demand Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }
    console.log(obj_cair)
    this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', obj_cair, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          Swal.fire("Success", "Your input has been saved. Demand Loan Successfully Processed", "success");
          this.location.back();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  }

  dateChange(value){
    // console.log(value);
    // var dateNow   = moment(value, 'DD/MM/YYYY');
    // var dateReq = moment(this.requestDate);
    // var futureYear = moment(this.requestDate).add(1, 'years');

    // var x = dateNow.isBetween(dateReq, futureYear, null, '[]');

    // console.log(dateNow);
    // console.log(dateReq);
    // console.log(futureYear.format('DD MM YYYY'));
    // console.log(x);
    // // console.log(this.requestDate);
  }

  checkDate(){
    const dateNow = moment(this.date, 'DD/MM/YYYY');
    const dateReq = moment(this.requestDate);
    const dateExp = moment(this.requestDate).add(1, 'years');
    const dateAcc = moment(this.date).add(parseInt(this.tenor), 'months');

    if (dateNow.isBetween(dateReq, dateExp, null, '[]')){
      if (dateAcc.isBetween(dateReq, dateExp, null, '[]')){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}
