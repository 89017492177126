import { Component, OnInit, Inject } from '@angular/core';
import { inject } from '@angular/core/testing';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrencyPipe, DatePipe, Location, NgLocaleLocalization } from '@angular/common';

export interface insObj {
  ins_name          : any;
  ins_legality      : any;
  ins_relationship  : any;
  v_brand   : any;
  v_type    : any;
  v_year    : any;
  v_no      : any;
  v_stnk    : any;
  b_desc    : any;
  b_no      : any;
  b_name    : any;
  b_address : any;
  no_pk     : any;
  cover_note: any;
  no_apht   : any;
  kjpp      : any;
  harga_jaminan : any;
  tanggal_survey : any;
}

@Component({
  selector: 'app-dialog-insurance',
  templateUrl: './dialog-insurance.component.html',
  styleUrls: ['./dialog-insurance.component.sass']
})



export class DialogInsuranceComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<DialogInsuranceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: insObj,
    private currencyPipe : CurrencyPipe
  ) { }
  ins_name          : any = "";
  ins_legality      : any = "";
  ins_relationship  : any = "";
  v_brand   : any = "";
  v_type    : any = "";
  v_year    : any = "";
  v_no      : any = "";
  v_stnk    : any = "";
  b_desc    : any = "";
  b_no      : any = "";
  b_name    : any = "";
  b_address : any = "";
  no_pk     : any = "";
  cover_note: any = "";
  no_apht   : any = "";
  kjpp      : any = "";
  harga_jaminan : any = "";
  tanggal_survey : any = "";

  local_data : any = "";

  ngOnInit(): void {
    const el = this.data;
    this.ins_name = el.ins_name;
    this.ins_legality = el.ins_legality;
    this.ins_relationship = el.ins_relationship;
    this.v_brand = el.v_brand;
    this.v_type = el.v_type;
    this.v_year = el.v_year;
    this.v_no = el.v_no;
    this.v_stnk = el.v_stnk;
    this.b_desc = el.b_desc;
    this.b_no = el.b_no;
    this.b_name = el.b_name;
    this.b_address = el.b_address;
    this.no_pk = el.no_pk;
    this.cover_note = el.cover_note;
    this.no_apht = el.no_apht;
    this.kjpp = el.kjpp;
    this.harga_jaminan = el.harga_jaminan;
    this.tanggal_survey = el.tanggal_survey;
  }

  formattedAmount:any;
  transformAmount(element){
    this.formattedAmount = this.currencyPipe.transform(element.target.value, 'Rp. ');    
    element.target.value = this.formattedAmount;
  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  doAction(){
    this.local_data = {
      ins_name : this.ins_name,
      ins_legality : this.ins_legality,
      ins_relationship : this.ins_relationship,
      v_brand : this.v_brand,
      v_type    : this.v_type,
      v_year    : this.v_year,
      v_no      : this.v_no,
      v_stnk    : this.v_stnk,
      b_desc    : this.b_desc,
      b_no      : this.b_no,
      b_name    : this.b_name,
      b_address : this.b_address,
      no_pk     : this.no_pk,
      cover_note: this.cover_note,
      no_apht   : this.no_apht,
      kjpp      : this.kjpp,
      harga_jaminan : this.harga_jaminan,
      tanggal_survey : this.tanggal_survey,
      ok : true
    }
    // console.log(this.local_data)
    this.dialogRef.close(this.local_data);
  }

}
