import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  constructor(
    private http: HttpClient, 
    private location: Location, 
    private ApiConfig: ApiConfig
  ) { }

  userForm = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    userPassword: new FormControl('', [Validators.required]),
    idStore: new FormControl('', [Validators.required]),
    idRole: new FormControl('', [Validators.required]),
    createdBy: new FormControl('', [Validators.required]),
    fullName: new FormControl('', [Validators.required])
  });

  storeArray: any[];
  tempStoreData: Array<Select2OptionData>;

  roleArray: any[];
  tempRoleData: Array<Select2OptionData>;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };

  responseApi: any;

  ngOnInit(): void {
    this.userForm.patchValue({
      createdBy: "Admin"
    });

    this.getStore();
    this.getRole();
  }

  changeStore(event: any) {
    this.userForm.patchValue({
      idStore: event
    });
  }

  changeRole(event: any) {
    this.userForm.patchValue({
      idRole: event
    });
  }

  getStore() {
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.storeArray = this.responseApi.data;
          this.tempStoreData = this.responseApi.data.map(store => ({
            "id": store.id_store,
            "text": store.store_name,
            "additional": store
          }));
        }

      },
      err => {
        Swal.fire("Error", err.error.message, "error");
      }
    );
  }

  getRole() {
    this.http.get(this.ApiConfig.API_URL + "/role", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.roleArray = this.responseApi.data;
          this.tempRoleData = this.roleArray.map(role => ({
            "id": role.id_role,
            "text": role.role_name,
            "additional": role
          }));
        }

      },
      err => {
        Swal.fire("Error", err.error.message, "error");
      }
    );
  }

  addUser() {
    this.http.post(this.ApiConfig.API_URL + "/user", this.userForm.value, this.httpOptions).subscribe(
        res => {
            this.responseApi = res;

            if (this.responseApi.status == "200") {
              Swal.fire("Success", this.responseApi.message, "success").then(() => {
                this.location.back();
              });
            } 
            else{
              Swal.fire("Failed", this.responseApi.message, "error");
            }

        },
        err => {
          Swal.fire("An Error Occured", err.error.message, "error");
        }
    );
  }
}
