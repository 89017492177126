import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-simpanan-berjangka',
  templateUrl: './simpanan-berjangka.component.html',
  styleUrls: ['./simpanan-berjangka.component.sass']
})
export class SimpananBerjangkaComponent implements OnInit {
  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private globalService: ServicesComponent
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.globalService.checkRole("Simpanan Berjangka");    
    
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/simpanan-berjangka-by-status/APPROVED_ADD",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No Simpanan Berjangka', data:'no_simpanan_berjangka'},
          {title: 'No. CIF', data: 'cif_id'},
          {title: 'Nama', data: 'nama_rekening_sumberdana'},
          {title: 'Start Date', data: 'created_date'},
          {title: 'End Date', data: 'end_date'},      
          {
            title: 'Jatuh Tempo Dalam', 
            data: 'days_duration',
            render: function(data, type, row){
              if (data > 30){
                let firstNumber
                let backNumber
                firstNumber = data / 30
                firstNumber = firstNumber.toString().split('.')[0]

                backNumber = data - (firstNumber * 30)
                
                return firstNumber + ' bulan ' + backNumber + ' hari' + ' / ' + data + ' hari'
              }
              else{
                return data + ' hari'
              }
            }
          },  
          { 
            data : null, 
            title : 'Otomatis Perpanjang',
            render: function (data, type, row) {
              if (data.otomatis_perpanjang == true){
                return "Ya";
              } else {
                return '-';
              }
            }
          },
          // {title: 'Otomatis Perpanjang', data: 'otomatis_perpanjang'},           
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  // return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
                  return '<button class="btn btn-info view-btn"></button> <button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button> <button class="btn btn-info view-approval-btn" title="Transaksi Cross-Approval"><img src="../../../assets/icons/tick.svg"></button>  <button class="btn btn-info update-otomatis-perpanjang" title="Update Otomatis Perpanjang"><img src="../../../assets/icons/edit.svg"></button>';
              }
          }
      ],
      order: [],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.view-btn', row).unbind('click');
          $('.view-approval-btn', row).unbind('click');
          $('.update-otomatis-perpanjang', row).unbind('click');

          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.view-btn', row).bind('click', () => {
              self.viewData(data);
          });
          $('.view-approval-btn', row).bind('click', () => {
            self.viewRequest(data);
          });
          $('.update-otomatis-perpanjang', row).bind('click', () => {
            self.updateOtomatisPerpanjang(data);
          });

          return row;
      }
    };
  };

  testClick(){
    this.http.get(this.ApiConfig.API_URL + "/check-simpanan-berjangka-jatuh-tempo",  this.httpOptions).subscribe((res) => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        console.log('berhasil');
      } else {
        Swal.fire("Sorry", "An error occured, please try again", "error");
      }
    }, (err) => {
      Swal.fire("Sorry", err.error.message, "error");
    });
  };

  deleteData(data, row) {
    this.http.delete(this.ApiConfig.API_URL + "/simpanan-berjangka/" + data.id,  this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            Swal.fire("Success", this.responseApi.message, "success");
            row.remove();
        } else {
            Swal.fire("Sorry", "An error occured, please try again", "error");
        }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
  }

  updateOtomatisPerpanjang(data) {
    this.http.get(this.ApiConfig.API_URL + "/update-otomatis-perpanjang/" + data.id,  this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", this.responseApi.message, "success").then(() => {
            window.location.reload()
          });
        } else {
            Swal.fire("Sorry", "An error occured, please try again", "error");
        }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
  }
  
  viewData(data) {
    // window.open(this.ApiConfig.API_URL + "/view/bilyet-simpanan-berjangka?noSimpananBerjangka=" + data.no_simpanan_berjangka, '_blank')
    this.router.navigate(['/admin/detail-simpanan-berjangka/' + data.no_simpanan_berjangka]);
  }

  viewRequest(data){
    let dataParam = [data.id];
    this.http.get(this.ApiConfig.API_URL + "/request-approved-simpanan-berjangka/" + dataParam, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        console.log(this.responseApi);
        let strHTML="";
        let strApproved="";
        let strApprovedContent="";
        if(this.responseApi.status == "200"){
          if(this.responseApi.data.length > 0){
            strApproved+= '<div class="grid grid-cols-2 gap-12 md:grid-cols-2">'
            strHTML += "<div><p style='font-size: large;'> REQUEST ADD BY: </p> <h1 style='color:brown; font-size: xx-large;'> " + data.created_by + " </h1></div>"
            for(let i = 0; i < this.responseApi.data.length; i++){
              strApprovedContent+= "<div><p style='font-size: large;'> "+ this.responseApi.data[i].action_type.toUpperCase() +" BY: </p> <h1 style='color:brown; font-size: xx-large;'> " + this.responseApi.data[i].username + " </h1></div>"
            }
            if(strApprovedContent!=""){
              strApproved+=strApprovedContent;
              strApproved+="</div>";
            } else {
              strApproved+="<div><h1 style='color:brown; font-size: xx-large;'> BELUM ADA APPROVAL/REJECT </h1></div>";
              strApproved+="</div>";
            }
            strHTML+="<hr style='padding-top: 1rem;'>";
            strHTML+=strApproved;
          }else{
            strHTML+="<hr style='padding-top: 1rem;'>";
            strHTML+="<div><h1 style='color:brown; font-size: xx-large;'> Tidak Ada Transaksi </h1></div>";
          }
          Swal.fire(
            {
              title: 'Transaksi Cross-Approval',
              icon: 'info',
              html: strHTML
            }
          )
          // console.log(strHTML);
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

}
