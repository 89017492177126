import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { ReplaySubject } from "rxjs";
import { Route, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.sass']
})
export class AddAttachmentComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute
  ) { }

  documentForm: FormGroup = new FormGroup({
    docType: new FormControl('', [Validators.required]),
    docDesc: new FormControl('', [Validators.required]),
    docDate: new FormControl('', [Validators.required]),
    docFileName: new FormControl('', [Validators.required]),
    docFileExt: new FormControl('', [Validators.required]),
    docFileBuffer: new FormControl('', [Validators.required]),
    companyId: new FormControl('', [Validators.required]),
    createdBy: new FormControl(),
  });

  responseApi: any; idParam: any; companyName: any; companyId: any; feFileName : any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  ngOnInit(): void {
    if (this.route.snapshot.params.companyId) {
      this.idParam = this.route.snapshot.params.companyId;
      this.getCompanyName(this.idParam);
    }
    this.documentForm.patchValue({
      createdBy : this.ApiConfig.USER_DATA.username,
      companyId : this.idParam,
    });
  }

  getCompanyName(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company-attachment-name/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.companyName = this.responseApi.data.company_name;
          this.companyId = this.responseApi.data.id;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  uploadFile(event) {
    let fileName = event.target.files[0].name;
    this.feFileName = fileName;
    let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let name = this.companyName+" - "+fileName.substring(0,fileName.indexOf("."));
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.documentForm.patchValue({
        docFileName : name,
        docFileExt : extension,
        docFileBuffer : base64
      });
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  add(){
    let pipe = new DatePipe('en-GB');
    this.documentForm.value.docDate = pipe.transform(this.documentForm.value.docDate,'yyyyMMdd');
    this.http.post(this.ApiConfig.API_URL + "/cif-company-attachment/", this.documentForm.value, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        Swal.fire("Success", "Your input has been saved.", "success");
        this.location.back();
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });
  }

}
