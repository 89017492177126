import { Component, OnInit, ViewChild } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
declare function getAVGTransaction(): any;

// BUAT FORM
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import { style } from '@angular/animations';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatAccordion} from '@angular/material/expansion';
import { Location } from '@angular/common';


@Component({
  selector: 'app-add-cif-individual',
  templateUrl: './add-cif-individual.component.html',
  styleUrls: ['./add-cif-individual.component.sass']
})
export class AddCifIndividualComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private route: ActivatedRoute, 
    private ApiConfig: ApiConfig,
    private location: Location
  ) { }

  custForm: FormGroup = new FormGroup({
    custPrefixName: new FormControl('', [Validators.maxLength(10)]),
    custSuffixName: new FormControl('', [Validators.maxLength(10)]),
    custFullName: new FormControl('', [Validators.required]),
    custPlaceOfBirth: new FormControl('', [Validators.required]),
    custCountryOfBirth: new FormControl('', [Validators.required]),
    custDateOfBirth: new FormControl('',Validators.required),
    custReligion: new FormControl(),
    custMothersName: new FormControl('', [Validators.required]),
    custSex: new FormControl('', [Validators.required]),    
    custMaritalStatus: new FormControl('', [Validators.required]),

    //New
    custCoupleName: new FormControl(''),
    custCoupleAddress: new FormControl(''),
    custCoupleCountry: new FormControl(''),
    custCoupleProvince: new FormControl(''),
    custCoupleCity: new FormControl(''),
    custCoupleDistricts: new FormControl(''),
    custCoupleWard: new FormControl(''),
    custCouplePostalCode: new FormControl(''),
    custCouplePhone: new FormControl(''),
    custNumberOfDependents: new FormControl('', [Validators.required]),
    custLongWorked: new FormControl(''),
    custProductSpecification: new FormControl(''),
    custSystemSelling: new FormControl(''),
    custSystemSellingOthers: new FormControl(''),
    custWorkingOwner: new FormControl(''),
    custWorkingLandOwner: new FormControl(''),
    custWorkingLandOwnerOthers: new FormControl(''),
    custGrossIncome: new FormControl(''),
    custNetIncome: new FormControl(''),
    //End

    custLastEducation: new FormControl(),
    custNationality: new FormControl('', [Validators.required]),
    custIdentityType: new FormControl('', [Validators.required]),
    custIdentityNumber: new FormControl('', [Validators.required]),
    custvpText: new FormControl('', [Validators.required]),
    custvpDate: new FormControl('', [Validators.required]),
    custCountryOfIssue: new FormControl('', [Validators.required]),
    custIdentityAddress: new FormControl('', [Validators.required]),
    custIdentityCountry: new FormControl('', [Validators.required]),
    custIdentityProvince: new FormControl('', [Validators.required]),
    custIdentityCity: new FormControl('', [Validators.required]),
    custIdentityDistricts: new FormControl('', [Validators.required]),
    custIdentityWard: new FormControl('', [Validators.required]),
    custIdentityPostalCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custDomicileAddress: new FormControl('', [Validators.required]),
    custDomicileCountry: new FormControl('', [Validators.required]),
    custDomicileProvince: new FormControl('', [Validators.required]),
    custDomicileCity: new FormControl('', [Validators.required]),
    custDomicileDistricts: new FormControl('', [Validators.required]),
    custDomicileWard: new FormControl('', [Validators.required]),
    custDomicilePostalCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custStatusOfResidence: new FormControl('', [Validators.required]),
    custLongTimeOccupy: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    custHousePhone: new FormControl('', [Validators.maxLength(15)]),
    custCompanyPhone: new FormControl('', [Validators.maxLength(15)]),
    custCellphone: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    custFax: new FormControl('', [Validators.maxLength(15)]),
    custEmail: new FormControl('', [Validators.email]),
    custTOWDD: new FormControl('', [Validators.required]),
    custTOWText: new FormControl('', [Validators.required]),
    custBusinessField: new FormControl(),
    custPosition: new FormControl(),
    custCompanyName: new FormControl(),
    custCompanyAddress: new FormControl(),
    custCompanyCountry: new FormControl(),
    custCompanyProvince: new FormControl(''),
    custCompanyCity: new FormControl(''),
    custCompanyDistricts: new FormControl(''),
    custCompanyWard: new FormControl(''),
    // custSourceOfFunds: new FormControl(false, [Validators.requiredTrue]),
    // custPurpose: new FormControl('', [Validators.required]),
    custMonthlyIncome: new FormControl('', [Validators.required]),
    custMonthlyAverageTransaction: new FormControl('', [Validators.required]),
    custGuardianFullName: new FormControl(''),
    custGuardianAddress: new FormControl(''),
    custGuardianCountry: new FormControl(''),
    custGuardianProvince: new FormControl(''),
    custGuardianCity: new FormControl(''),
    custGuardianDistricts: new FormControl(''),
    custGuardianWard: new FormControl(''),
    custGuardianPostalCode: new FormControl('', [Validators.maxLength(5)]),
    custGuardianHousePhone: new FormControl('', [Validators.maxLength(15)]),
    custGuardianCellphone: new FormControl('', [Validators.maxLength(15)]),
    custRelationshipDD: new FormControl(''),
    custRelationshipText: new FormControl(''),
    // custSourceOfFunds_Investment: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Others: new FormControl('', [Validators.required]),
    // custPurpose_Investment: new FormControl('', [Validators.required]),
    // custPurpose_Study: new FormControl('', [Validators.required]),
    // custPurpose_Capital: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Income: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Legacy: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Grant: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Lottery: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Couple: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Deposit: new FormControl('', [Validators.required]),
    // custSourceOfFunds_Parent: new FormControl('', [Validators.required]),
    custSourceOfFunds_OthersText: new FormControl('', [Validators.required]),
    // custPurpose_Commerce: new FormControl('', [Validators.required]),
    // custPurpose_Salary: new FormControl('', [Validators.required]),
    // custPurpose_Routine: new FormControl('', [Validators.required]),
    // custPurpose_Others: new FormControl('', [Validators.required]),
    custPurpose_OthersText: new FormControl('', [Validators.required]),
    custNickname: new FormControl(),
    custValid: new FormControl(''),
    custSOF: new FormArray([], [Validators.required]),
    custTemp: new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()
    ]),
    custPurpose: new FormArray([], [Validators.required]),
    custSOFValue: new FormControl(''),
    custPurposeValue: new FormControl(''),
    custCIF: new FormControl(''),
    createdBy: new FormControl(),
  });

  dtAVGTransaction: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  tokenRaja : any;
  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  tempProvinceDomicile : any; tempProvinceCompany : any; tempProvinceGuardian : any;
  tempCityDomicile : any; tempCityCompany : any; tempCityGuardian : any;
  tempDistrictDomicile : any; tempDistrictCompany : any; tempDistrictGuardian : any;
  tempSubdistrictDomicile : any; tempSubdistrictCompany : any; tempSubdistrictGuardian : any;
  tempCityCouple:any; tempDistrictsCouple:any; tempWardCouple:any;
  SOFValue = ""; PurposeValue = "";
  tempCIF:any; newCIF:any;
  arrSOF = [
    {text: "Penghasilan"},
    {text: "Warisan"},
    {text: "Hibah"},
    {text: "Undian"},
    {text: "Dari Suami/Istri"},
    {text: "Bunga Simpanan"},
    {text: "Dari Orang Tua"},  
    {text: "Lain-lain"}
  ];
  arrPurpose = [
    {text: "Investasi"},
    {text: "Pendidikan"},
    {text: "Niaga"},
    {text: "Modal"},
    {text: "Gaji"},
    {text: "Biaya Rutin"},  
    {text: "Lain-lain"}
  ];
  newMemberID:string;


  ngOnInit(): void {

    this.getProvince();
    // GET AVERAGE TRANSACTION
    this.getAVGTransaction();
    // this.getTokenRaja();
    this.custForm.patchValue({
      createdBy : this.ApiConfig.USER_DATA.username
    });

    // Onchange Masa Berlaku
    this.custForm.get("custvpText").valueChanges.subscribe(x => {
      if(x=="Berdasarkan Tanggal"){
        $("#inline-full-validityperiodtext").css("display", "block")
      } else {
        $("#inline-full-validityperiodtext").css("display", "none")
      }
    })

    // Onchange Hubungan Lainnya
    this.custForm.get("custRelationshipDD").valueChanges.subscribe(x => {
      if(x=="Lainnya"){
        $("#inline-full-guardianrelationshiptext").css("display", "block")
      } else {
        $("#inline-full-guardianrelationshiptext").css("display", "none")
      }
    })

    // Onchange Jenis Pekerjaan ke Lainnya
    this.custForm.get("custTOWDD").valueChanges.subscribe(x => {
      if(x=="Lainnya"){
        $("#inline-full-typeofworktext").css("display", "block")
      } else {
        $("#inline-full-typeofworktext").css("display", "none")
      }
    })

    // Onchange Negara Identitas
    this.custForm.get("custIdentityCountry").valueChanges.subscribe(x => {
      $("#inline-full-domicilecountry").val(x);
      this.custDomicileCountry.setValue(x);
      this.custDomicileCountry.markAsTouched();
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custIdentityCity.setValue('');
        this.custIdentityDistricts.setValue('');
        this.custIdentityProvince.setValue('');
        this.custIdentityWard.setValue('');
        this.custIdentityPostalCode.setValue('');
        this.custDomicileCity.setValue('');
        this.custDomicileDistricts.setValue('');
        this.custDomicileProvince.setValue('');
        this.custDomicileWard.setValue('');
        this.custDomicilePostalCode.setValue('');

        this.tempCity=[];
        this.tempCityDomicile=[];
        this.tempDistrict=[];
        this.tempDistrictDomicile=[];
        this.tempSubdistrict=[];
        this.tempSubdistrictDomicile=[];

      }
    })

    // Onchange Negara Perusahaan
    this.custForm.get("custCompanyCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custCompanyCity.setValue('');
        this.custCompanyDistricts.setValue('');
        this.custCompanyProvince.setValue('');
        this.custCompanyWard.setValue('');

              
        this.tempCityCompany=[];        
        this.tempDistrictCompany=[];
        this.tempSubdistrictCompany=[];
      }
    })

    // Onchange Negara Kontak Darurat
    this.custForm.get("custGuardianCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custGuardianCity.setValue('');
        this.custGuardianDistricts.setValue('');
        this.custGuardianProvince.setValue('');
        this.custGuardianWard.setValue('');
        this.custGuardianPostalCode.setValue('');

              
        this.tempCityGuardian=[];        
        this.tempDistrictGuardian=[];
        this.tempSubdistrictGuardian=[];
      }
    })

    // Onchange Negara Domisili
    this.custForm.get("custDomicileCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custDomicileCity.setValue('');
        this.custDomicileDistricts.setValue('');
        this.custDomicileProvince.setValue('');
        this.custDomicileWard.setValue('');
        this.custDomicilePostalCode.setValue('');

              
        this.tempCityDomicile=[];        
        this.tempDistrictDomicile=[];
        this.tempSubdistrictDomicile=[];
      }
    })

    // Onchange Alamat Identitas
    this.custForm.get("custIdentityAddress").valueChanges.subscribe(x => {
      $("#inline-full-domicileaddress").val(x)
      this.custDomicileAddress.setValue(x)
    })

    // Onchange Provinsi Identitas
    this.custForm.get("custIdentityProvince").valueChanges.subscribe(x => {
      $("#inline-full-domicileprovince").val(x)
      this.custDomicileProvince.setValue(x)

      this.custIdentityCity.setValue('');
      this.custIdentityDistricts.setValue('');
      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileCity.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempCity=[];
      this.tempCityDomicile=[];
      this.tempDistrict=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Domisili
    this.custForm.get("custDomicileProvince").valueChanges.subscribe(x => {

      this.custDomicileCity.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempCityDomicile=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Kontak Darurat
    this.custForm.get("custGuardianProvince").valueChanges.subscribe(x => {

      this.custGuardianCity.setValue('');
      this.custGuardianDistricts.setValue('');
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      this.tempCityGuardian=[];
      this.tempDistrictGuardian=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Perusahaan
    this.custForm.get("custCompanyProvince").valueChanges.subscribe(x => {

      this.custCompanyCity.setValue('');
      this.custCompanyDistricts.setValue('');
      this.custCompanyWard.setValue('');

      this.tempCityCompany=[];
      this.tempDistrictCompany=[];
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kota/Kabupaten Identitas
    this.custForm.get("custIdentityCity").valueChanges.subscribe(x => {
      $("#inline-full-domicilecity").val(x);
      this.custDomicileCity.setValue(x);

      this.custIdentityDistricts.setValue('');
      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempDistrict=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Kota/Kabupaten Domisili
    this.custForm.get("custDomicileCity").valueChanges.subscribe(x => {
      if(this.custDomicileProvince.value!=""){
        this.provinceId=this.custDomicileProvince.value;
        this.getCity("custDomicileProvince");
      }

      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempDistrictDomicile=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Kota/Kabupaten Perusahaan
    this.custForm.get("custCompanyCity").valueChanges.subscribe(x => {
      if(this.custCompanyProvince.value!=""){
        this.provinceId=this.custCompanyProvince.value;
        this.getCity("custCompanyProvince");
      }

      this.custCompanyDistricts.setValue('');
      this.custCompanyWard.setValue('');

      this.tempDistrictCompany=[];
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kota/Kabupaten Kontak Darurat
    this.custForm.get("custGuardianCity").valueChanges.subscribe(x => {
      if(this.custGuardianProvince.value!=""){
        this.provinceId=this.custGuardianProvince.value;
        this.getCity("custGuardianProvince");
      }

      this.custGuardianDistricts.setValue('');
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      this.tempDistrictGuardian=[];
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Kecamatan Identitas
    this.custForm.get("custIdentityDistricts").valueChanges.subscribe(x => {
      $("#inline-full-domiciledistricts").val(x)
      this.custDomicileDistricts.setValue(x)

      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Kecamatan Domisili
    this.custForm.get("custDomicileDistricts").valueChanges.subscribe(x => {
      if(this.custDomicileCity.value!=""){
        this.cityId = this.custDomicileCity.value;
        this.getDistrict("custDomicileCity");
      }
      
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Kecamatan Perusahaan
    this.custForm.get("custCompanyDistricts").valueChanges.subscribe(x => {
      // if(this.custCompanyCity.value!=""){
      //   this.cityId = this.custCompanyCity.value;
      //   this.getDistrict("custCompanyCity");
      // }
      
      this.custCompanyWard.setValue('');
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kecamatan Kontak Darurat
    this.custForm.get("custGuardianDistricts").valueChanges.subscribe(x => {
      // if(this.custGuardianCity.value!=""){
      //   this.cityId = this.custGuardianCity.value;
      //   this.getDistrict("custGuardianCity");
      // }
      
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Kelurahan Identitas
    this.custForm.get("custIdentityWard").valueChanges.subscribe(x => {
      $("#inline-full-domicileward").val(x)
      this.custDomicileWard.setValue(x)

      this.custIdentityPostalCode.setValue('');
      this.custDomicilePostalCode.setValue('');
    })

    // Onchange Kelurahan Domisili
    this.custForm.get("custDomicileWard").valueChanges.subscribe(x => {
      if(this.custDomicileDistricts.value!="" && x!=""){
        this.dataDistrict = this.custDomicileDistricts.value;
        this.getSubDistrict("custDomicileDistricts");
      }

      this.custDomicilePostalCode.setValue('');
    })

    // Onchange Kelurahan Perusahaan
    this.custForm.get("custCompanyWard").valueChanges.subscribe(x => {
      // console.log("nilai companyward : " + x);
      // console.log("nilai companydistrict : " + this.custCompanyDistricts.value);
      // if(this.custCompanyDistricts.value!=""){
      //   this.dataDistrict = this.custCompanyDistricts.value;
      //   this.getSubDistrict("custCompanyDistricts");
      // }
    })

    // Onchange Kelurahan Kontak Darurat
    this.custForm.get("custGuardianWard").valueChanges.subscribe(x => {
      // if(this.custGuardianDistricts.value!=""){
      //   this.dataDistrict = this.custGuardianDistricts.value;
      //   this.getSubDistrict("custGuardianDistricts");
      // }

      this.custGuardianPostalCode.setValue('');
    })

    // Onchange Kode Pos Identitas
    this.custForm.get("custIdentityPostalCode").valueChanges.subscribe(x => {
      $("#inline-full-domicilepostalcode").val(x)
      this.custDomicilePostalCode.setValue(x)
    })

    // Onchange Kewarganegaraan
    this.custForm.get("custNationality").valueChanges.subscribe(x => {
      $("#inline-full-coi").val(x)
      this.custCountryOfIssue.setValue(x)
    })

    //New
    // Onchange Provinsi Suami/Istri
    this.custForm.get("custCoupleProvince").valueChanges.subscribe(x => {
      this.custCoupleCity.setValue('');
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
      this.tempCityCouple = [];
      this.tempDistrictsCouple = [];
      this.tempWardCouple = [];
    });

    // Onchange Kota Suami/Istri
    this.custForm.get("custCoupleCity").valueChanges.subscribe(x => {
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
      this.tempDistrictsCouple = [];
      this.tempWardCouple = [];
    });

    // Onchange Kelurahan Suami/Istri
    this.custForm.get("custCoupleDistricts").valueChanges.subscribe(x => {
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
      this.tempWardCouple = [];
    });

    // Onchange Kecamatan Suami/Istri
    this.custForm.get("custCoupleWard").valueChanges.subscribe(x => {
      this.custCouplePostalCode.setValue('');
    });

    //End
    

    this.addCheckboxes_SOF();
    this.addCheckboxes_Purpose();
    // this.getLastCIF();
  }

  //New
  changeMaritalStatus(){
    if(this.custMaritalStatus.value == "Kawin"){
      this.custForm.get('custCoupleAddress').setValidators([Validators.required]);
      this.custForm.get('custCoupleAddress').updateValueAndValidity();
      this.custForm.get('custCoupleCountry').setValidators([Validators.required]);
      this.custForm.get('custCoupleCountry').updateValueAndValidity();
      this.custForm.get('custCoupleName').setValidators([Validators.required]);
      this.custForm.get('custCoupleName').updateValueAndValidity();
      this.custForm.get('custCouplePhone').setValidators([Validators.required]);
      this.custForm.get('custCouplePhone').updateValueAndValidity();

    } else {
      this.custForm.get('custCoupleAddress').clearValidators();
      this.custForm.get('custCoupleAddress').updateValueAndValidity();
      this.custForm.get('custCoupleCountry').clearValidators();
      this.custForm.get('custCoupleCountry').updateValueAndValidity();
      this.custForm.get('custCoupleName').clearValidators();
      this.custForm.get('custCoupleName').updateValueAndValidity();
      this.custForm.get('custCouplePhone').clearValidators();
      this.custForm.get('custCouplePhone').updateValueAndValidity();
      this.custCoupleName.setValue('');
      this.custCoupleAddress.setValue('');
      this.custCoupleCountry.setValue('');
      this.custCoupleProvince.setValue('');
      this.custCoupleCity.setValue('');
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
      this.custCouplePhone.setValue('');
    }
  }

  changeCoupleCountry(){
    if(this.custCoupleCountry.value.toUpperCase() == "INDONESIA"){
      this.custForm.get('custCoupleProvince').setValidators([Validators.required]);
      this.custForm.get('custCoupleCity').setValidators([Validators.required]);
      this.custForm.get('custCoupleDistricts').setValidators([Validators.required]);
      this.custForm.get('custCoupleWard').setValidators([Validators.required]);
      this.custForm.get('custCouplePostalCode').setValidators([Validators.required]);
      this.custForm.get('custCoupleProvince').updateValueAndValidity();
      this.custForm.get('custCoupleCity').updateValueAndValidity();
      this.custForm.get('custCoupleDistricts').updateValueAndValidity();
      this.custForm.get('custCoupleWard').updateValueAndValidity();
      this.custForm.get('custCouplePostalCode').updateValueAndValidity();
    }else{
      this.custForm.get('custCoupleProvince').clearValidators();
      this.custForm.get('custCoupleCity').clearValidators();
      this.custForm.get('custCoupleDistricts').clearValidators();
      this.custForm.get('custCoupleWard').clearValidators();
      this.custForm.get('custCouplePostalCode').clearValidators();
      this.custForm.get('custCoupleProvince').updateValueAndValidity();
      this.custForm.get('custCoupleCity').updateValueAndValidity();
      this.custForm.get('custCoupleDistricts').updateValueAndValidity();
      this.custForm.get('custCoupleWard').updateValueAndValidity();
      this.custForm.get('custCouplePostalCode').updateValueAndValidity();
      this.custCoupleProvince.setValue('');
      this.custCoupleCity.setValue('');
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
    }
  }

  changeWorkType(){
    if(this.custTOWDD.value == "Wiraswasta"){
      // this.custForm.get('custLongWorked').setValidators([Validators.required]);
      this.custForm.get('custProductSpecification').setValidators([Validators.required]);
      this.custForm.get('custSystemSelling').setValidators([Validators.required]);
      this.custForm.get('custWorkingOwner').setValidators([Validators.required]);
      this.custForm.get('custWorkingLandOwner').setValidators([Validators.required]);
      this.custForm.get('custNetIncome').setValidators([Validators.required]);
      this.custForm.get('custGrossIncome').setValidators([Validators.required]);
      // this.custForm.get('custLongWorked').updateValueAndValidity();
      this.custForm.get('custProductSpecification').updateValueAndValidity();
      this.custForm.get('custSystemSelling').updateValueAndValidity();
      this.custForm.get('custWorkingOwner').updateValueAndValidity();
      this.custForm.get('custWorkingLandOwner').updateValueAndValidity();
      this.custForm.get('custNetIncome').updateValueAndValidity();
      this.custForm.get('custGrossIncome').updateValueAndValidity();      
    }else{
      // this.custForm.get('custLongWorked').clearValidators();
      this.custForm.get('custProductSpecification').clearValidators();
      this.custForm.get('custSystemSelling').clearValidators();
      this.custForm.get('custWorkingOwner').clearValidators();
      this.custForm.get('custWorkingLandOwner').clearValidators();
      this.custForm.get('custNetIncome').clearValidators();
      this.custForm.get('custGrossIncome').clearValidators();
      // this.custForm.get('custLongWorked').updateValueAndValidity();
      this.custForm.get('custProductSpecification').updateValueAndValidity();
      this.custForm.get('custSystemSelling').updateValueAndValidity();
      this.custForm.get('custWorkingOwner').updateValueAndValidity();
      this.custForm.get('custWorkingLandOwner').updateValueAndValidity();
      this.custForm.get('custNetIncome').updateValueAndValidity();
      this.custForm.get('custGrossIncome').updateValueAndValidity();
      
      // this.custLongWorked.setValue('');
      this.custProductSpecification.setValue('');
      this.custSystemSelling.setValue('');
      this.custSystemSellingOthers.setValue('');
      this.custWorkingOwner.setValue('');
      this.custWorkingLandOwner.setValue('');
      this.custWorkingLandOwnerOthers.setValue('');
      this.custNetIncome.setValue('');
      this.custGrossIncome.setValue('');
      
    }
  }

  changeSystemSelling(){
    if(this.custSystemSelling.value=="Lainnya"){
      this.custForm.get('custSystemSellingOthers').setValidators([Validators.required]);
      this.custForm.get('custSystemSellingOthers').updateValueAndValidity();
    }else{
      this.custForm.get('custSystemSellingOthers').clearValidators();
      this.custForm.get('custSystemSellingOthers').updateValueAndValidity();
      this.custSystemSellingOthers.setValue('');
    }
  }

  changeWorkingLandOwner(){
    if(this.custWorkingLandOwner.value=="Lainnya"){
      this.custForm.get('custWorkingLandOwnerOthers').setValidators([Validators.required]);
      this.custForm.get('custWorkingLandOwnerOthers').updateValueAndValidity();
    }else{
      this.custForm.get('custWorkingLandOwnerOthers').clearValidators();
      this.custForm.get('custWorkingLandOwnerOthers').updateValueAndValidity();
      this.custWorkingLandOwnerOthers.setValue('');
    }
  }
  //End

  get SOFFormArray() {
    return this.custForm.controls.custSOF as FormArray;
  }

  private addCheckboxes_SOF() {
    this.arrSOF.forEach(() => this.SOFFormArray.push(new FormControl(false)));
  }

  get PurposeFormArray() {
    return this.custForm.controls.custPurpose as FormArray;
  }

  private addCheckboxes_Purpose() {
    this.arrPurpose.forEach(() => this.PurposeFormArray.push(new FormControl(false)));
  }

  getTokenRaja(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.code == "200"){
          this.tokenRaja = this.responseApi.token;
          this.getProvince();
          
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvince(){
    // this.http.get('https://x.rajaapi.com/MeP7c5ne'+this.tokenRaja+'/m/wilayah/provinsi').subscribe(
    //   res => {
    //     this.responseApi = res;
    //     if(this.responseApi.code == "200"){
    //       this.dataProvince = this.responseApi.data;
    //       this.tempProvince = this.dataProvince.map(province => ({
    //         "id": province.id,
    //         "text": province.name,
    //         "additional": province
    //       }));
    //     }  
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );

    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

  onProvinceChange(event: any){  
    // this.custForm.patchValue({
    //   custIdentityProvince: event
    // });
    this.provinceId = this.custForm.controls[event.source.ngControl.name].value;
    this.getCity(event.source.ngControl.name);
  }

  getCity(type:any){       
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        // console.log(this.responseApi);
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;

          if(type == "custIdentityProvince"){
            this.tempCity = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custDomicileProvince"){
            this.tempCityDomicile = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custCompanyProvince"){
            this.tempCityCompany = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custGuardianProvince"){
            this.tempCityGuardian = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          //New
          if(type == "custCoupleProvince"){
            this.tempCityCouple = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          //End
          
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onCityChange(event: any){
    this.cityId = this.custForm.controls[event.source.ngControl.name].value;
    this.getDistrict(event.source.ngControl.name);
  }

  getDistrict(type:any){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;

          if(type == "custIdentityCity"){
            this.tempDistrict = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custDomicileCity"){
            this.tempDistrictDomicile = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custCompanyCity"){
            this.tempDistrictCompany = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custGuardianCity"){
            this.tempDistrictGuardian = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          //New
          if(type == "custCoupleCity"){
            this.tempDistrictsCouple = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          //End
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.districtId = this.custForm.controls[event.source.ngControl.name].value;
    this.getSubDistrict(event.source.ngControl.name);
  }

  getSubDistrict(type:any){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          if(type == "custIdentityDistricts"){
            this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custDomicileDistricts"){
            this.tempSubdistrictDomicile = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custCompanyDistricts"){
            this.tempSubdistrictCompany = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custGuardianDistricts"){
            this.tempSubdistrictGuardian = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          //New
          if(type == "custCoupleDistricts"){
            this.tempWardCouple = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          //End
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubdistrictChange(event: any){
    this.custForm.patchValue({
      custIdentityWard: event
    });
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  getAVGTransaction(){
    this.http.get(this.ApiConfig.API_URL + "/add-cif-individual-average-transaction", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dtAVGTransaction = this.responseApi.data;
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  validateForm() {
    this.SOFValue = "";
    this.PurposeValue = "";
    this.custForm.controls["custValid"].setValue("");
    let skipval_id = ["custIdentityProvince", "custIdentityCity", "custIdentityDistricts", "custIdentityWard", "custIdentityPostalCode"];
    let skipval_domicile = ["custDomicileProvince", "custDomicileCity", "custDomicileDistricts", "custDomicileWard", "custDomicilePostalCode"];
    let skipval_guardian = ["custGuardianProvince", "custGuardianCity", "custGuardianDistricts", "custGuardianWard", "custGuardianPostalCode"];
    const invalid = [];
    const controls = this.custForm.controls;

    for (const name in controls) {
        if (skipval_id.find(function(element){return element == name;}) == name && controls["custIdentityCountry"].value.toUpperCase() != "INDONESIA"){
          continue;
        }
        if (skipval_domicile.find(function(element){return element == name;}) == name && controls["custDomicileCountry"].value.toUpperCase() != "INDONESIA"){
          continue;
        }
        // if (skipval_guardian.find(function(element){return element == name;}) == name && controls["custGuardianCountry"].value.toUpperCase() != "INDONESIA"){
        //   continue;
        // }
        if (name == "custSourceOfFunds_OthersText"){
          if(this.custSOF.value.includes(true)){
            // Sumber Dana Value
            this.custSourceOfFunds_OthersText.markAsUntouched();
            // this.custSourceOfFunds_OthersText.setErrors(null);
            const selectedSOF = this.custForm.value.custSOF
              .map((checked, i) => checked ? this.arrSOF[i].text : null)
              .filter(v => v !== null);
            for(let x = 0; x < selectedSOF.length; x++){
              this.SOFValue = this.SOFValue + selectedSOF[x] + ";";
              if(selectedSOF[x] == "Lain-lain"){
                this.custSourceOfFunds_OthersText.markAsTouched();
                if(this.custSourceOfFunds_OthersText.value==""){
                  this.openAll();
                  controls["custValid"].setValue("TIDAK VALID");
                  return false;
                } else {
                  this.SOFValue = this.SOFValue + this.custSourceOfFunds_OthersText.value + ";";
                }
              }
            }
            if(this.SOFValue==""){
              this.openAll();
              controls["custValid"].setValue("TIDAK VALID");
              return false;
            }
            if(this.custSourceOfFunds_OthersText.untouched){
              continue;
            }
          } else{
            this.custSOF.markAsTouched();
            this.openAll();
            controls["custValid"].setValue("TIDAK VALID");
            return false;
          }
        }
        if (name == "custPurpose_OthersText"){
          if(this.custPurpose.value.includes(true)){
            // Tujuan Pembukaan Rekening Value
            this.custPurpose_OthersText.markAsUntouched();
            const selectedPurpose = this.custForm.value.custPurpose
              .map((checked, i) => checked ? this.arrPurpose[i].text : null)
              .filter(v => v !== null);
            for(let x = 0; x < selectedPurpose.length; x++){
              this.PurposeValue = this.PurposeValue + selectedPurpose[x] + ";";
              if(selectedPurpose[x]== "Lain-lain"){
                this.custPurpose_OthersText.markAsTouched();
                if(this.custPurpose_OthersText.value==""){
                  this.openAll();
                  controls["custValid"].setValue("TIDAK VALID");
                  return false;
                } else {
                  this.PurposeValue = this.PurposeValue + this.custPurpose_OthersText.value + ";";
                }
              }
            }
            if(this.PurposeValue==""){
              this.openAll();
              controls["custValid"].setValue("TIDAK VALID");
              return false;
            }
            if(this.custPurpose_OthersText.untouched){
              continue;
            }
          } else {
            this.custPurpose.markAsTouched();
            this.openAll();
            controls["custValid"].setValue("TIDAK VALID");
            return false;
          }
        }

        if (controls[name].invalid) {
            invalid.push(name);
            // console.log(name);
            // Skip Required Value Berdasarkan Tanggal
            if(name == "custvpDate"){
              if(this.custvpText.value!='Berdasarkan Tanggal'){
                continue;
              }
            }
            // Skip Required Value Jenis Pekerjaan Lainnya
            if(name == "custTOWText"){
              if(this.custTOWDD.value!='Lainnya'){
                continue;
              }
            }
            // Skip Required Value Hubungan Lainnya
            // if(name == "custRelationshipText"){
            //   if(this.custRelationshipDD.value!='Lainnya'){
            //     continue;
            //   }
            // }
            controls["custValid"].setValue("TIDAK VALID");
            this.openAll();
            return false;
        }
    }
    this.custSOFValue.setValue(this.SOFValue);
    this.custPurposeValue.setValue(this.PurposeValue);
    return true;
  }

  getLastCIF(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/last-cif-individual", this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.tempCIF = this.responseApi.data;
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 500);
    });
  }

  getLastMemberID(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/data-member", this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              let arrMember = this.responseApi.data;
              
              if (arrMember.length == 0) {
                this.newMemberID = "CDN-01";
              } else {
                this.newMemberID = (parseInt(arrMember[0].no_member.replace("CDN-", "")) + 1).toString();
                if(this.newMemberID.length == 1){
                  this.newMemberID = "CDN-0" + this.newMemberID;
                } else {
                  this.newMemberID = "CDN-" + this.newMemberID;
                }
              }
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 50);
    });
  }

  callInsertNewMember(CIFNo, MemberNo){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        let tempInsertNewMember = [CIFNo,MemberNo];
        this.http.post(this.ApiConfig.API_URL + "/insert-new-member", tempInsertNewMember, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          resolve(false);
        });
      }, 100);
    });
  }

  tester(){
    this.getLastMemberID().then(res=> this.callInsertNewMember(this.newCIF,this.newMemberID).then(res => true));
  }

  addCust(){

    if(this.validateForm()){
      // INSERT DATA
      this.newCIF="";
      this.getLastCIF().then(res => this.createNewCIF());

      // if(this.tempCIF.length > 0){
      //   if(this.tempCIF[0].cif_id.substring(5,7) == new Date().getFullYear().toString().substr(-2)){
      //     this.newCIF =  this.tempCIF[0].cif_id.substring(0,7) + this.getIncrementNumber(parseInt(this.tempCIF[0].cif_id.substring(7))+1);
      //   } else {
      //     this.newCIF = "CDN-1" + new Date().getFullYear().toString().substr(-2) + "0001";
      //   }
      // } else{
      //   this.newCIF = "CDN-1" + new Date().getFullYear().toString().substr(-2) + "0001";
      // }

      // if(this.newCIF!=""){
      //   this.custCIF.setValue(this.newCIF);
      //   this.http.post(this.ApiConfig.API_URL + "/insert-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
      //     this.responseApi = res;
      //     if (this.responseApi.status == "200") {
      //       Swal.fire("Success", "Your input has been saved.", "success").then(function() {
      //         window.location.href = "/admin/cif-individual";
      //       });
      //     } else {
      //       Swal.fire("Ooops", "There's something wrong happened", "error");
      //     }
      //   }, err => {
      //     Swal.fire("Ooops", "There's something wrong happened", "error");
      //   });
      // }
    }
  }

  createNewCIF(){
    if(this.tempCIF.length > 0){
      if(this.tempCIF[0].cif_id.substring(5,7) == new Date().getFullYear().toString().substr(-2)){
        this.newCIF =  this.tempCIF[0].cif_id.substring(0,7) + this.getIncrementNumber(parseInt(this.tempCIF[0].cif_id.substring(7))+1);
      } else {
        this.newCIF = "CDN-1" + new Date().getFullYear().toString().substr(-2) + "0001";
      }
    } else{
      this.newCIF = "CDN-1" + new Date().getFullYear().toString().substr(-2) + "0001";
    }

    this.getLastMemberID().then(res=> this.callInsertNewMember(this.newCIF, this.newMemberID).then(res=> this.callInsertTempCIF().then(res => this.callInsertCIF()))) ;
  }

  callInsertTempCIF(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        if(this.newCIF!=""){
          this.custTemp.setValue([this.newCIF, 1, "ACTIVE", "PENDING_ADD", "0"]);
          this.http.post(this.ApiConfig.API_URL + "/insert-temp-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
            this.responseApi = res;
            if (this.responseApi.status == "200") {
              resolve(true);
            } else {
              Swal.fire("Ooops", "There's something wrong happened", "error");
              resolve(false);
            }
          }, err => {
            Swal.fire("Ooops", "There's something wrong happened", "error");
            resolve(false);
          });
        }
      }, 100);
    });
  }

  callInsertCIF(){
    if(this.newCIF!=""){
      this.custCIF.setValue(this.newCIF);
      this.http.post(this.ApiConfig.API_URL + "/insert-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success").then(function() {
            window.location.href = "/admin/cif-individual";
          });
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    }
  }

  getIncrementNumber(n){
    let strValue = "" + n;
    let pattern = "0000";
    n = pattern.substring(0, pattern.length - strValue.length) + strValue;
    return n;
  }

  changeSOF(event:any){
    // console.log(event);
    // console.log(event.path[0].checked);
    // console.log(event.path[1].innerText);
    // console.log(this.custForm.get("custSOF").value);
    this.custSOF.markAsTouched();
    // console.log(this.custForm.get("custSOF").value.includes(true));
    if(event.path[0].checked && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-sourceoffunds-other-text").css("display", "block");
    }
    if(!(event.path[0].checked) && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-sourceoffunds-other-text").css("display", "none");
      this.custSourceOfFunds_OthersText.markAsUntouched();
    }

  }

  changePurpose(event:any){
    // console.log(event);
    this.custPurpose.markAsTouched();
    if(event.path[0].checked && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-purpose-others-text").css("display", "block");
    }
    if(!(event.path[0].checked) && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-purpose-others-text").css("display", "none");
      this.custPurpose_OthersText.markAsUntouched();
    }

  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  get custFullName(){return this.custForm.get("custFullName")}
  get custPlaceOfBirth(){return this.custForm.get("custPlaceOfBirth")}
  get custCountryOfBirth(){return this.custForm.get("custCountryOfBirth")}
  get custDateOfBirth(){return this.custForm.get("custDateOfBirth")}
  get custSex(){return this.custForm.get("custSex")}
  get custMothersName(){return this.custForm.get("custMothersName")}
  get custMaritalStatus(){return this.custForm.get("custMaritalStatus")}
  get custNationality(){return this.custForm.get("custNationality")}
  get custIdentityType(){return this.custForm.get("custIdentityType")}
  get custIdentityNumber(){return this.custForm.get("custIdentityNumber")}
  get custvpText(){return this.custForm.get("custvpText")}
  get custvpDate(){return this.custForm.get("custvpDate")}
  get custCountryOfIssue(){return this.custForm.get("custCountryOfIssue")}
  get custIdentityAddress(){return this.custForm.get("custIdentityAddress")}
  get custIdentityCountry(){return this.custForm.get("custIdentityCountry")}
  get custDomicileAddress(){return this.custForm.get("custDomicileAddress")}
  get custDomicileCountry(){return this.custForm.get("custDomicileCountry")}
  get custStatusOfResidence(){return this.custForm.get("custStatusOfResidence")}
  get custLongTimeOccupy(){return this.custForm.get("custLongTimeOccupy")}
  get custCellphone(){return this.custForm.get("custCellphone")}
  get custTOWText(){return this.custForm.get("custTOWText")}
  get custTOWDD(){return this.custForm.get("custTOWDD")}
  get custSourceOfFunds_income(){return this.custForm.get("custSourceOfFunds_income")}
  get custSourceOfFunds_Investment(){return this.custForm.get("custSourceOfFunds_Investment")}
  get custSourceOfFunds_Others(){return this.custForm.get("custSourceOfFunds_Others")}
  get custSourceOfFunds(){return this.custForm.get("custSourceOfFunds")}
  get custPurpose_Investment(){return this.custForm.get("custPurpose_Investment")}
  get custPurpose_Study(){return this.custForm.get("custPurpose_Study")}
  get custPurpose_Capital(){return this.custForm.get("custPurpose_Capital")}
  get custMonthlyIncome(){return this.custForm.get("custMonthlyIncome")}
  get custMonthlyAverageTransaction(){return this.custForm.get("custMonthlyAverageTransaction")}
  get custGuardianFullName(){return this.custForm.get("custGuardianFullName")}
  get custGuardianAddress(){return this.custForm.get("custGuardianAddress")}
  get custGuardianCountry(){return this.custForm.get("custGuardianCountry")}
  get custGuardianCellphone(){return this.custForm.get("custGuardianCellphone")}
  get custRelationshipDD(){return this.custForm.get("custRelationshipDD")}
  get custRelationshipText(){return this.custForm.get("custRelationshipText")}
  get custSourceOfFunds_Income(){return this.custForm.get("custSourceOfFunds_Income")}
  get custSourceOfFunds_Legacy(){return this.custForm.get("custSourceOfFunds_Legacy")}
  get custSourceOfFunds_Grant(){return this.custForm.get("custSourceOfFunds_Grant")}
  get custSourceOfFunds_Lottery(){return this.custForm.get("custSourceOfFunds_Lottery")}
  get custIdentityPostalCode(){return this.custForm.get("custIdentityPostalCode")}
  get custSourceOfFunds_Couple(){return this.custForm.get("custSourceOfFunds_Couple")}
  get custSourceOfFunds_Deposit(){return this.custForm.get("custSourceOfFunds_Deposit")}
  get custSourceOfFunds_Parent(){return this.custForm.get("custSourceOfFunds_Parent")}
  get custSourceOfFunds_OthersText(){return this.custForm.get("custSourceOfFunds_OthersText")}
  get custPurpose_Commerce(){return this.custForm.get("custPurpose_Commerce")}
  get custPurpose_Salary(){return this.custForm.get("custPurpose_Salary")}
  get custPurpose_Routine(){return this.custForm.get("custPurpose_Routine")}
  get custPurpose_Others(){return this.custForm.get("custPurpose_Others")}
  get custPurpose_OthersText(){return this.custForm.get("custPurpose_OthersText")}
  get custDomicileProvince(){return this.custForm.get("custDomicileProvince")}
  get custDomicileCity(){return this.custForm.get("custDomicileCity")}
  get custDomicileDistricts(){return this.custForm.get("custDomicileDistricts")}
  get custDomicileWard(){return this.custForm.get("custDomicileWard")}
  get custDomicilePostalCode(){return this.custForm.get("custDomicilePostalCode")}
  get custIdentityProvince(){return this.custForm.get("custIdentityProvince")}
  get custIdentityCity(){return this.custForm.get("custIdentityCity")}
  get custIdentityDistricts(){return this.custForm.get("custIdentityDistricts")}
  get custIdentityWard(){return this.custForm.get("custIdentityWard")}
  get custEmail(){return this.custForm.get("custEmail")}
  get custGuardianProvince(){return this.custForm.get("custGuardianProvince")}
  get custGuardianCity(){return this.custForm.get("custGuardianCity")}
  get custGuardianDistricts(){return this.custForm.get("custGuardianDistricts")}
  get custGuardianWard(){return this.custForm.get("custGuardianWard")}
  get custGuardianPostalCode(){return this.custForm.get("custGuardianPostalCode")}
  get custValid(){return this.custForm.get("custValid")}
  get custCompanyCountry(){return this.custForm.get("custCompanyCountry")}
  get custSOF(){return this.custForm.get("custSOF")}
  get custPurpose(){return this.custForm.get("custPurpose")}
  get custCompanyCity(){return this.custForm.get("custCompanyCity")}
  get custCompanyDistricts(){return this.custForm.get("custCompanyDistricts")}
  get custCompanyWard(){return this.custForm.get("custCompanyWard")}
  get custCompanyProvince(){return this.custForm.get("custCompanyProvince")}
  get custSOFValue(){return this.custForm.get("custSOFValue")}
  get custPurposeValue(){return this.custForm.get("custPurposeValue")}
  get custCIF(){return this.custForm.get("custCIF")}
  get custTemp(){return this.custForm.get("custTemp")}

  //New
  get custNumberOfDependents(){return this.custForm.get("custNumberOfDependents")}
  get custCoupleAddress(){return this.custForm.get("custCoupleAddress")}
  get custCoupleCountry(){return this.custForm.get("custCoupleCountry")}
  get custCoupleProvince(){return this.custForm.get("custCoupleProvince")}
  get custCoupleCity(){return this.custForm.get("custCoupleCity")}
  get custCoupleDistricts(){return this.custForm.get("custCoupleDistricts")}
  get custCoupleWard(){return this.custForm.get("custCoupleWard")}
  get custCouplePostalCode(){return this.custForm.get("custCouplePostalCode")}
  get custCoupleName(){return this.custForm.get("custCoupleName")}
  get custCouplePhone(){return this.custForm.get("custCouplePhone")}
  get custLongWorked(){return this.custForm.get("custLongWorked")}
  get custProductSpecification(){return this.custForm.get("custProductSpecification")}
  get custSystemSelling(){return this.custForm.get("custSystemSelling")}
  get custSystemSellingOthers(){return this.custForm.get("custSystemSellingOthers")}
  get custWorkingOwner(){return this.custForm.get("custWorkingOwner")}
  get custWorkingLandOwner(){return this.custForm.get("custWorkingLandOwner")}
  get custWorkingLandOwnerOthers(){return this.custForm.get("custWorkingLandOwnerOthers")}
  get custGrossIncome(){return this.custForm.get("custGrossIncome")}
  get custNetIncome(){return this.custForm.get("custNetIncome")}
  //End


}
