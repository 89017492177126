import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.css']
})
export class LoanComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/loan",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'ID',data:'id'},
          {title: 'No. CIF',data:'cif_id'},
          {title: 'Nama',data:'full_name'},
          {
            title: 'Jenis Pinjaman', data: null, render: function (data, type, row) {
              let facility = data.loan_facility == "TL" ? "Term Loan" : "Demand Loan" ; 
              return facility
            } 
          },
          {
            data: null, title: 'Action', defaultContent: "",
            render: function (data, type, row) {
                return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button>';
            }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          // $('.approved-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          $('.view-btn', row).bind('click', () => {
              self.viewRequest(data);
          });
          return row;
      } 
    };
  }//ngOnInit

  deleteData(data, row){  
  }
  editData(data){  
    this.router.navigate(['/admin/edit-loan/' + data.id ]);
  }
  viewRequest(data){  
  }






}
