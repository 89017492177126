import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-add-coa',
  templateUrl: './add-coa.component.html',
  styleUrls: ['./add-coa.component.sass']
})
export class AddCoaComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  coaForm = new FormGroup({
    coaName       : new FormControl('', [Validators.required]),
    coaCode       : new FormControl('', [Validators.required]),
    coaSubtype    : new FormControl('', [Validators.required]),
    normalBalance : new FormControl(1, [Validators.required])
  });

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempCoaSubtype: any;

  ngOnInit(): void {
    this.getCoaSubtype();
  }

  addCoa(){
    this.http.post(this.ApiConfig.API_URL + "/coa", this.coaForm.value, this.httpOptions).subscribe(
      res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil menambahkan COA", "success").then(() => {
              this.router.navigate(['/admin/coa']);
            });
          } else {
            Swal.fire("Maaf", "Anda gagal menambahkan COA", "error")
          }
      },
      err => {
        Swal.fire("Maaf", "Anda gagal menambahkan COA", "error")
      }
    );
  }

  getCoaSubtype(){
    this.http.get(this.ApiConfig.API_URL + "/coa-subtype", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempCoaSubtype = this.responseApi.data.map(coa => ({
            "id"    : coa.id_subtype_coa,
            "text"  : coa.coa_type + '.' + coa.code + ' - ' + coa.coa_subtype_name,
            "additional"  : coa
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  onChangeCoaSubtype(event: any){
    this.coaForm.patchValue({
      coaType : event
    });
  }

}
