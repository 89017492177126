import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-resubmit-cif-individual',
  templateUrl: './resubmit-cif-individual.component.html',
  styleUrls: ['./resubmit-cif-individual.component.sass']
})
export class ResubmitCifIndividualComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/reject-add-customer-individual",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'CIF Perorangan',data:'cif_id'},
          {title: 'Nama Lengkap',data:'full_name'},
          {title: 'Status CIF',data:'status_data'},
          {title: 'Status Pengajuan',data:'status_pengajuan'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-info view-btn">';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.approved-btn', row).unbind('click');
          $('.approved-btn', row).bind('click', () => {
              // self.approvedData(data);
          });
          $('.view-btn', row).bind('click', () => {
            self.viewData(data);
        });
          return row;
      } 
    };
  }

  viewData(data) {
    this.router.navigate(['/admin/form-resubmit-cif-individual/' + data.id ]);
  }

}
