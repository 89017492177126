import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-detail-simpanan-berjangka',
  templateUrl: './detail-simpanan-berjangka.component.html',
  styleUrls: ['./detail-simpanan-berjangka.component.sass']
})
export class DetailSimpananBerjangkaComponent implements OnInit {
  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private globalService: ServicesComponent,
    private route: ActivatedRoute
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  noSimpananBerjangka: any;

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.noSimpananBerjangka = this.route.snapshot.params.id
      this.getSimpananBerjangkaDetail();
    }
  }

  getSimpananBerjangkaDetail() {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/simpanan-berjangka-detail/" + this.noSimpananBerjangka,
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No Simpanan Berjangka', data:'no_simpanan_berjangka'},
          {title: 'No. CIF', data: 'cif_id'},
          {title: 'Start Date', data: 'created_date'},
          {title: 'End Date', data: 'end_date'},    
          {title: 'Status', data: null, render: function(data, type, row){ return data.status == 'completed' ? 'Selesai' : 'On-going' }},       
          {
            title: 'Jatuh Tempo Dalam', 
            data: 'days_duration',
            render: function(data, type, row){
              if(row.status == 'completed') return '-'

              if (data > 30){
                let firstNumber
                let backNumber
                firstNumber = data / 30
                firstNumber = firstNumber.toString().split('.')[0]

                backNumber = data - (firstNumber * 30)
                
                return firstNumber + ' bulan ' + backNumber + ' hari' + ' / ' + data + ' hari'
              }
              else{
                return data + ' hari'
              }
            }
          },          
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  // return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
                  return '<button class="btn btn-info view-btn"></button><button class="btn btn-edit btn-info" title="Detail Simpanan Berjangka"><img src="../../../assets/icons/edit.svg"></button>';
              }
          }
      ],
      order: [],
      rowCallback: (row: Node, data: any | Object, index: number) => {
          const self = this;
          $('.view-btn', row).unbind('click');
          $('.view-btn', row).bind('click', () => {
              self.viewData(data);
          });

          $('.btn-edit', row).unbind('click');
          $('.btn-edit', row).bind('click', () => {
              self.editData(data);
          });
          return row;
      }
    };
  }

  viewData(data) {
    window.open(this.ApiConfig.API_URL + "/view/bilyet-simpanan-berjangka?noSimpananBerjangka=" + data.no_simpanan_berjangka + '-' + data.id, '_blank')
  }

  editData(data){
    this.router.navigate(['/admin/simpanan-berjangka/detail/edit/' + data.id]);
  }
}
