import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-add-general-journal',
  templateUrl: './add-general-journal.component.html',
  styleUrls: ['./add-general-journal.component.sass']
})
export class AddGeneralJournalComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }
  
  generalJournalForm = new FormGroup({
    transactionDate       : new FormControl('', [Validators.required]),
    idStore               : new FormControl('', [Validators.required]),
    coaList               : new FormArray([
      new FormGroup({
        idCoa         : new FormControl('', [Validators.required]),
        debitAmount   : new FormControl(0, [Validators.required]),
        creditAmount  : new FormControl(0, [Validators.required]),
      }),
      new FormGroup({
        idCoa         : new FormControl('', [Validators.required]),
        debitAmount   : new FormControl(0, [Validators.required]),
        creditAmount  : new FormControl(0, [Validators.required]),
      })
    ], [Validators.required]),
    description           : new FormControl('', [Validators.required]),
    createdBy             : new FormControl('', [Validators.required]),
  });

  tempCoaData: any;
  tempStoreData: any;
  responseApi: any;
  isDisabled: boolean = true;
  totalDebit: any = 0; totalCredit: any = 0;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  ngOnInit(): void {
    this.getCoa();
    this.getStore();

    this.generalJournalForm.patchValue({
      createdBy : this.ApiConfig.USER_DATA.id_user,
    });
  }

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempCoaData = this.responseApi.data.map(coa => ({
            "id"    : coa.id_coa,
            "text"  : coa.code + ' - ' + coa.coa_name,
            "additional"  : coa
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  getStore(){
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempStoreData = this.responseApi.data.map(store => ({
            "id"          : store.id_store,
            "text"        : store.id_store + ' - ' + store.store_name,
            "additional"  : store
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  debitSum() {
    this.totalDebit = 0;
    this.generalJournalForm.get('coaList')['controls'].forEach(element => {
      this.totalDebit += (+element.value.debitAmount);
    });
    this.compareValue();
  }

  creditSum() {
    this.totalCredit = 0;
    this.generalJournalForm.get('coaList')['controls'].forEach(element => {
      this.totalCredit += (+element.value.creditAmount);
    });
    this.compareValue();
  }

  compareValue() {
    if (this.totalDebit == this.totalCredit && this.totalDebit != 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
  addRow(){
    const coaList : FormArray = this.generalJournalForm.get('coaList') as FormArray;
    coaList.push(
      new FormGroup({
        idCoa         : new FormControl('', [Validators.required]),
        debitAmount   : new FormControl(0, [Validators.required]),
        creditAmount  : new FormControl(0, [Validators.required]),
      })
    );

    console.log(coaList);
  }

  removeRow(i: any){
    const coaList : FormArray = this.generalJournalForm.get('coaList') as FormArray;
    coaList.removeAt(i);
  }

  onChangeStore(event: any){
    this.generalJournalForm.patchValue({
      idStore : event
    });
  };

  changeCoa(event: any, i: any){
    const coaList : FormArray = this.generalJournalForm.get('coaList') as FormArray;
    coaList.at(i).patchValue({
      idCoa : event
    });
  }

  addJournal(){
    this.http.post(this.ApiConfig.API_URL + "/general-journal", this.generalJournalForm.value, this.httpOptions).subscribe(
      res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil menambahkan Jurnal Umum", "success").then(() => {
              this.router.navigate(['/admin/general-journal']);
            });
          } else {
            Swal.fire("Maaf", "Anda gagal menambahkan Jurnal Umum", "error")
          }
      },
      err => {
        Swal.fire("Maaf", "Anda gagal menambahkan Jurnal Umum", "error")
      }
    );
  }

}
