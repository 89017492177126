import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeLayoutComponent } from './layout/home-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarModule } from './navbar/navbar.module';
import { DataTablesModule } from 'angular-datatables';
import { UserComponent } from './pages/user/user.component';
import { RoleComponent } from './pages/role/role.component';
import { AddUserComponent } from './pages/user/add-user/add-user.component';
import { EditUserComponent } from './pages/user/edit-user/edit-user.component';
import { AddRoleComponent } from './pages/role/add-role/add-role.component';
import { EditRoleComponent } from './pages/role/edit-role/edit-role.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgSelect2Module } from 'ng-select2';
import { CifIndividualComponent } from './pages/customer/cif-individual/cif-individual.component';
import { AddCifIndividualComponent } from './pages/customer/cif-individual/add-cif-individual/add-cif-individual.component';
import { EditCifIndividualComponent } from './pages/customer/cif-individual/edit-cif-individual/edit-cif-individual.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CifCompanyComponent } from './pages/customer/cif-company/cif-company.component';
import { AddCifCompanyComponent } from './pages/customer/cif-company/add-cif-company/add-cif-company.component';
import { EditCifCompanyComponent } from './pages/customer/cif-company/edit-cif-company/edit-cif-company.component';
import { DailySavingsComponent } from './pages/daily-savings/daily-savings.component';
import { AddDailySavingsComponent } from './pages/daily-savings/add-daily-savings/add-daily-savings.component';
import { EditDailySavingsComponent } from './pages/daily-savings/edit-daily-savings/edit-daily-savings.component';
import { PendingDailySavingsComponent } from './pages/daily-savings/pending-daily-savings/pending-daily-savings.component';
import { ViewDailySavingsComponent } from './pages/daily-savings/view-daily-savings/view-daily-savings.component';
import { DetailDailySavingsComponent } from './pages/daily-savings/detail-daily-savings/detail-daily-savings.component';
import { AttachmentCifCompanyComponent } from './pages/customer/cif-company/attachment-cif-company/attachment-cif-company.component';
import { SimpananBerjangkaComponent } from './pages/simpanan-berjangka/simpanan-berjangka.component';
import { AddSimpananBerjangkaComponent } from './pages/simpanan-berjangka/add-simpanan-berjangka/add-simpanan-berjangka.component';
import { EditSimpananBerjangkaComponent } from './pages/simpanan-berjangka/edit-simpanan-berjangka/edit-simpanan-berjangka.component';
import { AntarRekeningComponent } from './pages/transfer-dana/antar-rekening/antar-rekening.component';
import { AddAntarRekeningComponent } from './pages/transfer-dana/antar-rekening/add-antar-rekening/add-antar-rekening.component';
import { ApprovalCifIndividualComponent } from './pages/customer/cif-individual/approval-cif-individual/approval-cif-individual.component';
import { PendingCifIndividualComponent } from './pages/customer/cif-individual/pending-cif-individual/pending-cif-individual.component';
import { ResubmitCifIndividualComponent } from './pages/customer/cif-individual/resubmit-cif-individual/resubmit-cif-individual.component';
import { AddAttachmentComponent } from './pages/customer/cif-company/attachment-cif-company/add-attachment/add-attachment.component';
import { EditAttachmentComponent } from './pages/customer/cif-company/attachment-cif-company/edit-attachment/edit-attachment.component';
import { ServicesComponent } from './services/services.component';
import { PendingSimpananBerjangkaComponent } from './pages/simpanan-berjangka/pending-simpanan-berjangka/pending-simpanan-berjangka.component';
import { ViewSimpananBerjangkaComponent } from './pages/simpanan-berjangka/view-simpanan-berjangka/view-simpanan-berjangka.component';
import { PendingCifCompanyComponent } from './pages/customer/cif-company/pending-cif-company/pending-cif-company.component';
import { ViewCifCompanyComponent } from './pages/customer/cif-company/view-cif-company/view-cif-company.component';
import { FormResubmitCifIndividualComponent } from './pages/customer/cif-individual/form-resubmit-cif-individual/form-resubmit-cif-individual.component';
import { CifCompanyResubmissionComponent } from './pages/customer/cif-company/cif-company-resubmission/cif-company-resubmission.component';
import { EditCompanyResubmissionComponent } from './pages/customer/cif-company/cif-company-resubmission/edit-company-resubmission/edit-company-resubmission.component';
import { LoanComponent } from './pages/loan/loan.component';
import { AddLoanComponent } from './pages/loan/add-loan/add-loan.component';
import { EditLoanComponent } from './pages/loan/edit-loan/edit-loan.component';
import { PendingLoanComponent } from './pages/loan/pending-loan/pending-loan.component';
import { ApprovalLoanComponent } from './pages/loan/approval-loan/approval-loan.component';
import { SimulasiSimpananBerjangkaComponent } from './pages/simpanan-berjangka/simulasi-simpanan-berjangka/simulasi-simpanan-berjangka.component';
import { SimulasiSimpananBerjangkaGrossComponent } from './pages/simpanan-berjangka/simulasi-simpanan-berjangka-gross/simulasi-simpanan-berjangka-gross.component';
import { DialogInsuranceComponent } from './pages/loan/add-loan/dialog-insurance/dialog-insurance.component';
import { ApproveMenuLoanComponent } from './pages/loan/approval-loan/approve-menu-loan/approve-menu-loan.component';
import { DemandLoanComponent } from './pages/demand-loan/demand-loan.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TermLoanComponent } from './pages/term-loan/term-loan.component';
import { DialogTermLoanComponent } from './pages/term-loan/dialog-term-loan/dialog-term-loan.component';
import { TermLoanPaymentComponent } from './pages/term-loan/term-loan-payment/term-loan-payment.component';
import { AddDemandLoanComponent } from './pages/demand-loan/add-demand-loan/add-demand-loan.component';
import { AddPlafonDemandLoanComponent } from './pages/demand-loan/add-plafon-demand-loan/add-plafon-demand-loan.component';
import { DemandLoanPaymentComponent } from './pages/demand-loan/demand-loan-payment/demand-loan-payment.component';
import { AddDepositComponent } from './pages/setoran/add-deposit/add-deposit.component';
import { DialogDemandLoanComponent } from './pages/demand-loan/add-demand-loan/dialog-demand-loan/dialog-demand-loan.component';
import { DetailSimpananBerjangkaComponent } from './pages/simpanan-berjangka/detail-simpanan-berjangka/detail-simpanan-berjangka.component';
import { CoaTypeComponent } from './pages/coa-type/coa-type.component';
import { AddCoaTypeComponent } from './pages/coa-type/add-coa-type/add-coa-type.component';
import { EditCoaTypeComponent } from './pages/coa-type/edit-coa-type/edit-coa-type.component';
import { CoaSubtypeComponent } from './pages/coa-subtype/coa-subtype.component';
import { AddCoaSubtypeComponent } from './pages/coa-subtype/add-coa-subtype/add-coa-subtype.component';
import { EditCoaSubtypeComponent } from './pages/coa-subtype/edit-coa-subtype/edit-coa-subtype.component';
import { CoaComponent } from './pages/coa/coa.component';
import { AddCoaComponent } from './pages/coa/add-coa/add-coa.component';
import { EditCoaComponent } from './pages/coa/edit-coa/edit-coa.component';
import { GeneralJournalComponent } from './pages/general-journal/general-journal.component';
import { AddGeneralJournalComponent } from './pages/general-journal/add-general-journal/add-general-journal.component';
import { IncomeStatementComponent } from './pages/income-statement/income-statement.component';
import { BalanceSheetComponent } from './pages/balance-sheet/balance-sheet.component';
import { GeneralLedgerComponent } from './pages/general-ledger/general-ledger.component';
import { RepaymentMainComponent } from './pages/loan-repayment/repayment-main/repayment-main.component';
import { RepaymentDemandComponent } from './pages/loan-repayment/repayment-demand/repayment-demand.component';
import { RepaymentTermComponent } from './pages/loan-repayment/repayment-term/repayment-term.component';
import { KeluarBankComponent } from './pages/transfer-dana/keluar-bank/keluar-bank.component';
import { AddKeluarBankComponent } from './pages/transfer-dana/keluar-bank/add-keluar-bank/add-keluar-bank.component';
import { FinanceSettingComponent } from './pages/finance-setting/finance-setting.component';
import { AdminOptionComponent } from './pages/admin-option/admin-option.component';
import { EditGeneralJournalComponent } from './pages/general-journal/edit-general-journal/edit-general-journal.component';
import { IndividualComponent } from './pages/individual/individual.component';
import { DialogIndividualComponent } from './pages/individual/dialog-individual/dialog-individual.component';
import { ProjectComponent } from './pages/project/project.component';
import { AddProjectComponent } from './pages/project/add-project/add-project.component';
import { EditProjectComponent } from './pages/project/edit-project/edit-project.component';
import { EditSimpananBerjangkaDetailComponent } from './pages/simpanan-berjangka/edit-simpanan-berjangka-detail/edit-simpanan-berjangka-detail.component';
import { DialogDemandLoanPerpanjangComponent } from './pages/demand-loan/add-demand-loan/dialog-demand-loan-perpanjang/dialog-demand-loan-perpanjang.component';


@NgModule({
  declarations: [
    AppComponent,

    //Base
    LoginComponent,
    HomeLayoutComponent,
    //End Base

    DashboardComponent,
    UserComponent,
    RoleComponent,
    AddUserComponent,
    EditUserComponent,
    AddRoleComponent,
    EditRoleComponent,
    CifIndividualComponent,
    AddCifIndividualComponent,
    EditCifIndividualComponent,
    CifCompanyComponent,
    AddCifCompanyComponent,
    EditCifCompanyComponent,
    DailySavingsComponent,
    AddDailySavingsComponent,
    EditDailySavingsComponent,
    PendingDailySavingsComponent,
    ViewDailySavingsComponent,
    DetailDailySavingsComponent,
    AttachmentCifCompanyComponent,
    SimpananBerjangkaComponent,
    AddSimpananBerjangkaComponent,
    EditSimpananBerjangkaComponent,
    AntarRekeningComponent,
    AddAntarRekeningComponent,
    ApprovalCifIndividualComponent,
    PendingCifIndividualComponent,
    ResubmitCifIndividualComponent,
    AddAttachmentComponent,
    EditAttachmentComponent,
    ServicesComponent,
    PendingSimpananBerjangkaComponent,
    ViewSimpananBerjangkaComponent,
    PendingCifCompanyComponent,
    ViewCifCompanyComponent,
    FormResubmitCifIndividualComponent,
    CifCompanyResubmissionComponent,
    EditCompanyResubmissionComponent,
    LoanComponent,
    AddLoanComponent,
    PendingLoanComponent,
    ApprovalLoanComponent,
    SimulasiSimpananBerjangkaComponent,
    DialogInsuranceComponent,
    ApproveMenuLoanComponent,
    DemandLoanComponent,
    TermLoanComponent,
    DialogTermLoanComponent,
    TermLoanPaymentComponent,
    AddDemandLoanComponent,
    AddPlafonDemandLoanComponent,
    DemandLoanPaymentComponent,
    AddDepositComponent,
    DialogDemandLoanComponent,
    DetailSimpananBerjangkaComponent,
    CoaTypeComponent,
    AddCoaTypeComponent,
    EditCoaTypeComponent,
    CoaSubtypeComponent,
    AddCoaSubtypeComponent,
    EditCoaSubtypeComponent,
    CoaComponent,
    AddCoaComponent,
    EditCoaComponent,
    GeneralJournalComponent,
    AddGeneralJournalComponent,
    IncomeStatementComponent,
    BalanceSheetComponent,
    GeneralLedgerComponent,
    RepaymentMainComponent,
    RepaymentDemandComponent,
    RepaymentTermComponent,
    EditLoanComponent,
    KeluarBankComponent,
    AddKeluarBankComponent,
    FinanceSettingComponent,
    AdminOptionComponent,
    EditGeneralJournalComponent,
    IndividualComponent,
    DialogIndividualComponent,
    ProjectComponent,
    AddProjectComponent,
    EditProjectComponent,
    EditSimpananBerjangkaDetailComponent,
    DialogDemandLoanPerpanjangComponent,
    SimulasiSimpananBerjangkaGrossComponent

  ],
  imports: [
    //Base
    SidebarModule,
    NavbarModule,
    //End Base

    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    NgSelect2Module,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    NgxCleaveDirectiveModule,
    MatCardModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTableModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    CurrencyPipe,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
