import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../utility/ApiConfig';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-daily-savings',
  templateUrl: './daily-savings.component.html',
  styleUrls: ['./daily-savings.component.sass']
})
export class DailySavingsComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {    
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/daily-savings-table/",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'Nomor Rekening',data:'account_number'},
          {title: 'Nama',data:'full_name'},
          {title: 'Nomor Handphone',data:'cellphone'},
          {title: 'Cabang', data: null, defaultContent: '', render: function (data, type, row){ return "Head Office" }},
          {
            title: 'Jenis Rekening', data: null, defaultContent: '', 
            render: function (data, type, row){ 
              return data.account_type == "tabungan-nusantara" ? "Tabungan Nusantara" : (data.account_type == "tabungan-nusantara-bisnis" ? "Tabungan Nusantara Bisnis" : "Tabungan Nusantara Karyawan")
            }
          },
          {title: 'Status', data: null, defaultContent: '', render: function (data, type, row){ return data.approve_status == "APPROVED_ADD" ? 'Approved' : 'Rejected'  }},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-gray view-btn"></button>';
              }
          }
          // {
          //     data: null, title: 'Action', defaultContent: "",
          //     render: function (data, type, row) {
          //         return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button><button class="btn btn-gray view-btn"></button>';
          //     }
          // }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.view-btn', row).unbind('click');
          $('.view-btn', row).bind('click', () => {
            self.viewData(data);
        });
          return row;
      }
    };
  }

  viewData(data){
    this.router.navigate(['/admin/daily-saving/detail/' + data.account_number ]);
  }

  //Job Scheduler Count Interest 15.00 WIB
  test1500PM(){
    console.log(this.ApiConfig.API_URL);
    // this.http.get(this.ApiConfig.API_URL + "/count-interest", this.httpOptions).subscribe(
    //   res => {
    //     this.responseApi = res;
    //     if(this.responseApi.status == "200"){
    //       let arrData = this.responseApi.data;
    //       console.log(arrData);
    //     }
    //   }, 
    //   err => {
    //     console.log(err);
    //   }
    // )
  }
  //End Job Scheduler Count Interest 15.00 WIB
}
