import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import * as moment from 'moment';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-dialog-term-loan',
  templateUrl: './dialog-term-loan.component.html',
  styleUrls: ['./dialog-term-loan.component.css']
})
export class DialogTermLoanComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    private currencyPipe : CurrencyPipe,
    public dialogRef: MatDialogRef<DialogTermLoanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  showTable : boolean = false;
  loanId: any;
  loanData: any;
  masterLoan: any;
  paymentData: any; totalPaid: any; totalRemaining: any; cDate: any;

  ngOnInit(): void {
    this.loanId = this.data;
    this.getPaymentInfo(this.loanId);
  }

  getPaymentInfo(id){
    this.http.get(this.ApiConfig.API_URL + '/term-loan-payment-info/' + id[0] + '/' + id[1], this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.loanData = this.responseApi.data.loan_data;
          this.paymentData = this.responseApi.data.loan_detail;
          this.masterLoan = this.responseApi.data.master_loan;
          this.cDate = moment(this.masterLoan.c_date).format('DD-MM-YYYY');
          this.totalPaid = 0;
          this.paymentData.forEach((e,i,a) => {
            e.status = this.masterLoan.status;
            console.log(e.status);
            if (i == 0){
              e.actRemain = parseFloat(this.loanData.principal);
            } else if (i == (this.paymentData.length - 1)){
              e.actRemain = 0;
            } 
            else {
              if (e.status == 'INACTIVE'){
                e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.paid_principal);
              } else {
                if (e.paid_principal == 0 && e.paid_principal_first == 0){
                  e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.principal);
                } else {
                  e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.paid_principal) - parseFloat(e.paid_principal_first);
                }
              }
                   
            }
            if(this.totalRemaining == undefined && e.remaining_payment == '0.00') {
              this.totalRemaining = a[0].remaining_payment;
            }
            
            e.payment_date = moment(e.payment_date).format('DD/MM/yyyy');
            if (e.late_charge_paid){
              e.total_paid = parseFloat(e.paid_principal) + parseFloat(e.paid_interest) + parseFloat(e.late_charge);
              this.totalPaid += parseFloat(e.paid_principal) + parseFloat(e.paid_interest) + parseFloat(e.late_charge);
            } else {
              e.total_paid = parseFloat(e.paid_principal) + parseFloat(e.paid_interest);
              this.totalPaid += parseFloat(e.paid_principal) + parseFloat(e.paid_interest);
            }
            e.total_due = parseFloat(e.principal) + parseFloat(e.interest);
          });
          this.showTable = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
