import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-pending-cif-individual',
  templateUrl: './pending-cif-individual.component.html',
  styleUrls: ['./pending-cif-individual.component.sass']
})
export class PendingCifIndividualComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/pending-customer-individual",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'CIF Perorangan',data:'cif_id'},
          {title: 'Nama Lengkap',data:'full_name'},
          {title: 'Status CIF',data:'status_data'},
          {title: 'Status Pengajuan',data:'status_pengajuan'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-warning approved-btn"><img src="../../../assets/icons/tick.svg"></button><button class="btn btn-info view-btn" title="Transaksi Cross-Approval">';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.approved-btn', row).unbind('click');
          $('.approved-btn', row).bind('click', () => {
              self.approvedData(data);
          });
          $('.view-btn', row).bind('click', () => {
            self.viewRequest(data);
        });
          return row;
      } 
    };
  }

  approvedData(data){
    this.router.navigate(['/admin/approval-cif-individual/'+data.id ]);
  }

  viewRequest(data){
    let arrData = [data.id, data.cif_id];
    this.http.get(this.ApiConfig.API_URL + "/request-approved-pending-cif-individual/" + arrData, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        let strHTML="";
        let strApproved="";
        let strApprovedContent="";
        if(this.responseApi.status == "200"){
          if(this.responseApi.data.length>0){
            strApproved+= '<div class="grid grid-cols-2 gap-12 md:grid-cols-2">'
            for(let i=0; i<this.responseApi.data.length; i++){
              if(i==0){
                strHTML += "<div><p style='font-size: large;'> REQUEST " + this.responseApi.data[i].status_pengajuan.replace("PENDING_", "") +" BY: </p> <h1 style='color:brown; font-size: xx-large;'> " + this.responseApi.data[i].request + " </h1></div>"
              } else {
                strApprovedContent+= "<div><p style='font-size: large;'> "+ this.responseApi.data[i].status_pengajuan.toUpperCase() +" BY: </p> <h1 style='color:brown; font-size: xx-large;'> " + this.responseApi.data[i].request + " </h1></div>"
              }
            }
            if(strApprovedContent!=""){
              strApproved+=strApprovedContent;
              strApproved+="</div>";
            } else {
              strApproved="<div><h1 style='color:brown; font-size: xx-large;'> BELUM ADA APPROVAL/REJECT </h1></div>";
              // strApproved+="</div>";
            }
            strHTML+="<hr style='padding-top: 1rem;'>";
            strHTML+=strApproved;
          }else{
            strHTML+="<hr style='padding-top: 1rem;'>";
            strHTML+="<div><h1 style='color:brown; font-size: xx-large;'> Tidak Ada Transaksi </h1></div>";
          }
          Swal.fire(
            {
              title: 'Transaksi Cross-Approval',
              icon: 'info',
              html: strHTML
            }
          )
          // console.log(strHTML);
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

}
