import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from '../utility/ApiConfig';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

declare function showNotifMenu(): any;
declare function showDocsMenu(): any;
declare function showSettingsMenu(): any;
declare function toggleBurgerMenu(): any;

@Component({
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    constructor(
        private http: HttpClient, 
        private router: Router,
        private ApiConfig: ApiConfig        
    ) { }

    responseApi: any;
    inboxData: any[] = [];
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
    };

    ngOnInit() {
        
    }

    updateInboxData(){
        this.http.get(this.ApiConfig.API_URL + "/inbox?userId=" + this.ApiConfig.USER_DATA.id_user + "&type=msg&limit" + 10, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
      
              if (this.responseApi.status == "200") {
                this.inboxData = this.responseApi.data;
              }
      
            },
            err => {
              
            }
        );
    }

    toggleNotifMenu() {
        var getDataPromise = new Promise<void>((resolve, reject) => {
            this.http.get(this.ApiConfig.API_URL + "/inbox?userId=" + this.ApiConfig.USER_DATA.id_user + "&type=msg&limit" + 10, this.httpOptions).subscribe(
                res => {
                  this.responseApi = res;
          
                  if (this.responseApi.status == "200") {
                    this.inboxData = this.responseApi.data;
                    resolve();
                  }
          
                },
                err => {
                  reject(err);
                }
            );
        });

        getDataPromise.then(() => {
            showNotifMenu(); 
        }).catch((err) => {
            Swal.fire("An Error Occured", err.error.message, "error");
        });
    }

    readMessage(i: any) {
        this.http.put(this.ApiConfig.API_URL + "/notif/" + this.inboxData[i].id_inbox, {}, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
      
              if (this.responseApi.status == "200") {
                // this.inboxData[i] = this.responseApi.data;
                this.updateInboxData();
              }
            },
            err => {
                Swal.fire("An Error Occured", err.error.message, "error");
            }
        );
        
    }

    toggleSettingsMenu() {
        showSettingsMenu();
    }

    toggleDocsMenu() {
        showDocsMenu();
    }

    logout(){
        this.ApiConfig.clear();
        localStorage.clear();
        this.router.navigate(['/']);
    }

    openSidebar(){
        toggleBurgerMenu();
    }

}
