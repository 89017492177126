import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {
  constructor(
    private http: HttpClient, 
    private route: ActivatedRoute, 
    private router: Router, 
    private ApiConfig: ApiConfig
  ) { }

  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };

  masterMenuData: any[] = [];
  transactionMenuData: any[] = [];
  financeMenuData: any[] = [];
  roleDtlData: any[];

  roleForm = new FormGroup({
    namaRole: new FormControl('', [Validators.required]),
    menuChecked: new FormArray([]),
    createdBy: new FormControl('', [Validators.required]),
    canApprove: new FormControl('', [Validators.required])
  });

  responseApi: any;
  idParam: any;

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;

      this.getRoleDtlData();
    }
  }

  onCheckChange(event){
    const formArray: FormArray = this.roleForm.get('menuChecked') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  onCheckCanApproveChange(event) {
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.roleForm.patchValue({'canApprove': true});
    }
    /* unselected */
    else {
      this.roleForm.patchValue({'canApprove': false});
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getRoleDtlData() {
    this.http.get(this.ApiConfig.API_URL + "/role/" + this.idParam, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.roleDtlData = this.responseApi.data;

          for (var x = 0; x < this.roleDtlData.length; x++) {
            const formArrr: FormArray = this.roleForm.get('menuChecked') as FormArray;

            if (this.roleDtlData[x].checked == "true") {
              formArrr.push(new FormControl(this.roleDtlData[x].id_menu));
            }

            if (this.roleDtlData[x].main_menu_category == "master") {
              this.masterMenuData.push(this.roleDtlData[x]);
            } else if (this.roleDtlData[x].main_menu_category == "transaction") {
              this.transactionMenuData.push(this.roleDtlData[x]);
            } else {
              this.financeMenuData.push(this.roleDtlData[x]);
            }
          }

          this.roleForm.patchValue({
            namaRole: this.responseApi.data[0].role_name,
            createdBy: "Admin",
            canApprove: this.roleDtlData[0]?.can_approve
          });
        }

      },
      err => {
        Swal.fire("Error", err.error.message, "error");
      }
    );
  }

  editRole() {
    this.http.put(this.ApiConfig.API_URL + "/role/" + this.idParam, this.roleForm.value, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Success Update Role Data", "success").then(() => {
            this.router.navigate(['/admin/role']).then(() => {
              window.location.reload();
            });
          });
        }
        else{
          Swal.fire("Failed", "Failed Update Role Data", "error");
        }

      },
      err => {
        Swal.fire("Error", err.error.message, "error");
      }
    );
  }

}
