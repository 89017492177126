import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-simpanan-berjangka-detail',
  templateUrl: './edit-simpanan-berjangka-detail.component.html',
  styleUrls: ['./edit-simpanan-berjangka-detail.component.sass']
})
export class EditSimpananBerjangkaDetailComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    private router: Router,
    private globalService: ServicesComponent,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  cifCustomerData: any;
  cifCompanyData: any;

  tempCifCustomerData: Array<Select2OptionData> = [];
  tempCifCompanyData: Array<Select2OptionData> = [];

  simpananBerjangkaForm: FormGroup = new FormGroup({
    noSimpananBerjangka: new FormControl('', [Validators.required]),
    namaCustomer : new FormControl('', [Validators.required]),
    sukuBunga    : new FormControl(0, [Validators.required]),
    startDate    : new FormControl('', [Validators.required]),
    endDate      : new FormControl('', [Validators.required]),
  });

  showIndividual: any = false;
  showIndividual2: any = false;
  showCompany: any = false;
  idParam: number;

  ngOnInit(): void {
    this.globalService.checkRole("Simpanan Berjangka"); 

    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
    }
    

    // this.simpananBerjangkaForm.patchValue({
    //   createdBy : this.ApiConfig.USER_DATA.username
    // });

    // this.getCifIndividual();
    // this.getCifCompany();
  }

  getCifIndividual(){
    this.http.get(this.ApiConfig.API_URL + "/simpanan-berjangka/get-cif-individual", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.cifCustomerData = this.responseApi.data;
          this.tempCifCustomerData = this.cifCustomerData.map(cifCustomer => ({
            "id": cifCustomer.cif_id,
            "text": cifCustomer.cif_id + ' - ' + cifCustomer.full_name,
            "additional": cifCustomer
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  getCifCompany(){
    this.http.get(this.ApiConfig.API_URL + "/simpanan-berjangka/get-cif-company", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.cifCompanyData = this.responseApi.data;
          this.tempCifCompanyData = this.cifCompanyData.map(cifCompany => ({
            "id": cifCompany.cif_code,
            "text": cifCompany.cif_code + ' - ' + cifCompany.company_name,
            "additional": cifCompany
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  onCifCustomerNumberChange(e){
    this.simpananBerjangkaForm.patchValue({
      cifNumber: e
    });

    this.http.get(this.ApiConfig.API_URL + "/simpanan-berjangka/get-cif-daily-savings?cifCode=" + e, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          console.log(this.responseApi)
          if(this.responseApi.data.length == 0){
            Swal.fire("Ooops", "Cif belum punya simpanan harian", "error");
            this.router.navigate(['/admin/daily-saving']);
          }
          else{
            if(this.simpananBerjangkaForm.value.tipeCustomer == 'individual'){
              let tempData = this.cifCustomerData.find(el => el.cif_id == e)

              this.simpananBerjangkaForm.patchValue({
                noRekPembayaran: this.responseApi.data[0].account_number,
                namaRekPembayaran: tempData.full_name,
                namaCdnPembayaran: tempData.full_name,

                noRekSumberDana: this.responseApi.data[0].account_number,
                namaRekSumberDana: tempData.full_name
              });

              this.getEndingBalance(this.responseApi.data[0].account_number)
            }
            else{
              let tempData = this.cifCompanyData.find(el => el.cif_code == e)

              this.simpananBerjangkaForm.patchValue({
                noRekPembayaran: this.responseApi.data[0].account_number,
                namaRekPembayaran: tempData.company_name,
                namaCdnPembayaran: tempData.company_name,

                noRekSumberDana: this.responseApi.data[0].account_number,
                namaRekSumberDana: tempData.company_name
              });
            }
          }
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  onCifCompanyNumberChange(e){
    this.simpananBerjangkaForm.patchValue({
      cifNumber: e
    });
  }

  tipeCustOnChange(e){
    if(e.target.value == "individual"){
      this.showIndividual = true; 
      this.showCompany = false;
    } 
    if(e.target.value == "company") {
      this.showCompany = true; 
      this.showIndividual = false;
    }
  }

  statusRekeningOnChange(e){
    console.log("qwd", e);
    if(e.target.value == "or" || e.target.value == "and"){
      this.showIndividual2 = true; 
    } 
    else{
      this.showIndividual2 = false;
    }
  }

  addSimpananBerjangka(){
    console.log(this.simpananBerjangkaForm.value);
    if(this.simpananBerjangkaForm.valid){
      let nomPenempatan = Number(this.simpananBerjangkaForm.value.nominalPenempatan.replace(/\./g,''))
      let balanceNow = Number($("#balance").text().split('Rp')[1].replace(/\./g,''))

      if(nomPenempatan > balanceNow) {
        Swal.fire("Error", "Saldo Tidak Cukup", "error");
      } else {
        this.http.post(this.ApiConfig.API_URL + "/simpanan-berjangka", this.simpananBerjangkaForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Success", "Your input has been saved.", "success");
            this.router.navigate(['/admin/simpanan-berjangka']);
          } else {
            Swal.fire("Ooops", "There's something wrong happened", "error");
          }
        }, err => {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        });
      }
    } else {
        this.simpananBerjangkaForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
    }
  }

  getEndingBalance(idParam){
    this.http.get(this.ApiConfig.API_URL + "/ending-balance/" + idParam, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      let amount = 0;
      if(this.responseApi.data.length > 0){
        amount = this.responseApi.data[0].ending_balance;
      }
      $("#balance").html(this.formatRupiah(amount));
    });
  }

  formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  formatCurrency(input, blur) {
    // get input value
    var input_val = input.target.value;
  
    // don't validate empty input
    if (input_val === "") { return; }
  
    // original length
    var original_len = input_val.length;
  
    // initial caret position
    var caret_pos = input.target.selectionStart;

    // remove all non-digits
    input_val = this.formatNumber(input_val);
    input_val = input_val;

    // final formatting
    if (blur === "blur") {
      // input_val += ".00";
      return false;
    }
  
    // send updated string to input
    input.target.value = input_val;

    this.simpananBerjangkaForm.patchValue({
      nominalPenempatan : input_val
    });
  }

  getData(idParam){
    this.http.get(this.ApiConfig.API_URL + "/simpanan-berjangka/detail/" + idParam, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if(this.responseApi.status == '200'){
        this.simpananBerjangkaForm.patchValue({
          namaCustomer: this.responseApi.data.full_name,
          noSimpananBerjangka : this.responseApi.data.no_simpanan_berjangka,
          sukuBunga : this.responseApi.data.final_bunga,
          startDate : moment(this.responseApi.data.start_date).format('YYYY-MM-DD'),
          endDate : moment(this.responseApi.data.end_date).format('YYYY-MM-DD'),
        })
      }
    });
  };

  updateDetail(){
    this.http.put(this.ApiConfig.API_URL + "/simpanan-berjangka/detail/" + this.idParam, this.simpananBerjangkaForm.value, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if(this.responseApi.status == '200'){
        Swal.fire("Success", "Your input has been saved.", "success");
        this.location.back();
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    });
  }


}
