import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import { ServicesComponent } from 'src/app/services/services.component';

declare function setDatatableSearch(): any;
@Component({
  selector: 'app-pending-cif-company',
  templateUrl: './pending-cif-company.component.html',
  styleUrls: ['./pending-cif-company.component.sass']
})
export class PendingCifCompanyComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private globalService : ServicesComponent
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.globalService.checkRole("CIF Company");    
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/pending-cif-company",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No. CIF',data:'cif_code'},
          {title: 'Nama Perusahaan',data:'company_name'},
          {title: 'Status',data:'status_approval'},
          {title: 'Submitted By',data:'submitted_by'},
          {title: 'First Approver',data:'approved_by'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  // return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
                  return '<button class="btn btn-info view-btn">'
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          $('.print2-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          $('.view-btn', row).bind('click', () => {
            self.viewData(data);
        });
          return row;
      }
    };
  }

  deleteData(data, row) {
    this.http.delete(this.ApiConfig.API_URL + "/cif-company/" + data.id,  this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            Swal.fire("Success", this.responseApi.message, "success");
            row.remove();
        } else {
            Swal.fire("Sorry", "An error occured, please try again", "error");
        }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
  }
  
  editData(data) {
    this.router.navigate(['/admin/edit-cif-company/' + data.id ]);
  }

  viewData(data) {
    this.router.navigate(['/admin/view-cif-company/' + data.id ]);
  }

  uploadData(data) {
    this.router.navigate(['/admin/attachment-cif-company/' + data.id ]);
  }

}
