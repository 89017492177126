import { Component, OnInit } from '@angular/core';

// ADD BY YSA
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
declare function setDatatableSearch(): any;
// END

@Component({
  selector: 'app-cif-individual',
  templateUrl: './cif-individual.component.html',
  styleUrls: ['./cif-individual.component.css']
})
export class CifIndividualComponent implements OnInit {

  constructor(
    // ADD BY YSA
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
    // END
  ) {  }
  
  custForm: FormGroup = new FormGroup({
    custTemp: new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()
    ]),
    createdBy: new FormControl(),
  })

  // ADD BY YSA
  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;
  sequence:any;
  // END

  ngOnInit(): void {
    // ADD BY YSA
    this.custForm.patchValue({
      createdBy : this.ApiConfig.USER_DATA.username
    });

    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/customer-individual",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'CIF Perorangan',data:'cif_id'},
          {title: 'Nama Lengkap',data:'full_name'},
          {title: 'Tanggal Lahir',data:'date_of_birth'},
          {title: 'Alamat',data:'domicile_address'},
          {title: 'Email',data:'email'},
          {title: 'Nomor Telepon',data:'cellphone'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button><button class="btn btn-info view-btn" title="Transaksi Cross-Approval">';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          // $('.approved-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          $('.view-btn', row).bind('click', () => {
              self.viewRequest(data);
          });
          return row;
      } 
    };
    // END
  }

  // ADD BY YSA
  deleteData(data, row) {
    Swal.fire({
      text: 'Apakah Anda yakin menonaktifkan data ini?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Setuju',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.value) {
        this.getLastSequenceCIF(data.cif_id).then(res => this.callInsertTemp(data.cif_id).then(res => this.updateDeleteCIF(data.id, row)));
      }
    })
  }

  editData(data) {
    this.router.navigate(['/admin/edit-cif-individual/' + data.id ]);
  }
  // END

  approvedData(data){
    this.router.navigate(['/admin/approval-cif-individual/' + data.id ]);
  }

  updateDeleteCIF(id:any, row:any){
    let arrTemp = [id, "ACTIVE", "PENDING_DELETE"];
    this.http.post(this.ApiConfig.API_URL + "/update-pending-cif-individual/", arrTemp,  this.httpOptions).subscribe((res) => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
          // Swal.fire("Success", this.responseApi.message, "success");
          Swal.fire({
            text: this.responseApi.message,
            icon: 'success',
            confirmButtonText: 'Submit'
          }).then((result) => {
            row.remove();
            window.location.href = "/admin/cif-individual";
          });
      } else {
          Swal.fire("Sorry", "An error occured, please try again", "error");
      }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
  }

  getLastSequenceCIF(cif:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/last-sequence-cif-individual/" + cif, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.sequence = parseInt(this.responseApi.data[0].sequence) + 1;
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 500);
    });
  }

  callInsertTemp(cif:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.custTemp.setValue([cif, this.sequence, "ACTIVE", "PENDING_DELETE", "0"]);
        this.http.post(this.ApiConfig.API_URL + "/copy-temp-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            Swal.fire("Ooops", "There's something wrong happened", "error");
            resolve(false);
          }
        }, err => {
          Swal.fire("Ooops", "There's something wrong happened", "error");
          resolve(false);
        });
      }, 100);
    });
  }

  viewRequest(data){
    let arrData = [data.id, data.cif_id];
    this.http.get(this.ApiConfig.API_URL + "/request-approved-cif-individual/" + arrData, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        let strHTML="";
        let strApproved="";
        let strApprovedContent="";
        if(this.responseApi.status == "200"){
          if(this.responseApi.data.length>0){
            strApproved+= '<div class="grid grid-cols-2 gap-12 md:grid-cols-2">'
            for(let i=0; i<this.responseApi.data.length; i++){
              if(i==0){
                strHTML += "<div><p style='font-size: large;'> REQUEST " + this.responseApi.data[i].status_pengajuan.replace("PENDING_", "") +" BY: </p> <h1 style='color:brown; font-size: xx-large;'> " + this.responseApi.data[i].request + " </h1></div>"
              } else {
                strApprovedContent+= "<div><p style='font-size: large;'> "+ this.responseApi.data[i].status_pengajuan.toUpperCase() +" BY: </p> <h1 style='color:brown; font-size: xx-large;'> " + this.responseApi.data[i].request + " </h1></div>"
              }
            }
            if(strApprovedContent!=""){
              strApproved+=strApprovedContent;
              strApproved+="</div>";
            } else {
              strApproved+="<div><h1 style='color:brown; font-size: xx-large;'> BELUM ADA APPROVAL/REJECT </h1></div>";
              strApproved+="</div>";
            }
            strHTML+="<hr style='padding-top: 1rem;'>";
            strHTML+=strApproved;
          }else{
            strHTML+="<hr style='padding-top: 1rem;'>";
            strHTML+="<div><h1 style='color:brown; font-size: xx-large;'> Tidak Ada Transaksi </h1></div>";
          }
          Swal.fire(
            {
              title: 'Transaksi Cross-Approval',
              icon: 'info',
              html: strHTML
            }
          )
          // console.log(strHTML);
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  get custTemp(){return this.custForm.get("custTemp")}

}
