import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormArray,FormControl, Validators, AbstractControl, CheckboxControlValueAccessor } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ValidationErrors } from '@angular/forms'
import { ServicesComponent } from 'src/app/services/services.component';
import { Observable } from 'rxjs';
import { ReplaySubject } from "rxjs"; 
import { Router } from '@angular/router';
import { createInferTypeNode } from 'typescript';

@Component({
  selector: 'app-add-cif-company',
  templateUrl: './add-cif-company.component.html',
  styleUrls: ['./add-cif-company.component.css']
})

export class AddCifCompanyComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private globalService : ServicesComponent,
    private router: Router
  ) { }

  companyForm: FormGroup = new FormGroup({
    cpCif: new FormControl('', [Validators.required]),
    cpName: new FormControl('', [Validators.required]),
    cpFieldOfWork: new FormControl('', [Validators.required]),
    cpPlaceOfEstablishment: new FormControl('', [Validators.required]),
    cpDeedNumber: new FormControl('', [Validators.required]),
    cpDateOfEstablisment: new FormControl('20210202', [Validators.required]),
    cpDeedIssuedPlace: new FormControl('', [Validators.required]),
    cpDeedIssuedDate: new FormControl('20210202', [Validators.required]),
    cpNPWP: new FormControl('', [Validators.required]),

    cpAddress: new FormControl('', [Validators.required]),
    cpNationality: new FormControl('', [Validators.required]),
    cpProvince: new FormControl('', [Validators.required]),
    cpDistrict: new FormControl('', [Validators.required]),
    cpSubDistrict: new FormControl('', [Validators.required]),
    cpCity: new FormControl('', [Validators.required]),
    cpPostalCode: new FormControl('', [Validators.required]),
    cpOccupiedSince: new FormControl('', [Validators.required]),

    cpPhoneNumber: new FormControl('', [Validators.required]),
    cpFaxNumber: new FormControl(),
    cpEmail: new FormControl('', [Validators.required]),
    cpPicNumber: new FormControl('', [Validators.required]),
    cpHandphoneNumber: new FormControl('', [Validators.required]),

    picName: new FormControl('', [Validators.required]),
    picPosition: new FormControl('', [Validators.required]),
    picIdentityType: new FormControl('', [Validators.required]),
    picIdentityNumber: new FormControl('', [Validators.required]),
    
    cpFundSource: new FormControl('', [Validators.required]),
    cpAccountPurpose: new FormControl('', [Validators.required]),
    cpMonthlyIncome: new FormControl('', [Validators.required]),
    cpAvgTransaction: new FormControl('', [Validators.required]),

    createdBy: new FormControl(),
    documentForm: new FormArray([
      new FormGroup({
        docType: new FormControl('', [Validators.required]),
        docDesc: new FormControl('', [Validators.required]),
        docDate: new FormControl('', [Validators.required]),
        docFileName: new FormControl('', [Validators.required]),
        docFileExt: new FormControl('', [Validators.required]),
        docFileBuffer: new FormControl('', [Validators.required]),
        companyId: new FormControl(),
        createdBy: new FormControl(),
      })
    ], [Validators.required])
  });

  tokenRaja : any;
  errArr : any;
  dataQuestion : any; tempQuestion : any;

  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  tempCIF : any; newCIF : any;
  etcFund : any; etcPurpose : any;
  fundSource : any = 
  [
    {
      name : "Penghasilan",
      checked : false
    },
    {
      name : "Warisan",
      checked : false
    },
    {
      name : "Hibah",
      checked : false
    },
    {
      name : "Undian",
      checked : false
    },
    {
      name : "Dari Suami / Istri",
      checked : false
    },
    {
      name : "Bunga Simpanan",
      checked : false
    },
    {
      name : "Dari Orang Tua",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];
  accountPurpose : any = 
  [
    {
      name : "Investasi",
      checked : false
    },
    {
      name : "Pendidikan",
      checked : false
    },
    {
      name : "Niaga",
      checked : false
    },
    {
      name : "Modal",
      checked : false
    },
    {
      name : "Gaji",
      checked : false
    },
    {
      name : "Biaya Rutin",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  ngOnInit(): void {
    this.globalService.checkRole("CIF Company");
    this.getQuestion();
    this.getProvince();
    this.getLastCIF();
    this.companyForm.patchValue({
      createdBy : this.ApiConfig.USER_DATA.id_user,
    });
  }
  getIncrementNumber(n){
    let strValue = "" + n;
    let pattern = "0000";
    n = pattern.substring(0, pattern.length - strValue.length) + strValue;
    return n;
  }
  getLastCIF(){
    this.http.get(this.ApiConfig.API_URL + "/last-cif-company", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempCIF = this.responseApi.data;
          this.newCIF="";

          if(this.tempCIF.length > 0){
            if(this.tempCIF[0].cif_code.substring(5,7) == new Date().getFullYear().toString().substr(-2)){
              this.newCIF =  this.tempCIF[0].cif_code.substring(0,7) + this.getIncrementNumber(parseInt(this.tempCIF[0].cif_code.substring(7))+1);
            } else {
              this.newCIF = "CDN-2" + new Date().getFullYear().toString().substr(-2) + "0001";
            }
          } else{
            this.newCIF = "CDN-2" + new Date().getFullYear().toString().substr(-2) + "0001";
          }
          this.companyForm.patchValue({
            cpCif : this.newCIF
          });
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  getTokenRaja(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.code == "200"){
          this.tokenRaja = this.responseApi.token;
          this.getProvince();
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvince(){
    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

  onProvinceChange(event: any){
    this.companyForm.patchValue({
      cpProvince: event
    });
    this.provinceId = this.companyForm.controls['cpProvince'].value;
    this.getCity();
  }

  getCity(){
    if(this.provinceId == ""){return};
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;
          this.tempCity = this.dataCity.map(city => ({
            "id": city.code,
            "text": city.name,
            "additional": city
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onCityChange(event: any){
    this.companyForm.patchValue({
      cpCity: event
    });
    this.cityId = this.companyForm.controls['cpCity'].value;
    this.getDistrict();
  }

  getDistrict(){
    if(this.cityId == ""){return};
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;
          this.tempDistrict = this.dataDistrict.map(district => ({
            "id": district.code,
            "text": district.name,
            "additional": district
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.companyForm.patchValue({
      cpDistrict: event
    });
    this.districtId = this.companyForm.controls['cpDistrict'].value;
    this.getSubDistrict();
  }

  getSubDistrict(){
    if(this.districtId == ""){return};
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
            "id": subdistrict.code,
            "text": subdistrict.name,
            "additional": subdistrict
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubdistrictChange(event: any){
    this.companyForm.patchValue({
      cpSubDistrict: event
    });
  }

  getQuestion(){
    this.http.get(this.ApiConfig.API_URL + "/cif-company-question", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.dataQuestion = this.responseApi.data;
        }        
      },
      err => {
        console.log(err);
      }
    );
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  changeFund(e){
    this.fundSource[7].value = this.etcFund;
  }

  changeAccPurpose(e){
    this.accountPurpose[6].value = this.etcPurpose;
  }

  compileCheckbox(){
    let fund = "";
    this.fundSource.forEach((e,i) => {
      if(e.checked && i != 7){
        fund = fund + e.name+";";
      } else if (e.checked && i == 7){
        fund = fund + e.value+";";
      }
    });
    
    let purpose = "";
    this.accountPurpose.forEach((e,i) => {
      if(e.checked && i != 6){
        purpose = purpose + e.name+";";
      } else if (e.checked && i == 6){
        purpose = purpose + e.value+";";
      }
    });
    this.companyForm.patchValue({
      cpFundSource : fund,
      cpAccountPurpose : purpose
    });
  }

  validateForm(){
    let pipe = new DatePipe('en-GB');
    this.companyForm.value.cpDeedIssuedDate = pipe.transform(this.companyForm.value.cpDeedIssuedDate,'yyyyMMdd');
    this.companyForm.value.cpDateOfEstablisment = pipe.transform(this.companyForm.value.cpDateOfEstablisment,'yyyyMMdd');
    let errArr = [];
    Object.keys(this.companyForm.controls).forEach(key => {
      if(this.companyForm.controls[key].errors != null){
        errArr.push(key);
      }
    });
  }

  addCifCompany(){
    this.compileCheckbox();
    this.validateForm();
    // if(1==1){
    if(this.companyForm.valid){
      this.http.post(this.ApiConfig.API_URL + "/cif-company/", this.companyForm.value, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else {
        this.companyForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
    }
  }
  //---------------------------LAMPIRAN DOKUMEN------------------------

  addRow() {
    const documentForm: FormArray = this.companyForm.get('documentForm') as FormArray;
    documentForm.push(new FormGroup({
      docType: new FormControl('', [Validators.required]),
      docDesc: new FormControl('', [Validators.required]),
      docDate: new FormControl('', [Validators.required]),
      docFileName: new FormControl('', [Validators.required]),
      docFileExt: new FormControl('', [Validators.required]),
      docFileBuffer: new FormControl('', [Validators.required]),
      companyId: new FormControl(),
      createdBy: new FormControl(),
    }))
  }

  removeRow(index) {
    const documentForm: FormArray = this.companyForm.get('documentForm') as FormArray;
    documentForm.removeAt(index);
  }

  feFileName : any;

  uploadFile(event,i) {
    let fileName = event.target.files[0].name;
    this.feFileName = fileName;
    let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let name = this.companyForm.value.cpName+" - "+fileName.substring(0,fileName.indexOf("."));
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.companyForm.get('documentForm')['controls'][i].patchValue({
        docFileName : name,
        docFileExt : extension,
        docFileBuffer : base64
      });
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }
}
