import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  constructor(
    private ApiConfig: ApiConfig,
    private router: Router
  ) { }

  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
        url: this.ApiConfig.API_URL + "/role",
        headers: { 'token': this.ApiConfig.USER_DATA.token },
        data: function (data) {
          setDatatableSearch();
          return data;
        }
      },
      columns: [
        {
          title: 'Role Name',
          data: 'role_name'
        },
        {
          data: null, title: 'Action', defaultContent: "",
          render: function (data, type, row) {
            return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button>';
          }
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('.edit-btn', row).unbind('click');
        $('.edit-btn', row).bind('click', () => {
          self.editData(data);
        });
        return row;
      }
    };
  }

  editData(data) {
    this.router.navigate(['/admin/edit-role/' + data.id_role]);
  }

}
