import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-coa-type',
  templateUrl: './coa-type.component.html',
  styleUrls: ['./coa-type.component.sass']
})
export class CoaTypeComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;

  deleteData(data, row) {
    this.http.delete(this.ApiConfig.API_URL + "/tipe-coa/" + data.kode_besar, this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil menghapus Tipe COA", "success");
            row.remove();
        } else {
            Swal.fire("Maaf", "Anda gagal menghapus Tipe COA", "error");
        }
    }, (err) => {
        Swal.fire("Maaf", "Anda gagal menghapus Tipe COA", "error");
    });
  }

  editData(data) {
      this.router.navigate(['/adminlayoutpage/edit-tipe-coa/' + data.kode_besar]);
  }

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/coa-type",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {
              title: 'Kode Tipe COA',
              data: 'code'
          },
          {
              title: 'Tipe COA',
              data: 'coa_type_name'
          },
        //   {
        //       data: null, title: 'Action', defaultContent: "",
        //       render: function (data, type, row) {
        //           return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
        //       }
        //   }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          return row;
      }
    };
  }

}
