import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from '../utility/ApiConfig';
declare function setCollapsible(): any;
declare function closeSidebar(): any;

@Component({
	selector: 'sidebar-cmp',
	templateUrl: 'sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
	constructor(
		private http: HttpClient,
		private router: Router,
		private ApiConfig: ApiConfig
	) { }

	public menuItems: any[];
	public menuItemsChild: any[];

	httpOptions: any = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })
	};
	username = "";
	storeName = "";

	menus: any = {
		parent: "",
		child: ""
	};
	
	showMaster: any;
	showTransaction: any;
	showFinance: any;

	responseApi: any;

	ngOnInit() {
		this.username = this.ApiConfig.get("user-data").username;
		this.storeName = this.ApiConfig.get("user-data").store_name;

		this.showMaster = false;
		this.showTransaction = false;
		this.showFinance = false;

		this.getMenus();

		// Jangan Delete DULU ya !!
		// if(this.userData != ""){
		// 	this.getMenus();
		// 	if (this.userData.tipe_lokasi == "HQ"){
		// 		this.showExtras = true;
		// 	}
		// }
		// else{
		// 	this.storeService.clear();
		// 	this.router.navigate(['/login']);
		// }
	}

	getMenus() {
		this.http.get(this.ApiConfig.API_URL + "/menu-user/" + this.username, this.httpOptions).subscribe(
		  res => {
			this.responseApi = res;

			if (this.responseApi.status == "200") {
				this.menus.parent = this.responseApi.data.parent;
				this.menus.child = this.responseApi.data.child;
				
				for(let i = 0; i < this.menus.parent.length ; i++){
					if(this.menus.parent[i].main_menu_category == "master"){
						this.showMaster = true;
					}
					else if(this.menus.parent[i].main_menu_category == "transaction"){
						this.showTransaction = true;
					}
					else{
						this.showFinance = true;
					}
				}

				setTimeout(function(){
					setCollapsible();
				}, 1000);
			}
		  },
		  err => {
			if(err.status == "401"){
				this.router.navigate(['/login']);
			}
		  }
		);
	}

	doAction(){
		localStorage.removeItem('model-filter');
		localStorage.removeItem('is-reset');
	}
}
