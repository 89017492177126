import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ValidationErrors } from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'app-finance-setting',
  templateUrl: './finance-setting.component.html',
  styleUrls: ['./finance-setting.component.sass']
})
export class FinanceSettingComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private router: Router
  ) { }

  // Initialize Usable Variable
  responseApi   : any;
  httpOptions   : any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  // Initialize Data Variable
  coaData       : any;

  // Initialize Form
  financeForm         : FormGroup = new FormGroup({
    simpananHarian          : new FormControl('', [Validators.required]),
    simpananBerjangka       : new FormControl('', [Validators.required]),
    piutangTerm             : new FormControl('', [Validators.required]),
    piutangDemand           : new FormControl('', [Validators.required]),
    simpananWajib           : new FormControl('', [Validators.required]),
    simpananPokok           : new FormControl('', [Validators.required]),
    uangTitipan             : new FormControl('', [Validators.required]),
    pendapatanUsaha         : new FormControl('', [Validators.required]),
    pendapatanLain          : new FormControl('', [Validators.required]),
    piutangTakTertagih      : new FormControl('', [Validators.required]),
    cadangan                : new FormControl('', [Validators.required]),
    bebanBunga              : new FormControl('', [Validators.required]),

    // Old
    // openAccount             : new FormControl('', [Validators.required]),
    // payInterestAccount      : new FormControl('', [Validators.required]),
    // receiveInterestAccount  : new FormControl('', [Validators.required]),
    // expenseInterestAccount  : new FormControl('', [Validators.required]),
    // receiveFeeAccount       : new FormControl('', [Validators.required]),
    // incomeAccount           : new FormControl('', [Validators.required]),
    // otherIncomeAccount      : new FormControl('', [Validators.required]),
    // payableAccount          : new FormControl('', [Validators.required]),
    // receivableAccount       : new FormControl('', [Validators.required]),  
  });

  ngOnInit(): void {
    this.getCoa();
    this.getSetting();
  }

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.coaData = this.responseApi.data.map(coa => ({
            "id"    : coa.id_coa,
            "text"  : coa.code + ' - ' + coa.coa_name,
            "additional"  : coa
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  onCoaChange(event: any, type: string){
    this.financeForm.patchValue({
      [type] : event
    });
  };

  setFinance(){
    this.http.post(this.ApiConfig.API_URL + "/set-finance", this.financeForm.value, this.httpOptions).subscribe(
      res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil melakukan pengaturan finance", "success").then(() => {
              this.router.navigate(['/admin']);
            });
          } else {
            Swal.fire("Maaf", "Anda gagal melakukan pengaturan finance", "error")
          }
      },
      err => {
        Swal.fire("Maaf", "Anda gagal melakukan pengaturan finance", "error")
      }
    );
  };

  getSetting(){
    this.http.get(this.ApiConfig.API_URL + "/set-finance", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.responseApi.data.forEach((e) => {
            this.financeForm.patchValue({
              [e.finance_code] : e.id_coa
            });
          });
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

}
