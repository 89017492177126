import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ValidationErrors } from '@angular/forms'
import { Router } from '@angular/router';

export interface SelectObject {
  text  : String;
  id    : String;
};

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.sass']
})

export class AddProjectComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private router: Router
  ) { }

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  // Initialize Form
  projectForm         : FormGroup = new FormGroup({
    name              : new FormControl('', [Validators.required]),
    projectDate       : new FormControl('', [Validators.required]),
    nominal           : new FormControl('', [Validators.required]),
    type              : new FormControl('percentage', [Validators.required]),
    typeBagi          : new FormControl('percentage', [Validators.required]),
    status            : new FormControl('pending', []),
    createdBy         : new FormControl(this.ApiConfig.USER_DATA.id_user, [Validators.required]),
    detail            : new FormArray(
      [
        new FormGroup({
          userId  : new FormControl(0, [Validators.required]),
          valueBagi : new FormControl(0, [Validators.required]),
          value   : new FormControl(0, []),
          isPayer : new FormControl(false, [Validators.required]),
          type    : new FormControl('', [Validators.required]),
          typeBagi: new FormControl('', [Validators.required]),
          accountNumber : new FormControl('', [Validators.required]),
          accountName   : new FormControl('', [Validators.required]),
    })], [Validators.required])
  });

  projectType         : SelectObject[] = [{'text' : '%', 'id' : 'percentage'}, {'text' : 'Nominal', 'id' : 'nominal'}];
  userData            : SelectObject[];

  ngOnInit(): void {
    this.getIndividu();
    this.onProjectTypeChange('percentage');
    this.onProjectTypeBagiChange('percentage');
  }

  formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  };

  setFormatRupiah(value, element): void{
    this.projectForm.patchValue({
      [element] : this.formatRupiah(value)
    })
    // this.formatRupiah(element);
  };

  setFormat(index, element, typeData){
    // const value = this.projectForm.get(element).value;
    const type = this.projectForm.get(typeData).value;
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    const value = detailData.at(index).get(element).value;
    if (type == 'percentage'){
      detailData.at(index).patchValue({
        [element] : value > 100 ? 100 : (value < 0 ? 0 : value)
      })
    } else {
      detailData.at(index).patchValue({
        [element] : this.formatRupiah(value)
      })
    }
  }

  checkFocus(value, element){
    this.projectForm.patchValue({
      [element] : Number(value.toString().replace(/[^0-9]+/g,""))
    })
  };

  checkFocusDetail(index, element, typeData){
    const type = this.projectForm.get(typeData).value;
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    const value = detailData.at(index).get(element).value;

    console.log(value);

    console.log(type);
    if (type == 'nominal'){
      detailData.at(index).patchValue({
        [element] : Number(value.toString().replace(/[^0-9]+/g,""))
      })
    }
  }

  addRow() {
    const detailData: FormArray = this.projectForm.get('detail') as FormArray;
    detailData.push(new FormGroup({
      userId  : new FormControl(0, [Validators.required]),
      value   : new FormControl(0, [Validators.required]),
      valueBagi : new FormControl(0, [Validators.required]),
      isPayer : new FormControl(false, [Validators.required]),
      type    : new FormControl(this.projectForm.get('type').value, [Validators.required]),
      typeBagi: new FormControl(this.projectForm.get('typeBagi').value, [Validators.required]),
      accountNumber : new FormControl('', [Validators.required]),
      accountName   : new FormControl('', [Validators.required]),
    }));
  }

  removeRow(index) {
    const detailData: FormArray = this.projectForm.get('detail') as FormArray;
    detailData.removeAt(index);
  }

  getIndividu() : void {
    this.http.get(this.ApiConfig.API_URL + "/individual", this.httpOptions).subscribe(res => {
      this.responseApi = res;
      this.userData = this.responseApi.data.map(u => ({
        "id"    : u.id,
        "text"  : u.id + ' - ' + u.individual_name
      }));
    });
  };

  // Project Type Listener
  onProjectTypeChange(event : String){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    this.projectForm.patchValue({
      type : event
    });

    for (let index = 0; index < detailData.length; index++) {
      const element = detailData.at(index);
      element.patchValue({
        type : event,
        value: 0
      });
    }
  };

  onProjectTypeBagiChange(event : String){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    this.projectForm.patchValue({
      typeBagi : event
    });

    for (let index = 0; index < detailData.length; index++) {
      const element = detailData.at(index);
      element.patchValue({
        typeBagi : event,
        valueBagi: 0
      });
    }
  };

  onUserDataChange(event : String, i : number){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    detailData.at(i).patchValue({
      userId : event
    });
  };

  checkValidityForm(): number {
    let totalValue = 0;
    let countPayer = 0;
    const totalProject = Number(this.projectForm.get('nominal').value.replace(/[^0-9]+/g,""));
    const type = this.projectForm.get('type').value;
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    if (this.projectForm.valid){
      for (let index = 0; index < detailData.length; index++) {
        const element = detailData.at(index);
        totalValue += Number(element.get('value').value.toString().replace(/[^0-9]+/g,""));
        if (element.get('isPayer').value == true){
          countPayer++;
        }
      };

      if (countPayer == 0){
        return 202;
      }

      if (type == 'percentage'){
        return ((totalValue / 100) == 1 ? 200 : 201);
      } else {
        return (totalProject == totalValue ? 200 : 201);
      }



    } else {
      return 500;
    }
  }

  addProject(){
    const returnValue = this.checkValidityForm();
    if (returnValue == 200){
      this.http.post(this.ApiConfig.API_URL + "/project", this.projectForm.value , this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/project']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, (err) => {
        this.projectForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
      });
    } else if (returnValue == 201) {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Jumlah Total Project tidak sesuai", "error");
    } else if (returnValue == 202) {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Tidak terdapat Payer", "error");
    } else {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Harap Lengkapi Form", "error");
    }

  }
}
