import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-admin-option',
  templateUrl: './admin-option.component.html',
  styleUrls: ['./admin-option.component.sass']
})
export class AdminOptionComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  adminForm = new FormGroup({
    coaName       : new FormControl('', [Validators.required]),
    coaCode       : new FormControl('', [Validators.required]),
    coa           : new FormControl('', [Validators.required]),
    type          : new FormControl('open-account', [Validators.required])
  });

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempCoa: any;

  ngOnInit(): void {
    // this.getCoaSubtype();
    this.getCoa();
  }

  addCoa(){
    if (this.adminForm.get('type').value == 'pembagian-bunga'){
      this.http.get(this.ApiConfig.API_URL + "/check-simpanan-berjangka-jatuh-tempo", this.httpOptions).subscribe(
        res => {
            this.responseApi = res;
            // console.log(this.responseApi);
            if (this.responseApi.status == "200") {
              Swal.fire("Selamat", "Anda berhasil Melakukan Input", "success").then(() => {
                this.router.navigate(['/admin/admin-option']);
              });
            } else {
              Swal.fire("Maaf", "Anda gagal Melakukan Input", "error")
            }
        },
        err => {
          Swal.fire("Maaf", "Anda gagal Melakukan Input", "error")
        }
      );
    } else{
      this.http.post(this.ApiConfig.API_URL + "/backdate/" + this.adminForm.get('type').value, this.adminForm.value, this.httpOptions).subscribe(
        res => {
            this.responseApi = res;
            // console.log(this.responseApi);
            if (this.responseApi.status == "200") {
              Swal.fire("Selamat", "Anda berhasil Melakukan Input", "success").then(() => {
                this.router.navigate(['/admin/admin-option']);
              });
            } else {
              Swal.fire("Maaf", "Anda gagal Melakukan Input", "error")
            }
        },
        err => {
          Swal.fire("Maaf", "Anda gagal Melakukan Input", "error")
        }
      );
    }
  }

  // addCoa(){
  //   this.http.post(this.ApiConfig.API_URL + "/admin-cost", this.adminForm.value, this.httpOptions).subscribe(
  //     res => {
  //         this.responseApi = res;
  //         console.log(this.responseApi);
  //         // if (this.responseApi.status == "200") {
  //         //   Swal.fire("Selamat", "Anda berhasil menambahkan COA", "success").then(() => {
  //         //     this.router.navigate(['/admin/admin-option']);
  //         //   });
  //         // } else {
  //         //   Swal.fire("Maaf", "Anda gagal menambahkan COA", "error")
  //         // }
  //     },
  //     err => {
  //       Swal.fire("Maaf", "Anda gagal menambahkan COA", "error")
  //     }
  //   );
  // }


  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempCoa = this.responseApi.data.map(coa => ({
            "id"    : coa.id_coa,
            "text"  : coa.code + ' - ' + coa.coa_name,
            "additional"  : coa
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  onChangeCoa(event: any){
    this.adminForm.patchValue({
      coaType : event
    });
  };

}
