import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ValidationErrors } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

export interface SelectObject {
  text  : String;
  id    : String;
};

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.sass']
})
export class EditProjectComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  idParam: any;

  // Initialize Form
  projectForm         : FormGroup = new FormGroup({
    name              : new FormControl('', [Validators.required]),
    projectDate       : new FormControl('', [Validators.required]),
    nominal           : new FormControl('', []),
    type              : new FormControl('percentage', [Validators.required]),
    typeBagi          : new FormControl('percentage', [Validators.required]),
    status            : new FormControl('pending', []),
    createdBy         : new FormControl(this.ApiConfig.USER_DATA.id_user, [Validators.required]),
    detail            : new FormArray(
      [], [Validators.required])
  });

  projectType         : SelectObject[] = [{'text' : '%', 'id' : 'percentage'}, {'text' : 'Nominal', 'id' : 'nominal'}];
  projectStatus       : SelectObject[] = [{'text' : 'Pending', 'id' : 'pending'}, {'text' : 'Complete', 'id' : 'complete'}];
  userData            : SelectObject[];

  ngOnInit(): void {
    // this.getCoaSubtype();
    
    // this.onProjectTypeChange('percentage');
    if (this.route.snapshot.params.id) {
      this.getIndividu();
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
    }
    
    
  }

  formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  };

  setFormatRupiah(value, element): void{
    this.projectForm.patchValue({
      [element] : this.formatRupiah(value)
    })
    // this.formatRupiah(element);
  };

  setFormat(index, element, typeData){
    // const value = this.projectForm.get(element).value;
    const type = this.projectForm.get(typeData).value;
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    const value = detailData.at(index).get(element).value;
    if (type == 'percentage'){
      detailData.at(index).patchValue({
        [element] : value > 100 ? 100 : (value < 0 ? 0 : value)
      })
    } else {
      detailData.at(index).patchValue({
        [element] : this.formatRupiah(value)
      })
    }
  }

  checkFocus(value, element){
    this.projectForm.patchValue({
      [element] : Number(value.replace(/[^0-9]+/g,""))
    })
  };

  checkFocusDetail(index, element, typeData){
    const type = this.projectForm.get(typeData).value;
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    const value = detailData.at(index).get(element).value;

    if (type == 'nominal'){
      detailData.at(index).patchValue({
        [element] : Number(value.toString().replace(/[^0-9]+/g,""))
      })
    }
  }

  addRow() {
    const detailData: FormArray = this.projectForm.get('detail') as FormArray;
    detailData.push(new FormGroup({
      userId  : new FormControl(0, [Validators.required]),
      value   : new FormControl(0, [Validators.required]),
      valueBagi : new FormControl(0, [Validators.required]),
      isPayer : new FormControl(false, [Validators.required]),
      type    : new FormControl(this.projectForm.get('type').value, [Validators.required]),
      typeBagi: new FormControl(this.projectForm.get('typeBagi').value, [Validators.required]),
      accountNumber : new FormControl('', [Validators.required]),
      accountName   : new FormControl('', [Validators.required]),
    }));
  }

  removeRow(index) {
    const detailData: FormArray = this.projectForm.get('detail') as FormArray;
    detailData.removeAt(index);
  }

  getIndividu() : void {
    const projectDetail : FormArray = this.projectForm.get('detail') as FormArray;
    this.http.get(this.ApiConfig.API_URL + "/individual", this.httpOptions).subscribe(res => {
      this.responseApi = res;
      this.userData = this.responseApi.data.map(u => ({
        "id"    : u.id,
        "text"  : u.id + ' - ' + u.individual_name
      }));
    });
  };

  // Project Type Listener
  onProjectTypeChange(event : String){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    this.projectForm.patchValue({
      type : event
    });

    for (let index = 0; index < detailData.length; index++) {
      const element = detailData.at(0);
      element.patchValue({
        type : event
      });
    }
  };

  onProjectTypeBagiChange(event : String){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    this.projectForm.patchValue({
      typeBagi : event
    });

    for (let index = 0; index < detailData.length; index++) {
      const element = detailData.at(index);
      element.patchValue({
        typeBagi : event,
        valueBagi: 0
      });
    }
  };

  // Project Status Listener
  onProjectStatusChange(event : String){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    this.projectForm.patchValue({
      status : event
    });
  };

  onUserDataChange(event : String, i : number){
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    detailData.at(i).patchValue({
      userId : event
    });
  };

  checkValidityForm(): number {
    let totalValue = 0;
    let countPayer = 0;
    const totalProject = Number(this.projectForm.get('nominal').value.replace(/[^0-9]+/g,""));
    const type = this.projectForm.get('type').value;
    const detailData : FormArray = this.projectForm.get('detail') as FormArray;
    if (this.projectForm.valid){
      for (let index = 0; index < detailData.length; index++) {
        const element = detailData.at(index);
        totalValue += Number(element.get('value').value.toString().replace(/[^0-9]+/g,""));
        if (element.get('isPayer').value == true){
          countPayer++;
        }
      };

      if (countPayer == 0){
        return 202;
      }

      if (type == 'percentage'){
        return ((totalValue / 100) == 1 ? 200 : 201);
      } else {
        return (totalProject == totalValue ? 200 : 201);
      }
    } else {
      return 500;
    }
  }


  editProject(){
    const returnValue = this.checkValidityForm();
    if (returnValue == 200){
      this.http.put(this.ApiConfig.API_URL + "/project/" + this.idParam, this.projectForm.value , this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/project']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, (err) => {
        this.projectForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
      });
    } else if (returnValue == 201) {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Jumlah Total Project tidak sesuai", "error");
    } else if (returnValue == 202) {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Tidak terdapat Payer", "error");
    } else {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Harap Lengkapi Form", "error");
    }

  }

  getData(id: number){
    const projectForm : FormGroup = this.projectForm;
    const projectDetail : FormArray = this.projectForm.get('detail') as FormArray;


    this.http.get(this.ApiConfig.API_URL + "/project/" + id, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        projectForm.patchValue({
          name          : this.responseApi.data[0].name,
          projectDate   : moment(this.responseApi.data[0].project_date).format('YYYY-MM-DD'),
          nominal       : this.responseApi.data[0].nominal,
          type          : this.responseApi.data[0].type,
          typeBagi      : this.responseApi.data[0].type_bagi,
          status        : this.responseApi.data[0].status,
          createdBy     : this.responseApi.data[0].created_by
        });

        for (let index = 0; index < this.responseApi.data.length; index++) {
          projectDetail.push(new FormGroup({
            userId        : new FormControl(this.responseApi.data[index].user_id, [Validators.required]),
            value         : new FormControl(this.responseApi.data[index].value, [Validators.required]),
            valueBagi     : new FormControl(this.responseApi.data[index].value_bagi, [Validators.required]),
            isPayer       : new FormControl(this.responseApi.data[index].is_payer, [Validators.required]),
            type          : new FormControl(this.responseApi.data[index].type, [Validators.required]),
            typeBagi      : new FormControl(this.responseApi.data[index].type_bagi, [Validators.required]),
            accountNumber : new FormControl(this.responseApi.data[index].account_number, [Validators.required]),
            accountName   : new FormControl(this.responseApi.data[index].account_name, [Validators.required]),
          }));

          this.setFormat(index, 'value', 'type');
          this.setFormat(index, 'valueBagi', 'typeBagi');
        }

        this.setFormatRupiah(this.responseApi.data[0].nominal, 'nominal');
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, (err) => {
      this.projectForm.markAllAsTouched();
      Swal.fire("Error", "Harap Lengkapi Form", "error");
    });
    
  }

}
