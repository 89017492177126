import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  constructor(
    private http: HttpClient, 
    private ApiConfig: ApiConfig, 
    private router: Router
  ) { }

  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };

  masterMenuData: any[] = [];
  transactionMenuData: any[] = [];
  financeMenuData: any[] = [];

  roleForm = new FormGroup({
    namaRole: new FormControl('', [Validators.required]),
    levelRole: new FormControl('5', [Validators.required]),
    menuChecked: new FormArray([]),
    createdBy: new FormControl('', [Validators.required]),
    canApprove: new FormControl('', [Validators.required]),
  });

  responseApi: any;

  ngOnInit(): void {
    this.roleForm.patchValue({
      createdBy: "Admin"
    });

    this.getMenuList();
  }

  getMenuList() {
    this.http.get(this.ApiConfig.API_URL + "/menu-list", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          this.responseApi.data.forEach(element => {
            if (element.main_menu_category == "master") {
              this.masterMenuData.push(element);
            } else if (element.main_menu_category == "transaction") {
              this.transactionMenuData.push(element);
            } else {
              this.financeMenuData.push(element);
            }
          });
        }

      },
      err => {
        
      }
    );
  }

  onCheckChange(event) {
    const formArray: FormArray = this.roleForm.get('menuChecked') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  onCheckCanApproveChange(event) {
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.roleForm.patchValue({'canApprove': true});
    }
    /* unselected */
    else {
      this.roleForm.patchValue({'canApprove': false});
    }
  }

  addRole() {
    this.http.post(this.ApiConfig.API_URL + "/role", this.roleForm.value, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;

        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Success Create Role Data", "success");
          this.router.navigate(['/admin/role']);
        }
        else{
          Swal.fire("Failed", "Failed Create Role Data", "error");
        }

      },
      err => {
        
      }
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


}
