import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-simpanan-berjangka',
  templateUrl: './edit-simpanan-berjangka.component.html',
  styleUrls: ['./edit-simpanan-berjangka.component.sass']
})
export class EditSimpananBerjangkaComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    private router: Router,
    private route: ActivatedRoute,
    private globalService: ServicesComponent
  ) { }

  idParam: any;
  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  simpananBerjangkaForm: FormGroup = new FormGroup({
    tipeCustomer: new FormControl('', [Validators.required]),
    cifNumber: new FormControl('', [Validators.required]),
    jenisNasabah: new FormControl('', [Validators.required]),

    mataUang: new FormControl('', [Validators.required]),
    statusRekening: new FormControl('', [Validators.required]),
    jangkaWaktu: new FormControl('', [Validators.required]),
    instruksiJatuhTempo: new FormControl('', [Validators.required]),

    noRekPembayaran: new FormControl('', [Validators.required]),
    namaRekPembayaran: new FormControl('', [Validators.required]),
    namaCdnPembayaran: new FormControl('', [Validators.required]),

    tanggalValuta: new FormControl('', [Validators.required]),
    sukuBunga: new FormControl('', [Validators.required]),
    nominalPenempatan: new FormControl(),
    terbilang: new FormControl('', [Validators.required]),

    noRekSumberDana: new FormControl('', [Validators.required]),
    namaRekSumberDana: new FormControl('', [Validators.required]),
    jenisRekSumberDana: new FormControl('', [Validators.required]),
    cabangSumberDana: new FormControl('', [Validators.required]),

    createdBy: new FormControl(),
  });

  showIndividual: any = false;
  showCompany: any = false;

  ngOnInit(): void {
    this.globalService.checkRole("Simpanan Berjangka"); 

    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getSimpananBerjangkaData(this.idParam);
    }
  }

  tipeCustOnChange(e){
    if(e.target.value == "individual"){
      this.showIndividual = true; 
      this.showCompany = false;
    } 
    if(e.target.value == "company") {
      this.showCompany = true; 
      this.showIndividual = false;
    }
  }

  getSimpananBerjangkaData(id){
    this.http.get(this.ApiConfig.API_URL + '/simpanan-berjangka/' + id, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.simpananBerjangkaForm.patchValue({
            tipeCustomer: this.responseApi.data.cif_type,
            cifNumber: this.responseApi.data.cif_id,
            jenisNasabah: this.responseApi.data.jenis_nasabah,
      
            mataUang: this.responseApi.data.mata_uang,
            statusRekening: this.responseApi.data.status_rekening,
            jangkaWaktu: this.responseApi.data.jangka_waktu,
            instruksiJatuhTempo: this.responseApi.data.instruksi_jatuh_tempo,
      
            noRekPembayaran: this.responseApi.data.no_rekening_pembayaran,
            namaRekPembayaran: this.responseApi.data.nama_rekening_pembayaran,
            namaCdnPembayaran: this.responseApi.data.nama_pada_cdn_pembayaran,
      
            tanggalValuta: this.responseApi.data.tanggal_valuta,
            sukuBunga: this.responseApi.data.suku_bunga,
            nominalPenempatan: this.responseApi.data.nominal_penempatan,
            terbilang: this.responseApi.data.terbilang,
      
            noRekSumberDana: this.responseApi.data.no_rekening_sumberdana,
            namaRekSumberDana: this.responseApi.data.nama_rekening_sumberdana,
            jenisRekSumberDana: this.responseApi.data.jenis_rekening_sumberdana,
            cabangSumberDana: this.responseApi.data.cabang_rekening_sumberdana,
          });

          if(this.simpananBerjangkaForm.value.tipeCustomer == "individual"){
            this.showIndividual = true; 
            this.showCompany = false;
          }
          else{
            this.showCompany = true; 
            this.showIndividual = false;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editSimpananBerjangka(){
    console.log(this.simpananBerjangkaForm.value);
    if(this.simpananBerjangkaForm.valid){
      this.http.put(this.ApiConfig.API_URL + "/simpanan-berjangka/" + this.idParam, this.simpananBerjangkaForm.value, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your edit has been saved.", "success");
          this.router.navigate(['/admin/simpanan-berjangka']);

        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else {
        this.simpananBerjangkaForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
    }
  }

}
