import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import Swal from 'sweetalert2';
import { ApiConfig } from '../utility/ApiConfig';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ApiConfig: ApiConfig
  ) { }

  formLogin = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    userPassword: new FormControl('', [Validators.required]),
  });

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  hide = true;

  responseApi: any;

  ngOnInit(): void {
  
  }

  loginSubmit() {
    this.http.post(this.ApiConfig.API_URL + "/login", this.formLogin.value, this.httpOptions).subscribe((res) => {
      this.responseApi = res;
      
      if (this.responseApi.status == "200") {
        var setDataPromise = new Promise<void>((resolve) => {
          this.ApiConfig.set('user-data', this.responseApi.data);
          resolve();
        });

        setDataPromise.then(() => {
          // this.router.navigate(['/admin']);
          window.location.href="/admin";
        });
        
      } else {
        Swal.fire("Ooops", "Incorrect Password / Username", "error");
      }
    },err => {
      Swal.fire("Ooops", "Incorrect Password / Username", "error");
		});
  }

}
