import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { ValidationErrors } from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-daily-savings',
  templateUrl: './add-daily-savings.component.html',
  styleUrls: ['./add-daily-savings.component.sass']
})
export class AddDailySavingsComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;
  @ViewChild('cif2') cif2;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private router: Router
  ) { }

  // Initialize Usable Variable
  responseApi   : any;
  httpOptions   : any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  singleAccount : boolean = true;

  // Initialize Options
  accountType     : object[] = [{'text' : 'Tabungan Nusantara', 'id' : 'tabungan-nusantara'}, {'text' : 'Tabungan Nusantara Bisnis', 'id' : 'tabungan-nusantara-bisnis'}, {'text' : 'Tabungan Nusantara Karyawan', 'id' : 'tabungan-nusantara-karyawan'}];
  accountStatus   : object[] = [{'text' : 'Tunggal', 'id' : 'tunggal'}, {'text' : 'Gabungan "OR"', 'id' : 'gabungan-or'}, {'text' : 'Gabungan "AND"', 'id' : 'gabungan-and'}, {'text' : 'Atas nama "QQ"', 'id' : 'atas-nama'}];
  accountFacility : object[] = [{'text' : 'Buku Tabungan', 'id' : 'buku-tabungan'}, {'text' : 'Rekening Koran', 'id' : 'rekening-koran'}];

  // Data Sample

  dummyDataStore    : any = [{'name' : 'Head Office', 'id' : '1'}, {'name' : 'Cabang 1', 'id' : '2'}];
  dummyDataCustomer : any = [{'name' : 'Ivan', 'id' : '00000001'}, {'name' : 'Angga', 'id' : '00000002'}];

  // Initialize Select2 Component

  dataStore: any; tempStore: any;
  dataCustomer: any; tempCustomer: any;

  // Initialize Form

  accountForm         : FormGroup = new FormGroup({
    storeId           : new FormControl('', [Validators.required]),
    cifFirst          : new FormControl('', [Validators.required]),
    cifSecond         : new FormControl('', []),
    nameFirst         : new FormControl('', [Validators.required]),
    nameSecond        : new FormControl('', []),
    address           : new FormControl('', [Validators.required]),
    accountType       : new FormControl('', [Validators.required]),
    accountStatus     : new FormControl('', [Validators.required]),
    accountFacility   : new FormControl([], [Validators.required]),
    accountReferral   : new FormControl('none', [Validators.required]),
    createdBy         : new FormControl('', [Validators.required]),   
    monthly_interest  : new FormControl('', [Validators.required]),   
  });

  ngOnInit(): void {
    this.getCIF();
    // console.log(this.ApiConfig.USER_DATA);

    this.accountForm.patchValue({
      createdBy : this.ApiConfig.USER_DATA.id_user
    });

    
  }

  // Reusable Function

  getByValue(arr, value) {
    for (var i=0, iLen=arr.length; i<iLen; i++) {
      if (arr[i].id == value) return arr[i];
    }
  }

  // Function Initialize Data
  getAllData(){
    this.getStore();
    this.getCustomer();
  }

  // Get Data

  getStore(){
    // this.dataStore = this.responseApi.data;
    this.dataStore = this.dummyDataStore;
    this.tempStore = this.dataStore.map(store => ({
      "id": store.id,
      "text": store.name,
      "additional": store
    }));

    // this.http.get('https://x.rajaapi.com/MeP7c5ne'+this.tokenRaja+'/m/wilayah/provinsi').subscribe(
    //   res => {
    //     this.responseApi = res;
    //     if(this.responseApi.code == "200"){
    //       this.dataProvince = this.responseApi.data;
    //       this.tempProvince = this.dataProvince.map(province => ({
    //         "id": province.id,
    //         "text": province.name,
    //         "additional": province
    //       }));
    //     }      
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
  }

  getCustomer(){
    this.dataCustomer = this.dummyDataCustomer;
    this.tempCustomer = this.dataCustomer.map(customer => ({
      "id"          : customer.id,
      "text"        : customer.id,
      "additional"  : customer
    }));
  }


  // Ng-Select2 On Change

  onStoreChange(event: any){
    this.accountForm.patchValue({
      storeId : event
    });
  };

  onFirstCustomerChange(event: any){
    if(event!=""){
      var tempArray = this.getByValue(this.dummyDataCustomer, event);
      this.accountForm.patchValue({
        cifFirst : event,
        nameFirst: tempArray.name
      });
    }    
  }

  onSecondCustomerChange(event: any){
    var tempArray = this.getByValue(this.dummyDataCustomer, event);
    let cifFirst  = this.accountForm.get('cifFirst');
    if (event == cifFirst.value){
      Swal.fire('Error', 'CIF Kedua tidak boleh sama dengan CIF Pertama', 'error');
      $('#cif2 select').val(null).trigger('change');

      this.accountForm.patchValue({
        cifSecond  : '',
        nameSecond : '',
      })
    } else {
      this.accountForm.patchValue({
        cifSecond : event,
        nameSecond: tempArray.name
      });
    }
  }

  onAccountTypeChange(event: any){
    this.accountForm.patchValue({
      accountType : event
    });
  };

  onAccountStatusChange(event: any){
    let cifSecond   = this.accountForm.get('cifSecond');
    let nameSecond  = this.accountForm.get('nameSecond');
    if (event == 'gabungan-or' || event == 'gabungan-and'){
      this.singleAccount = false;
      cifSecond.setValidators(Validators.required);
      nameSecond.setValidators(Validators.required);
    } else {
      this.singleAccount = true;
      cifSecond.clearValidators();
      nameSecond.clearValidators();
    }

    cifSecond.updateValueAndValidity();
    nameSecond.updateValueAndValidity();

    this.accountForm.patchValue({
      accountStatus : event
    });
  };

  // Checkbox Value Change

  onAccountFacilityChange(event){
    var arrayTemp = this.accountForm.get('accountFacility').value;
    var countFlag = 0;
    var indexGet  = '';
    arrayTemp.forEach((element, index) => {
      if (element.id == event.id){
        countFlag++;
        indexGet = index;
      } 
    });

    if (countFlag == 0){
      arrayTemp.push(event);
    } else {
      arrayTemp.splice(indexGet, 1);
    }

    this.accountForm.patchValue({
      accountFacility : arrayTemp
    });
  }

  onReferralChange(event){
    this.accountForm.patchValue({
      accountReferral : event.value
    });

    // console.log(this.accountForm.get('accountReferral'));
  }


  // Click Event
  addDailySavings(){
    // this.http.post(this.ApiConfig.API_URL + "/daily-savings/", this.accountForm.value, this.httpOptions).subscribe(res => {
    //   this.responseApi = res;
    //   if (this.responseApi.status == "200") {
    //     Swal.fire("Success", "Your input has been saved.", "success").then(() => {
    //       this.router.navigate(['/admin/daily-savings']);
    //     })
    //   } else {
    //     Swal.fire("Ooops", "There's something wrong happened", "error");
    //   }
    // }, err => {
    //   Swal.fire("Ooops", "There's something wrong happened", "error");
    // });
    if(this.accountForm.valid){
      this.http.post(this.ApiConfig.API_URL + "/daily-savings/", this.accountForm.value, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success").then(() => {
            this.router.navigate(['/admin/daily-saving/']);
          })
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else {
        this.accountForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
    }
  }

  getInvalid(){
    const invalid = [];
    const controls = this.accountForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    
    console.log(invalid);
  }

  valDecimal(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
      if(ASCIICode == 46){
        return true;
      }else{
        return false;
      }
    } else {
      return true;
    }
  }

  getCIF(){
    this.http.get(this.ApiConfig.API_URL + "/customer-individual-for-daily-saving", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dummyDataCustomer = this.responseApi.data;
          this.getAllData();
          // console.log(this.dummyDataCustomer);
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }


  // Function Panel

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

}
