import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { CurrencyPipe, Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogInsuranceComponent } from '../add-loan/dialog-insurance/dialog-insurance.component';
import { ReplaySubject } from "rxjs"; 
import {identity, Observable} from 'rxjs';
import { not } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-edit-loan',
  templateUrl: './edit-loan.component.html',
  styleUrls: ['./edit-loan.component.css']
})
export class EditLoanComponent implements OnInit {

  insObj : any = {
    ins_name : "",
    ins_legality      : "",
    ins_relationship  : "",
    v_brand   : "",
    v_type    : "",
    v_year    : "",
    v_no      : "",
    v_stnk    : "",
    b_desc    : "",
    b_no      : "",
    b_name    : "",
    b_address : "",
    no_pk     : "",
    cover_note: "",
    no_apht   : "",
    kjpp      : "",
    harga_jaminan : "",
    tanggal_survey : ""
  }

  arrDocument = 
  [
    {text: "Formulir Aplikasi Permohonan Pembiayaan"},
    {text: "Copy KTP Pemohon Beserta Pasangan & Pemilik Jabatan"},
    {text: "Copy Kartu Keluarga Suami, Istri dan Pemilik Jaminan"},
    {text: "Copy NPWP/SPT"},
    {text: "Copy SIUP/SKU/Surat Ijin Profesi Untuk Pengusaha/Profesional"}
  ]

  generateDocument(){
    const isTanah = "Copy Sertifikat SHM/SHGB, IMB, PBB, AJB, Akta Hibah, Akta Waris (untuk jaminan tanah & Bangunan)";
    const isVehicle = "Copy BPKB & STNK (untuk jaminan kendaraan)";
    const loanDocuments: FormArray = this.loanForm.get('loanDocuments') as FormArray;

    this.arrDocument.forEach((e,i,a) => {
      if(this.documentData.some(dt => dt.doc_name === e.text)){
        
      }else{
        this.documentData.push({type : e.text, doc_name : e.text});
      }      
    });
    // console.log(this.documentData);
    this.documentData.forEach((e,i,a) => {
      // console.log(e.doc_name);
      // console.log(e.file_path);
      if(e.doc_name == isTanah || e.doc_name == isVehicle){
        loanDocuments.push(new FormGroup({
          type : new FormControl(e.doc_name),
          doc_name : new FormControl(e.type?"":e.doc_name, []),
          file_path : new FormControl(e.file_path?e.file_path:""),
          file_extension : new FormControl(e.file_extension),
          docFileBuffer: new FormControl('',[]),
          booValue : new FormControl(e.file_path!=""?true:false)        
        }))
      } else {
        loanDocuments.push(new FormGroup({
          type : new FormControl(e.doc_name),
          doc_name : new FormControl(e.type?"":e.doc_name, [Validators.required]),
          file_path : new FormControl(e.file_path?e.file_path:""),
          file_extension : new FormControl(e.file_extension),
          docFileBuffer: new FormControl('',[Validators.required]),
          booValue : new FormControl(e.file_path!=""?true:false)        
        }))
      }
    });

  }

  removeRowUpload(index) {
    const loanDocuments: FormArray = this.loanForm.get('loanDocuments') as FormArray;
    loanDocuments.removeAt(index);
  }

  feFileName : any;
  uploadFile(event,i) {
    let fileName = event.target.files[0].name;
    this.feFileName = fileName;
    let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let name = fileName.substring(0,fileName.indexOf("."));
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.loanForm.get('loanDocuments')['controls'][i].patchValue({
        doc_name : name,
        file_extension : extension,
        docFileBuffer : base64
      });
    });
  }

  addRowUpload() {
    // console.log(this.loanDocuments);
    const loanDocuments: FormArray = this.loanForm.get('loanDocuments') as FormArray;
    loanDocuments.push(new FormGroup({
      type : new FormControl(''),
      doc_name : new FormControl('', [Validators.required]),
      file_path : new FormControl(''),
      file_extension : new FormControl(''),
      docFileBuffer: new FormControl('', [Validators.required]),
      booValue : new FormControl(false)       
    }))
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  openDialogInsurance(){
    let dialogRef = this.dialog.open(DialogInsuranceComponent , {data:this.insObj});
    dialogRef.afterClosed().subscribe(res => {
      // console.log(this.insObj,res)
      const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
      if(res.ok){
        insurance.push(new FormGroup({
          ins_name: new FormControl(res.ins_name, []),
          ins_legality: new FormControl(res.ins_legality, []),
          ins_relationship: new FormControl(res.ins_relationship, []),
          v_brand: new FormControl(res.v_brand, []),
          v_type: new FormControl(res.v_type, []),
          v_year: new FormControl(res.v_year, []),
          v_no: new FormControl(res.v_no, []),
          b_desc: new FormControl(res.b_desc, []),
          b_name: new FormControl(res.b_name, []),
          b_no: new FormControl(res.b_no, []),
          b_address: new FormControl(res.b_address, []),
          no_pk: new FormControl(res.no_pk, []),
          cover_note: new FormControl(res.cover_note, []),
          no_apht: new FormControl(res.no_apht, []),
          kjpp: new FormControl(res.kjpp, []),
          harga_jaminan: new FormControl(res.harga_jaminan, []),
          tanggal_survey: new FormControl(res.tanggal_survey, []),
        }));
        
        // if(res.ins_name == "Ruko" || res.ins_name == "Tanah & Bangunan") {
        //   this.loanForm.get('loanDocuments')['controls'][5].controls.docFileBuffer.setValidators([Validators.required]);
        //   this.loanForm.get('loanDocuments')['controls'][5].controls.doc_name.setValidators([Validators.required]);
        //   this.loanForm.get('loanDocuments')['controls'][5].patchValue({booValue : false});
        //   this.loanForm.get('loanDocuments')['controls'][5].controls.docFileBuffer.updateValueAndValidity();
        //   this.loanForm.get('loanDocuments')['controls'][5].controls.doc_name.updateValueAndValidity();
        // } else if (res.ins_name == "Kendaraan Beroda Empat") {
        //   this.loanForm.get('loanDocuments')['controls'][6].controls.docFileBuffer.setValidators([Validators.required]);
        //   this.loanForm.get('loanDocuments')['controls'][6].controls.doc_name.setValidators([Validators.required]);
        //   this.loanForm.get('loanDocuments')['controls'][6].patchValue({booValue : false});
        //   this.loanForm.get('loanDocuments')['controls'][6].controls.docFileBuffer.updateValueAndValidity();
        //   this.loanForm.get('loanDocuments')['controls'][6].controls.doc_name.updateValueAndValidity();
        // }
        // this.loanForm.get('loanDocuments').updateValueAndValidity();
      }
    })
  }

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private currencyPipe : CurrencyPipe,
    public dialog : MatDialog
  ) { }

  loanForm: FormGroup = new FormGroup({
    fullname: new FormControl({value: '', disabled: true}),
    nickname: new FormControl({value: '', disabled: true}),
    sex: new FormControl({value: '', disabled: true}),
    ktp_no: new FormControl({value: '', disabled: true}),
    validity_identity: new FormControl({value: '', disabled: true}),
    place_of_birth: new FormControl({value: '', disabled: true}),
    date_of_birth: new FormControl({value: '', disabled: true}),
    identity_address: new FormControl({value: '', disabled: true}),
    identity_districts: new FormControl({value: '', disabled: true}),
    identity_ward: new FormControl({value: '', disabled: true}),
    identity_city: new FormControl({value: '', disabled: true}),
    identity_country: new FormControl({value: '', disabled: true}),
    identity_province: new FormControl({value: '', disabled: true}),
    identity_postalcode: new FormControl({value: '', disabled: true}),
    identity_phonenumber: new FormControl({value: '', disabled: true}),
    identity_smartphonenumber: new FormControl({value: '', disabled: true}),
    company_phone: new FormControl({value: '', disabled: true}),
    number_of_dependents: new FormControl({value: '', disabled: true}),
    last_study: new FormControl({value: '', disabled: true}),
    mothername: new FormControl({value: '', disabled: true}),
    marital_status: new FormControl({value: '', disabled: true}),
    couple_name: new FormControl({value: '', disabled: true}),
    couple_address: new FormControl({value: '', disabled: true}),
    couple_country: new FormControl({value: '', disabled: true}),
    couple_province: new FormControl({value: '', disabled: true}),
    couple_districts: new FormControl({value: '', disabled: true}),
    couple_ward: new FormControl({value: '', disabled: true}),
    couple_city: new FormControl({value: '', disabled: true}),
    couple_postalcode: new FormControl({value: '', disabled: true}),
    couple_phonenumber: new FormControl({value: '', disabled: true}),
    validity_identity_date: new FormControl({value: '', disabled: true}),

    guardian_name: new FormControl({value: '', disabled: true}),
    guardian_relationship: new FormControl({value: '', disabled: true}),
    guardian_address: new FormControl({value: '', disabled: true}),
    guardian_country: new FormControl({value: '', disabled: true}),
    guardian_province: new FormControl({value: '', disabled: true}),
    guardian_districts: new FormControl({value: '', disabled: true}),
    guardian_ward: new FormControl({value: '', disabled: true}),
    guardian_city: new FormControl({value: '', disabled: true}),
    guardian_postalcode: new FormControl({value: '', disabled: true}),
    guardian_phone: new FormControl({value: '', disabled: true}),
    guardian_mobilephone: new FormControl({value: '', disabled: true}),

    domicile_address: new FormControl({value: '', disabled: true}),
    domicile_country: new FormControl({value: '', disabled: true}),
    domicile_province: new FormControl({value: '', disabled: true}),
    domicile_districts: new FormControl({value: '', disabled: true}),
    domicile_ward: new FormControl({value: '', disabled: true}),
    domicile_city: new FormControl({value: '', disabled: true}),
    domicile_postalcode: new FormControl({value: '', disabled: true}),
    domicile_phone: new FormControl({value: '', disabled: true}),
    domicile_mobilephone: new FormControl({value: '', disabled: true}),
    domicile_status: new FormControl({value: '', disabled: true}),
    domicile_longtimeoccupy: new FormControl({value: '', disabled: true}),


    company_name: new FormControl({value: '', disabled: true}),
    company_address: new FormControl({value: '', disabled: true}),
    company_country: new FormControl({value: '', disabled: true}),
    company_province: new FormControl({value: '', disabled: true}),
    company_districts: new FormControl({value: '', disabled: true}),
    company_ward: new FormControl({value: '', disabled: true}),
    company_city: new FormControl({value: '', disabled: true}),
    company_worktype: new FormControl({value: '', disabled: true}),
    company_worktypetext: new FormControl({value: '', disabled: true}),
    company_position: new FormControl({value: '', disabled: true}),
    company_businessfield: new FormControl({value: '', disabled: true}),
    company_businessfieldtext: new FormControl({value: '', disabled: true}),
    net_income: new FormControl({value: '', disabled: true}),
    gross_income: new FormControl({value: '', disabled: true}),
    entre_sales_system_others: new FormControl({value: '', disabled: true}),
    entre_land_status_others: new FormControl({value: '', disabled: true}),
    company_longeffort: new FormControl({value: '', disabled: true}),

    entre_product_specification: new FormControl({value: '', disabled: true}),
    entre_sales_system: new FormControl({value: '', disabled: true}),
    entre_business_ownership: new FormControl({value: '', disabled: true}),
    entre_land_status: new FormControl({value: '', disabled: true}),
    entre_gross_profit: new FormControl({value: '', disabled: true}),
    entre_net_profit: new FormControl({value: '', disabled: true}),
    //SEND TO WS------------------------------------------------------------------------------------------------------------------------
    product: new FormControl('', [Validators.required]),
    branch_code: new FormControl('', [Validators.required]),
    officer_name: new FormControl('', [Validators.required]),
    officer_code: new FormControl('', [Validators.required]),
    cif_id: new FormControl('', [Validators.required]),

    is_debtor: new FormControl('', [Validators.required]),
    bank_name: new FormControl('', []),
    bank_product: new FormControl('', [Validators.required]),
    other_bank_loan: new FormArray([
      new FormGroup({
        name : new FormControl('', []),
        remaining_debt : new FormControl('', []),
        monthly_installment : new FormControl('', []),
        loan_term : new FormControl('', []),
      })
    ]),
    loan_amount: new FormControl('', [Validators.required]),
    loan_interest: new FormControl('', [Validators.required]),
    loan_duration_month: new FormControl('', [Validators.required]),
    loan_facility: new FormControl('', [Validators.required]),
    loan_purpose: new FormControl('', [Validators.required]),

    request_date: new FormControl(''),
    request_name: new FormControl(''),
    created_by: new FormControl(''),
    v_cmo_id: new FormControl('', [Validators.required]),
    v_tl_id: new FormControl('', [Validators.required]),
    v_bm_id: new FormControl('', [Validators.required]),
    v_am_id: new FormControl('', [Validators.required]),
    verification_date: new FormControl('', [Validators.required]),
    provision_fee: new FormControl('', [Validators.required]),
    admin_fee: new FormControl('', [Validators.required]),
    notary_fee: new FormControl('', [Validators.required]),
    insurance_fee: new FormControl('', [Validators.required]),
    loanDocument: new FormArray([]),
    loanDocuments: new FormArray([]),
    custValid: new FormControl(''),
    other_products : new FormArray([
    ]),
    insurance : new FormArray([

    ]),
    loanID: new FormControl('')
  });

  idParam : any;
  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  loanData : any; cifData : any; statusApproval : any; submittedBy : any; regionData : any; approvedBy : any; cannotApprove : any;
  jumlahPinjaman : any;
  loanAmount: any; notaryFee: any; insuranceFee: any;
  documentData: any;

  ngOnInit(): void {
    // this.spinner.show();
    // console.log(this.loanForm.get('insurance'));
    
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
      this.loanID.setValue(this.idParam);
      // this.getApprover(this.idParam)
    }
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  getApprover(id){
    this.http.get(this.ApiConfig.API_URL + '/loan-first-approver/' + id , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.approvedBy = 
          {
            username : this.responseApi.data.username,
            id : this.responseApi.data.id,
          }
          if(this.ApiConfig.USER_DATA.id_user == this.approvedBy.id) {
            this.cannotApprove = true;
          } else if (this.ApiConfig.USER_DATA.id_role == '1') {
            this.cannotApprove = false;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }

  deleteInsurance(idx){
    const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
    insurance.removeAt(idx);
  }

  editInsurance(idx){
    const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
    const data = insurance.at(idx).value;
    
    let dialogRef = this.dialog.open(DialogInsuranceComponent , {data:data});
    dialogRef.afterClosed().subscribe(res => {
      // console.log(this.insObj,res)
      const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
      if(res.ok){
        insurance.at(idx).patchValue({
          ins_name: res.ins_name,
          ins_legality: res.ins_legality,
          ins_relationship: res.ins_relationship,
          v_brand: res.v_brand,
          v_type: res.v_type,
          v_year: res.v_year,
          v_no: res.v_no,
          b_desc: res.b_desc,
          b_name: res.b_name,
          b_no: res.b_no,
          b_address: res.b_address,
          no_pk: res.no_pk,
          cover_note: res.cover_note,
          no_apht: res.no_apht,
          kjpp: res.kjpp,
          harga_jaminan: res.harga_jaminan,
          tanggal_survey: res.tanggal_survey,
        })
        // insurance.push(new FormGroup({
        //   ins_name: new FormControl(res.ins_name, []),
        //   ins_legality: new FormControl(res.ins_legality, []),
        //   ins_relationship: new FormControl(res.ins_relationship, []),
        //   v_brand: new FormControl(res.v_brand, []),
        //   v_type: new FormControl(res.v_type, []),
        //   v_year: new FormControl(res.v_year, []),
        //   v_no: new FormControl(res.v_no, []),
        //   b_desc: new FormControl(res.b_desc, []),
        //   b_name: new FormControl(res.b_name, []),
        //   b_no: new FormControl(res.b_no, []),
        //   b_address: new FormControl(res.b_address, []),
        // }));
      }
    })
    // insurance.removeAt(idx);
  }

  getData(id){
    this.http.get(this.ApiConfig.API_URL + '/loan/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        console.log(this.responseApi.data);
        if(this.responseApi.status == "200"){

        this.loanData = this.responseApi.data.loanData;
        this.cifData = this.responseApi.data.cifData;
        this.approvedBy = {
          username : '',
          id : ''
        }
        this.responseApi.data.getApprover.map((data, index) => {
          this.approvedBy.username += data.fullname;
          if ((index + 1) != this.responseApi.data.getApprover.length){
            this.approvedBy.username += ', ';
          } 
        });
        // console.log(this.cifData);
        this.cif_id.setValue(this.cifData.cif_id);
        this.statusApproval = this.loanData.status_approval;
        this.submittedBy = this.responseApi.data.submittedBy
        this.loanData.v_date = moment(this.loanData.v_date).format('DD-MM-YYYY');
        this.loanData.request_date = moment(this.loanData.request_date).format('DD-MM-YYYY');
        // console.log(this.loanForm.get('loanDocuments'));
        const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
        
        for(let i = 0; i < this.loanData.insurance.length; i++){
          let res = this.loanData.insurance[i];
          insurance.push(new FormGroup({
            ins_name: new FormControl(res.ins_name, []),
            ins_legality: new FormControl(res.ins_legality, []),
            ins_relationship: new FormControl(res.ins_relationship, []),
            v_brand: new FormControl(res.v_brand, []),
            v_type: new FormControl(res.v_type, []),
            v_year: new FormControl(res.v_year, []),
            v_no: new FormControl(res.v_no, []),
            b_desc: new FormControl(res.b_desc, []),
            b_name: new FormControl(res.b_name, []),
            b_no: new FormControl(res.b_no, []),
            b_address: new FormControl(res.b_address, []),
            no_pk: new FormControl(res.no_pk, []),
            cover_note: new FormControl(res.cover_note, []),
            no_apht: new FormControl(res.no_apht, []),
            kjpp: new FormControl(res.kjpp, []),
            harga_jaminan: new FormControl(res.harga_jaminan, []),
            tanggal_survey: new FormControl(res.tanggal_survey, []),
          }));
          
          // if(res.ins_name == "Ruko" || res.ins_name == "Tanah & Bangunan") {
          //   this.loanForm.get('loanDocuments')['controls'][5].controls.docFileBuffer.setValidators([Validators.required]);
          //   this.loanForm.get('loanDocuments')['controls'][5].controls.doc_name.setValidators([Validators.required]);
          //   this.loanForm.get('loanDocuments')['controls'][5].patchValue({booValue : false});
          //   this.loanForm.get('loanDocuments')['controls'][5].controls.docFileBuffer.updateValueAndValidity();
          //   this.loanForm.get('loanDocuments')['controls'][5].controls.doc_name.updateValueAndValidity();
          // } else if (res.ins_name == "Kendaraan Beroda Empat") {
          //   this.loanForm.get('loanDocuments')['controls'][6].controls.docFileBuffer.setValidators([Validators.required]);
          //   this.loanForm.get('loanDocuments')['controls'][6].controls.doc_name.setValidators([Validators.required]);
          //   this.loanForm.get('loanDocuments')['controls'][6].patchValue({booValue : false});
          //   this.loanForm.get('loanDocuments')['controls'][6].controls.docFileBuffer.updateValueAndValidity();
          //   this.loanForm.get('loanDocuments')['controls'][6].controls.doc_name.updateValueAndValidity();
          // }
          // this.loanForm.get('loanDocuments').updateValueAndValidity();
        }
        
        // this.cifData.date_of_birth = moment(this.cifData.date_of_birth).format('DD-MM-YYYY');
        this.loanAmount = this.loanData.loan_amount;
        this.notaryFee = this.loanData.notary_fee;
        this.insuranceFee = this.loanData.insurance_fee;
        this.jumlahPinjaman = this.loanData.loan_amount;
        this.loanData.loan_amount = this.currencyPipe.transform(this.loanData.loan_amount, 'Rp. ');
        this.loanData.notary_fee = this.currencyPipe.transform(this.loanData.notary_fee, 'Rp. ');
        this.loanData.insurance_fee = this.currencyPipe.transform(this.loanData.insurance_fee, 'Rp. ');
        this.parseRegionData();
        this.getRegionData(this.cifData.cif_id);
        this.getUploadDocument(this.loanData.id)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getUploadDocument(id) {
    this.http.get(this.ApiConfig.API_URL + "/loan-document/" + id, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200") {
          this.documentData = this.responseApi.data;
          this.generateDocument();
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }

  viewDocument(i) {
    let filepath = this.documentData[i].file_path +"."+ this.documentData[i].file_extension

    window.open(
      this.ApiConfig.API_URL + "/openPDF?pdfName=" + filepath
    );
  }

  viewRecentlyUpload(i){
    let pdfWindow = window.open("")
    pdfWindow.document.write(
    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    encodeURI(this.loanForm.get('loanDocuments')['controls'][i].value.docFileBuffer) + "'></iframe>"
)
  }

  
  parseRegionData(){
    this.cifData.identity_province = this.cifData.identity_province ? this.cifData.identity_province : '0';
    this.cifData.couple_province = this.cifData.couple_province ? this.cifData.couple_province : '0';
    this.cifData.guardian_province = this.cifData.guardian_province ? this.cifData.guardian_province : '0';
    this.cifData.company_province = this.cifData.company_province ? this.cifData.company_province : '0';
    this.cifData.domicile_province = this.cifData.domicile_province ? this.cifData.domicile_province : '0';

    this.cifData.identity_city = this.cifData.identity_city ? this.cifData.identity_city : '0';
    this.cifData.couple_city = this.cifData.couple_city ? this.cifData.couple_city : '0';
    this.cifData.guardian_city = this.cifData.guardian_city ? this.cifData.guardian_city : '0';
    this.cifData.company_city = this.cifData.company_city ? this.cifData.company_city : '0';
    this.cifData.domicile_city = this.cifData.domicile_city ? this.cifData.domicile_city : '0';

    this.cifData.identity_districts = this.cifData.identity_districts ? this.cifData.identity_districts : '0';
    this.cifData.couple_districts = this.cifData.couple_districts ? this.cifData.couple_districts : '0';
    this.cifData.guardian_districts = this.cifData.guardian_districts ? this.cifData.guardian_districts : '0';
    this.cifData.company_districts = this.cifData.company_districts ? this.cifData.company_districts : '0';
    this.cifData.domicile_districts = this.cifData.domicile_districts ? this.cifData.domicile_districts : '0';

    this.cifData.identity_ward = this.cifData.identity_ward ? this.cifData.identity_ward : '0';
    this.cifData.couple_ward = this.cifData.couple_ward ? this.cifData.couple_ward : '0';
    this.cifData.guardian_ward = this.cifData.guardian_ward ? this.cifData.guardian_ward : '0';
    this.cifData.company_ward = this.cifData.company_ward ? this.cifData.company_ward : '0';
    this.cifData.domicile_ward = this.cifData.domicile_ward ? this.cifData.domicile_ward : '0';
  }

  getRegionData(cif_id){
    this.http.get(this.ApiConfig.API_URL + '/loan-region-api/' + cif_id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200") {
          this.regionData = this.responseApi.data;          
          this.cifData.identity_province = this.regionData.province.find(item => item.code == this.cifData.identity_province).name;          
          this.cifData.couple_province = this.regionData.province.find(item => item.code == this.cifData.couple_province).name;
          this.cifData.guardian_province = this.regionData.province.find(item => item.code == this.cifData.guardian_province).name;
          this.cifData.company_province = this.regionData.province.find(item => item.code == this.cifData.company_province).name;
          this.cifData.domicile_province = this.regionData.province.find(item => item.code == this.cifData.domicile_province).name;

          this.cifData.identity_city = this.regionData.identity_city.find(item => item.code == this.cifData.identity_city).name;
          this.cifData.couple_city = this.regionData.couple_city.find(item => item.code == this.cifData.couple_city).name;
          this.cifData.guardian_city = this.regionData.guardian_city.find(item => item.code == this.cifData.guardian_city).name;
          this.cifData.company_city = this.regionData.company_city.find(item => item.code == this.cifData.company_city).name;
          this.cifData.domicile_city = this.regionData.domicile_city.find(item => item.code == this.cifData.domicile_city).name;

          this.cifData.identity_districts = this.regionData.identity_district.find(item => item.code == this.cifData.identity_districts).name;
          this.cifData.couple_districts = this.regionData.couple_district.find(item => item.code == this.cifData.couple_districts).name;
          this.cifData.guardian_districts = this.regionData.guardian_district.find(item => item.code == this.cifData.guardian_districts).name;
          this.cifData.company_districts = this.regionData.company_district.find(item => item.code == this.cifData.company_districts).name;
          this.cifData.domicile_districts = this.regionData.domicile_district.find(item => item.code == this.cifData.domicile_districts).name;

          this.cifData.identity_ward = this.regionData.identity_ward.find(item => item.code == this.cifData.identity_ward).name;
          this.cifData.couple_ward = this.regionData.couple_ward.find(item => item.code == this.cifData.couple_ward).name;
          this.cifData.guardian_ward = this.regionData.guardian_ward.find(item => item.code == this.cifData.guardian_ward).name;
          this.cifData.company_ward = this.regionData.company_ward.find(item => item.code == this.cifData.company_ward).name;
          this.cifData.domicile_ward = this.regionData.domicile_ward.find(item => item.code == this.cifData.domicile_ward).name;

          
          this.spinner.hide();

                 
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  approve(){
    let data = {
      data_type: "loan",
      approval_type: "PENDING_ADD",
      data_identifier: this.loanData.id,
      approved_by: this.ApiConfig.USER_DATA.id_user,
      action_type: "approve"
    }

    let isTerm = false;
    if(this.loanData.loan_facility == "TL") {
      isTerm = true;
    }

    this.http.post(this.ApiConfig.API_URL + '/loan-approval' , data , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          let isProcess = this.responseApi.data;
          if(!isTerm || (isTerm && !isProcess)) {
            Swal.fire("Success", "Your input has been saved.", "success");
            this.location.back();
          }
          else if(isTerm && isProcess) {
            this.initLoan();
          }
          else if (!isTerm && isProcess) {
            this.registrationFeePayment();
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    
  }

  reject(){
    let data = {
      data_type: "loan",
      approval_type: "PENDING_ADD",
      data_identifier: this.loanData.id,
      approved_by: this.ApiConfig.USER_DATA.id_user,
      action_type: "reject"
    }
    this.http.post(this.ApiConfig.API_URL + '/loan-rejection' , data , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
            Swal.fire("Success", "Your input has been saved.", "success");
            this.location.back();
        }
      },
      (err) => {
        console.log(err);
      }
    );

  }

  initLoan(){
    this.http.get(this.ApiConfig.API_URL + '/term-loan-first-setup-v2/' + this.loanData.id + '/' + this.ApiConfig.USER_DATA.id_user , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.registrationFeePayment();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //Potong Rekening Daily Saving untuk Biaya Registrasi Term Loan, Demand Loan tdk d proses disini
  registrationFeePayment() {
    let loanAmount = this.loanAmount;
    let provisionFee = (this.loanData.provision_fee / 100) * loanAmount;
    let adminFee = (this.loanData.admin_fee / 100) * loanAmount
    let notaryFee = parseFloat(this.notaryFee);
    let insuranceFee = parseFloat(this.insuranceFee);
    let totalFee = provisionFee + adminFee + notaryFee + insuranceFee

    let provisi = {
      cif_id : this.loanData.cif_id,
      amount : provisionFee,
      type : "D",
      note : "Biaya Provisi Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let admin = {
      cif_id : this.loanData.cif_id,
      amount : adminFee,
      type : "D",
      note : "Biaya Admin Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let notary = {
      cif_id : this.loanData.cif_id,
      amount : notaryFee,
      type : "D",
      note : "Biaya Notaris Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let insurance = {
      cif_id : this.loanData.cif_id,
      amount : insuranceFee,
      type : "D",
      note : "Biaya Asuransi Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let paymentArr = [provisi,admin,notary,insurance];

    paymentArr.forEach((e,i,a) => {
      setTimeout(() => {

        this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', e, this.httpOptions).subscribe(
          (res) => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              // this.sendLoan();
            }
          },
          (err) => {
            console.log(err);
          }
        );

      },500)

      if(i == paymentArr.length - 1) {
        this.sendLoan();
      }
    })
  }

  sendLoan() {
    let obj_cair = {
      cif_id : this.loanData.cif_id,
      amount : this.jumlahPinjaman,
      type : "K",
      note : "Pencairan Dana Pinjaman Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }
    console.log(obj_cair)
    this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', obj_cair, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          Swal.fire("Success", "Your input has been saved. Term Loan Successfully Processed", "success");
          this.location.back();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  updateLoan(){
    this.http.post(this.ApiConfig.API_URL + "/update-loan", this.loanForm.value , this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        Swal.fire("Success", "Your input has been saved.", "success");
        this.location.back();
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });
  }

  get loanDocument(){return this.loanForm.get("loanDocument")}
  get loanDocuments(){return this.loanForm.get("loanDocuments")}
  // get ins_name(){return this.loanForm.get("ins_name")}
  get insurance(){return this.loanForm.get("insurance")}
  get fullname(){return this.loanForm.get("fullname")}
  get nickname(){return this.loanForm.get("nickname")}
  get sex(){return this.loanForm.get("sex")}
  get ktp_no(){return this.loanForm.get("ktp_no")}
  get validity_identity(){return this.loanForm.get("validity_identity")}
  get place_of_birth(){return this.loanForm.get("place_of_birth")}
  get date_of_birth(){return this.loanForm.get("date_of_birth")}
  get identity_address(){return this.loanForm.get("identity_address")}
  get identity_districts(){return this.loanForm.get("identity_districts")}
  get identity_ward(){return this.loanForm.get("identity_ward")}
  get identity_city(){return this.loanForm.get("identity_city")}
  get identity_postalcode(){return this.loanForm.get("identity_postalcode")}
  get identity_country(){return this.loanForm.get("identity_country")}
  get identity_province(){return this.loanForm.get("identity_province")}
  get identity_phonenumber(){return this.loanForm.get("identity_phonenumber")}
  get identity_smartphonenumber(){return this.loanForm.get("identity_smartphonenumber")}
  get company_phone(){return this.loanForm.get("company_phone")}
  get number_of_dependents(){return this.loanForm.get("number_of_dependents")}
  get last_study(){return this.loanForm.get("last_study")}
  get mothername(){return this.loanForm.get("mothername")}
  get marital_status(){return this.loanForm.get("marital_status")}
  get couple_name(){return this.loanForm.get("couple_name")}
  get couple_address(){return this.loanForm.get("couple_address")}
  get couple_country(){return this.loanForm.get("couple_country")}
  get couple_province(){return this.loanForm.get("couple_province")}
  get couple_districts(){return this.loanForm.get("couple_districts")}
  get couple_ward(){return this.loanForm.get("couple_ward")}
  get couple_city(){return this.loanForm.get("couple_city")}
  get couple_postalcode(){return this.loanForm.get("couple_postalcode")}
  get couple_phonenumber(){return this.loanForm.get("couple_phonenumber")}
  get domicile_address(){return this.loanForm.get("domicile_address")}
  get domicile_country(){return this.loanForm.get("domicile_country")}
  get domicile_province(){return this.loanForm.get("domicile_province")}
  get domicile_districts(){return this.loanForm.get("domicile_districts")}
  get domicile_ward(){return this.loanForm.get("domicile_ward")}
  get domicile_city(){return this.loanForm.get("domicile_city")}
  get domicile_postalcode(){return this.loanForm.get("domicile_postalcode")}
  get domicile_phone(){return this.loanForm.get("domicile_phone")}
  get domicile_mobilephone(){return this.loanForm.get("domicile_mobilephone")}
  get domicile_status(){return this.loanForm.get("domicile_status")}
  get domicile_longtimeoccupy(){return this.loanForm.get("domicile_longtimeoccupy")}
  get guardian_name(){return this.loanForm.get("guardian_name")}
  get guardian_relationship(){return this.loanForm.get("guardian_relationship")}
  get guardian_address(){return this.loanForm.get("guardian_address")}
  get guardian_country(){return this.loanForm.get("guardian_country")}
  get guardian_province(){return this.loanForm.get("guardian_province")}
  get guardian_districts(){return this.loanForm.get("guardian_districts")}
  get guardian_ward(){return this.loanForm.get("guardian_ward")}
  get guardian_city(){return this.loanForm.get("guardian_city")}
  get guardian_postalcode(){return this.loanForm.get("guardian_postalcode")}
  get guardian_phone(){return this.loanForm.get("guardian_phone")}
  get guardian_mobilephone(){return this.loanForm.get("guardian_mobilephone")}
  get company_name(){return this.loanForm.get("company_name")}
  get company_address(){return this.loanForm.get("company_address")}
  get company_country(){return this.loanForm.get("company_country")}
  get company_province(){return this.loanForm.get("company_province")}
  get company_districts(){return this.loanForm.get("company_districts")}
  get company_ward(){return this.loanForm.get("company_ward")}
  get company_city(){return this.loanForm.get("company_city")}
  // get company_postalcode(){return this.loanForm.get("company_postalcode")}
  get company_worktype(){return this.loanForm.get("company_worktype")}
  get company_worktypetext(){return this.loanForm.get("company_worktypetext")}
  get company_position(){return this.loanForm.get("company_position")}
  get company_businessfield(){return this.loanForm.get("company_businessfield")}
  get company_businessfieldtext(){return this.loanForm.get("company_businessfieldtext")}
  get company_longeffort(){return this.loanForm.get("company_longeffort")}
  get net_income(){return this.loanForm.get("net_income")}
  get gross_income(){return this.loanForm.get("gross_income")}
  get entre_sales_system_others(){return this.loanForm.get("entre_sales_system_others")}
  get entre_land_status_others(){return this.loanForm.get("entre_land_status_others")}
  get product(){return this.loanForm.get("product")}
  get branch_code(){return this.loanForm.get("branch_code")}
  get officer_name(){return this.loanForm.get("officer_name")}
  get officer_code(){return this.loanForm.get("officer_code")}
  get cif_id(){return this.loanForm.get("cif_id")}
  get entre_product_specification(){return this.loanForm.get("entre_product_specification")}
  get entre_sales_system(){return this.loanForm.get("entre_sales_system")}
  get entre_business_ownership(){return this.loanForm.get("entre_business_ownership")}
  get entre_land_status(){return this.loanForm.get("entre_land_status")}
  get entre_gross_profit(){return this.loanForm.get("entre_gross_profit")}
  get entre_net_profit(){return this.loanForm.get("entre_net_profit")}
  get is_debtor(){return this.loanForm.get("is_debtor")}
  get bank_name(){return this.loanForm.get("bank_name")}
  get bank_product(){return this.loanForm.get("bank_product")}
  get other_bank_loan(){return this.loanForm.get("other_bank_loan")}
  get loan_amount(){return this.loanForm.get("loan_amount")}
  get loan_interest(){return this.loanForm.get("loan_interest")}
  get loan_duration_year(){return this.loanForm.get("loan_duration_year")}
  get loan_duration_month(){return this.loanForm.get("loan_duration_month")}
  get loan_facility(){return this.loanForm.get("loan_facility")}
  get loan_purpose(){return this.loanForm.get("loan_purpose")}
  get ins(){return this.loanForm.get("ins")}
  get ins_legality(){return this.loanForm.get("ins_legality")}
  get ins_relationship(){return this.loanForm.get("ins_relationship")}
  get ins_vehicle_brand(){return this.loanForm.get("ins_vehicle_brand")}
  get ins_vehicle_type(){return this.loanForm.get("ins_vehicle_type")}
  get ins_vehicle_year(){return this.loanForm.get("ins_vehicle_year")}
  get ins_vehicle_no(){return this.loanForm.get("ins_vehicle_no")}
  get ins_vehicle_stnk_name(){return this.loanForm.get("ins_vehicle_stnk_name")}
  get ins_land_description(){return this.loanForm.get("ins_land_description")}
  get ins_land_cert_no(){return this.loanForm.get("ins_land_cert_no")}
  get ins_land_cert_name(){return this.loanForm.get("ins_land_cert_name")}
  get ins_land_address(){return this.loanForm.get("ins_land_address")}
  get request_date(){return this.loanForm.get("request_date")}
  get request_name(){return this.loanForm.get("request_name")}
  get created_by(){return this.loanForm.get("created_by")}
  get created_date(){return this.loanForm.get("created_date")}
  get last_updated(){return this.loanForm.get("last_updated")}
  get is_deleted(){return this.loanForm.get("is_deleted")}
  get validity_identity_date(){return this.loanForm.get("validity_identity_date")}
  get custValid(){return this.loanForm.get("custValid")}
  get debt_name(){return this.loanForm.get("debt_name")}
  get v_cmo_id(){return this.loanForm.get("v_cmo_id")}
  get v_tl_id(){return this.loanForm.get("v_tl_id")}
  get v_bm_id(){return this.loanForm.get("v_bm_id")}
  get v_am_id(){return this.loanForm.get("v_am_id")}
  get verification_date(){return this.loanForm.get("verification_date")}
  get bank_product_others(){return this.loanForm.get("bank_product_others")}
  get other_products(){return this.loanForm.get("other_products")}
  get provision_fee(){return this.loanForm.get("provision_fee")}
  get admin_fee(){return this.loanForm.get("admin_fee")}
  get notary_fee(){return this.loanForm.get("notary_fee")}
  get insurance_fee(){return this.loanForm.get("insurance_fee")}
  get loanID(){return this.loanForm.get("loanID")}
}
