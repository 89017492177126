import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ServicesComponent } from 'src/app/services/services.component';

@Component({
  selector: 'app-simulasi-simpanan-berjangka',
  templateUrl: './simulasi-simpanan-berjangka.component.html',
  styleUrls: ['./simulasi-simpanan-berjangka.component.sass']
})
export class SimulasiSimpananBerjangkaComponent implements OnInit {
  constructor(
    private router: Router,
    private globalService: ServicesComponent
  ) { }

  simpananBerjangkaForm: FormGroup = new FormGroup({
    mataUang: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    jangkaWaktu: new FormControl('', [Validators.required]),
    instruksiJatuhTempo: new FormControl('', [Validators.required]),
    sukuBunga: new FormControl('', [Validators.required]),
    nominalPenempatan: new FormControl()
  });
  showTablePokok: boolean = false;
  showTablePokokBunga: boolean = false;

  simulasiPokokBungaArr: any = [];

  bulan: any;
  jumlahHari: any = [];

  ngOnInit(): void {
    this.simpananBerjangkaForm.patchValue({
      mataUang: "idr",
      jangkaWaktu: "6",
      instruksiJatuhTempo: "pokok",
      sukuBunga: "7",
      // nominalPenempatan: "10000000"
    });
  }

  async simulasiSimpananBerjangka(){
    console.log(this.simpananBerjangkaForm.value);
    this.simpananBerjangkaForm.value.nominalPenempatan = this.simpananBerjangkaForm.value.nominalPenempatan.replaceAll('.','');
    this.bulan = this.simpananBerjangkaForm.value.jangkaWaktu;

    if(this.simpananBerjangkaForm.value.instruksiJatuhTempo == 'pokok'){
      this.showTablePokokBunga = false;
      this.showTablePokok = true;

      this.jumlahHari = [];
      let startDateAddMonths = [];
      let startDateTemp = this.simpananBerjangkaForm.value.startDate;

      for (let i = 0; i < 4; i++) {
        startDateTemp = moment(startDateTemp).add(this.bulan, 'M').format('YYYY-MM-DD')
        startDateAddMonths.push(startDateTemp)
      }
      
      let startDateMomentObj = moment(this.simpananBerjangkaForm.value.startDate, 'YYYY-MM-DD').startOf('day')
      await startDateAddMonths.forEach((el, i, arr) => {
        if(i == 0) {
          let endDateEachMomentObj = moment(el, 'YYYY-MM-DD').startOf('day')
          let diffDay = endDateEachMomentObj.diff(startDateMomentObj, 'days', false)
          this.jumlahHari.push(diffDay)
        }
        else{
          let startDateBeforeMomentObj = moment(arr[i - 1], 'YYYY-MM-DD').startOf('day')
          let endDateEachMomentObj = moment(el, 'YYYY-MM-DD').startOf('day')
          let diffDay = endDateEachMomentObj.diff(startDateBeforeMomentObj, 'days', false)
          this.jumlahHari.push(diffDay)
        }
      });
    } 
    else{
      this.showTablePokok = false;
      this.showTablePokokBunga = true;

      for(let i = 1; i <= 4; i++){
        let tempObj = {
          bulanKe: null,
          sukuBunga: null,
          jumlahHari: null,
          totalBunga: null,
          jumlahUangDisimpan: null
        };
        
        tempObj.bulanKe = this.simpananBerjangkaForm.value.jangkaWaktu * i;
        tempObj.sukuBunga = this.simpananBerjangkaForm.value.sukuBunga;
        tempObj.jumlahHari = this.simpananBerjangkaForm.value.jangkaWaktu == '12' ? 365 : this.simpananBerjangkaForm.value.jangkaWaktu * 30;

        let totalBunga;
        if(i == 1){
          if(this.simpananBerjangkaForm.value.jangkaWaktu == '12'){
            totalBunga = (this.simpananBerjangkaForm.value.sukuBunga / 100) * (365 / 365) * this.simpananBerjangkaForm.value.nominalPenempatan * 0.9;
          }
          else{
            totalBunga = (this.simpananBerjangkaForm.value.sukuBunga / 100) * ((this.bulan / 30) / 365) * this.simpananBerjangkaForm.value.nominalPenempatan * 0.9;
          }

          tempObj.jumlahUangDisimpan = this.simpananBerjangkaForm.value.nominalPenempatan
          tempObj.totalBunga = totalBunga;
        }

        else {
          //====================================================
          if(this.simpananBerjangkaForm.value.jangkaWaktu == '12'){
            totalBunga = (this.simpananBerjangkaForm.value.sukuBunga / 100) * (365 / 365) * this.simulasiPokokBungaArr[i - 2].jumlahUangDisimpan * 0.9;
          }
          else{
            totalBunga = (this.simpananBerjangkaForm.value.sukuBunga / 100) * ((this.bulan / 30) / 365) * this.simulasiPokokBungaArr[i - 2].jumlahUangDisimpan * 0.9;
          }
          tempObj.jumlahUangDisimpan = parseInt(this.simulasiPokokBungaArr[i - 2].jumlahUangDisimpan) + parseInt(totalBunga);
          //====================================================

          //====================================================
          if(this.simpananBerjangkaForm.value.jangkaWaktu == '12'){
            totalBunga = (this.simpananBerjangkaForm.value.sukuBunga / 100) * (365 / 365) * tempObj.jumlahUangDisimpan * 0.9;
          }
          else{
            totalBunga = (this.simpananBerjangkaForm.value.sukuBunga / 100) * ((this.bulan / 30) / 365) * tempObj.jumlahUangDisimpan * 0.9;
          }
          tempObj.totalBunga = totalBunga;
          //====================================================
        }

        this.simulasiPokokBungaArr.push(tempObj);

      }

      console.log(this.simulasiPokokBungaArr);

    }
  }

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  formatCurrency(input, blur) {
    // get input value
    var input_val = input.target.value;
  
    // don't validate empty input
    if (input_val === "") { return; }
  
    // original length
    var original_len = input_val.length;
  
    // initial caret position
    var caret_pos = input.target.selectionStart;

    // remove all non-digits
    input_val = this.formatNumber(input_val);
    input_val = input_val;

    // final formatting
    if (blur === "blur") {
      // input_val += ".00";
      return false;
    }
  
    // send updated string to input
    input.target.value = input_val;

    this.simpananBerjangkaForm.patchValue({
      nominalPenempatan : input_val
    });
  }

}
