import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-general-ledger',
  templateUrl: './general-ledger.component.html',
  styleUrls: ['./general-ledger.component.sass']
})
export class GeneralLedgerComponent implements OnInit {

  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempStoreData: any;
  tempFilterData: any;
  coaArray: any = [];
  firstTime: boolean = true;

  generalLedgerForm = new FormGroup({
    storeId   : new FormControl('', [Validators.required]),
    storeName : new FormControl('', [Validators.required]),
    startDate : new FormControl('', [Validators.required]),
    endDate   : new FormControl('', [Validators.required]),
    tipeFilter: new FormControl('', [Validators.required]),
  });

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getStore();

    this.tempFilterData = [
      {
        "id": 'COA',
        "text": 'COA',
        "additional": 'COA'
      },
    ];
  }

  getStore(){
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        this.tempStoreData = this.responseApi.data.map(store => ({
          "id": store.id_store,
          "text": store.store_name,
          "additional": store
        }));

        this.tempStoreData.push({
          "id" : 'all',
          "text" : 'All',
          "additional" : 'all',
        });
      }
    )
  };

  changeStore(event: any){
    if (!this.firstTime){
      this.generalLedgerForm.patchValue({
        storeId   : event,
        storeName : this.tempStoreData.find(element => element.id == event).text,
      });
    } else {
      this.firstTime = false;
    }
  };

  changeFilter(event: any){
    // console.log(event); 
    // this.filterFunction(event);
  }

  filterFunction(event){
    var idToko = this.generalLedgerForm.get('namaToko').value;
    if (idToko != '' && event != 'All'){
      this.coaArray = [];
      this.http.get(this.ApiConfig.API_URL + "/filterFunction/" + event + "/" + idToko, this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200"){
          this.responseApi.data.forEach((element,index) => {
            var objPush = {
              "nama" : element.nama,
              "kode" : element.kode,
              "check" : true,
            };

            this.coaArray.push(objPush);
          });
        }
      });
    } else if (event == 'All') {
      
    } else {
      console.log('err');
    }
  }

  generateGeneralLedger(){
    this.http.post(this.ApiConfig.API_URL + "/generate/general-ledger", this.generalLedgerForm.value, this.httpOptions).subscribe((res) => {
      this.responseApi = res;
      if (this.responseApi.status == "200"){
        window.open(
          this.ApiConfig.API_URL + "/open-report?directory=" + this.responseApi.data.directory + "&pdfName=" + this.responseApi.data.name
        );
      }
    });
  }

}
