import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.sass']
})
export class IncomeStatementComponent implements OnInit {

  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempStoreData: any;

  firstTime: boolean = true;

  incomeStatementForm = new FormGroup({
    storeId   : new FormControl('', [Validators.required]),
    storeName : new FormControl('', [Validators.required]),
    startDate : new FormControl('', [Validators.required]),
    endDate : new FormControl('', [Validators.required]),
    datePeriod: new FormControl('', [Validators.required]),
  });

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getStore();
  }

  getStore(){
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        this.tempStoreData = this.responseApi.data.map(store => ({
          "id": store.id_store,
          "text": store.store_name,
          "additional": store
        }));

        this.tempStoreData.push({
          "id" : 'all',
          "text" : 'All',
          "additional" : 'all',
        });

        console.log(this.tempStoreData);
      }
    )
  };

  changeStore(event: any){
    if (!this.firstTime){
      this.incomeStatementForm.patchValue({
        storeId   : event,
        storeName : this.tempStoreData.find(element => element.id == event).text,
      });
    } else {
      this.firstTime = false;
    }
  };

  generateIncome(){
    this.http.post(this.ApiConfig.API_URL + "/generate/income-statement", this.incomeStatementForm.value, this.httpOptions).subscribe((res) => {
      this.responseApi = res;
      if (this.responseApi.status == "200"){
        window.open(
          this.ApiConfig.API_URL + "/open-report?directory=" + this.responseApi.data.directory + "&pdfName=" + this.responseApi.data.name
        );
      }
    });
  }

}
