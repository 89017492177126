import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { ApiConfig } from '../utility/ApiConfig';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.sass']
})

export class ServicesComponent {
  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private router: Router,
  ) { }

  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;
  
  checkRole(menu){
    this.http.get(this.ApiConfig.API_URL + '/check-role/' + this.ApiConfig.get("user-data").username+'/'+menu,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          if(!this.responseApi.data){
            Swal.fire("Unauthorized", "", "warning");
            setTimeout(() => {
              Swal.close();
              this.router.navigate(['/admin']);
            }, 1500);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}