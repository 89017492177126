import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../utility/ApiConfig';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})

export class HomeLayoutComponent implements OnInit {
	constructor(
	  private http: HttpClient,
    private router: Router,
    private ApiConfig: ApiConfig
  ) {
	}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      token: this.ApiConfig.USER_DATA.token,
    }),
  };

  responseApi: any;

  ngOnInit() { 

  }

  // checkUserAvailability(){
  //   this.http.get(this.ApiConfig.API_URL + '/check-login-availability/' + this.ApiConfig.USER_DATA.id_user, this.httpOptions).subscribe(
  //     (res) => {
  //       this.responseApi = res;
  //       if (!this.responseApi.data.userCanLogin) {
  //         Swal.fire("Mohon Maaf", "Stock opname sedang berlangsung. Silahkan menunggu beberapa saat lagi.", "error").then(() => {
  //           this.logout();
  //         });
  //       }
  //     }, (err) => {
  //       Swal.fire("Error", err.error.message, 'error');
  //     }
  //   );
  // }

  logout(){
    this.ApiConfig.clear();
    localStorage.clear();
    this.router.navigate(['/']);
  }

}

export function checkRole(menu){
  this.http.get(this.ApiConfig.API_URL + '/check-role/' + this.ApiConfig.get("user-data").username+'/'+menu,this.httpOptions).subscribe(
    (res) => {
      this.responseApi = res;
      if (this.responseApi.status == '200') {
        if(!this.responseApi.data){
          Swal.fire("Unauthorized", "", "warning").then(result =>{
            this.router.navigate(['/admin']);
          })
        }
      }
    },
    (err) => {
      console.log(err);
    }
  );
}
