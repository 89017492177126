import { Component, OnInit } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormArray,FormControl, Validators, AbstractControl, CheckboxControlValueAccessor } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ValidationErrors } from '@angular/forms'
import { ServicesComponent } from 'src/app/services/services.component';
import { Router } from '@angular/router';
import { parse } from 'querystring';
import * as moment from 'moment'

@Component({
  selector: 'app-repayment-demand',
  templateUrl: './repayment-demand.component.html',
  styleUrls: ['./repayment-demand.component.css']
})
export class RepaymentDemandComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private globalService : ServicesComponent,
    private router: Router
  ) { }

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  tempActiveLoan: any;
  tempLoanData: any;
  showBody : any = false; selesaiHitung: any = false; showPelunasan: boolean = false;
  picker: any;
  checked: any = false;
  penaltyNominal: any;
  penaltyPercent: any;
  diskonDenda: any; diskonBungaBerjalan: any; jumlahBayar: any;
  coaId: any;
  tempCoa: any;
  typeChoice: any = [{id : 'sebagian', text: 'Pelunasan Sebagian'},{id: 'seluruh', text: 'Pelunasan Seluruhnya'}, {id: 'pelunasan', text: 'Pelunasan'}];
  typeValue: any;
  pelunasanArr: Object[] = [];
  pelunasanTotal: number = 0;
  discBayar: number = 0; discInterest: number = 0;
  loanDetail: any;


  demandForm: FormGroup = new FormGroup ({
    loanId: new FormControl('', [Validators.required]),
    tanggalBayar:new FormControl('',[Validators.required]),
    type: new FormControl('', [Validators.required])
  })

  pelunasanForm: FormGroup = new FormGroup({
    pelunasanDetail : new FormArray([], [Validators.required])
  });

  ngOnInit(): void {
    this.getActiveLoan();
    this.getCoa();
  }

  onCoaIdChange(event: any){
    this.coaId = event;
  };

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  };

  getActiveLoan() {
    this.http.get(this.ApiConfig.API_URL + "/demand-loan", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempActiveLoan = this.responseApi.data;
          console.log(this.tempActiveLoan)
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  onChangeAutocomplete(e) {
    let value = e.value;
    this.http.get(this.ApiConfig.API_URL + "/loan/" + value, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempLoanData = this.responseApi.data.loanData;
        }
      }, 
      err => { console.log(err); }
    )
  }

  onChangeType(e){
    this.typeValue = e.value;
  }

  onChangeDatePicker() {
    // this.http.post(this.ApiConfig.API_URL + "/calculate-demand-loan-repayment", this.demandForm.value, this.httpOptions).subscribe(
    //   res => {
    //     this.responseApi = res;
    //     if(this.responseApi.status == "200"){
    //       this.showBody = true;
    //       // console.log(this.responseApi.data)
    //       this.tempLoanData.sisaPokok = this.responseApi.data.pokok;
    //       this.tempLoanData.sisaBunga = this.responseApi.data.bunga;
    //       this.tempLoanData.sisaDenda = this.responseApi.data.denda;
    //       this.tempLoanData.bungaBerjalan = this.responseApi.data.bungaBerjalan;
    //       this.tempLoanData.paidDenda = this.responseApi.data.denda;
    //       this.tempLoanData.paidPokok = this.responseApi.data.pokok;
    //       this.tempLoanData.paidBunga = this.responseApi.data.bunga;
    //       this.tempLoanData.paidBungaBerjalan = this.responseApi.data.bungaBerjalan;
    //       // this.tempLoanData.penalty = this.responseApi.data.penalty;
    //     }
    //   }, 
    //   err => {
    //     console.log(err);
    //   }
    // )

    this.selesaiHitung = false;
    this.showBody = false;
    this.showPelunasan = false;
    if (this.demandForm.get('type').value == 'sebagian' || this.demandForm.get('type').value == 'seluruh'){
      this.http.post(this.ApiConfig.API_URL + "/calculate-demand-loan-repayment", this.demandForm.value, this.httpOptions).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200"){
            if (this.responseApi.data.tunggakan){
              Swal.fire("Mohon Maaf", "Masih terdapat tunggakan, tidak dapat melakukan pelunasan sebagian", "error");
            } else {
              this.showBody = true;
              // console.log(this.responseApi.data)
              this.tempLoanData.sisaPokok = this.responseApi.data.pokok;
              this.tempLoanData.sisaBunga = this.responseApi.data.bunga;
              this.tempLoanData.sisaDenda = this.responseApi.data.denda;
              this.tempLoanData.bungaBerjalan = this.responseApi.data.bungaBerjalan;
              this.tempLoanData.paidDenda = this.responseApi.data.denda;
              this.tempLoanData.paidPokok = this.responseApi.data.pokok;
              this.tempLoanData.paidBunga = this.responseApi.data.bunga;
              this.tempLoanData.paidBungaBerjalan = this.responseApi.data.bungaBerjalan;
              // this.tempLoanData.penalty = this.responseApi.data.penalty;
            }

          }
        }, 
        err => {
          console.log(err);
        }
      )
    } else if (this.demandForm.get('type').value == 'pelunasan'){
      this.http.post(this.ApiConfig.API_URL + "/calculate-demand-loan-repayment-v2", this.demandForm.value, this.httpOptions).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200"){
            console.log(this.responseApi.data);
            this.generateRow(this.responseApi.data);
            this.loanDetail = this.responseApi.data;
          }
        }, 
        err => {
          console.log(err);
        }
      )
    } else {
      Swal.fire("Mohon Maaf", "Mohon mengisi tipe pelunasan", "error");
    }
  }

  generateRow(data){
    const pelunasanDetail : FormArray = this.pelunasanForm.get('pelunasanDetail') as FormArray;
    pelunasanDetail.clear();
    data.forEach((e,i) => {
      if (e.tenor != '0'){  
        pelunasanDetail.push(
          new FormGroup({
            id      : new FormControl(e.id, [Validators.required]),
            type    : new FormControl('principal', [Validators.required]),
            title   : new FormControl('Pokok Ke-' + e.tenor, [Validators.required]),
            amount  : new FormControl((parseFloat(e.principal) - parseFloat(e.paid_principal) - parseFloat(e.disc_principal)), [Validators.required]),
            disc    : new FormControl(0, [Validators.required]),
            payment : new FormControl(0, [Validators.required]),
          })
        );

        pelunasanDetail.push(
          new FormGroup({
            id      : new FormControl(e.id, [Validators.required]),
            type    : new FormControl('interest', [Validators.required]),
            title   : new FormControl('Bunga Ke-' + e.tenor, [Validators.required]),
            amount  : new FormControl((parseFloat(e.interest) - parseFloat(e.paid_interest) - parseFloat(e.disc_interest)), [Validators.required]),
            disc    : new FormControl(0, [Validators.required]),
            payment : new FormControl(0, [Validators.required]),
          })
        );

        pelunasanDetail.push(
          new FormGroup({
            id      : new FormControl(e.id, [Validators.required]),
            type    : new FormControl('late_charge', [Validators.required]),
            title   : new FormControl('Denda Ke-' + e.tenor, [Validators.required]),
            amount  : new FormControl((parseFloat(e.late_charge) - parseFloat(e.paid_late_charge) - parseFloat(e.disc_late_charge)), [Validators.required]),
            disc    : new FormControl(0, [Validators.required]),
            payment : new FormControl(0, [Validators.required]),
          })
        );
        // this.pelunasanArr.push(objPokok, objBunga, objDenda);
      }
    });


    // console.log(pelunasanDetail);
    this.showPelunasan = true;
  }

  hitungLoan() {
    // // console.log(this.checked)
    // this.diskonDenda = this.diskonDenda != undefined ? this.diskonDenda : 0;
    // this.diskonBungaBerjalan = this.diskonBungaBerjalan  != undefined ? this.diskonBungaBerjalan : 0;
    // this.penaltyNominal = this.penaltyNominal  != undefined ? this.penaltyNominal : 0;
    // this.penaltyPercent = this.penaltyPercent  != undefined ? this.penaltyPercent : 0;

    // this.tempLoanData.paidDenda = this.tempLoanData.sisaDenda - this.diskonDenda;
    // this.tempLoanData.paidBungaBerjalan = this.tempLoanData.bungaBerjalan - this.diskonBungaBerjalan;
    // //hitung penalty
    // if(!this.checked) {
    //   let percentage = this.penaltyPercent/100
    //   this.tempLoanData.paidPenalty = percentage * this.tempLoanData.sisaPokok;
    // } 
    // if(this.checked) {
    //   this.tempLoanData.paidPenalty = parseFloat(this.penaltyNominal);
    // }

    // this.hitungTotalBayar();

    // console.log(this.checked)
    this.diskonDenda = this.diskonDenda != undefined ? this.diskonDenda : 0;
    this.diskonBungaBerjalan = this.diskonBungaBerjalan  != undefined ? this.diskonBungaBerjalan : 0;
    this.penaltyNominal = this.penaltyNominal  != undefined ? this.penaltyNominal : 0;
    this.penaltyPercent = this.penaltyPercent  != undefined ? this.penaltyPercent : 0;

    this.tempLoanData.paidDenda = this.tempLoanData.sisaDenda - this.diskonDenda;
    this.tempLoanData.paidBungaBerjalan = this.tempLoanData.bungaBerjalan - this.diskonBungaBerjalan;
    this.tempLoanData.paidPokok = this.tempLoanData.sisaPokok - this.discBayar;
    this.tempLoanData.paidBunga = this.tempLoanData.sisaBunga - this.discInterest;
    //hitung penalty
    if(!this.checked) {
      let percentage = this.penaltyPercent/100
      this.tempLoanData.paidPenalty = percentage * this.tempLoanData.sisaPokok;
    } 
    if(this.checked) {
      this.tempLoanData.paidPenalty = parseFloat(this.penaltyNominal);
    }

    this.hitungTotalBayar();
  }

  hitungTotalBayar() {
    // this.tempLoanData.totalBayar = 
    // this.tempLoanData.sisaPokok + this.tempLoanData.sisaBunga + this.tempLoanData.paidDenda + 
    // this.tempLoanData.paidBungaBerjalan + this.tempLoanData.paidPenalty;
    // this.selesaiHitung = true;
    // console.log(this.jumlahBayar);


    if (this.typeValue == 'seluruh'){
      this.tempLoanData.totalBayar = 
      this.tempLoanData.paidPokok + this.tempLoanData.paidBunga + this.tempLoanData.paidDenda + 
      this.tempLoanData.paidBungaBerjalan + this.tempLoanData.paidPenalty;
    } else if (this.typeValue == 'sebagian') {
      this.tempLoanData.totalBayar = 
      parseFloat(this.jumlahBayar) + this.tempLoanData.sisaBunga + this.tempLoanData.paidDenda + 
      this.tempLoanData.paidBungaBerjalan + this.tempLoanData.paidPenalty;
    } else {
      this.pelunasanTotal = 0;
      this.pelunasanForm.get('pelunasanDetail')['controls'].forEach((e, i) => {
        var payment = parseFloat(e.value.payment.toString().replace(/[^0-9,]+/g,"").replace(/[,]+/g,"."));
        var disc    = parseFloat(e.value.disc.toString().replace(/[^0-9,]+/g,"").replace(/[,]+/g,"."));

        this.pelunasanTotal += payment;
      });
    }

    this.selesaiHitung = true;
  }

  proses() {
    let date = moment(this.demandForm.value.tanggalBayar).format('DD MMMM YYYY')
    console.log(this.typeValue);
    if (this.typeValue == 'pelunasan'){
      let obj = {
        cifId   : this.tempLoanData.cif_id,
        amount  : this.pelunasanTotal,
        loanDetail : this.loanDetail,
        detail  : this.pelunasanForm.value.pelunasanDetail
      };

      this.http.post(this.ApiConfig.API_URL + "/demand-loan-process-repayment-v2", obj, this.httpOptions).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200") {
            Swal.fire("Success", "Your input has been saved.", "success");
            // this.location.back();
          }
        }, 
        err => {
          console.log(err);
        }
      )
    } else {
      let obj = {
        loanId: this.tempLoanData.id,
        cifId: this.tempLoanData.cif_id,
        amount: this.tempLoanData.totalBayar.toFixed(2),
        denda : this.tempLoanData.paidDenda,
        bungaBerjalan : this.tempLoanData.paidBungaBerjalan,
        penalty : this.tempLoanData.paidPenalty,
        pokok : this.tempLoanData.paidPokok,
        bunga : this.tempLoanData.paidBunga,
        discPokok : this.discBayar,
        discBunga : this.discInterest,
        discDenda : this.diskonDenda,
        discBungaBerjalan : this.diskonBungaBerjalan,
        type: 'D',
        note: 'Pelunasan Term Loan Sampai Tanggal ' + date + ' Kode Pinjaman ' + this.tempLoanData.id,
        create_operator: this.ApiConfig.USER_DATA.id_user,
        tanggalBayar: this.demandForm.value.tanggalBayar,
        coaId : this.coaId,
        paymentType : this.typeValue,
        // detail : 
      };
      
      this.http.post(this.ApiConfig.API_URL + "/demand-loan-process-repayment", obj, this.httpOptions).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200") {
            Swal.fire("Success", "Your input has been saved.", "success");
            // this.location.back();
          }
        }, 
        err => {
          console.log(err);
        }
      )
    }

    // this.http.post(this.ApiConfig.API_URL + "/demand-loan-process-repayment", obj, this.httpOptions).subscribe(
    //   res => {
    //     this.responseApi = res;
    //     if(this.responseApi.status == "200") {
    //       Swal.fire("Success", "Your input has been saved.", "success");
    //       this.location.back();
    //     }
    //   }, 
    //   err => {
    //     console.log(err);
    //   }
    // )
  }

  reloadPage(){
    window.location.reload();
  }

  formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  };

  setFormat(index, element){
    
    // const value = this.projectForm.get(element).value;
    const detailData : FormArray = this.pelunasanForm.get('pelunasanDetail') as FormArray;
    const value = detailData.at(index).get(element).value;

    detailData.at(index).patchValue({
      [element] : this.formatRupiah(value)
    })
  }

  checkFocusDetail(index, element){
    this.selesaiHitung = false;
    const detailData : FormArray = this.pelunasanForm.get('pelunasanDetail') as FormArray;
    const value = detailData.at(index).get(element).value;

    detailData.at(index).patchValue({
      [element] : Number(value.toString().replace(/[^0-9,]+/g,"").replace(/[,]+/g,"."))
    })
  }

}
