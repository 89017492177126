import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;
import * as moment from 'moment';

@Component({
  selector: 'app-general-journal',
  templateUrl: './general-journal.component.html',
  styleUrls: ['./general-journal.component.sass']
})
export class GeneralJournalComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempStoreData: any;

  generalJournalFilterForm = new FormGroup({
    startDate     : new FormControl('', [Validators.required]),
    endDate       : new FormControl('', [Validators.required]),
    idStore       : new FormControl('', [Validators.required]),
  });

  // deleteData(data, row) {
  //   this.http.delete(this.ApiConfig.API_URL + "/coa/" + data.id_coa, this.httpOptions).subscribe((res) => {
  //       this.responseApi = res;
  //       if (this.responseApi.status == "200") {
  //           Swal.fire("Selamat", "Anda berhasil menghapus COA", "success");
  //           row.remove();
  //       } else {
  //           Swal.fire("Maaf", "Anda gagal menghapus COA", "error");
  //       }
  //   }, (err) => {
  //       Swal.fire("Maaf", "Anda gagal menghapus COA", "error");
  //   });
  // }

  editData(data) {
      this.router.navigate(['/admin/general-journal/edit/' + data.id_journal]);
  }

  ngOnInit(): void {
    this.getStore();
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/general-journal",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {
              title: 'No. Ref Jurnal',
              data: 'id_journal'
          },
          {
              title: 'Tanggal Transaksi',
              data: null,
              render: function (data, type, row){
                return (moment(data.transaction_date).format('DD-MM-YYYY'));
              }
          },
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button>';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          // $('.delete-btn', row).bind('click', () => {
          //     self.deleteData(data, row);
          // });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          return row;
      }
    };
  }

  generateJournal(){
    let startDate = moment(this.generalJournalFilterForm.get('startDate').value).format("yyyy-MM-DD");
    let endDate = moment(this.generalJournalFilterForm.get('endDate').value).format("yyyy-MM-DD");
    let idStore = this.generalJournalFilterForm.get('idStore').value;

    window.open(
      this.ApiConfig.API_URL + "/general-journal/filter/?startDate=" + startDate 
      + "&endDate=" + endDate + "&idStore=" + idStore, '_blank'
    );
  }

  getStore(){
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe((res) => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        this.tempStoreData = this.responseApi.data.map(s => ({
          "id"          : s.id_store,
          "text"        : s.id_store + ' - ' + s.store_name,
          "additional"  : s
        }));
      }
  }, (err) => {
      Swal.fire("Maaf", "Anda gagal menghapus COA", "error");
  });
  }

  changeStore(event: any){
    this.generalJournalFilterForm.patchValue({
      idStore: event
    })
  }

}
