import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ReplaySubject } from "rxjs"; 
import { ServicesComponent } from 'src/app/services/services.component';

@Component({
  selector: 'app-edit-company-resubmission',
  templateUrl: './edit-company-resubmission.component.html',
  styleUrls: ['./edit-company-resubmission.component.css']
})
export class EditCompanyResubmissionComponent implements OnInit {
  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router : Router,
    private globalService : ServicesComponent,
  ) { }

  idParam : any;
  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  companyForm: FormGroup = new FormGroup({
    cpCif: new FormControl('', [Validators.required]),
    cpName: new FormControl('', [Validators.required]),
    cpFieldOfWork: new FormControl('', [Validators.required]),
    cpPlaceOfEstablishment: new FormControl('', [Validators.required]),
    cpDeedNumber: new FormControl('', [Validators.required]),
    cpDateOfEstablisment: new FormControl('', [Validators.required]),
    cpDeedIssuedPlace: new FormControl('', [Validators.required]),
    cpDeedIssuedDate: new FormControl('', [Validators.required]),
    cpNPWP: new FormControl('', [Validators.required]),

    cpAddress: new FormControl('', [Validators.required]),
    cpNationality: new FormControl('', [Validators.required]),
    cpProvince: new FormControl('', [Validators.required]),
    cpDistrict: new FormControl('', [Validators.required]),
    cpSubDistrict: new FormControl('', [Validators.required]),
    cpCity: new FormControl('', [Validators.required]),
    cpPostalCode: new FormControl('', [Validators.required]),
    cpOccupiedSince: new FormControl('', [Validators.required]),

    cpPhoneNumber: new FormControl('', [Validators.required]),
    cpFaxNumber: new FormControl(),
    cpEmail: new FormControl('', [Validators.required]),
    cpPicNumber: new FormControl('', [Validators.required]),
    cpHandphoneNumber: new FormControl('', [Validators.required]),

    picName: new FormControl('', [Validators.required]),
    picPosition: new FormControl('', [Validators.required]),
    picIdentityType: new FormControl('', [Validators.required]),
    picIdentityNumber: new FormControl('', [Validators.required]),

    cpFundSource: new FormControl('', [Validators.required]),
    cpAccountPurpose: new FormControl('', [Validators.required]),
    cpMonthlyIncome: new FormControl('', [Validators.required]),
    cpAvgTransaction: new FormControl('', [Validators.required]),
    createdBy: new FormControl('', [Validators.required]),
    documentForm: new FormArray([
      new FormGroup({
        docType: new FormControl(),
        docDesc: new FormControl(),
        docDate: new FormControl(),
        docFileName: new FormControl(),
        docFileExt: new FormControl(),
        docFileBuffer: new FormControl(),
        companyId: new FormControl(),
        createdBy: new FormControl(),
      })
    ], [Validators.required])
  });
  tempAttachment: any;
  tokenRaja : any;
  errArr : any;
  dataQuestion : any; tempQuestion : any;
  company_id : any;
  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  etcFund : any; etcPurpose : any;
  fundSource : any = 
  [
    {
      name : "Penghasilan",
      checked : false
    },
    {
      name : "Warisan",
      checked : false
    },
    {
      name : "Hibah",
      checked : false
    },
    {
      name : "Undian",
      checked : false
    },
    {
      name : "Dari Suami / Istri",
      checked : false
    },
    {
      name : "Bunga Simpanan",
      checked : false
    },
    {
      name : "Dari Orang Tua",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];
  accountPurpose : any = 
  [
    {
      name : "Investasi",
      checked : false
    },
    {
      name : "Pendidikan",
      checked : false
    },
    {
      name : "Niaga",
      checked : false
    },
    {
      name : "Modal",
      checked : false
    },
    {
      name : "Gaji",
      checked : false
    },
    {
      name : "Biaya Rutin",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
    }
    this.globalService.checkRole("CIF Company");
    this.getQuestion();
    this.getProvince();
  }

  getData(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.companyForm.patchValue({
            cpCif: this.responseApi.data.cif_code,
            cpName: this.responseApi.data.company_name,
            cpFieldOfWork: this.responseApi.data.field_of_work,
            cpPlaceOfEstablishment: this.responseApi.data.place_of_establishment,
            cpDeedNumber: this.responseApi.data.deed_number,
            cpDateOfEstablisment: this.responseApi.data.date_of_establishment,
            cpDeedIssuedPlace: this.responseApi.data.deed_issued_place,
            cpDeedIssuedDate: this.responseApi.data.deed_issued_date,
            cpNPWP: this.responseApi.data.npwp,
        
            cpAddress: this.responseApi.data.address,
            cpNationality: this.responseApi.data.nationality,
            cpProvince: this.responseApi.data.province,
            cpDistrict: this.responseApi.data.district,
            cpSubDistrict: this.responseApi.data.sub_district,
            cpCity: this.responseApi.data.city,
            cpPostalCode: this.responseApi.data.postal_code,
            cpOccupiedSince: this.responseApi.data.occupied_since,
        
            cpPhoneNumber: this.responseApi.data.phone_number,
            cpFaxNumber: this.responseApi.data.fax_number,
            cpEmail: this.responseApi.data.email,
            cpPicNumber: this.responseApi.data.pic_number,
            cpHandphoneNumber: this.responseApi.data.handphone_number,
        
            picName: this.responseApi.data.pic_name,
            picPosition: this.responseApi.data.pic_position,
            picIdentityType: this.responseApi.data.pic_identity_type,
            picIdentityNumber: this.responseApi.data.pic_identity_number,
        
            cpFundSource: this.responseApi.data.fund_source,
            cpAccountPurpose: this.responseApi.data.account_purpose,
            cpMonthlyIncome: this.responseApi.data.monthly_income,
            cpAvgTransaction: this.responseApi.data.average_transaction,
            createdBy: this.responseApi.data.created_by,
          });
        this.getQuestion();
        this.getProvince();
        this.parseCheckbox();
        let pipe2 = new DatePipe('en-GB');
        this.companyForm.value.cpDeedIssuedDate = pipe2.transform(this.companyForm.value.cpDeedIssuedDate,'yyyy-MM-dd');
        this.companyForm.value.cpDateOfEstablisment = pipe2.transform(this.companyForm.value.cpDateOfEstablisment,'yyyy-MM-dd');
        this.company_id = this.responseApi.data.id;
        this.getAttachment(this.company_id);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAttachment(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company-attachment/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.tempAttachment = this.responseApi.data;

          for (var x = 0; x < this.tempAttachment.length; x++) {
            const attachmentArr: FormArray = this.companyForm.get('documentForm') as FormArray;
            attachmentArr.push(new FormGroup({
              docType: new FormControl(this.tempAttachment[x].document_name, []),
              docDesc: new FormControl(this.tempAttachment[x].document_description, []),
              docDate: new FormControl(this.tempAttachment[x].document_date, []),
              docFileName: new FormControl(this.tempAttachment[x].file_path, []),
              docFileExt: new FormControl(this.tempAttachment[x].file_extension, []),
              docFileBuffer: new FormControl(this.tempAttachment[x].buffer, []),
              companyId: new FormControl(this.tempAttachment[x].company_id, []),
              createdBy: new FormControl(this.tempAttachment[x].created_by, []),
            }));
          }
        }
        this.removeRow(0);
      },
      (err) => {
        console.log(err);
      }
    );   
  }

  parseCheckbox(){
    //Fund Source
    let foundBoolean = false; 
    let fund = this.companyForm.value.cpFundSource.split(';');
    fund.splice(-1);
    fund.forEach((elementFund, indexFund) => {
      foundBoolean = false;
      this.fundSource.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          foundBoolean = true;
        }

        if (this.fundSource.length == (indexSource + 1) && !foundBoolean){
          // etcBoolean = true;
          elementSource.checked = true;
          this.etcFund = elementFund;
        }
      });
    });
    //Account Purpose
    let purposeBoolean = false;
    let purpose = this.companyForm.value.cpAccountPurpose.split(';');
    purpose.splice(-1);
    purpose.forEach((elementFund, indexFund) => {
      purposeBoolean = false;
      this.accountPurpose.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          purposeBoolean = true;
        }

        if (this.accountPurpose.length == (indexSource + 1) && !purposeBoolean){
          elementSource.checked = true;
          this.etcPurpose = elementFund;
        }
      });
    });
  }


  getTokenRaja(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.code == "200"){
          this.tokenRaja = this.responseApi.token;
          this.getProvince();
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvince(){
    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

  onProvinceChange(event: any){
    this.companyForm.patchValue({
      cpProvince: event
    });
    this.provinceId = this.companyForm.controls['cpProvince'].value;
    this.getCity();
  }

  getCity(){
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;
          this.tempCity = this.dataCity.map(city => ({
            "id": city.code,
            "text": city.name,
            "additional": city
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onCityChange(event: any){
    this.companyForm.patchValue({
      cpCity: event
    });
    this.cityId = this.companyForm.controls['cpCity'].value;
    this.getDistrict();
  }

  getDistrict(){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;
          this.tempDistrict = this.dataDistrict.map(district => ({
            "id": district.code,
            "text": district.name,
            "additional": district
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.companyForm.patchValue({
      cpDistrict: event
    });
    this.districtId = this.companyForm.controls['cpDistrict'].value;
    this.getSubDistrict();
  }

  getSubDistrict(){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
            "id": subdistrict.code,
            "text": subdistrict.name,
            "additional": subdistrict
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubdistrictChange(event: any){
    this.companyForm.patchValue({
      cpSubDistrict: event
    });
  }

  getQuestion(){
    this.http.get(this.ApiConfig.API_URL + "/cif-company-question", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.dataQuestion = this.responseApi.data;
        }        
      },
      err => {
        console.log(err);
      }
    );
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  changeFund(e){
    this.fundSource[7].value = this.etcFund;
  }

  changeAccPurpose(e){
    this.accountPurpose[6].value = this.etcPurpose;
  }

  addRow() {
    const documentForm: FormArray = this.companyForm.get('documentForm') as FormArray;
    documentForm.push(new FormGroup({
      docType: new FormControl('', [Validators.required]),
      docDesc: new FormControl('', [Validators.required]),
      docDate: new FormControl('', [Validators.required]),
      docFileName: new FormControl('', [Validators.required]),
      docFileExt: new FormControl('', [Validators.required]),
      docFileBuffer: new FormControl('', [Validators.required]),
      companyId: new FormControl(),
      createdBy: new FormControl(),
    }))
  }

  removeRow(index) {
    const documentForm: FormArray = this.companyForm.get('documentForm') as FormArray;
    documentForm.removeAt(index);
  }

  feFileName : any;

  uploadFile(event,i) {
    let fileName = event.target.files[0].name;
    this.feFileName = fileName;
    let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let name = this.companyForm.value.cpName+" - "+fileName.substring(0,fileName.indexOf("."));
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.companyForm.get('documentForm')['controls'][i].patchValue({
        docFileName : name,
        docFileExt : extension,
        docFileBuffer : base64
      });
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  downloadData(i){
    const download: FormArray = this.companyForm.get('documentForm') as FormArray;
    let file = download.controls[i]
    let fileName = file.value.docFileName +"."+ file.value.docFileExt;
    window.open(this.ApiConfig.API_URL + '/download-attachment?name='+fileName);    
  }


  compileCheckbox(){
    let fund = "";
    this.fundSource.forEach((e,i) => {
      if(e.checked && i != 7){
        fund = fund + e.name+";";
      } else if (e.checked && i == 7){
        fund = fund + e.value+";";
      }
    });
    
    let purpose = "";
    this.accountPurpose.forEach((e,i) => {
      if(e.checked && i != 6){
        purpose = purpose + e.name+";";
      } else if (e.checked && i == 6){
        purpose = purpose + e.value+";";
      }
    });
    this.companyForm.patchValue({
      cpFundSource : fund,
      cpAccountPurpose : purpose
    });
  }

  validateForm(){
    let pipe = new DatePipe('en-GB');
    this.companyForm.value.cpDeedIssuedDate = pipe.transform(this.companyForm.value.cpDeedIssuedDate,'yyyyMMdd');
    this.companyForm.value.cpDateOfEstablisment = pipe.transform(this.companyForm.value.cpDateOfEstablisment,'yyyyMMdd');
    let errArr = [];
    Object.keys(this.companyForm.controls).forEach(key => {
      if(this.companyForm.controls[key].errors != null){
        errArr.push(key);
      }
    });
  }

  addCifCompany(){
    this.compileCheckbox();
    this.validateForm();
    // if(1==1){
    if(this.companyForm.valid){
      this.http.post(this.ApiConfig.API_URL + "/cif-company/", this.companyForm.value, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else {
        this.companyForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
    }
  }


}
