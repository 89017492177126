import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-demand-loan',
  templateUrl: './dialog-demand-loan.component.html',
  styleUrls: ['./dialog-demand-loan.component.css']
})
export class DialogDemandLoanComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private ApiConfig: ApiConfig,
    public dialogRef: MatDialogRef<DialogDemandLoanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  showTable : boolean = false;
  
  loanData: any;
  paymentData: any; totalPaid: any; totalRemaining: any;

  paymentId: any; loanId: any;
  masterLoan: any;
  cDate: any;


  ngOnInit(): void {
    // console.log(this.data);
    this.paymentId = this.data.id;
    this.loanId = this.data.demand_loan_id;
    this.totalPaid = 0;
    this.getPaymentInfo();
  }

  getPaymentInfo(){
    this.http.get(this.ApiConfig.API_URL + '/demand-loan-payment-info/' + this.paymentId + '/' + this.loanId, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        // if(this.responseApi.status == "200"){
        //   // console.log(this.responseApi.data)
        //   this.loanData = this.responseApi.data.loan_data;
        //   this.paymentData = this.responseApi.data.loan_detail;
        //   this.paymentData.forEach((e,i,a) => {
        //     if (i == 0){
        //       e.actRemain = parseFloat(this.loanData.principal);
        //     } else if (i == (this.paymentData.length - 1)){
        //       e.actRemain = 0;
        //     } 
        //     else {
        //       e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.principal);
        //     }
        //     if(this.totalRemaining == undefined && e.remaining_payment == '0.00') {
        //       this.totalRemaining = a[0].remaining_payment;
        //     }
        //     this.totalPaid = this.totalPaid + parseFloat(e.paid_principal) + parseFloat(e.paid_interest);
        //     e.payment_date = moment(e.payment_date).format('DD/MM/yyyy');
        //     e.total_paid = parseFloat(e.paid_principal) + parseFloat(e.paid_interest);
        //     e.total_due = parseFloat(e.principal) + parseFloat(e.interest);
        //   })
        //   this.showTable = true;
        // }
        if(this.responseApi.status == "200"){
          this.loanData = this.responseApi.data.loan_data;
          this.paymentData = this.responseApi.data.loan_detail;
          this.masterLoan = this.responseApi.data.master_loan;
          this.cDate = moment(this.masterLoan.c_date).format('DD-MM-YYYY');
          this.totalPaid = 0;
          this.paymentData.forEach((e,i,a) => {
            e.status = this.masterLoan.status;
            console.log(e.status);
            if (i == 0){
              e.actRemain = parseFloat(this.loanData.principal) - parseFloat(e.paid_principal_first);
            } else if (i == (this.paymentData.length - 1)){
              e.actRemain = 0;
            } 
            else {
              if (e.status == 'INACTIVE'){
                e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.paid_principal);
              } else {
                if (e.paid_principal == 0 && e.paid_principal_first == 0){
                  e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.principal);
                } else {
                  e.actRemain = parseFloat(this.paymentData[i-1].actRemain) - parseFloat(e.paid_principal) - parseFloat(e.paid_principal_first);
                }
              }
                   
            }
            if(this.totalRemaining == undefined && e.remaining_payment == '0.00') {
              this.totalRemaining = a[0].remaining_payment;
            }
            
            e.payment_date = moment(e.payment_date).format('DD/MM/yyyy');
            e.total_paid = parseFloat(e.paid_principal) + parseFloat(e.paid_interest) + parseFloat(e.late_charge) + parseFloat(e.paid_principal_first);
            this.totalPaid += parseFloat(e.paid_principal) + parseFloat(e.paid_interest) + parseFloat(e.late_charge) + parseFloat(e.paid_principal_first);
            // if (e.late_charge_paid){
            //   e.total_paid = parseFloat(e.paid_principal) + parseFloat(e.paid_interest) + parseFloat(e.late_charge);
            //   this.totalPaid += parseFloat(e.paid_principal) + parseFloat(e.paid_interest) + parseFloat(e.late_charge);
            // } else {
            //   e.total_paid = parseFloat(e.paid_principal) + parseFloat(e.paid_interest);
            //   this.totalPaid += parseFloat(e.paid_principal) + parseFloat(e.paid_interest);
            // }
            e.total_due = parseFloat(e.principal) + parseFloat(e.interest);
          });
          this.showTable = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
