import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-edit-coa',
  templateUrl: './edit-coa.component.html',
  styleUrls: ['./edit-coa.component.sass']
})
export class EditCoaComponent implements OnInit {


  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) { }

  coaForm = new FormGroup({
    coaCode       : new FormControl('', [Validators.required]),
    coaName       : new FormControl('', [Validators.required]),
    coaSubtype    : new FormControl('', [Validators.required]),
    normalBalance : new FormControl('', [Validators.required]),
  });

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;
  tempCoaSubtype: any;
  idParam: any;

  ngOnInit(): void {
    this.getCoaSubtype();
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
    }
  }

  getData(idParam){
    this.http.get(this.ApiConfig.API_URL + "/coa/" + idParam, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          var data = this.responseApi.data;
          this.coaForm.patchValue({
            coaSubtype    : data.coa_subtype,
            coaCode       : data.code,
            coaName       : data.coa_name,
            normalBalance : data.normal_balance
          })
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

  editCoa(){
    this.http.put(this.ApiConfig.API_URL + "/coa/" + this.idParam, this.coaForm.value, this.httpOptions).subscribe(
      res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil mengedit COA", "success").then(() => {
              this.router.navigate(['/admin/coa']);
            });
          } else {
            Swal.fire("Maaf", "Anda gagal mengedit COA", "error")
          }
      },
      err => {
        Swal.fire("Maaf", "Anda gagal mengedit COA", "error")
      }
    );
  }

  getCoaSubtype(){
    this.http.get(this.ApiConfig.API_URL + "/coa-subtype", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempCoaSubtype = this.responseApi.data.map(coa => ({
            "id"    : coa.id_subtype_coa,
            "text"  : coa.code + ' - ' + coa.coa_subtype_name,
            "additional"  : coa
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  };

  onChangeCoaSubtype(event: any){
    this.coaForm.patchValue({
      coaType : event
    });
  }

}
