import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-demand-loan',
  templateUrl: './demand-loan.component.html',
  styleUrls: ['./demand-loan.component.css']
})
export class DemandLoanComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/demand-loan",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'ID',data:'id'},
          {title: 'No. CIF',data:'cif_id'},
          {title: 'Nama',data:'full_name'},
          {
            data: null, title: 'Action', defaultContent: "",
            render: function (data, type, row) {
              return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button>';

            }
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.edit-btn', row).unbind('click');
          $('.add-btn', row).unbind('click');
          $('.edit-btn', row).bind('click', () => {
              self.addData(data);
          });
          $('.add-btn', row).bind('click', () => {
              self.addData(data);
          });
          return row;
      } 
    };
  }

  editData(data){

  }

  addData(data){
    this.router.navigate(['/admin/demand-loan-info/' + data.id ]);
  }

}
