import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;


@Component({
  selector: 'app-add-coa-type',
  templateUrl: './add-coa-type.component.html',
  styleUrls: ['./add-coa-type.component.sass']
})
export class AddCoaTypeComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  tipeCoaForm = new FormGroup({
    kodeCoa: new FormControl('', [Validators.required]),
    tipeCoa: new FormControl('', [Validators.required]),
  });

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;

  ngOnInit(): void {
  }

  addTipe(){
    this.http.post(this.ApiConfig.API_URL + "/coa-type", this.tipeCoaForm.value, this.httpOptions).subscribe(
      res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil menambahkan Tipe COA", "success").then(() => {
              this.router.navigate(['/admin/coa-type']);
            });
          } else {
            Swal.fire("Maaf", "Anda gagal menambahkan Tipe COA", "error")
          }
      },
      err => {
        Swal.fire("Maaf", "Anda gagal menambahkan Tipe COA", "error")
      }
    );
  }

}
