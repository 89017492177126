import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../utility/ApiConfig';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogTermLoanComponent } from './dialog-term-loan/dialog-term-loan.component';

declare function setDatatableSearch(): any;

@Component({
  selector: 'app-term-loan',
  templateUrl: './term-loan.component.html',
  styleUrls: ['./term-loan.component.css']
})
export class TermLoanComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    public dialog : MatDialog,
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/term-loan",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'ID',data:'id'},
          {title: 'No. CIF',data:'cif_id'},
          {title: 'Nama',data:'full_name'},
          {
            data: null, title: 'Action', defaultContent: "",
            render: function (data, type, row) {
                return '<button class="btn view-btn"></button>';
            }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.edit-btn', row).unbind('click');
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          $('.view-btn', row).bind('click', () => {
              self.viewData(data);
          });
          return row;
      } 
    };
  }

  editData(data) {

  }

  viewData(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      top: '0',
      left : '0'
    }
    this.dialog.open(DialogTermLoanComponent , {
      data: [data.id,data.loan_id],
      position : {
        top : '0'
      },
      backdropClass: 'dialog-blur'
    });
  }

  cronDenda() {
    this.http.get(this.ApiConfig.API_URL + '/cron-loan-penalty', this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
