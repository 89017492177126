import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repayment-main',
  templateUrl: './repayment-main.component.html',
  styleUrls: ['./repayment-main.component.sass']
})
export class RepaymentMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
