import { CurrencyPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface arrAccountNumber {account_number: string}

@Component({
  selector: 'app-add-keluar-bank',
  templateUrl: './add-keluar-bank.component.html',
  styleUrls: ['./add-keluar-bank.component.css']
})
export class AddKeluarBankComponent implements OnInit {

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private route: ActivatedRoute, 
    private ApiConfig: ApiConfig,
    private currencyPipe : CurrencyPipe
  ) { }

  transForm: FormGroup = new FormGroup({
    transTOS_Sender: new FormControl({value: '', disabled: true}),
    transDate: new FormControl({value: '', disabled: true}, [Validators.required]),
    transAccNoSender: new FormControl('', [Validators.required]),
    transNameSender: new FormControl({value: '', disabled: true}),
    transAccNoReceiver: new FormControl('', [Validators.required]),
    transNameReceiver: new FormControl('', [Validators.required]),
    transAmount: new FormControl('', [Validators.required]),
    transAmountText: new FormControl('', [Validators.required]),
    transNews: new FormControl('', [Validators.required]),
    branch: new FormControl('', [Validators.required]),
    create_name: new FormControl('', [Validators.required]),
    cif_sender: new FormControl(''),
    cif_receiver: new FormControl(''),
    namaBankTujuan: new FormControl('', [Validators.required]),
    coaId: new FormControl('', [Validators.required]),
  })

  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any; tempStore:any;

  dtAccountType = [{'text' : 'Tabungan Nusantara', 'id' : 'tabungan-nusantara'}, {'text' : 'Tabungan Nusantara Bisnis', 'id' : 'tabungan-nusantara-bisnis'}, {'text' : 'Tabungan Nusantara Karyawan', 'id' : 'tabungan-nusantara-karyawan'}]

  tempAccountNumber:any;
  tempCoa: any;

  arrAccountNumber:any;
  options: arrAccountNumber[] = [];
  filteredOptions: Observable<arrAccountNumber[]>;

  ngOnInit(): void {
    this.getAccountNumber();
    this.getStoreData();
    this.getCoa();
    this.transForm.get("create_name").setValue(this.ApiConfig.USER_DATA.id_user);
    this.transForm.get("branch").setValue(parseInt(this.ApiConfig.USER_DATA.id_store));
    this.transForm.get("transDate").setValue(moment().format('YYYY-MM-DD'));
  }

  getStoreData(){
    this.http.get(this.ApiConfig.API_URL + "/store", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          // console.log(this.responseApi);
          let tempData = this.responseApi.data;
          this.tempStore = tempData.map(city => ({
            "id_store": parseInt(city.id_store),
            "store_name": city.store_name,
            "additional": city
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  GetDataSender(){
    this.transForm.get('transTOS_Sender').setValue(this.transAccNoSender.value.account_type);
    this.transForm.get('transNameSender').setValue(this.transAccNoSender.value.full_name);
    this.transForm.get('cif_sender').setValue(this.transAccNoSender.value.no_first_cif);
    this.getEndingBalance(this.transAccNoSender.value.account_number)
  }

  getEndingBalance(idParam){
    this.http.get(this.ApiConfig.API_URL + "/ending-balance/" + idParam, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      let amount = 0;
      if(this.responseApi.data.length > 0){
        amount = this.responseApi.data[0].ending_balance;

        if (amount < 0){
          $('#balance').addClass('minus-amount');
        } else {
          $('#balance').removeClass('minus-amount');
        }
      }
      $("#balance").html(this.formatRupiah(amount));
    });
  }
  
  displayFn(user: arrAccountNumber): string {
    return user && user.account_number ? user.account_number : '';
  }

  private _filter(account_number: string): arrAccountNumber[] {
    const filterValue = account_number.toLowerCase();

    return this.options.filter(option => option.account_number.toLowerCase().includes(filterValue));
  }

  getAccountNumber(){
    this.http.get(this.ApiConfig.API_URL + "/daily-savings-table", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tempAccountNumber = this.responseApi.data;
          console.log(this.tempAccountNumber);
          this.filteredOptions =  new Observable<arrAccountNumber[]>();
          this.options = this.tempAccountNumber;
          this.filteredOptions = this.transAccNoSender.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.account_number),
            map(account_number => account_number ? this._filter(account_number) : this.options.slice())
          );
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  getRupiahFormat(event:any){
    this.transAmount.setValue(this.formatRupiah(this.transAmount.value));
  }

  formatRupiah(angka:any){
    let prefix = "Rp. ";
    let separator:any;
    let number_string = angka.replace(/[^,\d]/g, '').toString(),
			split   		= number_string.split(','),
			sisa     		= split[0].length % 3,
			rupiah     		= split[0].substr(0, sisa),
			ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
 
			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  validateForm(){
    const invalid = [];
    const controls = this.transForm.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        console.log(name);
        this.transForm.markAllAsTouched();
        return false;
      }
    }
    return true;
  }

  async addTransfer(){
    if(this.validateForm()){
      console.log(this.cif_sender);
      let cif_sender = this.cif_sender.value;
      let cif_receiver = this.cif_receiver.value;

      let transNameSender = this.transNameSender.value;
      let transNameReceiver = this.transNameReceiver.value;

      let transAccNoReceiver = this.transAccNoReceiver.value;
      let namaBankTujuan = this.namaBankTujuan.value;

      let amount = this.transAmount.value;
      amount = amount.replace(/Rp. /g, "");
      amount = amount.replace(/\./g, "");
      let note = this.transNews.value;
      let create_operator = this.create_name.value;
      let objSender = {
        "cif_id" : cif_sender, 
        "amount" : amount, 
        "type" : "D", 
        "note" : "Transfer dana keluar bank ke " + transNameReceiver + " - " + namaBankTujuan + " - " + transAccNoReceiver + " , Notes : " + note, "create_operator" : create_operator
      };

      console.log(objSender);
  
      //Append Object
      let transAccNoReceiverObj = {
        "account_number": namaBankTujuan + " - " + transAccNoReceiver
      }
      await this.transForm.patchValue({ transAccNoReceiver: transAccNoReceiverObj })
      await this.transForm.patchValue({ transNews: objSender.note.toString() })

      this.http.post(this.ApiConfig.API_URL + "/insert-log-daily-savings-transactions", objSender, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.http.post(this.ApiConfig.API_URL + "/insert-transfer-transaction", this.transForm.value, this.httpOptions).subscribe(res => {
            this.responseApi = res;
            if (this.responseApi.status == "200") {
              Swal.fire("Success", "Transfer Berhasil", "success").then(() => {
                this.router.navigate(['/admin/transfer-dana-keluar-bank']);
              })
            } else {
              Swal.fire("Ooops", "Gagal Transfer. Mohon Coba Lagi.", "error");
            }
          }, err => {
            Swal.fire("Ooops", "Gagal Transfer. Mohon Coba Lagi.", "error");
          });
        } else {
          Swal.fire("Ooops", "Gagal Transfer. Mohon Coba Lagi.", "error");
        }
      }, err => {
        Swal.fire("Ooops", "Gagal Transfer. Mohon Coba Lagi.", "error");
      });
    } else{
      Swal.fire("Ooops", "Gagal Submit. Mohon Coba Lagi.", "error");
    }
  }

  rupiahFormat(value){
    return this.currencyPipe.transform(value, 'Rp ');
  }

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  }

  onCoaIdChange(event: any){
    this.transForm.patchValue({
      coaId : event
    });
  };

  // printValidation(){
  //   let current = new Date();
  //   let strTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
  //   let objAccNumber = this.transForm.get('transAccNoSender').value;
  //   let objAccNumberReceiver = this.transForm.get('transAccNoReceiver').value;
  //   // let currencyFormat = this.rupiahFormat(this.transForm.get('transAmount').value);
  //   let currencyFormat = this.transForm.get('transAmount').value;
    
  //   // if(currencyFormat != "" && currencyFormat != null){
  //   //   currencyFormat = currencyFormat.replace(".", "/");
  //   //   while(currencyFormat.indexOf(",") >= 0){
  //   //     currencyFormat = currencyFormat.replace(",", ".");
  //   //   }
  //   //   currencyFormat = currencyFormat.replace("/", ",");
  //   // }

  //   let currDate = new Date(this.transForm.get('transDate').value);
  //   let tempDate = currDate.getDate()+"-"+(currDate.getMonth()+1)+"-"+currDate.getFullYear();

  //   let a = window.open();
  //   a.document.write('<div id = "form-print" style = "display:block; font-size: 2mm; margin-top: 3cm; margin-left: 2cm;">');
  //   a.document.write('<table style = "font-size: 2.5mm;">');
  //   a.document.write('<tr><td>Tanggal</td><td style = "padding-left: 1mm"><p id = "print-date">: ' + tempDate + '</p></td>');
  //   a.document.write('<tr><td>Jam</td><td style = "padding-left: 1mm"><p id = "print-time">: ' + strTime + '</p></td>');
  //   a.document.write('<tr><td>Jumlah</td><td style = "padding-left: 1mm"><p id = "print-amount">: ' + currencyFormat + '</p></td>');
  //   a.document.write('<tr><td>Nomor Akun Pengirim</td><td style = "padding-left: 1mm"><p id = "print-account">: ' + objAccNumber.account_number + '</p></td>');
  //   a.document.write('<tr><td>Nomor Akun Penerima</td><td style = "padding-left: 1mm"><p id = "print-account">: ' + objAccNumberReceiver.account_number + '</p></td>');
  //   // a.document.write('<tr><td>Nama Akun</td><td style = "padding-left: 1mm"><p id = "print-accountname">: ' + this.transForm.get('account_name').value + '</p></td>');
  //   a.document.write('</table>');
  //   a.document.write('</div>');
  //   a.document.close();
  //   a.print();
  // }

  get transTOS_Sender(){return this.transForm.get("transTOS_Sender")};
  get transTOS_Receiver(){return this.transForm.get("transTOS_Receiver")};
  get transDate(){return this.transForm.get("transDate")};
  get transAccNoSender(){return this.transForm.get("transAccNoSender")};
  get transNameSender(){return this.transForm.get("transNameSender")};
  get transAccNoReceiver(){return this.transForm.get("transAccNoReceiver")};
  get transNameReceiver(){return this.transForm.get("transNameReceiver")};
  get transAmount(){return this.transForm.get("transAmount")};
  get transAmountText(){return this.transForm.get("transAmountText")};
  get transNews(){return this.transForm.get("transNews")};
  get transValid(){return this.transForm.get("transValid")};
  get transTOSText_Receiver(){return this.transForm.get("transTOSText_Receiver")};
  get cif_sender(){return this.transForm.get("cif_sender")};
  get cif_receiver(){return this.transForm.get("cif_receiver")};
  get create_name(){return this.transForm.get("create_name")};
  get namaBankTujuan(){return this.transForm.get("namaBankTujuan")};
}
