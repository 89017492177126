import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-pending-simpanan-berjangka',
  templateUrl: './pending-simpanan-berjangka.component.html',
  styleUrls: ['./pending-simpanan-berjangka.component.sass']
})
export class PendingSimpananBerjangkaComponent implements OnInit {
  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private globalService: ServicesComponent
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.globalService.checkRole("Simpanan Berjangka");    
    
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/simpanan-berjangka-pending",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No Simpanan Berjangka', data:'no_simpanan_berjangka'},
          {title: 'No. CIF', data: 'cif_id'},
          {title: 'Tipe CIF', data: 'cif_type'},   
          {title: 'Status', data: 'status',
            // render: function (data, type, row) {
            //   if(data == "01") return "Active";
            //   else if(data == "05") return "Pending";
            // }
          },          
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  // return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
                  return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button>';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          return row;
      }
    };
  }

  deleteData(data, row) {
    this.http.delete(this.ApiConfig.API_URL + "/simpanan-berjangka/" + data.id,  this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            Swal.fire("Success", this.responseApi.message, "success");
            row.remove();
        } else {
            Swal.fire("Sorry", "An error occured, please try again", "error");
        }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
  }
  
  editData(data) {
    this.router.navigate(['/admin/view-simpanan-berjangka/' + data.no_simpanan_berjangka]);
  }

}
