import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesComponent } from 'src/app/services/services.component';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-keluar-bank',
  templateUrl: './keluar-bank.component.html',
  styleUrls: ['./keluar-bank.component.sass']
})
export class KeluarBankComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private globalService: ServicesComponent
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  formatRupiah(angka:any){
    let prefix = "Rp. ";
    let separator:any;
    let number_string = angka.replace(/[^,\d]/g, '').toString(),
			split   		= number_string.split(','),
			sisa     		= split[0].length % 3,
			rupiah     		= split[0].substr(0, sisa),
			ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
 
			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  ngOnInit(): void {

    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/transfer-transaction-keluar-bank",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No. Rekening Pengirim', data:'account_number_sender'},
          {title: 'Nama. Rekening Pengirim', data:'full_name'},
          {title: 'No. Rekening Penerima', data: 'account_number_receiver'},
          { 
            title : 'Jumlah',
            data  : 'amount',
            render : function (data, type, row){
              // console.log(this.formatRupiah(data));
              let prefix = "Rp. ";
              let separator:any;
              let number_string = data.replace(/[^,\d]/g, '').toString(),
                split   		= number_string.split(','),
                sisa     		= split[0].length % 3,
                rupiah     		= split[0].substr(0, sisa),
                ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
           
                // tambahkan titik jika yang di input sudah menjadi angka ribuan
                if(ribuan){
                  separator = sisa ? '.' : '';
                  rupiah += separator + ribuan.join('.');
                }
           
                rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
                return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
              // return ("Rp " + this.formatRupiah(data));
            }
          },
          // {title: 'Amount', data: 'amount'},
          {title: 'Created Date', data: 'create_date'},
          {title: 'Note', data: 'note'},
      ],
      order: [],
    };
  }
}
