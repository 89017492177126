import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { DialogIndividualComponent } from './dialog-individual/dialog-individual.component';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.sass']
})
export class IndividualComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    public dialog : MatDialog,
  ) { }

  dtOptions: DataTables.Settings = {};
  httpOptions: any = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.USER_DATA.token })
  };
  responseApi: any;

  openDialog(){
    let dialogRef = this.dialog.open(DialogIndividualComponent);
  }

  deleteData(data, row) {
    this.http.delete(this.ApiConfig.API_URL + "/coa/" + data.id_coa, this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            Swal.fire("Selamat", "Anda berhasil menghapus COA", "success");
            row.remove();
        } else {
            Swal.fire("Maaf", "Anda gagal menghapus COA", "error");
        }
    }, (err) => {
        Swal.fire("Maaf", "Anda gagal menghapus COA", "error");
    });
  }

  editData(data) {
    let dialogRef = this.dialog.open(DialogIndividualComponent, {
      data : data
    });
  }

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/individual",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {
              title: 'ID',
              data: 'id',
          },
          {
              title: 'Nama Individu',
              data: 'individual_name'
          },
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          return row;
      }
    };
  }

}
