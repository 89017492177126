import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ReplaySubject } from "rxjs"; 

@Component({
  selector: 'app-edit-cif-company',
  templateUrl: './edit-cif-company.component.html',
  styleUrls: ['./edit-cif-company.component.css']
})
  export class EditCifCompanyComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router : Router
  ) { }
  idParam: any;
  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  companyForm: FormGroup = new FormGroup({
    cpCif: new FormControl('', [Validators.required]),
    cpName: new FormControl('', [Validators.required]),
    cpFieldOfWork: new FormControl('', [Validators.required]),
    cpPlaceOfEstablishment: new FormControl('', [Validators.required]),
    cpDeedNumber: new FormControl('', [Validators.required]),
    cpDateOfEstablisment: new FormControl('', [Validators.required]),
    cpDeedIssuedPlace: new FormControl('', [Validators.required]),
    cpDeedIssuedDate: new FormControl('', [Validators.required]),
    cpNPWP: new FormControl('', [Validators.required]),

    cpAddress: new FormControl('', [Validators.required]),
    cpNationality: new FormControl('', [Validators.required]),
    cpProvince: new FormControl('', [Validators.required]),
    cpDistrict: new FormControl('', [Validators.required]),
    cpSubDistrict: new FormControl('', [Validators.required]),
    cpCity: new FormControl('', [Validators.required]),
    cpPostalCode: new FormControl('', [Validators.required]),
    cpOccupiedSince: new FormControl('', [Validators.required]),

    cpPhoneNumber: new FormControl('', [Validators.required]),
    cpFaxNumber: new FormControl(),
    cpEmail: new FormControl('', [Validators.required]),
    cpPicNumber: new FormControl('', [Validators.required]),
    cpHandphoneNumber: new FormControl('', [Validators.required]),

    picName: new FormControl('', [Validators.required]),
    picPosition: new FormControl('', [Validators.required]),
    picIdentityType: new FormControl('', [Validators.required]),
    picIdentityNumber: new FormControl('', [Validators.required]),

    cpFundSource: new FormControl('', [Validators.required]),
    cpAccountPurpose: new FormControl('', [Validators.required]),
    cpMonthlyIncome: new FormControl('', [Validators.required]),
    cpAvgTransaction: new FormControl('', [Validators.required]),
    createdBy: new FormControl('', [Validators.required]),
    documentForm: new FormArray([
      new FormGroup({
        docType: new FormControl(),
        docDesc: new FormControl(),
        docDate: new FormControl(),
        docFileName: new FormControl(),
        docFileExt: new FormControl(),
        docFileBuffer: new FormControl(),
        companyId: new FormControl(),
        createdBy: new FormControl(),
      })
    ], [Validators.required])
  });

  tokenRaja : any;
  errArr : any;
  dataQuestion : any; tempQuestion : any;

  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  etcFund : any; etcPurpose : any;
  fundSource : any = 
  [
    {
      name : "Penghasilan",
      checked : false
    },
    {
      name : "Warisan",
      checked : false
    },
    {
      name : "Hibah",
      checked : false
    },
    {
      name : "Undian",
      checked : false
    },
    {
      name : "Dari Suami / Istri",
      checked : false
    },
    {
      name : "Bunga Simpanan",
      checked : false
    },
    {
      name : "Dari Orang Tua",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];
  accountPurpose : any = 
  [
    {
      name : "Investasi",
      checked : false
    },
    {
      name : "Pendidikan",
      checked : false
    },
    {
      name : "Niaga",
      checked : false
    },
    {
      name : "Modal",
      checked : false
    },
    {
      name : "Gaji",
      checked : false
    },
    {
      name : "Biaya Rutin",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];
  company_id: any; tempAttachment: any;
  oldData : any; newData : any; diffValueChanges: any;

  oldProvince:any;oldCity:any;oldDistrict:any;oldSubdistrict:any;
  ctProvince:any=0;ctCity:any=0;ctDistrict:any=0;ctSubdistrict:any=0;

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
    }
  }

  getData(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.companyForm.patchValue({
            cpCif: this.responseApi.data.cif_code,
            cpName: this.responseApi.data.company_name,
            cpFieldOfWork: this.responseApi.data.field_of_work,
            cpPlaceOfEstablishment: this.responseApi.data.place_of_establishment,
            cpDeedNumber: this.responseApi.data.deed_number,
            cpDateOfEstablisment: this.responseApi.data.date_of_establishment,
            cpDeedIssuedPlace: this.responseApi.data.deed_issued_place,
            cpDeedIssuedDate: this.responseApi.data.deed_issued_date,
            cpNPWP: this.responseApi.data.npwp,
        
            cpAddress: this.responseApi.data.address,
            cpNationality: this.responseApi.data.nationality,
            cpProvince: this.responseApi.data.province,
            cpDistrict: this.responseApi.data.district,
            cpSubDistrict: this.responseApi.data.sub_district,
            cpCity: this.responseApi.data.city,
            cpPostalCode: this.responseApi.data.postal_code,
            cpOccupiedSince: this.responseApi.data.occupied_since,
        
            cpPhoneNumber: this.responseApi.data.phone_number,
            cpFaxNumber: this.responseApi.data.fax_number,
            cpEmail: this.responseApi.data.email,
            cpPicNumber: this.responseApi.data.pic_number,
            cpHandphoneNumber: this.responseApi.data.handphone_number,
        
            picName: this.responseApi.data.pic_name,
            picPosition: this.responseApi.data.pic_position,
            picIdentityType: this.responseApi.data.pic_identity_type,
            picIdentityNumber: this.responseApi.data.pic_identity_number,
        
            cpFundSource: this.responseApi.data.fund_source,
            cpAccountPurpose: this.responseApi.data.account_purpose,
            cpMonthlyIncome: this.responseApi.data.monthly_income,
            cpAvgTransaction: this.responseApi.data.average_transaction,
            createdBy: this.responseApi.data.created_by,
          });
        this.getQuestion();
        this.getProvince();
        this.parseCheckbox();
        let pipe2 = new DatePipe('en-GB');
        this.companyForm.value.cpDeedIssuedDate = pipe2.transform(this.companyForm.value.cpDeedIssuedDate,'yyyy-MM-dd');
        this.companyForm.value.cpDateOfEstablisment = pipe2.transform(this.companyForm.value.cpDateOfEstablisment,'yyyy-MM-dd');
        this.oldData = this.companyForm.value;
        this.company_id = this.responseApi.data.id;
        this.getAttachment(this.company_id);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAttachment(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company-attachment/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.tempAttachment = this.responseApi.data;

          for (var x = 0; x < this.tempAttachment.length; x++) {
            const attachmentArr: FormArray = this.companyForm.get('documentForm') as FormArray;
            attachmentArr.push(new FormGroup({
              docType: new FormControl(this.tempAttachment[x].document_name, []),
              docDesc: new FormControl(this.tempAttachment[x].document_description, []),
              docDate: new FormControl(this.tempAttachment[x].document_date, []),
              docFileName: new FormControl(this.tempAttachment[x].file_path, []),
              docFileExt: new FormControl(this.tempAttachment[x].file_extension, []),
              docFileBuffer: new FormControl(this.tempAttachment[x].buffer, []),
              companyId: new FormControl(this.tempAttachment[x].company_id, []),
              createdBy: new FormControl(this.tempAttachment[x].created_by, []),
            }));
          }
        }
        this.removeRow(0);
      },
      (err) => {
        console.log(err);
      }
    );   
  }

  parseCheckbox(){
    //Fund Source
    let foundBoolean = false; 
    let fund = this.companyForm.value.cpFundSource.split(';');
    fund.splice(-1);
    fund.forEach((elementFund, indexFund) => {
      foundBoolean = false;
      this.fundSource.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          foundBoolean = true;
        }

        if (this.fundSource.length == (indexSource + 1) && !foundBoolean){
          // etcBoolean = true;
          elementSource.checked = true;
          this.etcFund = elementFund;
        }
      });
    });
    //Account Purpose
    let purposeBoolean = false;
    let purpose = this.companyForm.value.cpAccountPurpose.split(';');
    purpose.splice(-1);
    purpose.forEach((elementFund, indexFund) => {
      purposeBoolean = false;
      this.accountPurpose.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          purposeBoolean = true;
        }

        if (this.accountPurpose.length == (indexSource + 1) && !purposeBoolean){
          elementSource.checked = true;
          this.etcPurpose = elementFund;
        }
      });
    });
  }

  getTokenRaja(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.code == "200"){
          this.tokenRaja = this.responseApi.token;
          this.getProvince();
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvince(){
    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
          this.ctProvince = this.ctProvince + 1;
          if(this.ctProvince == 1){
            this.oldProvince = this.tempProvince;
          }
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onProvinceChange(event: any){
    this.companyForm.patchValue({
      cpProvince: event
    });
    this.provinceId = this.companyForm.controls['cpProvince'].value;
    this.getCity();
  }

  getCity(){
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;
          this.tempCity = this.dataCity.map(city => ({
            "id": city.code,
            "text": city.name,
            "additional": city
          }));
          this.ctCity = this.ctCity + 1;
          if(this.ctCity == 1){
            this.oldCity = this.tempCity;
          }
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onCityChange(event: any){
    this.companyForm.patchValue({
      cpCity: event
    });
    this.cityId = this.companyForm.controls['cpCity'].value;
    this.getDistrict();
  }

  getDistrict(){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;
          this.tempDistrict = this.dataDistrict.map(district => ({
            "id": district.code,
            "text": district.name,
            "additional": district
          }));
          this.ctDistrict = this.ctDistrict + 1;
          if(this.ctDistrict == 1){
            this.oldDistrict = this.tempDistrict;
          }
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.companyForm.patchValue({
      cpDistrict: event
    });
    this.districtId = this.companyForm.controls['cpDistrict'].value;
    this.getSubDistrict();
  }

  getSubDistrict(){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
            "id": subdistrict.code,
            "text": subdistrict.name,
            "additional": subdistrict
          }));
          this.ctSubdistrict = this.ctSubdistrict + 1;
          if(this.ctSubdistrict == 1){
            this.oldSubdistrict = this.tempSubdistrict;
          }
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubdistrictChange(event: any){
    this.companyForm.patchValue({
      cpSubDistrict: event
    });
  }

  getQuestion(){
    this.http.get(this.ApiConfig.API_URL + "/cif-company-question", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.dataQuestion = this.responseApi.data;
        }        
      },
      err => {
        console.log(err);
      }
    );
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  changeFund(e){
    this.fundSource[7].value = this.etcFund;
  }

  changeAccPurpose(e){
    this.accountPurpose[6].value = this.etcPurpose;
  }
  compileCheckbox(){
    let fund = "";
    this.fundSource.forEach((e,i) => {
      if(e.checked && i != 7){
        fund = fund + e.name+";";
      } else if (e.checked && i == 7){
        fund = fund + e.value+";";
      }
    });
    
    let purpose = "";
    this.accountPurpose.forEach((e,i) => {
      if(e.checked && i != 6){
        purpose = purpose + e.name+";";
      } else if (e.checked && i == 6){
        purpose = purpose + e.value+";";
      }
    });
    this.companyForm.patchValue({
      cpFundSource : fund,
      cpAccountPurpose : purpose
    });
  }

  validateForm(){
    let pipe = new DatePipe('en-GB');
    this.companyForm.value.cpDeedIssuedDate = pipe.transform(this.companyForm.value.cpDeedIssuedDate,'yyyy-MM-dd');
    this.companyForm.value.cpDateOfEstablisment = pipe.transform(this.companyForm.value.cpDateOfEstablisment,'yyyy-MM-dd');
    let errArr = [];
    Object.keys(this.companyForm.controls).forEach(key => {
      if(this.companyForm.controls[key].errors != null){
        errArr.push(key);
      }
    });
  }
  edit(){
    this.changeAccPurpose("e");
    this.changeFund("e");
    this.compileCheckbox();
    this.validateForm();
    if(this.companyForm.valid){
      this.newData = this.companyForm.value;
      
      const newEntries = Object.entries(this.newData);
      const oldEntries = Object.entries(this.oldData);
      let editDiff = [];
      newEntries.forEach((e,i) => {
        if(e[1]!=oldEntries[i][1] && e[0]!="documentForm"){
          var tsName = e[0];
          var feName = "";
          var type = "text";
          if(tsName=='cpName'){
            feName = 'Nama Perusahaan';
          } else if (tsName=='cpFieldOfWork'){
            feName = 'Bidang Perusahaan';
          } else if (tsName=='cpPlaceOfEstablishment'){
            feName = 'Tempat Pendirian';
          } else if (tsName=='cpDeedNumber'){
            feName = 'Nomor Akta Pendirian';
          } else if (tsName=='cpDateOfEstablisment'){
            feName = 'Tanggal Pendirian';
          } else if (tsName=='cpDeedIssuedPlace'){
            feName = 'Tempat Dikeluarkan Akta Pendirian';
          } else if (tsName=='cpDeedIssuedDate'){
            feName = 'Tanggal Dikeluarkan Akta Pendirian';
          } else if (tsName=='cpNPWP'){
            feName = 'NPWP Perusahaan';
          } else if (tsName=='cpAddress'){
            feName = 'Alamat Perusahaan';
          } else if (tsName=='cpNationality'){
            feName = 'Negara';
          } else if (tsName=='cpProvince'){
            feName = 'Provinsi';
            this.tempProvince.forEach(eProvince => {
              if(e[1]==eProvince.id){
                e[1]=eProvince.text;
              } 
            });
            this.oldProvince.forEach(eOldProvince => {
              if(oldEntries[i][1]==eOldProvince.id){
                oldEntries[i][1]=eOldProvince.text;
              } 
            });
          } else if (tsName=='cpCity'){
            feName = 'Kota';
            this.tempCity.forEach(eCity => {
              if(e[1]==eCity.id){
                e[1]=eCity.text;
              } 
            });
            this.oldCity.forEach(eOldCity => {
              if(oldEntries[i][1]==eOldCity.id){
                oldEntries[i][1]=eOldCity.text;
              } 
            });
          } else if (tsName=='cpDistrict'){
            feName = 'Kelurahan';
            this.tempDistrict.forEach(eDistrict => {
              if(e[1]==eDistrict.id){
                e[1]=eDistrict.text;
              }
            });
            this.oldDistrict.forEach(eOldDistrict => {
              if(oldEntries[i][1]==eOldDistrict.id){
                oldEntries[i][1]=eOldDistrict.text;
              } 
            });
          } else if (tsName=='cpSubDistrict'){
            feName = 'Kecamatan';
            this.tempSubdistrict.forEach(eSubDistrict => {
              if(e[1]==eSubDistrict.id){
                e[1]=eSubDistrict.text;
              }
            });
            this.oldSubdistrict.forEach(eOldSubdistrict => {
              if(oldEntries[i][1]==eOldSubdistrict.id){
                oldEntries[i][1]=eOldSubdistrict.text;
              } 
            });
          } else if (tsName=='cpPostalCode'){
            feName = 'Kode Pos';
          } else if (tsName=='cpOccupiedSince'){
            feName = 'Ditempati Sejak';
          } else if (tsName=='cpPhoneNumber'){
            feName = 'Nomor Telpon Perusahaan';
          } else if (tsName=='cpFaxNumber'){
            feName = 'Nomor Fax';
          } else if (tsName=='cpEmail'){
            feName = 'Alamat Email';
            console.log("ok")
          } else if (tsName=='cpPicNumber'){
            feName = 'Nama Orang yang Dapat Dihubungi';
          } else if (tsName=='cpHandphoneNumber'){
            feName = 'Nomor Telepon';
          } else if (tsName=='picName'){
            feName = 'Nama Pengurus Perusahaan';
          } else if (tsName=='picPosition'){
            feName = 'Jabatan Pengurus Perusahaan';
          } else if (tsName=='picIdentityType'){
            feName = 'Jenis Bukti Identitas Pengurus Perusahaan';
          } else if (tsName=='picIdentityNumber'){
            feName = 'Nomor Identitas Pengurus Perusahaan';
          } else if (tsName=='cpFundSource'){
            feName = 'Sumber Dana';
          } else if (tsName=='cpAccountPurpose'){
            feName = 'Tujuan Pembukaan Rekening';
          } else if (tsName=='cpMonthlyIncome'){
            feName = 'Pendapatan Per Bulan';
            this.dataQuestion.forEach(eMonth => {
              if(eMonth.id == e[1]){
                e[1] = eMonth.question_text
              }
              if(eMonth.id == oldEntries[i][1]){
                oldEntries[i][1] = eMonth.question_text
              }
            });
          } else if (tsName=='cpAvgTransaction'){
            feName = 'Rata-Rata Transaksi per Bulan';
            this.dataQuestion.forEach(eAvg => {
              if(eAvg.id == e[1]){
                e[1] = eAvg.question_text
              }
              if(eAvg.id == oldEntries[i][1]){
                oldEntries[i][1] = eAvg.question_text
              }
            });
          }
          var obj = {
            name: e[0],
            feName: feName,
            oldData: oldEntries[i][1],
            newData: e[1],
            type: type
          }
          editDiff.push(obj);
        }
      });
      let tableHeader ='<style> .center {margin-left:auto;margin-right:auto;} th td{ white-space: nowrap;} td {padding-left:2px;} table, th, td { border:1px solid black; text-align:left;margin:0px;padding-right:12px;} </style> <table class="center"> <tbody> <tr> <td>Nama</td> <td>Data Lama </td> <td>Data Baru</td> </tr>';
      let tableClose = "</tbody></table>";
      let tableContent = "";
      editDiff.forEach( e => {
        var tempArr = e.oldData.toString().split(";");
        var tempArrNew = e.newData.toString().split(";");
        let tContent = '';
        if(tempArr.length>1){
          tContent += "<tr><td>"+e.feName+"</td><td>";
          tempArr.forEach((eTempArr, eIndex) => {
            tContent += eTempArr;
            if (!(eIndex + 1 == tempArr.length)){
              tContent += '</br>';
            }
          });
          tContent += "</td><td>";
          tempArrNew.forEach((eTempArrNew, eIndexNew) => {
            tContent += eTempArrNew;
            if (!(eIndexNew + 1 == tempArrNew.length)){
              tContent += '</br>';
            }
          });
          tContent += "</td></tr>";
        } else {
          tContent = "<tr><td>"+e.feName+"</td><td>"+e.oldData+"</td><td>"+e.newData+"</td></tr>";
        }
        tableContent = tableContent + tContent;
      });
      Swal.fire(
        {
          title: 'Apakah data sudah benar ?',
          icon: 'info',
          html: tableHeader+tableContent+tableClose,
          showCancelButton: true,
          confirmButtonText: `Submit`,
          cancelButtonText: `Cancel`,
          customClass: {container : 'table-modal'}
        }
      ).then(result =>{
        if(result.isConfirmed){
          this.http.post(this.ApiConfig.API_URL + "/edit-cif-company/", this.companyForm.value, this.httpOptions).subscribe(res => {
            this.responseApi = res;
            if (this.responseApi.status == "200") {
              Swal.fire("Success", "Your input has been saved.", "success");
              this.router.navigate(['/admin/cif-company']);
            } else {
              Swal.fire("Ooops", "There's something wrong happened", "error");
            }
          }, err => {
            Swal.fire("Ooops", "There's something wrong happened", "error");
          });
        } else if (result.isDismissed){
          Swal.fire('Canceled', '', 'info');
        }
      })
    } else {
        this.companyForm.markAllAsTouched();
        Swal.fire("Error", "Harap Lengkapi Form", "error");
    }
  }

  //---------------------------LAMPIRAN DOKUMEN------------------------

  addRow() {
    const documentForm: FormArray = this.companyForm.get('documentForm') as FormArray;
    documentForm.push(new FormGroup({
      docType: new FormControl(),
      docDesc: new FormControl(),
      docDate: new FormControl(),
      docFileName: new FormControl(),
      docFileExt: new FormControl(),
      docFileBuffer: new FormControl(),
      companyId: new FormControl(),
      createdBy: new FormControl(),
    }))
  }

  removeRow(index) {
    const documentForm: FormArray = this.companyForm.get('documentForm') as FormArray;
    documentForm.removeAt(index);
  }

  feFileName : any;

  uploadFile(event,i) {
    let fileName = event.target.files[0].name;
    this.feFileName = fileName;
    let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let name = this.companyForm.value.cpName+" - "+fileName.substring(0,fileName.indexOf("."));
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.companyForm.get('documentForm')['controls'][i].patchValue({
        docFileName : name,
        docFileExt : extension,
        docFileBuffer : base64
      });
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  downloadData(i){
    const download: FormArray = this.companyForm.get('documentForm') as FormArray;
    let file = download.controls[i]
    let fileName = file.value.docFileName +"."+ file.value.docFileExt;
    window.open(this.ApiConfig.API_URL + '/download-attachment?name='+fileName);    
  }



}
