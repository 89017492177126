import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../utility/ApiConfig';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-pending-daily-savings',
  templateUrl: './pending-daily-savings.component.html',
  styleUrls: ['./pending-daily-savings.component.sass']
})
export class PendingDailySavingsComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {    
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/daily-savings/status/pending_add",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'Nomor Rekening',data:'account_number'},
          {title: 'Cabang',data:'id_store', render: function (data, type, row) { return 'Head Office'}},
          {title: 'Jenis Rekening',data:null, render: function(data, type, row){ return data.account_type == 'tabungan-nusantara' ? 'Tabungan Nusantara' : (data.account_type == 'tabungan-nusantara-bisnis' ? 'Tabungan Nusantara Bisnis' : 'Tabungan Nusantara Karyawan')  }},
          {title: 'Status', data:'approve_status'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-gray view-btn"></button>';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.view-btn', row).unbind('click');
          $('.view-btn', row).bind('click', () => {
            self.viewData(data);
        });
          return row;
      }
    };
  }

  viewData(data){
    this.router.navigate(['/admin/daily-saving/view/' + data.account_number ]);
  }


}
