import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})

export class ApiConfig {
	constructor() {
	}

	private _ls = new SecureLS({ encodingType: 'aes' });

    set(key: string, value: any, expired: number = 0) {
        this._ls.set(key, value);
    }

    remove(key: string) {
        this._ls.remove(key);
    }

    get(key: string) {
        return this._ls.get(key);
    }

    clear() {
        this._ls.removeAll();
    }

	//For Server`
	API_URL = "https://backend.kspcdn.co.id/api/v1";
	API_URL_BASE = "https://backend.kspcdn.co.id/";

	//For Local
	// API_URL = "http://localhost:8080/api/v1";
	// API_URL_BASE = "http://localhost:8080/";

    MINTSODA_USER = 752;

	// TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluIiwiaWF0IjoxNjE1NTQ4MTAxLCJleHAiOjE2MTU2MzQ1MDF9.VfqUcqEHNr7K8gJAOhxeuiO1Tp9In96R6zfOPNpuobg";
	USER_DATA = this.get("user-data");

}


