import { Component, OnInit, ViewChild } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import {MatAccordion} from '@angular/material/expansion';
import { Location } from '@angular/common';
import { sequence } from '@angular/animations';
import { analyzeAndValidateNgModules } from '@angular/compiler';
declare function setDatatableSearch(): any;

@Component({
  selector: 'app-approval-cif-individual',
  templateUrl: './approval-cif-individual.component.html',
  styleUrls: ['./approval-cif-individual.component.css']
})
export class ApprovalCifIndividualComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private route: ActivatedRoute, 
    private ApiConfig: ApiConfig,
    private location: Location
  ) { }

  custForm: FormGroup = new FormGroup({
    custPrefixName: new FormControl('', [Validators.maxLength(10)]),
    custSuffixName: new FormControl('', [Validators.maxLength(10)]),
    custFullName: new FormControl('', [Validators.required]),
    custPlaceOfBirth: new FormControl('', [Validators.required]),
    custCountryOfBirth: new FormControl('', [Validators.required]),
    custDateOfBirth: new FormControl('',Validators.required),
    custReligion: new FormControl({value: '', disabled: true}),
    custMothersName: new FormControl('', [Validators.required]),
    custSex: new FormControl({value: '', disabled: true}, [Validators.required]),
    custMaritalStatus: new FormControl({value: '', disabled: true}, [Validators.required]),

    //New
    custCoupleName: new FormControl({value: '', disabled: true}),
    custCoupleAddress: new FormControl({value: '', disabled: true}),
    custCoupleCountry: new FormControl({value: '', disabled: true}),
    custCoupleProvince: new FormControl({value: '', disabled: true}),
    custCoupleCity: new FormControl({value: '', disabled: true}),
    custCoupleDistricts: new FormControl({value: '', disabled: true}),
    custCoupleWard: new FormControl({value: '', disabled: true}),
    custCouplePostalCode: new FormControl({value: '', disabled: true}),
    custCouplePhone: new FormControl({value: '', disabled: true}),
    custNumberOfDependents: new FormControl({value: '', disabled: true}, [Validators.required]),
    custLongWorked: new FormControl({value: '', disabled: true}),
    custProductSpecification: new FormControl({value: '', disabled: true}),
    custSystemSelling: new FormControl({value: '', disabled: true}),
    custSystemSellingOthers: new FormControl({value: '', disabled: true}),
    custWorkingOwner: new FormControl({value: '', disabled: true}),
    custWorkingLandOwner: new FormControl({value: '', disabled: true}),
    custWorkingLandOwnerOthers: new FormControl({value: '', disabled: true}),
    custGrossIncome: new FormControl({value: '', disabled: true}),
    custNetIncome: new FormControl({value: '', disabled: true}),
    //End


    custLastEducation: new FormControl({value: '', disabled: true}),
    custNationality: new FormControl('', [Validators.required]),
    custIdentityType: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityNumber: new FormControl('', [Validators.required]),
    custvpText: new FormControl({value: '', disabled: true}, [Validators.required]),
    custvpDate: new FormControl('', [Validators.required]),
    custCountryOfIssue: new FormControl('', [Validators.required]),
    custIdentityAddress: new FormControl('', [Validators.required]),
    custIdentityCountry: new FormControl('', [Validators.required]),
    custIdentityProvince: new FormControl({value: '', disabled: true}, [Validators.required]),
    // custIdentityProvince: new FormControl('', [Validators.required]),
    // custIdentityCity: new FormControl('', [Validators.required]),
    custIdentityCity: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityDistricts: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityWard: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityPostalCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custDomicileAddress: new FormControl('', [Validators.required]),
    custDomicileCountry: new FormControl('', [Validators.required]),
    custDomicileProvince: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicileCity: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicileDistricts: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicileWard: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicilePostalCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custStatusOfResidence: new FormControl({value: '', disabled: true}, [Validators.required]),
    custLongTimeOccupy: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    custHousePhone: new FormControl('', [Validators.maxLength(15)]),
    custCompanyPhone: new FormControl('', [Validators.maxLength(15)]),
    custCellphone: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    custFax: new FormControl('', [Validators.maxLength(15)]),
    custEmail: new FormControl('', [Validators.email]),
    custTOWDD: new FormControl({value: '', disabled: true}, [Validators.required]),
    custTOWText: new FormControl('', [Validators.required]),
    custBusinessField: new FormControl(),
    custPosition: new FormControl(),
    custCompanyName: new FormControl(),
    custCompanyAddress: new FormControl(),
    custCompanyCountry: new FormControl(),
    custCompanyProvince: new FormControl({value: '', disabled: true}),
    custCompanyCity: new FormControl({value: '', disabled: true}),
    custCompanyDistricts: new FormControl({value: '', disabled: true}),
    custCompanyWard: new FormControl({value: '', disabled: true}),
    custMonthlyIncome: new FormControl({value: '', disabled: true}, [Validators.required]),
    custMonthlyAverageTransaction: new FormControl({value: '', disabled: true}, [Validators.required]),
    custGuardianFullName: new FormControl(''),
    custGuardianAddress: new FormControl(''),
    custGuardianCountry: new FormControl(''),
    custGuardianProvince: new FormControl({value: '', disabled: true}),
    custGuardianCity: new FormControl({value: '', disabled: true}),
    custGuardianDistricts: new FormControl({value: '', disabled: true}),
    custGuardianWard: new FormControl({value: '', disabled: true}),
    custGuardianPostalCode: new FormControl('', [Validators.maxLength(5)]),
    custGuardianHousePhone: new FormControl('', [Validators.maxLength(15)]),
    custGuardianCellphone: new FormControl('', [Validators.maxLength(15)]),
    custRelationshipDD: new FormControl({value: '', disabled: true}),
    custRelationshipText: new FormControl(''),
    custSourceOfFunds_OthersText: new FormControl('', [Validators.required]),
    custPurpose_OthersText: new FormControl('', [Validators.required]),
    custNickname: new FormControl(),
    custValid: new FormControl(''),
    custSOF: new FormArray([], [Validators.required]),
    custPurpose: new FormArray([], [Validators.required]),
    custSOFValue: new FormControl(''),
    custPurposeValue: new FormControl(''),
    custCIF: new FormControl(''),
    custSubmissionStatus: new FormControl(''),
    custStatusData: new FormControl(''),
    custID: new FormControl(''),
    custAction: new FormControl(''),
    custNote: new FormControl(''),
    custTemp: new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()
    ]),
    createdBy: new FormControl(),


    // PENDING_EDIT
    custPrefixName_Old: new FormControl('', [Validators.maxLength(10)]),
    custSuffixName_Old: new FormControl('', [Validators.maxLength(10)]),
    custFullName_Old: new FormControl('', [Validators.required]),
    custPlaceOfBirth_Old: new FormControl('', [Validators.required]),
    custCountryOfBirth_Old: new FormControl('', [Validators.required]),
    custDateOfBirth_Old: new FormControl('',Validators.required),
    custReligion_Old: new FormControl({value: '', disabled: true}),
    custMothersName_Old: new FormControl('', [Validators.required]),
    custSex_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custMaritalStatus_Old: new FormControl({value: '', disabled: true}, [Validators.required]),

    //New
    custCoupleName_Old: new FormControl({value: '', disabled: true}),
    custCoupleAddress_Old: new FormControl({value: '', disabled: true}),
    custCoupleCountry_Old: new FormControl({value: '', disabled: true}),
    custCoupleProvince_Old: new FormControl({value: '', disabled: true}),
    custCoupleCity_Old: new FormControl({value: '', disabled: true}),
    custCoupleDistricts_Old: new FormControl({value: '', disabled: true}),
    custCoupleWard_Old: new FormControl({value: '', disabled: true}),
    custCouplePostalCode_Old: new FormControl({value: '', disabled: true}),
    custCouplePhone_Old: new FormControl({value: '', disabled: true}),
    custNumberOfDependents_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custLongWorked_Old: new FormControl({value: '', disabled: true}),
    custProductSpecification_Old: new FormControl({value: '', disabled: true}),
    custSystemSelling_Old: new FormControl({value: '', disabled: true}),
    custSystemSellingOthers_Old: new FormControl({value: '', disabled: true}),
    custWorkingOwner_Old: new FormControl({value: '', disabled: true}),
    custWorkingLandOwner_Old: new FormControl({value: '', disabled: true}),
    custWorkingLandOwnerOthers_Old: new FormControl({value: '', disabled: true}),
    custGrossIncome_Old: new FormControl({value: '', disabled: true}),
    custNetIncome_Old: new FormControl({value: '', disabled: true}),
    //End


    custLastEducation_Old: new FormControl({value: '', disabled: true}),
    custNationality_Old: new FormControl('', [Validators.required]),
    custIdentityType_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityNumber_Old: new FormControl('', [Validators.required]),
    custvpText_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custvpDate_Old: new FormControl('', [Validators.required]),
    custCountryOfIssue_Old: new FormControl('', [Validators.required]),
    custIdentityAddress_Old: new FormControl('', [Validators.required]),
    custIdentityCountry_Old: new FormControl('', [Validators.required]),
    custIdentityProvince_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityCity_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityDistricts_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityWard_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custIdentityPostalCode_Old: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custDomicileAddress_Old: new FormControl('', [Validators.required]),
    custDomicileCountry_Old: new FormControl('', [Validators.required]),
    custDomicileProvince_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicileCity_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicileDistricts_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicileWard_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custDomicilePostalCode_Old: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custStatusOfResidence_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custLongTimeOccupy_Old: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    custHousePhone_Old: new FormControl('', [Validators.maxLength(15)]),
    custCompanyPhone_Old: new FormControl('', [Validators.maxLength(15)]),
    custCellphone_Old: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    custFax_Old: new FormControl('', [Validators.maxLength(15)]),
    custEmail_Old: new FormControl('', [Validators.email]),
    custTOWDD_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custTOWText_Old: new FormControl('', [Validators.required]),
    custBusinessField_Old: new FormControl(),
    custPosition_Old: new FormControl(),
    custCompanyName_Old: new FormControl(),
    custCompanyAddress_Old: new FormControl(),
    custCompanyCountry_Old: new FormControl(),
    custCompanyProvince_Old: new FormControl({value: '', disabled: true}),
    custCompanyCity_Old: new FormControl({value: '', disabled: true}),
    custCompanyDistricts_Old: new FormControl({value: '', disabled: true}),
    custCompanyWard_Old: new FormControl({value: '', disabled: true}),
    custMonthlyIncome_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custMonthlyAverageTransaction_Old: new FormControl({value: '', disabled: true}, [Validators.required]),
    custGuardianFullName_Old: new FormControl(''),
    custGuardianAddress_Old: new FormControl(''),
    custGuardianCountry_Old: new FormControl(''),
    custGuardianProvince_Old: new FormControl({value: '', disabled: true}),
    custGuardianCity_Old: new FormControl({value: '', disabled: true}),
    custGuardianDistricts_Old: new FormControl({value: '', disabled: true}),
    custGuardianWard_Old: new FormControl({value: '', disabled: true}),
    custGuardianPostalCode_Old: new FormControl('', [Validators.maxLength(5)]),
    custGuardianHousePhone_Old: new FormControl('', [Validators.maxLength(15)]),
    custGuardianCellphone_Old: new FormControl('', [Validators.maxLength(15)]),
    custRelationshipDD_Old: new FormControl({value: '', disabled: true}),
    custRelationshipText_Old: new FormControl(''),
    custSourceOfFunds_OthersText_Old: new FormControl('', [Validators.required]),
    custPurpose_OthersText_Old: new FormControl('', [Validators.required]),
    custNickname_Old: new FormControl(),
    custValid_Old: new FormControl(''),
    custSOF_Old: new FormArray([], [Validators.required]),
    custPurpose_Old: new FormArray([], [Validators.required]),
    custSOFValue_Old: new FormControl(''),
    custPurposeValue_Old: new FormControl(''),
    custCIF_Old: new FormControl(''),
    custSubmissionStatus_Old: new FormControl(''),
    custStatusData_Old: new FormControl(''),
    custID_Old: new FormControl(''),
    custAction_Old: new FormControl(''),
    custNote_Old: new FormControl(''),
    custTemp_Old: new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()
    ]),
    createdBy_Old: new FormControl(),
    custCreateDate_Old: new FormControl()
  });

  arrChanged=[];

  dtAVGTransaction: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any; dtOptions: any = {};

  tokenRaja : any;
  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  tempProvinceDomicile : any; tempProvinceCompany : any; tempProvinceGuardian : any;
  tempCityDomicile : any; tempCityCompany : any; tempCityGuardian : any;
  tempDistrictDomicile : any; tempDistrictCompany : any; tempDistrictGuardian : any;
  tempSubdistrictDomicile : any; tempSubdistrictCompany : any; tempSubdistrictGuardian : any;
  tempCity_Old:any; tempDistrict_Old:any; tempSubdistrict_Old:any; 
  tempCityGuardian_Old:any; tempDistrictGuardian_Old:any; tempSubdistrictGuardian_Old:any; 
  tempCityCompany_Old:any; tempDistrictCompany_Old:any; tempSubdistrictCompany_Old:any; 
  tempCityDomicile_Old:any; tempDistrictDomicile_Old:any; tempSubdistrictDomicile_Old:any; 

  //New
  tempCityCouple_Old:any; tempDistrictCouple_Old:any; tempSubdistrictCouple_Old:any; 
  tempCityCouple:any; tempDistrictCouple:any; tempSubdistrictCouple:any; 
  //End

  SOFValue = ""; PurposeValue = "";
  tempCIF:any; newCIF:any; countRow:any; sequence:any;
  arrSOF = [
    {text: "Penghasilan"},
    {text: "Warisan"},
    {text: "Hibah"},
    {text: "Undian"},
    {text: "Dari Suami/Istri"},
    {text: "Bunga Simpanan"},
    {text: "Dari Orang Tua"},  
    {text: "Lain-lain"}
  ];
  arrPurpose = [
    {text: "Investasi"},
    {text: "Pendidikan"},
    {text: "Niaga"},
    {text: "Modal"},
    {text: "Gaji"},
    {text: "Biaya Rutin"},  
    {text: "Lain-lain"}
  ];
  dtCustData:any;

  ngOnInit(): void {
    this.arrChanged["custPrefixName"] = {text: "Gelar Sebelum Nama"};
    this.arrChanged["custSuffixName"] = {text: "Gelar Setelah Nama"};
    this.arrChanged["custFullName"] = {text: "Nama Lengkap"};
    this.arrChanged["custPlaceOfBirth"] = {text: "Tempat Lahir"};
    this.arrChanged["custCountryOfBirth"] = {text: "Negara Tempat Lahir"};
    this.arrChanged["custDateOfBirth"] = {text: "Tanggal Lahir"};
    this.arrChanged["custReligion"] = {text: "Agama"};
    this.arrChanged["custMothersName"] = {text: "Nama Ibu Kandung"};
    this.arrChanged["custSex"] = {text: "Jenis Kelamin"};
    this.arrChanged["custMaritalStatus"] = {text: "Status Pernikahan"};

    //New
    this.arrChanged["custCoupleName"] = {text: "Nama Istri/Suami"};
    this.arrChanged["custCoupleAddress"] = {text: "Alamat Istri/Suami"};
    this.arrChanged["custCoupleCountry"] = {text: "Negara Istri/Suami"};
    this.arrChanged["custCoupleProvince"] = {text: "Provinsi Istri/Suami"};
    this.arrChanged["custCoupleCity"] = {text: "Kota Istri/Suami"};
    this.arrChanged["custCoupleDistricts"] = {text: "Kelurahan Istri/Suami"};
    this.arrChanged["custCoupleWard"] = {text: "Kecamatan Istri/Suami"};
    this.arrChanged["custCouplePostalCode"] = {text: "Kode Pos Istri/Suami"};
    this.arrChanged["custCouplePhone"] = {text: "Telepon Istri/Suami"};
    this.arrChanged["custNumberOfDependents"] = {text: "Jumlah Tanggungan"};

    this.arrChanged["custLongWorked"] = {text: "Lama Usaha/Bekerja (Tahun)"};
    this.arrChanged["custProductSpecification"] = {text: "Spesifik Produk"};
    this.arrChanged["custSystemSelling"] = {text: "Sistem Penjualan"};
    this.arrChanged["custSystemSellingOthers"] = {text: "Sistem Penjualan Lainnya"};
    this.arrChanged["custWorkingOwner"] = {text: "Kepemilikan Usaha"};
    this.arrChanged["custWorkingLandOwner"] = {text: "Status Tempat Usaha"};
    this.arrChanged["custWorkingLandOwnerOthers"] = {text: "Status Tempat Usaha Lainnya"};
    this.arrChanged["custGrossIncome"] = {text: "Penghasilan Kotor Per Bulan/Omset Usaha"};
    this.arrChanged["custNetIncome"] = {text: "Penghasilan Bersih Per Bulan Keuntungan Bersih"};
    //End

    this.arrChanged["custLastEducation"] = {text: "Pendidikan Terakhir"};
    this.arrChanged["custNationality"] = {text: "Kewarganegaraan"};
    this.arrChanged["custIdentityType"] = {text: "Jenis Identitas"};
    this.arrChanged["custIdentityNumber"] = {text: "Nomor Identitas"};
    this.arrChanged["custvpText"] = {text: "Masa Berlaku"};
    this.arrChanged["custvpDate"] = {text: "Tanggal Masa Berlaku"};
    this.arrChanged["custCountryOfIssue"] = {text: "Negara Penerbit"};
    this.arrChanged["custIdentityAddress"] = {text: "Alamat Sesuai Identitas"};
    this.arrChanged["custIdentityCountry"] = {text: "Negara Sesuai Identitas"};
    this.arrChanged["custIdentityProvince"] = {text: "Provinsi Sesuai Identitas"};
    this.arrChanged["custIdentityCity"] = {text: "Kota Sesuai Identitas"};
    this.arrChanged["custIdentityDistricts"] = {text: "Kecamatan Sesuai Identitas"};
    this.arrChanged["custIdentityWard"] = {text: "Kelurahan Sesuai Identitas"};
    this.arrChanged["custIdentityPostalCode"] = {text: "Kode Pos Sesuai Identitas"};
    this.arrChanged["custDomicileAddress"] = {text: "Alamat Domisili"};
    this.arrChanged["custDomicileCountry"] = {text: "Negara Domisili"};
    this.arrChanged["custDomicileProvince"] = {text: "Provinsi Domisili"};
    this.arrChanged["custDomicileCity"] = {text: "Kota Domisili"};
    this.arrChanged["custDomicileDistricts"] = {text: "Kecamatan Domisili"};
    this.arrChanged["custDomicileWard"] = {text: "Kelurahan Domisili"};
    this.arrChanged["custDomicilePostalCode"] = {text: "Kode Pos Domisili"};
    this.arrChanged["custStatusOfResidence"] = {text: "Status Tempat Tinggal"};
    this.arrChanged["custLongTimeOccupy"] = {text: "Lama Menempati (tahun)"};
    this.arrChanged["custHousePhone"] = {text: "Telepon Rumah"};
    this.arrChanged["custCompanyPhone"] = {text: "Telepon Kantor"};
    this.arrChanged["custCellphone"] = {text: "Telepon Seluler"};
    this.arrChanged["custFax"] = {text: "Fax"};
    this.arrChanged["custEmail"] = {text: "Email"};
    this.arrChanged["custTOWDD"] = {text: "Jenis Pekerjaan"};
    this.arrChanged["custTOWText"] = {text: "Jenis Pekerjaan Lainnya"};
    this.arrChanged["custBusinessField"] = {text: "Bidang Usaha"};
    this.arrChanged["custPosition"] = {text: "Posisi/Jabatan"};
    this.arrChanged["custCompanyName"] = {text: "Nama Perusahaan"};
    this.arrChanged["custCompanyAddress"] = {text: "Alamat Perusahaan"};
    this.arrChanged["custCompanyCountry"] = {text: "Negara Perusahaan"};
    this.arrChanged["custCompanyProvince"] = {text: "Provinsi Perusahaan"};
    this.arrChanged["custCompanyCity"] = {text: "Kota Perusahaan"};
    this.arrChanged["custCompanyDistricts"] = {text: "Kecamatan Perusahaan"};
    this.arrChanged["custCompanyWard"] = {text: "Kelurahan Perusahaan"};
    this.arrChanged["custMonthlyIncome"] = {text: "Penghasilan Per Bulan"};
    this.arrChanged["custMonthlyAverageTransaction"] = {text: "Rata Rata Transaksi Per Bulan"};
    this.arrChanged["custGuardianFullName"] = {text: "Nama Kontak Darurat"};
    this.arrChanged["custGuardianAddress"] = {text: "Alamat Kontak Darurat"};
    this.arrChanged["custGuardianCountry"] = {text: "Negara Kontak Darurat"};
    this.arrChanged["custGuardianProvince"] = {text: "Provinsi Kontak Darurat"};
    this.arrChanged["custGuardianCity"] = {text: "Kota Kontak Darurat"};
    this.arrChanged["custGuardianDistricts"] = {text: "Kecamatan Kontak Darurat"};
    this.arrChanged["custGuardianWard"] = {text: "Kelurahan Kontak Darurat"};
    this.arrChanged["custGuardianPostalCode"] = {text: "Kode Pos Kontak Darurat"};
    this.arrChanged["custGuardianHousePhone"] = {text: "Nomor Telepon Kontak Darurat"};
    this.arrChanged["custGuardianCellphone"] = {text: "Nomor Seluler Kontak Darurat"};
    this.arrChanged["custRelationshipDD"] = {text: "Hubungan Kontak Darurat"};
    this.arrChanged["custRelationshipText"] = {text: "Hubungan Lainnya Kontak Darurat"};
    this.arrChanged["custSourceOfFunds_OthersText"] = {text: "Sumber Dana Lainnya"};
    this.arrChanged["custPurpose_OthersText"] = {text: "Tujuan Pembukaan Rekening Lainnya"};
    this.arrChanged["custNickname"] = {text: "Nama Singkat"};
    this.arrChanged["custSOFValue"] = {text: "Sumber Dana"};
    this.arrChanged["custPurposeValue"] = {text: "Tujuan Pembukaan Rekening"};

    // console.log(this.ApiConfig.USER_DATA);
    // if(this.ApiConfig.USER_DATA.id_role != "1" && this.ApiConfig.USER_DATA.id_role != "2"){
    //   $('.btn-action').css('display', 'none');
    //   $('#information-label').css('display', 'block');
    // }

    this.isApproval(this.ApiConfig.USER_DATA.id_role);
    // GET AVERAGE TRANSACTION
    this.getAVGTransaction();
    this.getProvince();

    // Onchange Masa Berlaku
    this.custForm.get("custvpText").valueChanges.subscribe(x => {
      // console.log(x);
      if(x=="Berdasarkan Tanggal"){
        $("#inline-full-validityperiodtext").css("display", "block")
      } else {
        $("#inline-full-validityperiodtext").css("display", "none")
      }
    })

    // Onchange Hubungan Lainnya
    this.custForm.get("custRelationshipDD").valueChanges.subscribe(x => {
      if(x=="Lainnya"){
        $("#inline-full-guardianrelationshiptext").css("display", "block")
      } else {
        $("#inline-full-guardianrelationshiptext").css("display", "none")
      }
    })

    // Onchange Jenis Pekerjaan ke Lainnya
    this.custForm.get("custTOWDD").valueChanges.subscribe(x => {
      if(x=="Lainnya"){
        $("#inline-full-typeofworktext").css("display", "block")
      } else {
        $("#inline-full-typeofworktext").css("display", "none")
      }
    })

    // Onchange Negara Identitas
    this.custForm.get("custIdentityCountry").valueChanges.subscribe(x => {
      $("#inline-full-domicilecountry").val(x);
      this.custDomicileCountry.setValue(x);
      this.custDomicileCountry.markAsTouched();
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custIdentityCity.setValue('');
        this.custIdentityDistricts.setValue('');
        this.custIdentityProvince.setValue('');
        this.custIdentityWard.setValue('');
        this.custIdentityPostalCode.setValue('');
        this.custDomicileCity.setValue('');
        this.custDomicileDistricts.setValue('');
        this.custDomicileProvince.setValue('');
        this.custDomicileWard.setValue('');
        this.custDomicilePostalCode.setValue('');

        this.tempCity=[];
        this.tempCityDomicile=[];
        this.tempDistrict=[];
        this.tempDistrictDomicile=[];
        this.tempSubdistrict=[];
        this.tempSubdistrictDomicile=[];
      }
    })

    // Onchange Negara Perusahaan
    this.custForm.get("custCompanyCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custCompanyCity.setValue('');
        this.custCompanyDistricts.setValue('');
        this.custCompanyProvince.setValue('');
        this.custCompanyWard.setValue('');

              
        this.tempCityCompany=[];        
        this.tempDistrictCompany=[];
        this.tempSubdistrictCompany=[];
      }
    })

    // Onchange Negara Kontak Darurat
    this.custForm.get("custGuardianCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custGuardianCity.setValue('');
        this.custGuardianDistricts.setValue('');
        this.custGuardianProvince.setValue('');
        this.custGuardianWard.setValue('');
        this.custGuardianPostalCode.setValue('');

              
        this.tempCityGuardian=[];        
        this.tempDistrictGuardian=[];
        this.tempSubdistrictGuardian=[];
      }
    })

    // Onchange Negara Domisili
    this.custForm.get("custDomicileCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custDomicileCity.setValue('');
        this.custDomicileDistricts.setValue('');
        this.custDomicileProvince.setValue('');
        this.custDomicileWard.setValue('');
        this.custDomicilePostalCode.setValue('');

              
        this.tempCityDomicile=[];        
        this.tempDistrictDomicile=[];
        this.tempSubdistrictDomicile=[];
      }
    })

    // Onchange Alamat Identitas
    this.custForm.get("custIdentityAddress").valueChanges.subscribe(x => {
      $("#inline-full-domicileaddress").val(x)
      this.custDomicileAddress.setValue(x)
    })

    // Onchange Provinsi Identitas
    this.custForm.get("custIdentityProvince").valueChanges.subscribe(x => {
      // $("#inline-full-domicileprovince").val(x)
      this.custDomicileProvince.setValue(x)

      this.custIdentityCity.setValue('');
      this.custIdentityDistricts.setValue('');
      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileCity.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempCity=[];
      this.tempCityDomicile=[];
      this.tempDistrict=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Domisili
    this.custForm.get("custDomicileProvince").valueChanges.subscribe(x => {

      this.custDomicileCity.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempCityDomicile=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Kontak Darurat
    this.custForm.get("custGuardianProvince").valueChanges.subscribe(x => {

      this.custGuardianCity.setValue('');
      this.custGuardianDistricts.setValue('');
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      this.tempCityGuardian=[];
      this.tempDistrictGuardian=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Perusahaan
    this.custForm.get("custCompanyProvince").valueChanges.subscribe(x => {

      this.custCompanyCity.setValue('');
      this.custCompanyDistricts.setValue('');
      this.custCompanyWard.setValue('');

      this.tempCityCompany=[];
      this.tempDistrictCompany=[];
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kota/Kabupaten Domisili
    this.custForm.get("custDomicileCity").valueChanges.subscribe(x => {
      if(this.custDomicileProvince.value!=""){
        this.provinceId=this.custDomicileProvince.value;
        this.getCity("custDomicileProvince");
      }

      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempDistrictDomicile=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Kota/Kabupaten Perusahaan
    this.custForm.get("custCompanyCity").valueChanges.subscribe(x => {
      if(this.custCompanyProvince.value!=""){
        this.provinceId=this.custCompanyProvince.value;
        this.getCity("custCompanyProvince");
      }

      this.custCompanyDistricts.setValue('');
      this.custCompanyWard.setValue('');

      this.tempDistrictCompany=[];
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kota/Kabupaten Kontak Darurat
    this.custForm.get("custGuardianCity").valueChanges.subscribe(x => {
      if(this.custGuardianProvince.value!=""){
        this.provinceId=this.custGuardianProvince.value;
        this.getCity("custGuardianProvince");
      }

      this.custGuardianDistricts.setValue('');
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      this.tempDistrictGuardian=[];
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Kecamatan Domisili
    this.custForm.get("custDomicileDistricts").valueChanges.subscribe(x => {
      this.tempSubdistrictDomicile=[];
      
      if(this.custDomicileCity.value!="" && this.tempDistrictDomicile.length==0){
        this.cityId = this.custDomicileCity.value;
        this.getDistrict("custDomicileCity");
      }
      
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');
    })

    // Onchange Kecamatan Perusahaan
    this.custForm.get("custCompanyDistricts").valueChanges.subscribe(x => {      
      this.custCompanyWard.setValue('');
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kecamatan Kontak Darurat
    this.custForm.get("custGuardianDistricts").valueChanges.subscribe(x => {     
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Kelurahan Domisili
    this.custForm.get("custDomicileWard").valueChanges.subscribe(x => {
      if(this.custDomicileDistricts.value!="" && x!=""){
        this.dataDistrict = this.custDomicileDistricts.value;
        this.getSubDistrict("custDomicileDistricts");
      }

      this.custDomicilePostalCode.setValue('');
    })

    // Onchange Kelurahan Perusahaan
    this.custForm.get("custCompanyWard").valueChanges.subscribe(x => {

    })

    // Onchange Kelurahan Kontak Darurat
    this.custForm.get("custGuardianWard").valueChanges.subscribe(x => {
      this.custGuardianPostalCode.setValue('');
    })

    // Onchange Kode Pos Identitas
    this.custForm.get("custIdentityPostalCode").valueChanges.subscribe(x => {
      $("#inline-full-domicilepostalcode").val(x)
      this.custDomicilePostalCode.setValue(x)
    })

    // Onchange Kewarganegaraan
    this.custForm.get("custNationality").valueChanges.subscribe(x => {
      $("#inline-full-coi").val(x)
      this.custCountryOfIssue.setValue(x)
    })

    let cust_id = this.route.snapshot.paramMap.get("id");
    // GET CUSTOMER DATA
    // this.getCustDataByID(cust_id);
    this.getCustDataByID(cust_id).then(res => 
      {
        this.getAPIValue().then(res=>{
          this.getCustDataByID_Temp(this.custCIF.value).then(res => 
            {
              if(this.custSubmissionStatus.value=="PENDING_EDIT"){
                // console.log(this.arrChanged);
                // for (var key in this.arrChanged) {
                //   if((this.custForm.controls[key].value != this.custForm.controls[key+"_Old"].value && !isNaN(this.custForm.controls[key].value)) || (this.custForm.controls[key].value != this.custForm.controls[key+"_Old"].value && !isNaN(this.custForm.controls[key+"_Old"].value))){
                //     this.custForm.controls[key+"_Old"].markAsDirty();
                //     console.log(key + " " +this.custForm.controls[key+"_Old"].dirty + " " +this.custForm.controls[key+"_Old"].value);
                //   }
                // }
                this.getAPIValue_Temp();               
              }
              // console.log(this.tempCityCouple);
            }
          );  
        });
      }
    );
    // END

  }

  testing(){
    console.log(this.custIdentityProvince.value);
  }

  getCustDataByID(param:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/add-cif-individual-customer-data/" + param, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.dtCustData = this.responseApi.data;
              // console.log(this.dtCustData);
              if(this.dtCustData.length==0){
                Swal.fire("Ooops", "Data Tidak Ditemukan", "error").then(function() {
                  window.location.href = "/admin/cif-individual";
                });
              }
    
              // Inisialisasi Variabel
              let vp:any;               // dd Masa Berlaku
              let dtvp:any;             // text input Masa Berlaku Berdasarkan Tanggal
              let top:any;              // dd Jenis Pekerjaan
              let strtop:any;           // Jenis Pekerjaan Lainnya
              let datatop = "None;Pelajar/Mahasiswa;Pegawai Swasta;Pegawai Negeri;Ibu Rumah Tangga;Pensiunan;TNI/POLRI;Guru/Dosen;Wiraswasta;Profesional;";
              let gr:any;               // rb Hubungan
              let strgr:any;            // Hubungan Lainnya
              let datagr = "Saudara Kandung;Anak;Orang Tua;Suami/Istri";
    
              if(this.dtCustData[0].validity_period=="Seumur Hidup"){
                vp = "Seumur Hidup";
                dtvp="";
              } else {
                vp = "Berdasarkan Tanggal";
                dtvp = this.dtCustData[0].validity_period;
              };
    
              if(datatop.indexOf(this.dtCustData[0].type_of_work) >= 0){
                top = this.dtCustData[0].type_of_work;
                strtop="";
              } else {
                top = "Lainnya";
                strtop = this.dtCustData[0].type_of_work;
              };
    
              if(datagr.indexOf(this.dtCustData[0].guardian_relationship) >= 0){
                gr = this.dtCustData[0].guardian_relationship;
                strgr="";
              } else {
                gr = "Lainnya";
                strgr = this.dtCustData[0].guardian_relationship;
              };
              
              this.custForm.patchValue({
                custPrefixName:this.dtCustData[0].prefix_name,
                custSuffixName:this.dtCustData[0].suffix_name,
                custFullName:this.dtCustData[0].full_name,
                custNickname:this.dtCustData[0].nickname,
                custPlaceOfBirth:this.dtCustData[0].place_of_birth,
                custCountryOfBirth:this.dtCustData[0].country_of_birth,
                custDateOfBirth: this.dtCustData[0].dob,
                custReligion:this.dtCustData[0].religion,
                custSex:this.dtCustData[0].sex,
                custMothersName:this.dtCustData[0].mothers_name,
                custMaritalStatus:this.dtCustData[0].marital_status,
                custLastEducation:this.dtCustData[0].last_education,
                custNationality:this.dtCustData[0].nationality,
                custIdentityType:this.dtCustData[0].identity_type,
                custIdentityNumber:this.dtCustData[0].identity_number,

                //New
                custCoupleName:this.dtCustData[0].couple_name,
                custCoupleAddress:this.dtCustData[0].couple_address,
                custCoupleCountry:this.dtCustData[0].couple_country,
                custCoupleProvince:this.dtCustData[0].couple_province,
                custCoupleCity:this.dtCustData[0].couple_city,
                custCoupleDistricts:this.dtCustData[0].couple_districts,
                custCoupleWard:this.dtCustData[0].couple_ward,
                custCouplePostalCode:this.dtCustData[0].couple_postalcode,
                custCouplePhone:this.dtCustData[0].couple_phone,
                custNumberOfDependents:this.dtCustData[0].number_of_dependents,
                custLongWorked:this.dtCustData[0].company_longworked,
                custProductSpecification:this.dtCustData[0].entre_product_specification,
                custWorkingOwner:this.dtCustData[0].entre_business_ownership,
                custGrossIncome:this.dtCustData[0].gross_income,
                custNetIncome:this.dtCustData[0].net_income,
                //End
    
                // validity_period
                custvpText:vp,
                custvpDate:dtvp,
    
                custCountryOfIssue:this.dtCustData[0].country_of_issue,
                custIdentityAddress:this.dtCustData[0].identity_address,
                custIdentityCountry:this.dtCustData[0].identity_country,
                // custIdentityProvince: this.dtCustData[0].identity_province),
                custIdentityProvince: this.dtCustData[0].identity_province,
                custIdentityCity:this.dtCustData[0].identity_city,
                custIdentityDistricts:this.dtCustData[0].identity_districts,
                custIdentityWard:this.dtCustData[0].identity_ward,
                custIdentityPostalCode:this.dtCustData[0].identity_postcode,
                custDomicileAddress:this.dtCustData[0].domicile_address,
                custDomicileCountry:this.dtCustData[0].domicile_country,
                custDomicileProvince:this.dtCustData[0].domicile_province,
                custDomicileCity:this.dtCustData[0].domicile_city,
                custDomicileDistricts:this.dtCustData[0].domicile_districts,
                custDomicileWard:this.dtCustData[0].domicile_ward,
                custDomicilePostalCode:this.dtCustData[0].domicile_postcode,
                custStatusOfResidence:this.dtCustData[0].status_of_residence,
                custLongTimeOccupy:this.dtCustData[0].long_time_occupy,
                custHousePhone:this.dtCustData[0].house_phone,
                custCompanyPhone:this.dtCustData[0].company_phone,
                custFax:this.dtCustData[0].fax,
                custEmail:this.dtCustData[0].email,
                custCellphone:this.dtCustData[0].cellphone,
    
                // type_of_work
                custTOWText:strtop,
                custTOWDD:top,
    
                custBusinessField:this.dtCustData[0].business_fields,
                custPosition:this.dtCustData[0].position,
                custCompanyName:this.dtCustData[0].company_name,
                custCompanyAddress:this.dtCustData[0].company_address,
                custCompanyCountry:this.dtCustData[0].company_country,
                custCompanyProvince:this.dtCustData[0].company_province,
                custCompanyCity:this.dtCustData[0].company_city,
                custCompanyDistricts:this.dtCustData[0].company_districts,
                custCompanyWard:this.dtCustData[0].company_ward,
    
                custMonthlyIncome:this.dtCustData[0].monthly_income,
                custMonthlyAverageTransaction:this.dtCustData[0].monthly_average_transactions,
                custGuardianFullName:this.dtCustData[0].guardian_full_name,
                custGuardianAddress:this.dtCustData[0].guardian_address,
                custGuardianCountry:this.dtCustData[0].guardian_country,
                custGuardianProvince:this.dtCustData[0].guardian_province,
                custGuardianCity:this.dtCustData[0].guardian_city,
                custGuardianDistricts:this.dtCustData[0].guardian_districts,
                custGuardianWard:this.dtCustData[0].guardian_ward,
                custGuardianPostalCode:this.dtCustData[0].guardian_postcode,
                custGuardianCellphone:this.dtCustData[0].guardian_cellphone,
                custGuardianHousePhone:this.dtCustData[0].guardian_house_phone,
    
                // guardian_relationship
                custRelationshipDD:gr,
                custRelationshipText:strgr,
    
                custCIF:this.dtCustData[0].cif_id,
                custSOFValue:this.dtCustData[0].source_of_funds,
                custPurposeValue:this.dtCustData[0].purpose_of_account_opening,

                custSubmissionStatus:this.dtCustData[0].status_pengajuan,
                custStatusData:this.dtCustData[0].status_data,
                custID:this.dtCustData[0].id,
                createdBy : this.ApiConfig.USER_DATA.username
              })

              // this.getProvince();

              // console.log(this.custIdentityProvince.value);
              // console.log(this.tempProvince);

              //New
              let datass = "Konsinyasi/Bagi Hasil;Kredit dan Tunai;Kredit;Tunai;"
              let datawo = "Sewa/Kontrak;Milik Sendiri;"   

              if(datass.indexOf(this.dtCustData[0].entre_sales_system) >= 0){
                this.custSystemSelling.setValue(this.dtCustData[0].entre_sales_system);
              }else{
                this.custSystemSellingOthers.setValue(this.dtCustData[0].entre_sales_system);
                this.custSystemSelling.setValue("Lainnya");
              }

              if(datawo.indexOf(this.dtCustData[0].entre_land_status) >= 0){
                this.custWorkingLandOwner.setValue(this.dtCustData[0].entre_land_status);
              }else{
                this.custWorkingLandOwnerOthers.setValue(this.dtCustData[0].entre_land_status);
                this.custWorkingLandOwner.setValue("Lainnya");
              }
              //End
              // console.log(this.custCoupleCity.value);
              this.addCheckboxes_SOF();
              this.addCheckboxes_Purpose();
              // this.getAPIValue();
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 100);
    });
  }

  getCustDataByID_Temp(param:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/last-temp-cif-individual/" + param, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.dtCustData = this.responseApi.data;
              if(this.dtCustData.length==0){
                Swal.fire("Ooops", "Data Tidak Ditemukan", "error").then(function() {
                  window.location.href = "/admin/cif-individual";
                });
              }
    
              // Inisialisasi Variabel
              let vp:any;               // dd Masa Berlaku
              let dtvp:any;             // text input Masa Berlaku Berdasarkan Tanggal
              let top:any;              // dd Jenis Pekerjaan
              let strtop:any;           // Jenis Pekerjaan Lainnya
              let datatop = "None;Pelajar/Mahasiswa;Pegawai Swasta;Pegawai Negeri;Ibu Rumah Tangga;Pensiunan;TNI/POLRI;Guru/Dosen;Wiraswasta;Profesional;";
              let gr:any;               // rb Hubungan
              let strgr:any;            // Hubungan Lainnya
              let datagr = "Saudara Kandung;Anak;Orang Tua;Suami/Istri";         
    
              if(this.dtCustData[0].validity_period=="Seumur Hidup"){
                vp = "Seumur Hidup";
                dtvp ="";
              } else {
                vp = "Berdasarkan Tanggal";
                dtvp = this.dtCustData[0].validity_period;
              };
    
              if(datatop.indexOf(this.dtCustData[0].type_of_work) >= 0){
                top = this.dtCustData[0].type_of_work;
                strtop="";
              } else {
                top = "Lainnya";
                strtop = this.dtCustData[0].type_of_work;
              };
    
              if(datagr.indexOf(this.dtCustData[0].guardian_relationship) >= 0){
                gr = this.dtCustData[0].guardian_relationship;
                strgr="";
              } else {
                gr = "Lainnya";
                strgr = this.dtCustData[0].guardian_relationship;
              };
              
              this.custForm.patchValue({
                custPrefixName_Old:this.dtCustData[0].prefix_name,
                custSuffixName_Old:this.dtCustData[0].suffix_name,
                custFullName_Old:this.dtCustData[0].full_name,
                custNickname_Old:this.dtCustData[0].nickname,
                custPlaceOfBirth_Old:this.dtCustData[0].place_of_birth,
                custCountryOfBirth_Old:this.dtCustData[0].country_of_birth,
                custDateOfBirth_Old: this.dtCustData[0].dob,
                custReligion_Old:this.dtCustData[0].religion,
                custSex_Old:this.dtCustData[0].sex,
                custMothersName_Old:this.dtCustData[0].mothers_name,
                custMaritalStatus_Old:this.dtCustData[0].marital_status,
                custLastEducation_Old:this.dtCustData[0].last_education,
                custNationality_Old:this.dtCustData[0].nationality,
                custIdentityType_Old:this.dtCustData[0].identity_type,
                custIdentityNumber_Old:this.dtCustData[0].identity_number,

                //New
                custCoupleName_Old:this.dtCustData[0].couple_name,
                custCoupleAddress_Old:this.dtCustData[0].couple_address,
                custCoupleCountry_Old:this.dtCustData[0].couple_country,
                custCoupleProvince_Old:this.dtCustData[0].couple_province,
                custCoupleCity_Old:this.dtCustData[0].couple_city,
                custCoupleDistricts_Old:this.dtCustData[0].couple_districts,
                custCoupleWard_Old:this.dtCustData[0].couple_ward,
                custCouplePostalCode_Old:this.dtCustData[0].couple_postalcode,
                custCouplePhone_Old:this.dtCustData[0].couple_phone,
                custNumberOfDependents_Old:this.dtCustData[0].number_of_dependents,
                custLongWorked_Old:this.dtCustData[0].company_longworked,
                custProductSpecification_Old:this.dtCustData[0].entre_product_specification,
                custWorkingOwner_Old:this.dtCustData[0].entre_business_ownership,
                custGrossIncome_Old:this.dtCustData[0].gross_income,
                custNetIncome_Old:this.dtCustData[0].net_income,
                //End
    
                // validity_period
                custvpText_Old:vp,
                custvpDate_Old:dtvp,
    
                custCountryOfIssue_Old:this.dtCustData[0].country_of_issue,
                custIdentityAddress_Old:this.dtCustData[0].identity_address,
                custIdentityCountry_Old:this.dtCustData[0].identity_country,
                custIdentityProvince_Old: this.dtCustData[0].identity_province,
                custIdentityCity_Old:this.dtCustData[0].identity_city,
                custIdentityDistricts_Old:this.dtCustData[0].identity_districts,
                custIdentityWard_Old:this.dtCustData[0].identity_ward,
                custIdentityPostalCode_Old:this.dtCustData[0].identity_postcode,
                custDomicileAddress_Old:this.dtCustData[0].domicile_address,
                custDomicileCountry_Old:this.dtCustData[0].domicile_country,
                custDomicileProvince_Old:this.dtCustData[0].domicile_province,
                custDomicileCity_Old:this.dtCustData[0].domicile_city,
                custDomicileDistricts_Old:this.dtCustData[0].domicile_districts,
                custDomicileWard_Old:this.dtCustData[0].domicile_ward,
                custDomicilePostalCode_Old:this.dtCustData[0].domicile_postcode,
                custStatusOfResidence_Old:this.dtCustData[0].status_of_residence,
                custLongTimeOccupy_Old:this.dtCustData[0].long_time_occupy,
                custHousePhone_Old:this.dtCustData[0].house_phone,
                custCompanyPhone_Old:this.dtCustData[0].company_phone,
                custFax_Old:this.dtCustData[0].fax,
                custEmail_Old:this.dtCustData[0].email,
                custCellphone_Old:this.dtCustData[0].cellphone,
    
                // type_of_work
                custTOWText_Old:strtop,
                custTOWDD_Old:top,
    
                custBusinessField_Old:this.dtCustData[0].business_fields,
                custPosition_Old:this.dtCustData[0].position,
                custCompanyName_Old:this.dtCustData[0].company_name,
                custCompanyAddress_Old:this.dtCustData[0].company_address,
                custCompanyCountry_Old:this.dtCustData[0].company_country,
                custCompanyProvince_Old:this.dtCustData[0].company_province,
                custCompanyCity_Old:this.dtCustData[0].company_city,
                custCompanyDistricts_Old:this.dtCustData[0].company_districts,
                custCompanyWard_Old:this.dtCustData[0].company_ward,
    
                custMonthlyIncome_Old:this.dtCustData[0].monthly_income,
                custMonthlyAverageTransaction_Old:this.dtCustData[0].monthly_average_transactions,
                custGuardianFullName_Old:this.dtCustData[0].guardian_full_name,
                custGuardianAddress_Old:this.dtCustData[0].guardian_address,
                custGuardianCountry_Old:this.dtCustData[0].guardian_country,
                custGuardianProvince_Old:this.dtCustData[0].guardian_province,
                custGuardianCity_Old:this.dtCustData[0].guardian_city,
                custGuardianDistricts_Old:this.dtCustData[0].guardian_districts,
                custGuardianWard_Old:this.dtCustData[0].guardian_ward,
                custGuardianPostalCode_Old:this.dtCustData[0].guardian_postcode,
                custGuardianCellphone_Old:this.dtCustData[0].guardian_cellphone,
                custGuardianHousePhone_Old:this.dtCustData[0].guardian_house_phone,
    
                // guardian_relationship
                custRelationshipDD_Old:gr,
                custRelationshipText_Old:strgr,
    
                custCIF_Old:this.dtCustData[0].cif_id,
                custSOFValue_Old:this.dtCustData[0].source_of_funds,
                custPurposeValue_Old:this.dtCustData[0].purpose_of_account_opening,

                custSubmissionStatus_Old:this.dtCustData[0].status_pengajuan,
                custStatusData_Old:this.dtCustData[0].status_data,
                custID_Old:this.dtCustData[0].id,
                createdBy_Old: this.dtCustData[0].created_by,
                custCreateDate_Old: this.dtCustData[0].cd
              })
              //New
              let datass = "Konsinyasi/Bagi Hasil;Kredit dan Tunai;Kredit;Tunai;"
              let datawo = "Sewa/Kontrak;Milik Sendiri;"   

              if(datass.indexOf(this.dtCustData[0].entre_sales_system) >= 0){
                this.custSystemSelling_Old.setValue(this.dtCustData[0].entre_sales_system);
              }else{
                this.custSystemSellingOthers_Old.setValue(this.dtCustData[0].entre_sales_system);
                this.custSystemSelling_Old.setValue("Lainnya");
              }

              if(datawo.indexOf(this.dtCustData[0].entre_land_status) >= 0){
                this.custWorkingLandOwner_Old.setValue(this.dtCustData[0].entre_land_status);
              }else{
                this.custWorkingLandOwnerOthers_Old.setValue(this.dtCustData[0].entre_land_status);
                this.custWorkingLandOwner_Old.setValue("Lainnya");
              }
              //End

              // console.log(this.custIdentityCity_Old.value);
              $("#request-by").html(this.createdBy_Old.value);
              $("#request-date").html(this.custCreateDate_Old.value);
              this.addCheckboxes_SOF_Temp();
              this.addCheckboxes_Purpose_Temp();
              // this.getAPIValue();
              // console.log(isNaN(this.custForm.controls["custPlaceOfBirth_Old"].value));
              if(this.custSubmissionStatus.value=="PENDING_EDIT"){
                for (var key in this.arrChanged) {
                  if((this.custForm.controls[key].value != this.custForm.controls[key+"_Old"].value && !isNaN(this.custForm.controls[key].value)) || (this.custForm.controls[key].value != this.custForm.controls[key+"_Old"].value && !isNaN(this.custForm.controls[key+"_Old"].value)) || (this.custForm.controls[key].value != this.custForm.controls[key+"_Old"].value && isNaN(this.custForm.controls[key].value) && isNaN(this.custForm.controls[key+"_Old"].value))){
                    this.custForm.controls[key+"_Old"].markAsDirty();
                    // console.log(key+"_Old");
                    // console.log(key + " " +this.custForm.controls[key+"_Old"].dirty + " " +this.custForm.controls[key+"_Old"].value);
                  }
                }
                // console.log("ini data ttl " + this.custForm.controls["custPlaceOfBirth_Old"].dirty + " " + this.custForm.controls["custPlaceOfBirth_Old"].value);
              }
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 100);
    });
  }

  check(){
    // console.log(this.custIdentityProvince.value);
    // console.log(this.custIdentityCity.value);
    // console.log(this.custIdentityDistricts.value);
    // console.log(this.custIdentityWard.value);
    // console.log(this.custDomicileProvince.value);
    // console.log(this.custDomicileCity.value);
    // console.log(this.custDomicileDistricts.value);
    // console.log(this.custDomicileWard.value);
    // console.log(this.custCompanyProvince.value);
    // console.log(this.custCompanyCity.value);
    // console.log(this.custCompanyDistricts.value);
    // console.log(this.custCompanyWard.value);
    // console.log(this.custGuardianProvince.value);
    // console.log(this.custGuardianCity.value);
    // console.log(this.custGuardianDistricts.value);
    // console.log(this.custGuardianWard.value);
    console.log(this.custCoupleProvince.value);
    // console.log(this.custSex_Old.value);
    // this.custForm.controls.custReligion_Old.enable()
    // this.custForm.disable()
    // this.updateCustByTemp().then();
  }

  getAPIValue(){
    return new Promise((resolve) => {
      setTimeout(()=>{
        if(this.custIdentityCountry.value.toUpperCase() == "INDONESIA" || this.custDomicileCountry.value.toUpperCase() == "INDONESIA" || this.custCompanyCountry.value.toUpperCase() == "INDONESIA" || this.custGuardianCountry.value.toUpperCase() == "INDONESIA" || this.custCoupleCountry.value.toUpperCase() == "INDONESIA"){
          // console.log("hi");
          // this.loadCity("custIdentityProvince", this.custIdentityProvince.value);
          Promise.all([
            this.loadCity("custIdentityProvince", this.custIdentityProvince.value),
            this.loadDistrict("custIdentityCity", this.custIdentityCity.value),
            this.loadSubDistrict("custIdentityDistricts", this.custIdentityDistricts.value),
            this.loadCity("custDomicileProvince", this.custDomicileProvince.value),
            this.loadDistrict("custDomicileCity", this.custDomicileCity.value),
            this.loadSubDistrict("custDomicileDistricts", this.custDomicileDistricts.value),
            this.loadCity("custCompanyProvince", this.custCompanyProvince.value),
            this.loadDistrict("custCompanyCity", this.custCompanyCity.value),
            this.loadSubDistrict("custCompanyDistricts", this.custCompanyDistricts.value),
            this.loadCity("custGuardianProvince", this.custGuardianProvince.value),
            this.loadDistrict("custGuardianCity", this.custGuardianCity.value),
            this.loadSubDistrict("custGuardianDistricts", this.custGuardianDistricts.value),

            //New
            this.loadCity("custCoupleProvince", this.custCoupleProvince.value),
            this.loadDistrict("custCoupleCity", this.custCoupleCity.value),
            this.loadSubDistrict("custCoupleDistricts", this.custCoupleDistricts.value)
            //End
          
          ]).then(function() {
          });
        }
    
        this.getMyLog();
        this.getLogCIF(this.custID.value);
        resolve(true);
      },200);
    })


  }

  getAPIValue_Temp(){
    return new Promise((resolve) => {
      setTimeout(()=>{
        if(this.custSubmissionStatus.value=="PENDING_EDIT"){
          if(this.custIdentityCountry_Old.value.toUpperCase() == "INDONESIA" || this.custDomicileCountry_Old.value.toUpperCase() == "INDONESIA" || this.custCompanyCountry_Old.value.toUpperCase() == "INDONESIA" || this.custGuardianCountry_Old.value.toUpperCase() == "INDONESIA" || this.custCoupleCountry_Old.value.toUpperCase() == "INDONESIA"){
            // console.log("hi");
            Promise.all([
              this.loadCity("custIdentityProvince_Old", this.custIdentityProvince_Old.value),
              this.loadDistrict("custIdentityCity_Old", this.custIdentityCity_Old.value),
              this.loadSubDistrict("custIdentityDistricts_Old", this.custIdentityDistricts_Old.value),
              this.loadCity("custDomicileProvince_Old", this.custDomicileProvince_Old.value),
              this.loadDistrict("custDomicileCity_Old", this.custDomicileCity_Old.value),
              this.loadSubDistrict("custDomicileDistricts_Old", this.custDomicileDistricts_Old.value),
              this.loadCity("custCompanyProvince_Old", this.custCompanyProvince_Old.value),
              this.loadDistrict("custCompanyCity_Old", this.custCompanyCity_Old.value),
              this.loadSubDistrict("custCompanyDistricts_Old", this.custCompanyDistricts_Old.value),
              this.loadCity("custGuardianProvince_Old", this.custGuardianProvince_Old.value),
              this.loadDistrict("custGuardianCity_Old", this.custGuardianCity_Old.value),
              this.loadSubDistrict("custGuardianDistricts_Old", this.custGuardianDistricts_Old.value),

              //New
              this.loadCity("custCoupleProvince_Old", this.custCoupleProvince_Old.value),
              this.loadDistrict("custCoupleCity_Old", this.custCoupleCity_Old.value),
              this.loadSubDistrict("custCoupleDistricts_Old", this.custCoupleDistricts_Old.value)
              //End
            ]).then(function() {
            });
            resolve(true);
          }
        }
      },200)
    })
    
  }

  getMyLog(){
    
    let arrData = [this.createdBy.value, this.custID.value];
    this.http.get(this.ApiConfig.API_URL + "/log-cif-individual-by-idUser/" + arrData, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          if(this.responseApi.data.length > 0){
            $('.btn-action').css('display', 'none');
            $('#information-label').css('display', 'block');
            $('#information-label').html("Anda sudah pernah melakukan approval pada pendingan ini");
          }
        }
      }, 
      err => {
        console.log(err);
      }
    )
      
  }
  
  isApproval(id){
    // console.log(id);
    this.http.get(this.ApiConfig.API_URL + "/check-user-role/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          if(this.responseApi.data.length == 0){
            $('.btn-action').css('display', 'none');
            $('#information-label').css('display', 'block');
          }
        }
      }, 
      err => {
        console.log(err);
      }
    )
      
  }

  get SOFFormArray() {
    return this.custForm.controls.custSOF as FormArray;
  }

  get SOFFormArray_Temp() {
    return this.custForm.controls.custSOF_Old as FormArray;
  }

  private addCheckboxes_SOF() {
    let arrSOFValue = this.custSOFValue.value.split(";");
    let boolGetData
    for (let i = 0; i < this.arrSOF.length; i++){
      boolGetData = false;
      for (let j = 0; j < arrSOFValue.length-1; j++){
        if(this.arrSOF[i].text == arrSOFValue[j]){
          this.SOFFormArray.push(new FormControl({value: true, disabled: true}));
          boolGetData = true;
          if(arrSOFValue[j] == "Lain-lain"){
            this.custSourceOfFunds_OthersText.setValue(arrSOFValue[j+1]);
            $("#inline-full-sourceoffunds-other-text").css("display", "block");
            return;
          }
          break;
        }
      }
      if(!boolGetData){
        this.SOFFormArray.push(new FormControl({value: false, disabled: true}));
      }
    }
  }

  private addCheckboxes_SOF_Temp() {
    let arrSOFValue = this.custSOFValue_Old.value.split(";");
    let boolGetData
    for (let i = 0; i < this.arrSOF.length; i++){
      boolGetData = false;
      for (let j = 0; j < arrSOFValue.length-1; j++){
        if(this.arrSOF[i].text == arrSOFValue[j]){
          this.SOFFormArray_Temp.push(new FormControl({value: true, disabled: true}));
          boolGetData = true;
          if(arrSOFValue[j] == "Lain-lain"){
            this.custSourceOfFunds_OthersText_Old.setValue(arrSOFValue[j+1]);
            $("#inline-full-sourceoffunds-other-text").css("display", "block");
            return;
          }
          break;
        }
      }
      if(!boolGetData){
        this.SOFFormArray_Temp.push(new FormControl({value: false, disabled: true}));
      }
    }
  }

  get PurposeFormArray_Temp() {
    return this.custForm.controls.custPurpose_Old as FormArray;
  }

  get PurposeFormArray() {
    return this.custForm.controls.custPurpose as FormArray;
  }

  private addCheckboxes_Purpose_Temp() {
    // this.arrPurpose.forEach(() => this.PurposeFormArray.push(new FormControl(false)));
    let arrPurposeValue = this.custPurposeValue_Old.value.split(";");
    let boolGetData
    for (let i = 0; i < this.arrPurpose.length; i++){
      boolGetData = false;
      for (let j = 0; j < arrPurposeValue.length-1; j++){
        if(this.arrPurpose[i].text == arrPurposeValue[j]){
          this.PurposeFormArray_Temp.push(new FormControl({value: true, disabled: true}));
          boolGetData = true;
          if(arrPurposeValue[j] == "Lain-lain"){
            this.custPurpose_OthersText_Old.setValue(arrPurposeValue[j+1]);
            $("#inline-full-purpose-others-text").css("display", "block");
            return;
          }
          break;
        }
      }
      if(!boolGetData){
        this.PurposeFormArray_Temp.push(new FormControl({value: false, disabled: true}));
      }
    }
  }

  private addCheckboxes_Purpose() {
    // this.arrPurpose.forEach(() => this.PurposeFormArray.push(new FormControl(false)));
    let arrPurposeValue = this.custPurposeValue.value.split(";");
    let boolGetData
    for (let i = 0; i < this.arrPurpose.length; i++){
      boolGetData = false;
      for (let j = 0; j < arrPurposeValue.length-1; j++){
        if(this.arrPurpose[i].text == arrPurposeValue[j]){
          this.PurposeFormArray.push(new FormControl({value: true, disabled: true}));
          boolGetData = true;
          if(arrPurposeValue[j] == "Lain-lain"){
            this.custPurpose_OthersText.setValue(arrPurposeValue[j+1]);
            $("#inline-full-purpose-others-text").css("display", "block");
            return;
          }
          break;
        }
      }
      if(!boolGetData){
        this.PurposeFormArray.push(new FormControl({value: false, disabled: true}));
      }
    }
  }

  getTokenRaja(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.tokenRaja = this.responseApi.token;
          this.getProvince();
          
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvince(){
    
    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
          // console.log(this.tempProvince);
          // console.log(this.custIdentityProvince.value);
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
      
  }

  onProvinceChange(event: any){
    this.provinceId = this.custForm.controls[event.source.ngControl.name].value;
    this.getCity(event.source.ngControl.name);
  }

  getCity(type:any){       
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;

          if(type == "custIdentityProvince"){
            this.tempCity = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custDomicileProvince"){
            this.tempCityDomicile = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custCompanyProvince"){
            this.tempCityCompany = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custGuardianProvince"){
            this.tempCityGuardian = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }

          //New
          if(type == "custCoupleProvince"){
            this.tempCityCouple = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          //End
          
          
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  loadCity(type:any, id:any){       
    return new Promise((resolve) => {
      setTimeout(()=>{
        if(id == ""){
          resolve(true);
        }else{
          this.http.get(this.ApiConfig.API_URL + "/get-city/" + id, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
              // console.log("Masuk");
              // console.log(this.responseApi.status);
              if(this.responseApi.status == "200"){
                this.dataCity = this.responseApi.data;
  
                if(type == "custIdentityProvince"){
                  this.tempCity = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custDomicileProvince"){
                  this.tempCityDomicile = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custCompanyProvince"){
                  this.tempCityCompany = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custGuardianProvince"){
                  this.tempCityGuardian = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custIdentityProvince_Old"){
                  this.tempCity_Old = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custDomicileProvince_Old"){
                  this.tempCityDomicile_Old = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custCompanyProvince_Old"){
                  this.tempCityCompany_Old = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custGuardianProvince_Old"){
                  this.tempCityGuardian_Old = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
  
                //New
                if(type == "custCoupleProvince_Old"){
                  this.tempCityCouple_Old = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custCoupleProvince"){
                  this.tempCityCouple = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                //End
  
                // console.log(this.dataCity);
                resolve(true);
              } else {
                resolve(true);
              }
            },
            err => {
              console.log(err);
            }
          );
        }
        
      },100);
    })
  }

  onCityChange(event: any){
    this.cityId = this.custForm.controls[event.source.ngControl.name].value;
    if(event.source.ngControl.name == "custIdentityCity"){
      this.custDomicileCity.setValue(this.cityId);

      this.custIdentityDistricts.setValue('');
      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempDistrict=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    }
    this.getDistrict(event.source.ngControl.name);
  }

  loadDistrict(type:any, id:any){
    return new Promise((resolve) => {
      setTimeout(()=>{

        if(id == ""){
          resolve(true);
        }else{
          this.http.get(this.ApiConfig.API_URL + "/get-districts/" + id, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataDistrict = this.responseApi.data;
  
                if(type == "custIdentityCity"){
                  this.tempDistrict = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custDomicileCity"){
                  this.tempDistrictDomicile = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custCompanyCity"){
                  this.tempDistrictCompany = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custGuardianCity"){
                  this.tempDistrictGuardian = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
  
                if(type == "custIdentityCity_Old"){
                  this.tempDistrict_Old = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custDomicileCity_Old"){
                  this.tempDistrictDomicile_Old = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custCompanyCity_Old"){
                  this.tempDistrictCompany_Old = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custGuardianCity_Old"){
                  this.tempDistrictGuardian_Old = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
  
                //New
                if(type == "custCoupleCity_Old"){
                  this.tempDistrictCouple_Old = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custCoupleCity"){
                  this.tempDistrictCouple = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                //End
  
                resolve(true);
              } else {
                resolve(true);
              }
            },
            err => {
              console.log(err);
            }
          );
        }
        
      },100);
    })
  }

  getDistrict(type:any){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;

          if(type == "custIdentityCity"){
            this.tempDistrict = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custDomicileCity"){
            this.tempDistrictDomicile = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custCompanyCity"){
            this.tempDistrictCompany = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custGuardianCity"){
            this.tempDistrictGuardian = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }

        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.districtId = this.custForm.controls[event.source.ngControl.name].value;

    if(event.source.ngControl.name == "custIdentityDistricts"){
      this.custDomicileDistricts.setValue(this.districtId);

      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');
  
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    }
    
    this.getSubDistrict(event.source.ngControl.name);
  }

  loadSubDistrict(type:any, id:any){
    return new Promise((resolve) => {
      setTimeout(()=>{
        if(id == ""){
          resolve(true);
        }else{
          this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + id, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataSubdistrict = this.responseApi.data;
                if(type == "custIdentityDistricts"){
                  this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custDomicileDistricts"){
                  this.tempSubdistrictDomicile = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custCompanyDistricts"){
                  this.tempSubdistrictCompany = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custGuardianDistricts"){
                  this.tempSubdistrictGuardian = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
  
                if(type == "custIdentityDistricts_Old"){
                  this.tempSubdistrict_Old = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custDomicileDistricts_Old"){
                  this.tempSubdistrictDomicile_Old = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custCompanyDistricts_Old"){
                  this.tempSubdistrictCompany_Old = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custGuardianDistricts_Old"){
                  this.tempSubdistrictGuardian_Old = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
  
                //New
                if(type == "custCoupleDistricts_Old"){
                  this.tempSubdistrictCouple_Old = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custCoupleDistricts"){
                  this.tempSubdistrictCouple = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                //End
  
                resolve(true);
              } else {
                resolve(true);
              }    
            },
            err => {
              console.log(err);
            }
          );
        }
        
      },100);
    })
  }

  getSubDistrict(type:any){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          if(type == "custIdentityDistricts"){
            this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custDomicileDistricts"){
            this.tempSubdistrictDomicile = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custCompanyDistricts"){
            this.tempSubdistrictCompany = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custGuardianDistricts"){
            this.tempSubdistrictGuardian = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }

        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubdistrictChange(event: any){
    this.custDomicileWard.setValue(this.custForm.controls[event.source.ngControl.name].value)
    this.custIdentityPostalCode.setValue('');
    this.custDomicilePostalCode.setValue('');
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  getAVGTransaction(){
    this.http.get(this.ApiConfig.API_URL + "/add-cif-individual-average-transaction", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dtAVGTransaction = this.responseApi.data;
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  validateForm() {
    this.SOFValue = "";
    this.PurposeValue = "";
    this.custForm.controls["custValid"].setValue("");
    let skipval_id = ["custIdentityProvince", "custIdentityCity", "custIdentityDistricts", "custIdentityWard", "custIdentityPostalCode"];
    let skipval_domicile = ["custDomicileProvince", "custDomicileCity", "custDomicileDistricts", "custDomicileWard", "custDomicilePostalCode"];
    let skipval_guardian = ["custGuardianProvince", "custGuardianCity", "custGuardianDistricts", "custGuardianWard", "custGuardianPostalCode"];
    const invalid = [];
    const controls = this.custForm.controls;

    for (const name in controls) {
        if (skipval_id.find(function(element){return element == name;}) == name && controls["custIdentityCountry"].value.toUpperCase() != "INDONESIA"){
          continue;
        }
        if (skipval_domicile.find(function(element){return element == name;}) == name && controls["custDomicileCountry"].value.toUpperCase() != "INDONESIA"){
          continue;
        }
        // if (skipval_guardian.find(function(element){return element == name;}) == name && controls["custGuardianCountry"].value.toUpperCase() != "INDONESIA"){
        //   continue;
        // }
        if (name == "custSourceOfFunds_OthersText"){
          if(this.custSOF.value.includes(true)){
            // Sumber Dana Value
            this.custSourceOfFunds_OthersText.markAsUntouched();
            // this.custSourceOfFunds_OthersText.setErrors(null);
            const selectedSOF = this.custForm.value.custSOF
              .map((checked, i) => checked ? this.arrSOF[i].text : null)
              .filter(v => v !== null);
            for(let x = 0; x < selectedSOF.length; x++){
              this.SOFValue = this.SOFValue + selectedSOF[x] + ";";
              if(selectedSOF[x] == "Lain-lain"){
                this.custSourceOfFunds_OthersText.markAsTouched();
                if(this.custSourceOfFunds_OthersText.value==""){
                  this.openAll();
                  controls["custValid"].setValue("TIDAK VALID");
                  return false;
                } else {
                  this.SOFValue = this.SOFValue + this.custSourceOfFunds_OthersText.value + ";";
                }
              }
            }
            if(this.SOFValue==""){
              this.openAll();
              controls["custValid"].setValue("TIDAK VALID");
              return false;
            }
            if(this.custSourceOfFunds_OthersText.untouched){
              continue;
            }
          } else{
            this.custSOF.markAsTouched();
            this.openAll();
            controls["custValid"].setValue("TIDAK VALID");
            return false;
          }
        }
        if (name == "custPurpose_OthersText"){
          if(this.custPurpose.value.includes(true)){
            // Tujuan Pembukaan Rekening Value
            this.custPurpose_OthersText.markAsUntouched();
            const selectedPurpose = this.custForm.value.custPurpose
              .map((checked, i) => checked ? this.arrPurpose[i].text : null)
              .filter(v => v !== null);
            for(let x = 0; x < selectedPurpose.length; x++){
              this.PurposeValue = this.PurposeValue + selectedPurpose[x] + ";";
              if(selectedPurpose[x]== "Lain-lain"){
                this.custPurpose_OthersText.markAsTouched();
                if(this.custPurpose_OthersText.value==""){
                  this.openAll();
                  controls["custValid"].setValue("TIDAK VALID");
                  return false;
                } else {
                  this.PurposeValue = this.PurposeValue + this.custPurpose_OthersText.value + ";";
                }
              }
            }
            if(this.PurposeValue==""){
              this.openAll();
              controls["custValid"].setValue("TIDAK VALID");
              return false;
            }
            if(this.custPurpose_OthersText.untouched){
              continue;
            }
          } else {
            this.custPurpose.markAsTouched();
            this.openAll();
            controls["custValid"].setValue("TIDAK VALID");
            return false;
          }
        }

        if (controls[name].invalid) {
            invalid.push(name);
            // Skip Required Value Berdasarkan Tanggal
            if(name == "custvpDate"){
              if(this.custvpText.value!='Berdasarkan Tanggal'){
                continue;
              }
            }
            // Skip Required Value Jenis Pekerjaan Lainnya
            if(name == "custTOWText"){
              if(this.custTOWDD.value!='Lainnya'){
                continue;
              }
            }
            // Skip Required Value Hubungan Lainnya
            // if(name == "custRelationshipText"){
            //   if(this.custRelationshipDD.value!='Lainnya'){
            //     continue;
            //   }
            // }
            controls["custValid"].setValue("TIDAK VALID");
            this.openAll();
            return false;
        }
    }
    this.custSOFValue.setValue(this.SOFValue);
    this.custPurposeValue.setValue(this.PurposeValue);
    return true;
  }

  updateCust(){
    if(this.validateForm()){
      // UPDATE DATA
      this.http.post(this.ApiConfig.API_URL + "/update-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success").then(function() {
            window.location.href = "/admin/cif-individual";
          });
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });    
    }
  }

  updateCustByTemp(){
    // UPDATE DATA
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.custForm.enable();
        this.http.post(this.ApiConfig.API_URL + "/copy-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            this.custForm.disable();
            resolve(false);
          }
        }, err => {
          this.custForm.disable();
          resolve(false);
        });    
      },50);
    })
  }

  changeSOF(event:any){
    this.custSOF.markAsTouched();
    if(event.path[0].checked && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-sourceoffunds-other-text").css("display", "block");
    }
    if(!(event.path[0].checked) && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-sourceoffunds-other-text").css("display", "none");
      this.custSourceOfFunds_OthersText.markAsUntouched();
    }

  }

  changePurpose(event:any){
    this.custPurpose.markAsTouched();
    if(event.path[0].checked && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-purpose-others-text").css("display", "block");
    }
    if(!(event.path[0].checked) && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-purpose-others-text").css("display", "none");
      this.custPurpose_OthersText.markAsUntouched();
    }

  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  countApproval(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        let arrTemp = [this.custID.value, this.custSubmissionStatus.value];
        this.http.get(this.ApiConfig.API_URL + "/count-approval-cif-individual/" + arrTemp, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.countRow = parseInt(this.responseApi.data[0].countrow);
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      },100);
    });

  }

  approveCust(){
    this.countApproval().then(res => this.validateLogApprove());
  }

  rejectCust(){
    Swal.fire({
      text: 'Masukkan alasan pendingan di-reject',
      icon: 'info',
      input: 'text',
      padding: '2rem',
      inputPlaceholder : 'Masukkan alasan anda',
      showCancelButton: true,
      confirmButtonText: 'Setuju',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        this.custNote.setValue(result.value);
        this.countApproval().then(res => this.validateLogReject());
      }
    });
    
  }

  validateLogReject(){
    let strStatus:any; 
    let strPengajuan:any;

    if(this.countRow<=1){
      if(this.custSubmissionStatus.value!="PENDING_EDIT"){
        if(this.custSubmissionStatus.value=="PENDING_ADD"){
          strStatus = "NON_ACTIVE";
          strPengajuan = "REJECT_ADD";
        } else {
          strStatus = "ACTIVE";
          strPengajuan = "REJECT_DELETE";
        }

        // Ambil sequence terakhir dari temp > Insert Temp sebagai history > Update CIF > Add Log > Update All Status LOG
        this.getLastSequenceCIF(this.custCIF.value).then(res => this.callInsertTempCIF(strStatus, strPengajuan).then(res => this.callUpdtCust(strStatus, strPengajuan).then(res => this.rejectLog().then(res => this.updateAllLog()))));
      } else {
        strStatus = "ACTIVE";
        strPengajuan = "REJECT_EDIT";
        // Ambil sequence terakhir dari temp > Insert Temp sebagai history > Update CIF > Add Log > Update All Status LOG
        this.getLastSequenceCIF(this.custCIF.value).then(res => this.callInsertTempCIF(strStatus, strPengajuan).then(res => this.callUpdtCust(strStatus, strPengajuan).then(res => this.rejectLog().then(res => this.updateAllLog()))));
      }
    } else {
      Swal.fire("Information", "Data tidak perlu direject lagi", "info")
    }
  }

  validateLogApprove(){
    let strStatus:any; 
    let strPengajuan:any;

    if(this.countRow==0){
      this.approveLog().then(res=>{
        Swal.fire("Success", "Pendingan Berhasil Diapprove.", "success").then(function() {
          window.location.href = "/admin/pending-cif-individual";
        });
      })
    } else if(this.countRow==1){
      if(this.custSubmissionStatus.value!="PENDING_EDIT"){
        if(this.custSubmissionStatus.value=="PENDING_ADD"){
          strStatus = "ACTIVE";
          strPengajuan = "APPROVED_ADD";
        } else {
          strStatus = "NON_ACTIVE";
          strPengajuan = "APPROVED_DELETE";
        }

        // Ambil sequence terakhir dari temp > Insert Temp sebagai history > Update CIF > Add Log > Update All Status LOG
        this.getLastSequenceCIF(this.custCIF.value).then(res => this.callInsertTempCIF(strStatus, strPengajuan).then(res => this.callUpdtCust(strStatus, strPengajuan).then(res => this.approveLog().then(res => this.updateAllLog()))));
      } else {
        strStatus = "ACTIVE";
        strPengajuan = "APPROVED_EDIT";
        // Ambil sequence terakhir dari temp > Update Cust CIF > Insert Temp sebagai history > Add Log > Update All Status LOG
        this.getLastSequenceCIF(this.custCIF.value).then(res => this.updateCustByTemp().then(res => this.callInsertTempCIF(strStatus, strPengajuan).then(res => this.approveLog().then(res => this.updateAllLog()))));
      }
    } else {
      Swal.fire("Information", "Data tidak perlu diapprove lagi", "info")
    }
  }

  approveLog(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.custAction.setValue("approve");
        this.http.post(this.ApiConfig.API_URL + "/insert-log-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          resolve(false);
        });
      }, 100);
    })
  }

  updateAllLog(){
    this.http.delete(this.ApiConfig.API_URL + "/update-all-log-status-cif/" + this.custID.value, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        Swal.fire("Success", "Transaksi Berhasil Dilakukan.", "success").then(function() {
          window.location.href = "/admin/pending-cif-individual";
        });
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });  
  }

  rejectLog(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.custAction.setValue("reject");
        this.http.post(this.ApiConfig.API_URL + "/insert-log-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          resolve(false);
        });
      }, 100);
    })
  }

  getLastSequenceCIF(cif:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/last-sequence-cif-individual/" + cif, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.sequence = parseInt(this.responseApi.data[0].sequence) + 1;
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 100);
    });
  }

  getLogCIF(id:any){
    this.http.get(this.ApiConfig.API_URL + "/log-cif-individual/" + id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          if(this.responseApi.data.length>0){
            $("#approved-by").html(this.responseApi.data[0].id_user);
            $("#approved-date").html(this.responseApi.data[0].cd);
          }else{
            $("#approved-by").html("Belum ada");
          }
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  callInsertTempCIF(strStatus, strPengajuan){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.custTemp.setValue([this.custCIF.value, this.sequence, strStatus, strPengajuan, "0"]);
        this.http.post(this.ApiConfig.API_URL + "/copy-temp-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            Swal.fire("Ooops", "There's something wrong happened", "error");
            resolve(false);
          }
        }, err => {
          Swal.fire("Ooops", "There's something wrong happened", "error");
          resolve(false);
        });
      }, 100);
    });
  }

  callUpdtCust(strStatus, StrPengajuan){
    // UPDATE DATA
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        let arrTemp = [this.custID.value, strStatus, StrPengajuan];
        this.http.post(this.ApiConfig.API_URL + "/update-pending-cif-individual/", arrTemp, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          resolve(false);
        });  
      }, 100)
    })
  }

  get custFullName(){return this.custForm.get("custFullName")}
  get custPlaceOfBirth(){return this.custForm.get("custPlaceOfBirth")}
  get custCountryOfBirth(){return this.custForm.get("custCountryOfBirth")}
  get custDateOfBirth(){return this.custForm.get("custDateOfBirth")}
  get custSex(){return this.custForm.get("custSex")}
  get custMothersName(){return this.custForm.get("custMothersName")}
  get custMaritalStatus(){return this.custForm.get("custMaritalStatus")}
  get custNationality(){return this.custForm.get("custNationality")}
  get custIdentityType(){return this.custForm.get("custIdentityType")}
  get custIdentityNumber(){return this.custForm.get("custIdentityNumber")}
  get custvpText(){return this.custForm.get("custvpText")}
  get custvpDate(){return this.custForm.get("custvpDate")}
  get custCountryOfIssue(){return this.custForm.get("custCountryOfIssue")}
  get custIdentityAddress(){return this.custForm.get("custIdentityAddress")}
  get custIdentityCountry(){return this.custForm.get("custIdentityCountry")}
  get custDomicileAddress(){return this.custForm.get("custDomicileAddress")}
  get custDomicileCountry(){return this.custForm.get("custDomicileCountry")}
  get custStatusOfResidence(){return this.custForm.get("custStatusOfResidence")}
  get custLongTimeOccupy(){return this.custForm.get("custLongTimeOccupy")}
  get custCellphone(){return this.custForm.get("custCellphone")}
  get custTOWText(){return this.custForm.get("custTOWText")}
  get custTOWDD(){return this.custForm.get("custTOWDD")}
  get custSourceOfFunds_income(){return this.custForm.get("custSourceOfFunds_income")}
  get custSourceOfFunds_Investment(){return this.custForm.get("custSourceOfFunds_Investment")}
  get custSourceOfFunds_Others(){return this.custForm.get("custSourceOfFunds_Others")}
  get custSourceOfFunds(){return this.custForm.get("custSourceOfFunds")}
  get custPurpose_Investment(){return this.custForm.get("custPurpose_Investment")}
  get custPurpose_Study(){return this.custForm.get("custPurpose_Study")}
  get custPurpose_Capital(){return this.custForm.get("custPurpose_Capital")}
  get custMonthlyIncome(){return this.custForm.get("custMonthlyIncome")}
  get custMonthlyAverageTransaction(){return this.custForm.get("custMonthlyAverageTransaction")}
  get custGuardianFullName(){return this.custForm.get("custGuardianFullName")}
  get custGuardianAddress(){return this.custForm.get("custGuardianAddress")}
  get custGuardianCountry(){return this.custForm.get("custGuardianCountry")}
  get custGuardianCellphone(){return this.custForm.get("custGuardianCellphone")}
  get custRelationshipDD(){return this.custForm.get("custRelationshipDD")}
  get custRelationshipText(){return this.custForm.get("custRelationshipText")}
  get custSourceOfFunds_Income(){return this.custForm.get("custSourceOfFunds_Income")}
  get custSourceOfFunds_Legacy(){return this.custForm.get("custSourceOfFunds_Legacy")}
  get custSourceOfFunds_Grant(){return this.custForm.get("custSourceOfFunds_Grant")}
  get custSourceOfFunds_Lottery(){return this.custForm.get("custSourceOfFunds_Lottery")}
  get custIdentityPostalCode(){return this.custForm.get("custIdentityPostalCode")}
  get custSourceOfFunds_Couple(){return this.custForm.get("custSourceOfFunds_Couple")}
  get custSourceOfFunds_Deposit(){return this.custForm.get("custSourceOfFunds_Deposit")}
  get custSourceOfFunds_Parent(){return this.custForm.get("custSourceOfFunds_Parent")}
  get custSourceOfFunds_OthersText(){return this.custForm.get("custSourceOfFunds_OthersText")}
  get custPurpose_Commerce(){return this.custForm.get("custPurpose_Commerce")}
  get custPurpose_Salary(){return this.custForm.get("custPurpose_Salary")}
  get custPurpose_Routine(){return this.custForm.get("custPurpose_Routine")}
  get custPurpose_Others(){return this.custForm.get("custPurpose_Others")}
  get custPurpose_OthersText(){return this.custForm.get("custPurpose_OthersText")}
  get custDomicileProvince(){return this.custForm.get("custDomicileProvince")}
  get custDomicileCity(){return this.custForm.get("custDomicileCity")}
  get custDomicileDistricts(){return this.custForm.get("custDomicileDistricts")}
  get custDomicileWard(){return this.custForm.get("custDomicileWard")}
  get custDomicilePostalCode(){return this.custForm.get("custDomicilePostalCode")}
  get custIdentityProvince(){return this.custForm.get("custIdentityProvince")}
  get custIdentityCity(){return this.custForm.get("custIdentityCity")}
  get custIdentityDistricts(){return this.custForm.get("custIdentityDistricts")}
  get custIdentityWard(){return this.custForm.get("custIdentityWard")}
  get custEmail(){return this.custForm.get("custEmail")}
  get custGuardianProvince(){return this.custForm.get("custGuardianProvince")}
  get custGuardianCity(){return this.custForm.get("custGuardianCity")}
  get custGuardianDistricts(){return this.custForm.get("custGuardianDistricts")}
  get custGuardianWard(){return this.custForm.get("custGuardianWard")}
  get custGuardianPostalCode(){return this.custForm.get("custGuardianPostalCode")}
  get custValid(){return this.custForm.get("custValid")}
  get custCompanyCountry(){return this.custForm.get("custCompanyCountry")}
  get custSOF(){return this.custForm.get("custSOF")}
  get custPurpose(){return this.custForm.get("custPurpose")}
  get custCompanyCity(){return this.custForm.get("custCompanyCity")}
  get custCompanyDistricts(){return this.custForm.get("custCompanyDistricts")}
  get custCompanyWard(){return this.custForm.get("custCompanyWard")}
  get custCompanyProvince(){return this.custForm.get("custCompanyProvince")}
  get custSOFValue(){return this.custForm.get("custSOFValue")}
  get custPurposeValue(){return this.custForm.get("custPurposeValue")}
  get custCIF(){return this.custForm.get("custCIF")}
  get custSubmissionStatus(){return this.custForm.get("custSubmissionStatus")}
  get custStatusData(){return this.custForm.get("custStatusData")}
  get custID(){return this.custForm.get("custID")}
  get custAction(){return this.custForm.get("custAction")}
  get custNote(){return this.custForm.get("custNote")}
  get custTemp(){return this.custForm.get("custTemp")}
  get createdBy(){return this.custForm.get("createdBy")}

  get custFullName_Old(){return this.custForm.get("custFullName_Old")}
  get custPlaceOfBirth_Old(){return this.custForm.get("custPlaceOfBirth_Old")}
  get custCountryOfBirth_Old(){return this.custForm.get("custCountryOfBirth_Old")}
  get custDateOfBirth_Old(){return this.custForm.get("custDateOfBirth_Old")}
  get custReligion_Old(){return this.custForm.get("custReligion_Old")}
  get custSex_Old(){return this.custForm.get("custSex_Old")}
  get custMothersName_Old(){return this.custForm.get("custMothersName_Old")}
  get custMaritalStatus_Old(){return this.custForm.get("custMaritalStatus_Old")}
  get custNationality_Old(){return this.custForm.get("custNationality_Old")}
  get custIdentityType_Old(){return this.custForm.get("custIdentityType_Old")}
  get custIdentityNumber_Old(){return this.custForm.get("custIdentityNumber_Old")}
  get custvpText_Old(){return this.custForm.get("custvpText_Old")}
  get custvpDate_Old(){return this.custForm.get("custvpDate_Old")}
  get custCountryOfIssue_Old(){return this.custForm.get("custCountryOfIssue_Old")}
  get custIdentityAddress_Old(){return this.custForm.get("custIdentityAddress_Old")}
  get custIdentityCountry_Old(){return this.custForm.get("custIdentityCountry_Old")}
  get custDomicileAddress_Old(){return this.custForm.get("custDomicileAddress_Old")}
  get custDomicileCountry_Old(){return this.custForm.get("custDomicileCountry_Old")}
  get custStatusOfResidence_Old(){return this.custForm.get("custStatusOfResidence_Old")}
  get custLongTimeOccupy_Old(){return this.custForm.get("custLongTimeOccupy_Old")}
  get custCellphone_Old(){return this.custForm.get("custCellphone_Old")}
  get custTOWText_Old(){return this.custForm.get("custTOWText_Old")}
  get custTOWDD_Old(){return this.custForm.get("custTOWDD_Old")}
  get custSourceOfFunds_income_Old(){return this.custForm.get("custSourceOfFunds_income_Old")}
  get custSourceOfFunds_Investment_Old(){return this.custForm.get("custSourceOfFunds_Investment_Old")}
  get custSourceOfFunds_Others_Old(){return this.custForm.get("custSourceOfFunds_Others_Old")}
  get custSourceOfFunds_Old(){return this.custForm.get("custSourceOfFunds_Old")}
  get custPurpose_Investment_Old(){return this.custForm.get("custPurpose_Investment_Old")}
  get custPurpose_Study_Old(){return this.custForm.get("custPurpose_Study_Old")}
  get custPurpose_Capital_Old(){return this.custForm.get("custPurpose_Capital_Old")}
  get custMonthlyIncome_Old(){return this.custForm.get("custMonthlyIncome_Old")}
  get custMonthlyAverageTransaction_Old(){return this.custForm.get("custMonthlyAverageTransaction_Old")}
  get custGuardianFullName_Old(){return this.custForm.get("custGuardianFullName_Old")}
  get custGuardianAddress_Old(){return this.custForm.get("custGuardianAddress_Old")}
  get custGuardianCountry_Old(){return this.custForm.get("custGuardianCountry_Old")}
  get custGuardianCellphone_Old(){return this.custForm.get("custGuardianCellphone_Old")}
  get custRelationshipDD_Old(){return this.custForm.get("custRelationshipDD_Old")}
  get custRelationshipText_Old(){return this.custForm.get("custRelationshipText_Old")}
  get custSourceOfFunds_Income_Old(){return this.custForm.get("custSourceOfFunds_Income_Old")}
  get custSourceOfFunds_Legacy_Old(){return this.custForm.get("custSourceOfFunds_Legacy_Old")}
  get custSourceOfFunds_Grant_Old(){return this.custForm.get("custSourceOfFunds_Grant_Old")}
  get custSourceOfFunds_Lottery_Old(){return this.custForm.get("custSourceOfFunds_Lottery_Old")}
  get custIdentityPostalCode_Old(){return this.custForm.get("custIdentityPostalCode_Old")}
  get custSourceOfFunds_Couple_Old(){return this.custForm.get("custSourceOfFunds_Couple_Old")}
  get custSourceOfFunds_Deposit_Old(){return this.custForm.get("custSourceOfFunds_Deposit_Old")}
  get custSourceOfFunds_Parent_Old(){return this.custForm.get("custSourceOfFunds_Parent_Old")}
  get custSourceOfFunds_OthersText_Old(){return this.custForm.get("custSourceOfFunds_OthersText_Old")}
  get custPurpose_Commerce_Old(){return this.custForm.get("custPurpose_Commerce_Old")}
  get custPurpose_Salary_Old(){return this.custForm.get("custPurpose_Salary_Old")}
  get custPurpose_Routine_Old(){return this.custForm.get("custPurpose_Routine_Old")}
  get custPurpose_Others_Old(){return this.custForm.get("custPurpose_Others_Old")}
  get custPurpose_OthersText_Old(){return this.custForm.get("custPurpose_OthersText_Old")}
  get custDomicileProvince_Old(){return this.custForm.get("custDomicileProvince_Old")}
  get custDomicileCity_Old(){return this.custForm.get("custDomicileCity_Old")}
  get custDomicileDistricts_Old(){return this.custForm.get("custDomicileDistricts_Old")}
  get custDomicileWard_Old(){return this.custForm.get("custDomicileWard_Old")}
  get custDomicilePostalCode_Old(){return this.custForm.get("custDomicilePostalCode_Old")}
  get custIdentityProvince_Old(){return this.custForm.get("custIdentityProvince_Old")}
  get custIdentityCity_Old(){return this.custForm.get("custIdentityCity_Old")}
  get custIdentityDistricts_Old(){return this.custForm.get("custIdentityDistricts_Old")}
  get custIdentityWard_Old(){return this.custForm.get("custIdentityWard_Old")}
  get custEmail_Old(){return this.custForm.get("custEmail_Old")}
  get custGuardianProvince_Old(){return this.custForm.get("custGuardianProvince_Old")}
  get custGuardianCity_Old(){return this.custForm.get("custGuardianCity_Old")}
  get custGuardianDistricts_Old(){return this.custForm.get("custGuardianDistricts_Old")}
  get custGuardianWard_Old(){return this.custForm.get("custGuardianWard_Old")}
  get custGuardianPostalCode_Old(){return this.custForm.get("custGuardianPostalCode_Old")}
  get custValid_Old(){return this.custForm.get("custValid_Old")}
  get custCompanyCountry_Old(){return this.custForm.get("custCompanyCountry_Old")}
  get custSOF_Old(){return this.custForm.get("custSOF_Old")}
  get custPurpose_Old(){return this.custForm.get("custPurpose_Old")}
  get custCompanyCity_Old(){return this.custForm.get("custCompanyCity_Old")}
  get custCompanyDistricts_Old(){return this.custForm.get("custCompanyDistricts_Old")}
  get custCompanyWard_Old(){return this.custForm.get("custCompanyWard_Old")}
  get custCompanyProvince_Old(){return this.custForm.get("custCompanyProvince_Old")}
  get custSOFValue_Old(){return this.custForm.get("custSOFValue_Old")}
  get custPurposeValue_Old(){return this.custForm.get("custPurposeValue_Old")}
  get custCIF_Old(){return this.custForm.get("custCIF_Old")}
  get custSubmissionStatus_Old(){return this.custForm.get("custSubmissionStatus_Old")}
  get custStatusData_Old(){return this.custForm.get("custStatusData_Old")}
  get custID_Old(){return this.custForm.get("custID_Old")}
  get custAction_Old(){return this.custForm.get("custAction_Old")}
  get custNote_Old(){return this.custForm.get("custNote_Old")}
  get custTemp_Old(){return this.custForm.get("custTemp_Old")}
  get createdBy_Old(){return this.custForm.get("createdBy_Old")}
  get custCreateDate_Old(){return this.custForm.get("custCreateDate_Old")}
  get custPrefixName_Old(){return this.custForm.get("custPrefixName_Old")}
  get custSuffixName_Old(){return this.custForm.get("custSuffixName_Old")}
  get custLastEducation_Old(){return this.custForm.get("custLastEducation_Old")}
  get custHousePhone_Old(){return this.custForm.get("custHousePhone_Old")}
  get custCompanyPhone_Old(){return this.custForm.get("custCompanyPhone_Old")}
  get custFax_Old(){return this.custForm.get("custFax_Old")}
  get custBusinessField_Old(){return this.custForm.get("custBusinessField_Old")}
  get custPosition_Old(){return this.custForm.get("custPosition_Old")}
  get custCompanyName_Old(){return this.custForm.get("custCompanyName_Old")}
  get custCompanyAddress_Old(){return this.custForm.get("custCompanyAddress_Old")}
  get custGuardianHousePhone_Old(){return this.custForm.get("custGuardianHousePhone_Old")}
  get custNickname_Old(){return this.custForm.get("custNickname_Old")}
  //New
  get custNumberOfDependents(){return this.custForm.get("custNumberOfDependents")}
  get custCoupleAddress(){return this.custForm.get("custCoupleAddress")}
  get custCoupleCountry(){return this.custForm.get("custCoupleCountry")}
  get custCoupleProvince(){return this.custForm.get("custCoupleProvince")}
  get custCoupleCity(){return this.custForm.get("custCoupleCity")}
  get custCoupleDistricts(){return this.custForm.get("custCoupleDistricts")}
  get custCoupleWard(){return this.custForm.get("custCoupleWard")}
  get custCouplePostalCode(){return this.custForm.get("custCouplePostalCode")}
  get custCoupleName(){return this.custForm.get("custCoupleName")}
  get custCouplePhone(){return this.custForm.get("custCouplePhone")}
  get custLongWorked(){return this.custForm.get("custLongWorked")}
  get custProductSpecification(){return this.custForm.get("custProductSpecification")}
  get custSystemSelling(){return this.custForm.get("custSystemSelling")}
  get custSystemSellingOthers(){return this.custForm.get("custSystemSellingOthers")}
  get custWorkingOwner(){return this.custForm.get("custWorkingOwner")}
  get custWorkingLandOwner(){return this.custForm.get("custWorkingLandOwner")}
  get custWorkingLandOwnerOthers(){return this.custForm.get("custWorkingLandOwnerOthers")}
  get custGrossIncome(){return this.custForm.get("custGrossIncome")}
  get custNetIncome(){return this.custForm.get("custNetIncome")}

  get custNumberOfDependents_Old(){return this.custForm.get("custNumberOfDependents_Old")}
  get custCoupleAddress_Old(){return this.custForm.get("custCoupleAddress_Old")}
  get custCoupleCountry_Old(){return this.custForm.get("custCoupleCountry_Old")}
  get custCoupleProvince_Old(){return this.custForm.get("custCoupleProvince_Old")}
  get custCoupleCity_Old(){return this.custForm.get("custCoupleCity_Old")}
  get custCoupleDistricts_Old(){return this.custForm.get("custCoupleDistricts_Old")}
  get custCoupleWard_Old(){return this.custForm.get("custCoupleWard_Old")}
  get custCouplePostalCode_Old(){return this.custForm.get("custCouplePostalCode_Old")}
  get custCoupleName_Old(){return this.custForm.get("custCoupleName_Old")}
  get custCouplePhone_Old(){return this.custForm.get("custCouplePhone_Old")}
  get custLongWorked_Old(){return this.custForm.get("custLongWorked_Old")}
  get custProductSpecification_Old(){return this.custForm.get("custProductSpecification_Old")}
  get custSystemSelling_Old(){return this.custForm.get("custSystemSelling_Old")}
  get custSystemSellingOthers_Old(){return this.custForm.get("custSystemSellingOthers_Old")}
  get custWorkingOwner_Old(){return this.custForm.get("custWorkingOwner_Old")}
  get custWorkingLandOwner_Old(){return this.custForm.get("custWorkingLandOwner_Old")}
  get custWorkingLandOwnerOthers_Old(){return this.custForm.get("custWorkingLandOwnerOthers_Old")}
  get custGrossIncome_Old(){return this.custForm.get("custGrossIncome_Old")}
  get custNetIncome_Old(){return this.custForm.get("custNetIncome_Old")}
  //End
}
