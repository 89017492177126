import { Component, OnInit, ViewChild } from '@angular/core';
import {MatSelectModule} from '@angular/material/select';
declare function getAVGTransaction(): any;

// BUAT FORM
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import { style } from '@angular/animations';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatAccordion} from '@angular/material/expansion';
import { Location } from '@angular/common';
// import { count } from 'console';


@Component({
  selector: 'app-edit-cif-individual',
  templateUrl: './edit-cif-individual.component.html',
  styleUrls: ['./edit-cif-individual.component.sass']
})
export class EditCifIndividualComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private route: ActivatedRoute, 
    private ApiConfig: ApiConfig,
    private location: Location
  ) { }

  custForm: FormGroup = new FormGroup({
    custPrefixName: new FormControl('', [Validators.maxLength(10)]),
    custSuffixName: new FormControl('', [Validators.maxLength(10)]),
    custFullName: new FormControl('', [Validators.required]),
    custPlaceOfBirth: new FormControl('', [Validators.required]),
    custCountryOfBirth: new FormControl('', [Validators.required]),
    custDateOfBirth: new FormControl('',Validators.required),
    custReligion: new FormControl(),
    custMothersName: new FormControl('', [Validators.required]),
    custSex: new FormControl('', [Validators.required]),
    custMaritalStatus: new FormControl('', [Validators.required]),

    //New
    custCoupleName: new FormControl(''),
    custCoupleAddress: new FormControl(''),
    custCoupleCountry: new FormControl(''),
    custCoupleProvince: new FormControl(''),
    custCoupleCity: new FormControl(''),
    custCoupleDistricts: new FormControl(''),
    custCoupleWard: new FormControl(''),
    custCouplePostalCode: new FormControl(''),
    custCouplePhone: new FormControl(''),
    custNumberOfDependents: new FormControl('', [Validators.required]),
    custLongWorked: new FormControl(''),
    custProductSpecification: new FormControl(''),
    custSystemSelling: new FormControl(''),
    custSystemSellingOthers: new FormControl(''),
    custWorkingOwner: new FormControl(''),
    custWorkingLandOwner: new FormControl(''),
    custWorkingLandOwnerOthers: new FormControl(''),
    custGrossIncome: new FormControl(''),
    custNetIncome: new FormControl(''),
    //End

    custLastEducation: new FormControl(),
    custNationality: new FormControl('', [Validators.required]),
    custIdentityType: new FormControl('', [Validators.required]),
    custIdentityNumber: new FormControl('', [Validators.required]),
    custvpText: new FormControl('', [Validators.required]),
    custvpDate: new FormControl('', [Validators.required]),
    custCountryOfIssue: new FormControl('', [Validators.required]),
    custIdentityAddress: new FormControl('', [Validators.required]),
    custIdentityCountry: new FormControl('', [Validators.required]),
    custIdentityProvince: new FormControl('', [Validators.required]),
    custIdentityCity: new FormControl('', [Validators.required]),
    custIdentityDistricts: new FormControl('', [Validators.required]),
    custIdentityWard: new FormControl('', [Validators.required]),
    custIdentityPostalCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custDomicileAddress: new FormControl('', [Validators.required]),
    custDomicileCountry: new FormControl('', [Validators.required]),
    custDomicileProvince: new FormControl('', [Validators.required]),
    custDomicileCity: new FormControl('', [Validators.required]),
    custDomicileDistricts: new FormControl('', [Validators.required]),
    custDomicileWard: new FormControl('', [Validators.required]),
    custDomicilePostalCode: new FormControl('', [Validators.required, Validators.maxLength(5)]),
    custStatusOfResidence: new FormControl('', [Validators.required]),
    custLongTimeOccupy: new FormControl('', [Validators.required, Validators.maxLength(2)]),
    custHousePhone: new FormControl('', [Validators.maxLength(15)]),
    custCompanyPhone: new FormControl('', [Validators.maxLength(15)]),
    custCellphone: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    custFax: new FormControl('', [Validators.maxLength(15)]),
    custEmail: new FormControl('', [Validators.email]),
    custTOWDD: new FormControl('', [Validators.required]),
    custTOWText: new FormControl('', [Validators.required]),
    custBusinessField: new FormControl(),
    custPosition: new FormControl(),
    custCompanyName: new FormControl(),
    custCompanyAddress: new FormControl(),
    custCompanyCountry: new FormControl(''),
    custCompanyProvince: new FormControl(''),
    custCompanyCity: new FormControl(''),
    custCompanyDistricts: new FormControl(''),
    custCompanyWard: new FormControl(''),
    custMonthlyIncome: new FormControl('', [Validators.required]),
    custMonthlyAverageTransaction: new FormControl('', [Validators.required]),
    custGuardianFullName: new FormControl(''),
    custGuardianAddress: new FormControl(''),
    custGuardianCountry: new FormControl(''),
    custGuardianProvince: new FormControl(''),
    custGuardianCity: new FormControl(''),
    custGuardianDistricts: new FormControl(''),
    custGuardianWard: new FormControl(''),
    custGuardianPostalCode: new FormControl('', [Validators.maxLength(5)]),
    custGuardianHousePhone: new FormControl('', [Validators.maxLength(15)]),
    custGuardianCellphone: new FormControl('', [Validators.maxLength(15)]),
    custRelationshipDD: new FormControl(''),
    custRelationshipText: new FormControl(''),
    custSourceOfFunds_OthersText: new FormControl('', [Validators.required]),
    custPurpose_OthersText: new FormControl('', [Validators.required]),
    custNickname: new FormControl(),
    custValid: new FormControl(''),
    custSOF: new FormArray([], [Validators.required]),
    custPurpose: new FormArray([], [Validators.required]),
    custSOFValue: new FormControl(''),
    custPurposeValue: new FormControl(''),
    custCIF: new FormControl(''),
    custID: new FormControl(''),
    custTemp: new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()
    ]),
    createdBy: new FormControl(),
    custSubmissionStatus: new FormControl(),

  });

  dtAVGTransaction: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  tokenRaja : any;
  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  tempProvinceDomicile : any; tempProvinceCompany : any; tempProvinceGuardian : any;
  tempCityDomicile : any; tempCityCompany : any; tempCityGuardian : any;
  tempDistrictDomicile : any; tempDistrictCompany : any; tempDistrictGuardian : any;
  tempSubdistrictDomicile : any; tempSubdistrictCompany : any; tempSubdistrictGuardian : any;

  //New
  tempCityCouple:any; tempDistrictCouple:any; tempSubdistrictCouple:any; 
  //End

  SOFValue = ""; PurposeValue = "";
  tempCIF:any; newCIF:any; sequence:any;
  arrSOF = [
    {text: "Penghasilan"},
    {text: "Warisan"},
    {text: "Hibah"},
    {text: "Undian"},
    {text: "Dari Suami/Istri"},
    {text: "Bunga Simpanan"},
    {text: "Dari Orang Tua"},  
    {text: "Lain-lain"}
  ];
  arrPurpose = [
    {text: "Investasi"},
    {text: "Pendidikan"},
    {text: "Niaga"},
    {text: "Modal"},
    {text: "Gaji"},
    {text: "Biaya Rutin"},  
    {text: "Lain-lain"}
  ];
  dtCustData:any;
  
  arrChanged=[];

  ngOnInit(): void {

    // INITIAL ARRAY
    
    this.arrChanged["custPrefixName"] = {text: "Gelar Sebelum Nama"};
    this.arrChanged["custSuffixName"] = {text: "Gelar Setelah Nama"};
    this.arrChanged["custFullName"] = {text: "Nama Lengkap"};
    this.arrChanged["custPlaceOfBirth"] = {text: "Tempat Lahir"};
    this.arrChanged["custCountryOfBirth"] = {text: "Negara Tempat Lahir"};
    this.arrChanged["custDateOfBirth"] = {text: "Tanggal Lahir"};
    this.arrChanged["custReligion"] = {text: "Agama"};
    this.arrChanged["custMothersName"] = {text: "Nama Ibu Kandung"};
    this.arrChanged["custSex"] = {text: "Jenis Kelamin"};
    this.arrChanged["custMaritalStatus"] = {text: "Status Pernikahan"};

    //New
    this.arrChanged["custCoupleName"] = {text: "Nama Istri/Suami"};
    this.arrChanged["custCoupleAddress"] = {text: "Alamat Istri/Suami"};
    this.arrChanged["custCoupleCountry"] = {text: "Negara Istri/Suami"};
    this.arrChanged["custCoupleProvince"] = {text: "Provinsi Istri/Suami"};
    this.arrChanged["custCoupleCity"] = {text: "Kota Istri/Suami"};
    this.arrChanged["custCoupleDistricts"] = {text: "Kelurahan Istri/Suami"};
    this.arrChanged["custCoupleWard"] = {text: "Kecamatan Istri/Suami"};
    this.arrChanged["custCouplePostalCode"] = {text: "Kode Pos Istri/Suami"};
    this.arrChanged["custCouplePhone"] = {text: "Telepon Istri/Suami"};
    this.arrChanged["custNumberOfDependents"] = {text: "Jumlah Tanggungan"};

    this.arrChanged["custLongWorked"] = {text: "Lama Usaha/Bekerja (Tahun)"};
    this.arrChanged["custProductSpecification"] = {text: "Spesifik Produk"};
    this.arrChanged["custSystemSelling"] = {text: "Sistem Penjualan"};
    this.arrChanged["custSystemSellingOthers"] = {text: "Sistem Penjualan Lainnya"};
    this.arrChanged["custWorkingOwner"] = {text: "Kepemilikan Usaha"};
    this.arrChanged["custWorkingLandOwner"] = {text: "Status Tempat Usaha"};
    this.arrChanged["custWorkingLandOwnerOthers"] = {text: "Status Tempat Usaha Lainnya"};
    this.arrChanged["custGrossIncome"] = {text: "Penghasilan Kotor Per Bulan/Omset Usaha"};
    this.arrChanged["custNetIncome"] = {text: "Penghasilan Bersih Per Bulan Keuntungan Bersih"};
    //End

    this.arrChanged["custLastEducation"] = {text: "Pendidikan Terakhir"};
    this.arrChanged["custNationality"] = {text: "Kewarganegaraan"};
    this.arrChanged["custIdentityType"] = {text: "Jenis Identitas"};
    this.arrChanged["custIdentityNumber"] = {text: "Nomor Identitas"};
    this.arrChanged["custvpText"] = {text: "Masa Berlaku"};
    this.arrChanged["custvpDate"] = {text: "Tanggal Masa Berlaku"};
    this.arrChanged["custCountryOfIssue"] = {text: "Negara Penerbit"};
    this.arrChanged["custIdentityAddress"] = {text: "Alamat Sesuai Identitas"};
    this.arrChanged["custIdentityCountry"] = {text: "Negara Sesuai Identitas"};
    this.arrChanged["custIdentityProvince"] = {text: "Provinsi Sesuai Identitas"};
    this.arrChanged["custIdentityCity"] = {text: "Kota Sesuai Identitas"};
    this.arrChanged["custIdentityDistricts"] = {text: "Kecamatan Sesuai Identitas"};
    this.arrChanged["custIdentityWard"] = {text: "Kelurahan Sesuai Identitas"};
    this.arrChanged["custIdentityPostalCode"] = {text: "Kode Pos Sesuai Identitas"};
    this.arrChanged["custDomicileAddress"] = {text: "Alamat Domisili"};
    this.arrChanged["custDomicileCountry"] = {text: "Negara Domisili"};
    this.arrChanged["custDomicileProvince"] = {text: "Provinsi Domisili"};
    this.arrChanged["custDomicileCity"] = {text: "Kota Domisili"};
    this.arrChanged["custDomicileDistricts"] = {text: "Kecamatan Domisili"};
    this.arrChanged["custDomicileWard"] = {text: "Kelurahan Domisili"};
    this.arrChanged["custDomicilePostalCode"] = {text: "Kode Pos Domisili"};
    this.arrChanged["custStatusOfResidence"] = {text: "Status Tempat Tinggal"};
    this.arrChanged["custLongTimeOccupy"] = {text: "Lama Menempati (tahun)"};
    this.arrChanged["custHousePhone"] = {text: "Telepon Rumah"};
    this.arrChanged["custCompanyPhone"] = {text: "Telepon Kantor"};
    this.arrChanged["custCellphone"] = {text: "Telepon Seluler"};
    this.arrChanged["custFax"] = {text: "Fax"};
    this.arrChanged["custEmail"] = {text: "Email"};
    this.arrChanged["custTOWDD"] = {text: "Jenis Pekerjaan"};
    this.arrChanged["custTOWText"] = {text: "Jenis Pekerjaan Lainnya"};
    this.arrChanged["custBusinessField"] = {text: "Bidang Usaha"};
    this.arrChanged["custPosition"] = {text: "Posisi/Jabatan"};
    this.arrChanged["custCompanyName"] = {text: "Nama Perusahaan"};
    this.arrChanged["custCompanyAddress"] = {text: "Alamat Perusahaan"};
    this.arrChanged["custCompanyCountry"] = {text: "Negara Perusahaan"};
    this.arrChanged["custCompanyProvince"] = {text: "Provinsi Perusahaan"};
    this.arrChanged["custCompanyCity"] = {text: "Kota Perusahaan"};
    this.arrChanged["custCompanyDistricts"] = {text: "Kecamatan Perusahaan"};
    this.arrChanged["custCompanyWard"] = {text: "Kelurahan Perusahaan"};
    this.arrChanged["custMonthlyIncome"] = {text: "Penghasilan Per Bulan"};
    this.arrChanged["custMonthlyAverageTransaction"] = {text: "Rata Rata Transaksi Per Bulan"};
    this.arrChanged["custGuardianFullName"] = {text: "Nama Kontak Darurat"};
    this.arrChanged["custGuardianAddress"] = {text: "Alamat Kontak Darurat"};
    this.arrChanged["custGuardianCountry"] = {text: "Negara Kontak Darurat"};
    this.arrChanged["custGuardianProvince"] = {text: "Provinsi Kontak Darurat"};
    this.arrChanged["custGuardianCity"] = {text: "Kota Kontak Darurat"};
    this.arrChanged["custGuardianDistricts"] = {text: "Kecamatan Kontak Darurat"};
    this.arrChanged["custGuardianWard"] = {text: "Kelurahan Kontak Darurat"};
    this.arrChanged["custGuardianPostalCode"] = {text: "Kode Pos Kontak Darurat"};
    this.arrChanged["custGuardianHousePhone"] = {text: "Nomor Telepon Kontak Darurat"};
    this.arrChanged["custGuardianCellphone"] = {text: "Nomor Seluler Kontak Darurat"};
    this.arrChanged["custRelationshipDD"] = {text: "Hubungan Kontak Darurat"};
    this.arrChanged["custRelationshipText"] = {text: "Hubungan Lainnya Kontak Darurat"};
    this.arrChanged["custSourceOfFunds_OthersText"] = {text: "Sumber Dana Lainnya"};
    this.arrChanged["custPurpose_OthersText"] = {text: "Tujuan Pembukaan Rekening Lainnya"};
    this.arrChanged["custNickname"] = {text: "Nama Singkat"};
    this.arrChanged["custSOFValue"] = {text: "Sumber Dana"};
    this.arrChanged["custPurposeValue"] = {text: "Tujuan Pembukaan Rekening"};



    // GET AVERAGE TRANSACTION
    this.getAVGTransaction();
    

    // Onchange Masa Berlaku
    this.custForm.get("custvpText").valueChanges.subscribe(x => {
      if(x=="Berdasarkan Tanggal"){
        $("#inline-full-validityperiodtext").css("display", "block")
      } else {
        $("#inline-full-validityperiodtext").css("display", "none")
      }
    })

    // Onchange Hubungan Lainnya
    this.custForm.get("custRelationshipDD").valueChanges.subscribe(x => {
      if(x=="Lainnya"){
        $("#inline-full-guardianrelationshiptext").css("display", "block")
      } else {
        $("#inline-full-guardianrelationshiptext").css("display", "none")
      }
    })

    // Onchange Jenis Pekerjaan ke Lainnya
    this.custForm.get("custTOWDD").valueChanges.subscribe(x => {
      if(x=="Lainnya"){
        $("#inline-full-typeofworktext").css("display", "block")
      } else {
        $("#inline-full-typeofworktext").css("display", "none")
      }
    })

    // Onchange Negara Identitas
    this.custForm.get("custIdentityCountry").valueChanges.subscribe(x => {
      $("#inline-full-domicilecountry").val(x);
      this.custDomicileCountry.setValue(x);
      this.custDomicileCountry.markAsTouched();
      this.custDomicileCountry.markAsDirty();
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custIdentityCity.setValue('');
        this.custIdentityDistricts.setValue('');
        this.custIdentityProvince.setValue('');
        this.custIdentityWard.setValue('');
        this.custIdentityPostalCode.setValue('');
        this.custDomicileCity.setValue('');
        this.custDomicileDistricts.setValue('');
        this.custDomicileProvince.setValue('');
        this.custDomicileWard.setValue('');
        this.custDomicilePostalCode.setValue('');

        if(!isNaN(this.arrChanged["custIdentityCountry"].oldData)){
          if(this.arrChanged["custIdentityCountry"].oldData.toUpperCase() == "INDONESIA"){
            this.custIdentityCity.markAsDirty();
            this.custIdentityDistricts.markAsDirty();
            this.custIdentityProvince.markAsDirty();
            this.custIdentityWard.markAsDirty();
            this.custIdentityPostalCode.markAsDirty();
            this.custDomicileCity.markAsDirty();
            this.custDomicileDistricts.markAsDirty();
            this.custDomicileProvince.markAsDirty();
            this.custDomicileWard.markAsDirty();
            this.custDomicilePostalCode.markAsDirty();
          } else {
            this.custIdentityCity.markAsPristine();
            this.custIdentityDistricts.markAsPristine();
            this.custIdentityProvince.markAsPristine();
            this.custIdentityWard.markAsPristine();
            this.custIdentityPostalCode.markAsPristine();
            this.custDomicileCity.markAsPristine();
            this.custDomicileDistricts.markAsPristine();
            this.custDomicileProvince.markAsPristine();
            this.custDomicileWard.markAsPristine();
            this.custDomicilePostalCode.markAsPristine();
            // console.log(this.custIdentityCity);
          }
        }
        
        this.tempCity=[];
        this.tempCityDomicile=[];
        this.tempDistrict=[];
        this.tempDistrictDomicile=[];
        this.tempSubdistrict=[];
        this.tempSubdistrictDomicile=[];
      }
    })

    // Onchange Negara Perusahaan
    this.custForm.get("custCompanyCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custCompanyCity.setValue('');
        this.custCompanyDistricts.setValue('');
        this.custCompanyProvince.setValue('');
        this.custCompanyWard.setValue('');

        if(!isNaN(this.arrChanged["custCompanyCountry"].oldData)){
          if(this.arrChanged["custCompanyCountry"].oldData.toUpperCase() == "INDONESIA"){
            this.custCompanyCity.markAsDirty();
            this.custCompanyDistricts.markAsDirty();
            this.custCompanyProvince.markAsDirty();
            this.custCompanyWard.markAsDirty();
          }
        }     
              
        this.tempCityCompany=[];        
        this.tempDistrictCompany=[];
        this.tempSubdistrictCompany=[];
      }
    })

    // Onchange Negara Kontak Darurat
    this.custForm.get("custGuardianCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custGuardianCity.setValue('');
        this.custGuardianDistricts.setValue('');
        this.custGuardianProvince.setValue('');
        this.custGuardianWard.setValue('');
        this.custGuardianPostalCode.setValue('');

        if(!isNaN(this.arrChanged["custGuardianCountry"].oldData)){
          if(this.arrChanged["custGuardianCountry"].oldData.toUpperCase() == "INDONESIA"){
            this.custGuardianCity.markAsDirty();
            this.custGuardianDistricts.markAsDirty();
            this.custGuardianProvince.markAsDirty();
            this.custGuardianWard.markAsDirty();
            this.custGuardianPostalCode.markAsDirty();
          }
        }        
              
        this.tempCityGuardian=[];        
        this.tempDistrictGuardian=[];
        this.tempSubdistrictGuardian=[];
      }
    })

    // Onchange Negara Domisili
    this.custForm.get("custDomicileCountry").valueChanges.subscribe(x => {
      let countryValue = x.toUpperCase()
      if(countryValue != 'INDONESIA'){
        this.custDomicileCity.setValue('');
        this.custDomicileDistricts.setValue('');
        this.custDomicileProvince.setValue('');
        this.custDomicileWard.setValue('');
        this.custDomicilePostalCode.setValue('');

        if(!isNaN(this.arrChanged["custDomicileCountry"].oldData)){
          if(this.arrChanged["custDomicileCountry"].oldData.toUpperCase() == "INDONESIA"){
            this.custDomicileCity.markAsDirty();
            this.custDomicileDistricts.markAsDirty();
            this.custDomicileProvince.markAsDirty();
            this.custDomicileWard.markAsDirty();
            this.custDomicilePostalCode.markAsDirty();
          }
        }
        
              
        this.tempCityDomicile=[];        
        this.tempDistrictDomicile=[];
        this.tempSubdistrictDomicile=[];
      }
    })

    // Onchange Alamat Identitas
    this.custForm.get("custIdentityAddress").valueChanges.subscribe(x => {
      $("#inline-full-domicileaddress").val(x);
      this.custDomicileAddress.setValue(x);
      this.custDomicileAddress.markAsDirty();
    })

    // Onchange Provinsi Identitas
    this.custForm.get("custIdentityProvince").valueChanges.subscribe(x => {
      // $("#inline-full-domicileprovince").val(x)
      this.custDomicileProvince.setValue(x);

      this.custIdentityCity.setValue('');
      this.custIdentityDistricts.setValue('');
      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileCity.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      if(typeof this.arrChanged["custIdentityCountry"].oldData != "undefined"){
        if(this.arrChanged["custIdentityCountry"].oldData.toUpperCase() == "INDONESIA" || this.custIdentityCountry.value.toUpperCase() == "INDONESIA"){
          this.custDomicileProvince.markAsDirty();
          this.custIdentityCity.markAsDirty();
          this.custIdentityDistricts.markAsDirty();
          this.custIdentityWard.markAsDirty();
          this.custIdentityPostalCode.markAsDirty();
          this.custDomicileCity.markAsDirty();
          this.custDomicileDistricts.markAsDirty();
          this.custDomicileWard.markAsDirty();
          this.custDomicilePostalCode.markAsDirty();
        } else {
          this.custDomicileProvince.markAsPristine();
          this.custIdentityCity.markAsPristine();
          this.custIdentityDistricts.markAsPristine();
          this.custIdentityWard.markAsPristine();
          this.custIdentityPostalCode.markAsPristine();
          this.custDomicileCity.markAsPristine();
          this.custDomicileDistricts.markAsPristine();
          this.custDomicileWard.markAsPristine();
          this.custDomicilePostalCode.markAsPristine();
          this.custIdentityProvince.markAsPristine();
        }
      }
      

      this.tempCity=[];
      this.tempCityDomicile=[];
      this.tempDistrict=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Domisili
    this.custForm.get("custDomicileProvince").valueChanges.subscribe(x => {

      this.custDomicileCity.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      if(typeof this.arrChanged["custDomicileCountry"].oldData != "undefined"){
        if(this.arrChanged["custDomicileCountry"].oldData.toUpperCase() == "INDONESIA" || this.custDomicileCountry.value.toUpperCase() == "INDONESIA"){
          this.custDomicileCity.markAsDirty();
          this.custDomicileDistricts.markAsDirty();
          this.custDomicileWard.markAsDirty();
          this.custDomicilePostalCode.markAsDirty();
          this.custDomicileProvince.markAsDirty();
        }else{
          this.custDomicileCity.markAsPristine();
          this.custDomicileDistricts.markAsPristine();
          this.custDomicileWard.markAsPristine();
          this.custDomicilePostalCode.markAsPristine();
          this.custDomicileProvince.markAsPristine();
        }
      }
      

      this.tempCityDomicile=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Provinsi Kontak Darurat
    this.custForm.get("custGuardianProvince").valueChanges.subscribe(x => {

      this.custGuardianCity.setValue('');
      this.custGuardianDistricts.setValue('');
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      if(typeof this.arrChanged["custGuardianCountry"].oldData != "undefined"){
        if(this.arrChanged["custGuardianCountry"].oldData.toUpperCase() == "INDONESIA" || this.custGuardianCountry.value.toUpperCase() == "INDONESIA"){
          this.custGuardianCity.markAsDirty();
          this.custGuardianDistricts.markAsDirty();
          this.custGuardianWard.markAsDirty();
          this.custGuardianPostalCode.markAsDirty();
        } else {
          this.custGuardianCity.markAsPristine();
          this.custGuardianDistricts.markAsPristine();
          this.custGuardianWard.markAsPristine();
          this.custGuardianPostalCode.markAsPristine();
          this.custGuardianProvince.markAsPristine();
        }
      }
      
      this.tempCityGuardian=[];
      this.tempDistrictGuardian=[];
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Provinsi Perusahaan
    this.custForm.get("custCompanyProvince").valueChanges.subscribe(x => {

      this.custCompanyCity.setValue('');
      this.custCompanyDistricts.setValue('');
      this.custCompanyWard.setValue('');

      if(typeof this.arrChanged["custCompanyCountry"].oldData != "undefined"){
        if(this.arrChanged["custCompanyCountry"].oldData.toUpperCase() == "INDONESIA" || this.custCompanyCountry.value.toUpperCase() == "INDONESIA"){
          this.custCompanyCity.markAsDirty();
          this.custCompanyDistricts.markAsDirty();
          this.custCompanyWard.markAsDirty();
        } else {
          this.custCompanyCity.markAsPristine();
          this.custCompanyDistricts.markAsPristine();
          this.custCompanyWard.markAsPristine();
          this.custCompanyProvince.markAsPristine();
        }
      }

      this.tempCityCompany=[];
      this.tempDistrictCompany=[];
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kota/Kabupaten Identitas
    // this.custForm.get("custIdentityCity").valueChanges.subscribe(x => {
    //   // $("#inline-full-domicilecity").val(x);
    //   this.custDomicileCity.setValue(x);

    //   this.custIdentityDistricts.setValue('');
    //   this.custIdentityWard.setValue('');
    //   this.custIdentityPostalCode.setValue('');
    //   this.custDomicileDistricts.setValue('');
    //   this.custDomicileWard.setValue('');
    //   this.custDomicilePostalCode.setValue('');

    //   this.tempDistrict=[];
    //   this.tempDistrictDomicile=[];
    //   this.tempSubdistrict=[];
    //   this.tempSubdistrictDomicile=[];
    // })

    // Onchange Kota/Kabupaten Domisili
    this.custForm.get("custDomicileCity").valueChanges.subscribe(x => {
      if(this.custDomicileProvince.value!=""){
        this.provinceId=this.custDomicileProvince.value;
        this.getCity("custDomicileProvince");
      }

      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      if(typeof this.arrChanged["custDomicileCountry"].oldData != "undefined"){
        if(this.arrChanged["custDomicileCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custDomicileDistricts.markAsDirty();
          this.custDomicileWard.markAsDirty();
          this.custDomicilePostalCode.markAsDirty();
        }  
      }
          

      this.tempDistrictDomicile=[];
      this.tempSubdistrictDomicile=[];
    })

    // Onchange Kota/Kabupaten Perusahaan
    this.custForm.get("custCompanyCity").valueChanges.subscribe(x => {
      if(this.custCompanyProvince.value!=""){
        this.provinceId=this.custCompanyProvince.value;
        this.getCity("custCompanyProvince");
      }

      this.custCompanyDistricts.setValue('');
      this.custCompanyWard.setValue('');

      if(typeof this.arrChanged["custCompanyCountry"].oldData != "undefined"){
        if(this.arrChanged["custCompanyCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custCompanyDistricts.markAsDirty();
          this.custCompanyWard.markAsDirty();
        }
      }

      this.tempDistrictCompany=[];
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kota/Kabupaten Kontak Darurat
    this.custForm.get("custGuardianCity").valueChanges.subscribe(x => {
      if(this.custGuardianProvince.value!=""){
        this.provinceId=this.custGuardianProvince.value;
        this.getCity("custGuardianProvince");
      }

      this.custGuardianDistricts.setValue('');
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      if(typeof this.arrChanged["custGuardianCountry"].oldData != "undefined"){
        if(this.arrChanged["custGuardianCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custGuardianDistricts.markAsDirty();
          this.custGuardianWard.markAsDirty();
          this.custGuardianPostalCode.markAsDirty();
        }
      }

      this.tempDistrictGuardian=[];
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Kecamatan Identitas
    // this.custForm.get("custIdentityDistricts").valueChanges.subscribe(x => {
    //   // $("#inline-full-domiciledistricts").val(x)
    //   this.custDomicileDistricts.setValue(x)

    //   this.custIdentityWard.setValue('');
    //   this.custIdentityPostalCode.setValue('');
    //   this.custDomicileWard.setValue('');
    //   this.custDomicilePostalCode.setValue('');

    //   this.tempSubdistrict=[];
    //   this.tempSubdistrictDomicile=[];
    // })

    // Onchange Kecamatan Domisili
    this.custForm.get("custDomicileDistricts").valueChanges.subscribe(x => {
      this.tempSubdistrictDomicile=[];
      
      if(this.custDomicileCity.value!="" && this.tempDistrictDomicile.length==0){
        this.cityId = this.custDomicileCity.value;
        this.getDistrict("custDomicileCity");
      }
      
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      if(typeof this.arrChanged["custDomicileCountry"].oldData != "undefined"){
        if(this.arrChanged["custDomicileCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custDomicileWard.markAsDirty();
          this.custDomicilePostalCode.markAsDirty();
        }
      }
      
    })

    // Onchange Kecamatan Perusahaan
    this.custForm.get("custCompanyDistricts").valueChanges.subscribe(x => {      
      this.custCompanyWard.setValue('');
      if(typeof this.arrChanged["custCompanyCountry"].oldData != "undefined"){
        if(this.arrChanged["custCompanyCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custCompanyWard.markAsDirty();
        }
      }
      
      this.tempSubdistrictCompany=[];
    })

    // Onchange Kecamatan Kontak Darurat
    this.custForm.get("custGuardianDistricts").valueChanges.subscribe(x => {     
      this.custGuardianWard.setValue('');
      this.custGuardianPostalCode.setValue('');

      if(typeof this.arrChanged["custGuardianCountry"].oldData != "undefined"){
        if(this.arrChanged["custGuardianCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custGuardianWard.markAsDirty();
          this.custGuardianPostalCode.markAsDirty();
        }
      }
      
      
      this.tempSubdistrictGuardian=[];
    })

    // Onchange Kelurahan Identitas
    // this.custForm.get("custIdentityWard").valueChanges.subscribe(x => {
    //   $("#inline-full-domicileward").val(x)
    //   this.custDomicileWard.setValue(x)

    //   this.custIdentityPostalCode.setValue('');
    //   this.custDomicilePostalCode.setValue('');
    // })

    // Onchange Kelurahan Domisili
    this.custForm.get("custDomicileWard").valueChanges.subscribe(x => {
      if(this.custDomicileDistricts.value!="" && x!=""){
        this.dataDistrict = this.custDomicileDistricts.value;
        this.getSubDistrict("custDomicileDistricts");
      }

      this.custDomicilePostalCode.setValue('');
      if(typeof this.arrChanged["custDomicileCountry"].oldData != "undefined"){
        if(this.arrChanged["custDomicileCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custDomicilePostalCode.markAsDirty();
        }
      }
      
    })

    // Onchange Kelurahan Perusahaan
    this.custForm.get("custCompanyWard").valueChanges.subscribe(x => {

    })

    // Onchange Kelurahan Kontak Darurat
    this.custForm.get("custGuardianWard").valueChanges.subscribe(x => {
      this.custGuardianPostalCode.setValue('');
      if(typeof this.arrChanged["custGuardianCountry"].oldData != "undefined"){
        if(this.arrChanged["custGuardianCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custGuardianPostalCode.markAsDirty();
        }
      }
      
      
    })

    // Onchange Kode Pos Identitas
    this.custForm.get("custIdentityPostalCode").valueChanges.subscribe(x => {
      $("#inline-full-domicilepostalcode").val(x);
      this.custDomicilePostalCode.setValue(x);
      if(typeof this.arrChanged["custIdentityCountry"].oldData != "undefined"){
        if(this.arrChanged["custIdentityCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custDomicilePostalCode.markAsDirty();
        }
      }
      
    })

    // Onchange Kewarganegaraan
    this.custForm.get("custNationality").valueChanges.subscribe(x => {
      $("#inline-full-coi").val(x);
      this.custCountryOfIssue.setValue(x);
      this.custCountryOfIssue.markAsDirty();
    })

    //New
    // Onchange Provinsi Suami/Istri
    this.custForm.get("custCoupleProvince").valueChanges.subscribe(x => {
      // console.log("Masuk");
      this.custCoupleCity.setValue('');
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');

      if(typeof this.arrChanged["custCoupleCountry"].oldData != "undefined"){
        if(this.arrChanged["custCoupleCountry"].oldData.toUpperCase() == "INDONESIA" || this.custCoupleCountry.value.toUpperCase() == "INDONESIA"){
          this.custCoupleCity.markAsDirty();
          this.custCoupleDistricts.markAsDirty();
          this.custCoupleWard.markAsDirty();
          this.custCouplePostalCode.markAsDirty();
        } else {
          this.custCoupleCity.markAsPristine();
          this.custCoupleDistricts.markAsPristine();
          this.custCoupleWard.markAsPristine();
          this.custCouplePostalCode.markAsPristine();
          this.custCoupleProvince.markAsPristine();
        }
      }
      
      this.tempCityCouple=[];
      this.tempDistrictCouple=[];
      this.tempSubdistrictCouple=[];
    });

    // Onchange Kota Suami/Istri
    this.custForm.get("custCoupleCity").valueChanges.subscribe(x => {
      if(this.custCoupleProvince.value!=""){
        this.provinceId=this.custCoupleProvince.value;
        this.getCity("custCoupleProvince");
      }

      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');

      if(typeof this.arrChanged["custCoupleCountry"].oldData != "undefined"){
        if(this.arrChanged["custCoupleCountry"].oldData.toUpperCase() == "INDONESIA"){
          this.custCoupleDistricts.markAsDirty();
          this.custCoupleWard.markAsDirty();
          this.custCouplePostalCode.markAsDirty();
        }
      }

      this.tempDistrictCouple = [];
      this.tempSubdistrictCouple = [];
    });

    // Onchange Kelurahan Suami/Istri
    this.custForm.get("custCoupleDistricts").valueChanges.subscribe(x => {
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
      this.tempSubdistrictCouple = [];
    });

    // Onchange Kecamatan Suami/Istri
    this.custForm.get("custCoupleWard").valueChanges.subscribe(x => {
      this.custCouplePostalCode.setValue('');
    });

    //End

    let cust_id = this.route.snapshot.paramMap.get("id");
    this.getProvince().then(res => this.getCustDataByID(cust_id).then(res => this.getAPIValue().then(res=> {
      Object.keys(this.custForm.controls).forEach((name) => {
        // console.log(name);
        const currentControl = this.custForm.controls[name];
        if(name=="custIdentityProvince"){
          let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
          // console.log("ini idx: ");
          // console.log(strIdx);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custIdentityCity"){
          let strIdx = this.tempCity.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempCity[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custIdentityDistricts"){
          let strIdx = this.tempDistrict.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempDistrict[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custIdentityWard"){
          let strIdx = this.tempSubdistrict.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempSubdistrict[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custDomicileProvince"){
          let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custDomicileCity"){
          let strIdx = this.tempCityDomicile.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempCityDomicile[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custDomicileDistricts"){
          let strIdx = this.tempDistrictDomicile.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempDistrictDomicile[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custDomicileWard"){
          let strIdx = this.tempSubdistrictDomicile.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempSubdistrictDomicile[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCompanyProvince"){
          let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCompanyCity"){
          let strIdx = this.tempCityCompany.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempCityCompany[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCompanyDistricts"){
          let strIdx = this.tempDistrictCompany.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempDistrictCompany[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCompanyWard"){
          let strIdx = this.tempSubdistrictCompany.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempSubdistrictCompany[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custGuardianProvince"){
          let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custGuardianCity"){
          let strIdx = this.tempCityGuardian.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempCityGuardian[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custGuardianDistricts"){
          let strIdx = this.tempDistrictGuardian.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempDistrictGuardian[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custGuardianWard"){
          let strIdx = this.tempSubdistrictGuardian.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempSubdistrictGuardian[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }
        //New
        else if(name == "custCoupleProvince"){
          // console.log(this.tempProvince);
          // console.log(currentControl.value);
          let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCoupleCity"){
          let strIdx = this.tempCityCouple.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempCityCouple[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCoupleDistricts"){
          let strIdx = this.tempDistrictCouple.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempDistrictCouple[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }else if(name == "custCoupleWard"){
          // console.log(this.tempSubdistrictCouple);
          let strIdx = this.tempSubdistrictCouple.findIndex( x => x.id === currentControl.value);
          // console.log(strIdx);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.tempSubdistrictCouple[strIdx].text};
          this.arrChanged[name] = {...A, ...B};
        }
        //End
        else if(name == "custMonthlyIncome" || name == "custMonthlyAverageTransaction"){
          let strIdx = this.dtAVGTransaction.findIndex( x => x.id === currentControl.value);
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value, convertOldData: strIdx<0?currentControl.value:this.dtAVGTransaction[strIdx].question_text};
          this.arrChanged[name] = {...A, ...B};
        }else {
          let A = this.arrChanged[name];
          let B = {nama: name, oldData: currentControl.value};
          this.arrChanged[name] = {...A, ...B};
        }        
      });
      // console.log(this.custCoupleCity.value);
    })));
    // console.log(this.arrChanged);
  }

  //New
  changeMaritalStatus(){
    // console.log("Masuk");
    if(this.custMaritalStatus.value == "Kawin"){
      this.custForm.get('custCoupleAddress').setValidators([Validators.required]);
      this.custForm.get('custCoupleAddress').updateValueAndValidity();
      this.custForm.get('custCoupleCountry').setValidators([Validators.required]);
      this.custForm.get('custCoupleCountry').updateValueAndValidity();
      this.custForm.get('custCoupleName').setValidators([Validators.required]);
      this.custForm.get('custCoupleName').updateValueAndValidity();
      this.custForm.get('custCouplePhone').setValidators([Validators.required]);
      this.custForm.get('custCouplePhone').updateValueAndValidity();

    } else {
      this.custForm.get('custCoupleAddress').clearValidators();
      this.custForm.get('custCoupleAddress').updateValueAndValidity();
      this.custForm.get('custCoupleCountry').clearValidators();
      this.custForm.get('custCoupleCountry').updateValueAndValidity();
      this.custForm.get('custCoupleName').clearValidators();
      this.custForm.get('custCoupleName').updateValueAndValidity();
      this.custForm.get('custCouplePhone').clearValidators();
      this.custForm.get('custCouplePhone').updateValueAndValidity();
      this.custCoupleName.setValue('');
      this.custCoupleAddress.setValue('');
      this.custCoupleCountry.setValue('');
      this.custCoupleProvince.setValue('');
      this.custCoupleCity.setValue('');
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
      this.custCouplePhone.setValue('');
    }
  }

  changeCoupleCountry(){
    // console.log("Masuk kah ?");
    if(this.custCoupleCountry.value.toUpperCase() == "INDONESIA"){
      this.custForm.get('custCoupleProvince').setValidators([Validators.required]);
      this.custForm.get('custCoupleCity').setValidators([Validators.required]);
      this.custForm.get('custCoupleDistricts').setValidators([Validators.required]);
      this.custForm.get('custCoupleWard').setValidators([Validators.required]);
      this.custForm.get('custCouplePostalCode').setValidators([Validators.required]);
      this.custForm.get('custCoupleProvince').updateValueAndValidity();
      this.custForm.get('custCoupleCity').updateValueAndValidity();
      this.custForm.get('custCoupleDistricts').updateValueAndValidity();
      this.custForm.get('custCoupleWard').updateValueAndValidity();
      this.custForm.get('custCouplePostalCode').updateValueAndValidity();
    }else{
      this.custForm.get('custCoupleProvince').clearValidators();
      this.custForm.get('custCoupleCity').clearValidators();
      this.custForm.get('custCoupleDistricts').clearValidators();
      this.custForm.get('custCoupleWard').clearValidators();
      this.custForm.get('custCouplePostalCode').clearValidators();
      this.custForm.get('custCoupleProvince').updateValueAndValidity();
      this.custForm.get('custCoupleCity').updateValueAndValidity();
      this.custForm.get('custCoupleDistricts').updateValueAndValidity();
      this.custForm.get('custCoupleWard').updateValueAndValidity();
      this.custForm.get('custCouplePostalCode').updateValueAndValidity();
      this.custCoupleProvince.setValue('');
      this.custCoupleProvince.markAsDirty();
      this.custCoupleCity.setValue('');
      this.custCoupleDistricts.setValue('');
      this.custCoupleWard.setValue('');
      this.custCouplePostalCode.setValue('');
    }
  }

  changeWorkType(){
    if(this.custTOWDD.value == "Wiraswasta"){
      // this.custForm.get('custLongWorked').setValidators([Validators.required]);
      this.custForm.get('custProductSpecification').setValidators([Validators.required]);
      this.custForm.get('custSystemSelling').setValidators([Validators.required]);
      this.custForm.get('custWorkingOwner').setValidators([Validators.required]);
      this.custForm.get('custWorkingLandOwner').setValidators([Validators.required]);
      this.custForm.get('custNetIncome').setValidators([Validators.required]);
      this.custForm.get('custGrossIncome').setValidators([Validators.required]);
      // this.custForm.get('custLongWorked').updateValueAndValidity();
      this.custForm.get('custProductSpecification').updateValueAndValidity();
      this.custForm.get('custSystemSelling').updateValueAndValidity();
      this.custForm.get('custWorkingOwner').updateValueAndValidity();
      this.custForm.get('custWorkingLandOwner').updateValueAndValidity();
      this.custForm.get('custNetIncome').updateValueAndValidity();
      this.custForm.get('custGrossIncome').updateValueAndValidity();      
    }else{
      // this.custForm.get('custLongWorked').clearValidators();
      this.custForm.get('custProductSpecification').clearValidators();
      this.custForm.get('custSystemSelling').clearValidators();
      this.custForm.get('custWorkingOwner').clearValidators();
      this.custForm.get('custWorkingLandOwner').clearValidators();
      this.custForm.get('custNetIncome').clearValidators();
      this.custForm.get('custGrossIncome').clearValidators();
      // this.custForm.get('custLongWorked').updateValueAndValidity();
      this.custForm.get('custProductSpecification').updateValueAndValidity();
      this.custForm.get('custSystemSelling').updateValueAndValidity();
      this.custForm.get('custWorkingOwner').updateValueAndValidity();
      this.custForm.get('custWorkingLandOwner').updateValueAndValidity();
      this.custForm.get('custNetIncome').updateValueAndValidity();
      this.custForm.get('custGrossIncome').updateValueAndValidity();
      
      // this.custLongWorked.setValue('');
      this.custProductSpecification.setValue('');
      this.custSystemSelling.setValue('');
      this.custSystemSellingOthers.setValue('');
      this.custWorkingOwner.setValue('');
      this.custWorkingLandOwner.setValue('');
      this.custWorkingLandOwnerOthers.setValue('');
      this.custNetIncome.setValue('');
      this.custGrossIncome.setValue('');
      
    }
  }

  changeSystemSelling(){
    if(this.custSystemSelling.value=="Lainnya"){
      this.custForm.get('custSystemSellingOthers').setValidators([Validators.required]);
      this.custForm.get('custSystemSellingOthers').updateValueAndValidity();
    }else{
      this.custForm.get('custSystemSellingOthers').clearValidators();
      this.custForm.get('custSystemSellingOthers').updateValueAndValidity();
      this.custSystemSellingOthers.setValue('');
    }
  }

  changeWorkingLandOwner(){
    if(this.custWorkingLandOwner.value=="Lainnya"){
      this.custForm.get('custWorkingLandOwnerOthers').setValidators([Validators.required]);
      this.custForm.get('custWorkingLandOwnerOthers').updateValueAndValidity();
    }else{
      this.custForm.get('custWorkingLandOwnerOthers').clearValidators();
      this.custForm.get('custWorkingLandOwnerOthers').updateValueAndValidity();
      this.custWorkingLandOwnerOthers.setValue('');
    }
  }
  //End

  getCustDataByID(param:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/add-cif-individual-customer-data/" + param, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.dtCustData = this.responseApi.data;
              if(this.dtCustData.length==0){
                Swal.fire("Ooops", "Data Tidak Ditemukan", "error").then(function() {
                  window.location.href = "/admin/cif-individual";
                });
              }

              this.custSubmissionStatus.setValue(this.dtCustData[0].status_pengajuan);
              if(this.custSubmissionStatus.value == "PENDING_EDIT" || this.custSubmissionStatus.value == "PENDING_DELETE"){
                this.custForm.disable();
                $("#btnUpdate").prop('disabled', true);
                $("#information-label").css("display", "block");
                $("#information-label").html("Data Tidak Dapat Diubah Karena Dalam Status " + this.custSubmissionStatus.value);                
              }
    
              // Inisialisasi Variabel
              let vp:any;               // dd Masa Berlaku
              let dtvp:any;             // text input Masa Berlaku Berdasarkan Tanggal
              let top:any;              // dd Jenis Pekerjaan
              let strtop:any;           // Jenis Pekerjaan Lainnya
              let datatop = "None;Pelajar/Mahasiswa;Pegawai Swasta;Pegawai Negeri;Ibu Rumah Tangga;Pensiunan;TNI/POLRI;Guru/Dosen;Wiraswasta;Profesional;";
              let gr:any;               // rb Hubungan
              let strgr:any;            // Hubungan Lainnya
              let datagr = "Saudara Kandung;Anak;Orang Tua;Suami/Istri";          
    
              if(this.dtCustData[0].validity_period=="Seumur Hidup"){
                vp = "Seumur Hidup";
              } else {
                vp = "Berdasarkan Tanggal";
                dtvp = this.dtCustData[0].validity_period;
              };
    
              if(datatop.indexOf(this.dtCustData[0].type_of_work) >= 0){
                top = this.dtCustData[0].type_of_work;
              } else {
                top = "Lainnya";
                strtop = this.dtCustData[0].type_of_work;
              };
    
              if(datagr.indexOf(this.dtCustData[0].guardian_relationship) >= 0){
                gr = this.dtCustData[0].guardian_relationship;
              } else {
                gr = "Lainnya";
                strgr = this.dtCustData[0].guardian_relationship;
              };
              
              //New
              let tempCoupleCity = parseInt(this.dtCustData[0].couple_city);
              let tempCoupleDistricts = parseInt(this.dtCustData[0].couple_districts);
              let tempCoupleWard = parseInt(this.dtCustData[0].couple_ward);
              let tempCouplePostalCode = parseInt(this.dtCustData[0].couple_postalcode);
              //End

              this.custForm.patchValue({
                custPrefixName:this.dtCustData[0].prefix_name,
                custSuffixName:this.dtCustData[0].suffix_name,
                custFullName:this.dtCustData[0].full_name,
                custNickname:this.dtCustData[0].nickname,
                custPlaceOfBirth:this.dtCustData[0].place_of_birth,
                custCountryOfBirth:this.dtCustData[0].country_of_birth,
                custDateOfBirth: this.dtCustData[0].dob,
                custReligion:this.dtCustData[0].religion,
                custSex:this.dtCustData[0].sex,
                custMothersName:this.dtCustData[0].mothers_name,
                custMaritalStatus:this.dtCustData[0].marital_status,

                //New
                custCoupleName:this.dtCustData[0].couple_name,
                custCoupleAddress:this.dtCustData[0].couple_address,
                custCoupleCountry:this.dtCustData[0].couple_country,
                custCoupleProvince:this.dtCustData[0].couple_province,
                custCoupleCity:(this.dtCustData[0].couple_city),
                custCoupleDistricts:(this.dtCustData[0].couple_districts),
                custCoupleWard:(this.dtCustData[0].couple_ward),
                custCouplePostalCode:this.dtCustData[0].couple_postalcode,
                custCouplePhone:this.dtCustData[0].couple_phone,
                custNumberOfDependents:this.dtCustData[0].number_of_dependents,
                custLongWorked:this.dtCustData[0].company_longworked,
                custProductSpecification:this.dtCustData[0].entre_product_specification,
                custWorkingOwner:this.dtCustData[0].entre_business_ownership,
                custGrossIncome:this.dtCustData[0].gross_income,
                custNetIncome:this.dtCustData[0].net_income,
                //End

                custLastEducation:this.dtCustData[0].last_education,
                custNationality:this.dtCustData[0].nationality,
                custIdentityType:this.dtCustData[0].identity_type,
                custIdentityNumber:this.dtCustData[0].identity_number,
    
                // validity_period
                custvpText:vp,
                custvpDate:dtvp,
    
                custCountryOfIssue:this.dtCustData[0].country_of_issue,
                custIdentityAddress:this.dtCustData[0].identity_address,
                custIdentityCountry:this.dtCustData[0].identity_country,
                custIdentityProvince: (this.dtCustData[0].identity_province),
                custIdentityCity:(this.dtCustData[0].identity_city),
                custIdentityDistricts:(this.dtCustData[0].identity_districts),
                custIdentityWard:(this.dtCustData[0].identity_ward),
                custIdentityPostalCode:this.dtCustData[0].identity_postcode,
                custDomicileAddress:this.dtCustData[0].domicile_address,
                custDomicileCountry:this.dtCustData[0].domicile_country,
                custDomicileProvince:(this.dtCustData[0].domicile_province),
                custDomicileCity:(this.dtCustData[0].domicile_city),
                custDomicileDistricts:(this.dtCustData[0].domicile_districts),
                custDomicileWard:(this.dtCustData[0].domicile_ward),
                custDomicilePostalCode:this.dtCustData[0].domicile_postcode,
                custStatusOfResidence:this.dtCustData[0].status_of_residence,
                custLongTimeOccupy:this.dtCustData[0].long_time_occupy,
                custHousePhone:this.dtCustData[0].house_phone,
                custCompanyPhone:this.dtCustData[0].company_phone,
                custFax:this.dtCustData[0].fax,
                custEmail:this.dtCustData[0].email,
                custCellphone:this.dtCustData[0].cellphone,
    
                // type_of_work
                custTOWText:strtop,
                custTOWDD:top,
    
                custBusinessField:this.dtCustData[0].business_fields,
                custPosition:this.dtCustData[0].position,
                custCompanyName:this.dtCustData[0].company_name,
                custCompanyAddress:this.dtCustData[0].company_address,
                custCompanyCountry:this.dtCustData[0].company_country,
                custCompanyProvince:(this.dtCustData[0].company_province),
                custCompanyCity:(this.dtCustData[0].company_city),
                custCompanyDistricts:(this.dtCustData[0].company_districts),
                custCompanyWard:(this.dtCustData[0].company_ward),
    
                custMonthlyIncome:this.dtCustData[0].monthly_income,
                custMonthlyAverageTransaction:this.dtCustData[0].monthly_average_transactions,
                custGuardianFullName:this.dtCustData[0].guardian_full_name,
                custGuardianAddress:this.dtCustData[0].guardian_address,
                custGuardianCountry:this.dtCustData[0].guardian_country,
                custGuardianProvince:(this.dtCustData[0].guardian_province),
                custGuardianCity:(this.dtCustData[0].guardian_city),
                custGuardianDistricts:(this.dtCustData[0].guardian_districts),
                custGuardianWard:(this.dtCustData[0].guardian_ward),
                custGuardianPostalCode:this.dtCustData[0].guardian_postcode,
                custGuardianCellphone:this.dtCustData[0].guardian_cellphone,
                custGuardianHousePhone:this.dtCustData[0].guardian_house_phone,
    
                // guardian_relationship
                custRelationshipDD:gr,
                custRelationshipText:strgr,
    
                custCIF:this.dtCustData[0].cif_id,
                custID:this.dtCustData[0].id,
                custSOFValue:this.dtCustData[0].source_of_funds,
                custPurposeValue:this.dtCustData[0].purpose_of_account_opening,
                custSubmissionStatus:this.dtCustData[0].status_pengajuan,
                createdBy : this.ApiConfig.USER_DATA.username
              })

              //New
              if(this.custMaritalStatus.value == "Kawin"){
                this.custForm.get('custCoupleAddress').setValidators([Validators.required]);
                this.custForm.get('custCoupleAddress').updateValueAndValidity();
                this.custForm.get('custCoupleCountry').setValidators([Validators.required]);
                this.custForm.get('custCoupleCountry').updateValueAndValidity();
                this.custForm.get('custCoupleName').setValidators([Validators.required]);
                this.custForm.get('custCoupleName').updateValueAndValidity();
                this.custForm.get('custCouplePhone').setValidators([Validators.required]);
                this.custForm.get('custCouplePhone').updateValueAndValidity();

                if(this.custCoupleCountry.value.toUpperCase() == "INDONESIA"){
                  this.custForm.get('custCoupleProvince').setValidators([Validators.required]);
                  this.custForm.get('custCoupleCity').setValidators([Validators.required]);
                  this.custForm.get('custCoupleDistricts').setValidators([Validators.required]);
                  this.custForm.get('custCoupleWard').setValidators([Validators.required]);
                  this.custForm.get('custCouplePostalCode').setValidators([Validators.required]);
                  this.custForm.get('custCoupleProvince').updateValueAndValidity();
                  this.custForm.get('custCoupleCity').updateValueAndValidity();
                  this.custForm.get('custCoupleDistricts').updateValueAndValidity();
                  this.custForm.get('custCoupleWard').updateValueAndValidity();
                  this.custForm.get('custCouplePostalCode').updateValueAndValidity();
                }
              }

              this.custCoupleCity.setValue(tempCoupleCity);
              this.custCoupleDistricts.setValue(tempCoupleDistricts);
              this.custCoupleWard.setValue(tempCoupleWard);
              this.custCouplePostalCode.setValue(tempCouplePostalCode);

              let datass = "Konsinyasi/Bagi Hasil;Kredit dan Tunai;Kredit;Tunai;"
              let datawo = "Sewa/Kontrak;Milik Sendiri;"   

              if(datass.indexOf(this.dtCustData[0].entre_sales_system) >= 0){
                this.custSystemSelling.setValue(this.dtCustData[0].entre_sales_system);
              }else{
                this.custSystemSellingOthers.setValue(this.dtCustData[0].entre_sales_system);
                this.custSystemSelling.setValue("Lainnya");
              }

              if(datawo.indexOf(this.dtCustData[0].entre_land_status) >= 0){
                this.custWorkingLandOwner.setValue(this.dtCustData[0].entre_land_status);
              }else{
                this.custWorkingLandOwnerOthers.setValue(this.dtCustData[0].entre_land_status);
                this.custWorkingLandOwner.setValue("Lainnya");
              }
              //End

              this.addCheckboxes_SOF();
              this.addCheckboxes_Purpose();
              // this.getAPIValue();
              // if(this.custSubmissionStatus.value == "PENDING_EDIT" || this.custSubmissionStatus.value == "PENDING_DELETE"){
              //   // this.custForm.disable();
              //   $("#btnUpdate").prop('disabled', true);
              //   $("#information-label").css("display", "block");
              //   $("#information-label").html("Data Tidak Dapat Diubah Karena Dalam Status " + this.custSubmissionStatus.value);                
              // }
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 500);
    });
  }

  check(){
    // console.log(this.custIdentityProvince.value);
    // console.log(this.custIdentityCity.value);
    // console.log(this.custIdentityDistricts.value);
    // console.log(this.custIdentityWard.value);
    // console.log(this.custDomicileProvince.value);
    // console.log(this.custDomicileCity.value);
    // console.log(this.custDomicileDistricts.value);
    // console.log(this.custDomicileWard.value);
    // console.log(this.custCompanyProvince.value);
    // console.log(this.custCompanyCity.value);
    // console.log(this.custCompanyDistricts.value);
    // console.log(this.custCompanyWard.value);
    // console.log(this.custGuardianProvince.value);
    // console.log(this.custGuardianCity.value);
    // console.log(this.custGuardianDistricts.value);
    // console.log(this.custGuardianWard.value);
    // console.log(this.custForm.controls);
    // this.arrChanged["custGuardianFullName"] = {oldData: "hehe"};
    // Object.keys(this.custForm.controls).forEach((name) => {
    //   const currentControl = this.custForm.controls[name];
    //   if (currentControl.dirty) {
    //     let A = this.arrChanged[name];
    //     let B = {nama: name, value: this.custForm.controls[name].value};
    //     this.arrChanged[name] = {...A, ...B};
    //     console.log(this.arrChanged);
    //   }
    // });
    console.log(this.custCoupleCity.value);
  }

  getAPIValue(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        // console.log(this.custCoupleCity.value);
        this.loadCity("custIdentityProvince", this.custIdentityProvince.value).then(res=> this.loadDistrict("custIdentityCity", this.custIdentityCity.value).then(res=> this.loadSubDistrict("custIdentityDistricts", this.custIdentityDistricts.value).then(res => this.loadCity("custDomicileProvince", this.custDomicileProvince.value).then(res => this.loadDistrict("custDomicileCity", this.custDomicileCity.value).then(res=>this.loadSubDistrict("custDomicileDistricts", this.custDomicileDistricts.value).then(res=>this.loadCity("custCompanyProvince", this.custCompanyProvince.value).then(res=>this.loadDistrict("custCompanyCity", this.custCompanyCity.value).then(res=>this.loadSubDistrict("custCompanyDistricts", this.custCompanyDistricts.value).then(res=>this.loadCity("custGuardianProvince", this.custGuardianProvince.value).then(res=>this.loadDistrict("custGuardianCity", this.custGuardianCity.value).then(res=>this.loadSubDistrict("custGuardianDistricts", this.custGuardianDistricts.value).then(res=>this.loadCity("custCoupleProvince", this.custCoupleProvince.value).then(res=>this.loadDistrict("custCoupleCity", this.custCoupleCity.value).then(res=>this.loadSubDistrict("custCoupleDistricts", this.custCoupleDistricts.value).then(res=>{
          resolve(true);
        })))))))))))))));
        
      },200);
    });
    
    // Promise.all([
    //   this.loadCity("custIdentityProvince", this.custIdentityProvince.value),
    //   this.loadDistrict("custIdentityCity", this.custIdentityCity.value),
    //   this.loadSubDistrict("custIdentityDistricts", this.custIdentityDistricts.value),
    //   this.loadCity("custDomicileProvince", this.custDomicileProvince.value),
    //   this.loadDistrict("custDomicileCity", this.custDomicileCity.value),
    //   this.loadSubDistrict("custDomicileDistricts", this.custDomicileDistricts.value),
    //   this.loadCity("custCompanyProvince", this.custCompanyProvince.value),
    //   this.loadDistrict("custCompanyCity", this.custCompanyCity.value),
    //   this.loadSubDistrict("custCompanyDistricts", this.custCompanyDistricts.value),
    //   this.loadCity("custGuardianProvince", this.custGuardianProvince.value),
    //   this.loadDistrict("custGuardianCity", this.custGuardianCity.value),
    //   this.loadSubDistrict("custGuardianDistricts", this.custGuardianDistricts.value)
    // ]).then(res=> {
    // });
  }

  get SOFFormArray() {
    return this.custForm.controls.custSOF as FormArray;
  }

  private addCheckboxes_SOF() {
    let arrSOFValue = this.custSOFValue.value.split(";");
    let boolGetData
    for (let i = 0; i < this.arrSOF.length; i++){
      boolGetData = false;
      for (let j = 0; j < arrSOFValue.length-1; j++){
        if(this.arrSOF[i].text == arrSOFValue[j]){
          this.SOFFormArray.push(new FormControl(true));
          boolGetData = true;
          if(arrSOFValue[j] == "Lain-lain"){
            this.custSourceOfFunds_OthersText.setValue(arrSOFValue[j+1]);
            $("#inline-full-sourceoffunds-other-text").css("display", "block");
            return;
          }
          break;
        }
      }
      if(!boolGetData){
        this.SOFFormArray.push(new FormControl(false));
      }
    }
  }

  get PurposeFormArray() {
    return this.custForm.controls.custPurpose as FormArray;
  }

  private addCheckboxes_Purpose() {
    // this.arrPurpose.forEach(() => this.PurposeFormArray.push(new FormControl(false)));
    let arrPurposeValue = this.custPurposeValue.value.split(";");
    let boolGetData
    for (let i = 0; i < this.arrPurpose.length; i++){
      boolGetData = false;
      for (let j = 0; j < arrPurposeValue.length-1; j++){
        if(this.arrPurpose[i].text == arrPurposeValue[j]){
          this.PurposeFormArray.push(new FormControl(true));
          boolGetData = true;
          if(arrPurposeValue[j] == "Lain-lain"){
            this.custPurpose_OthersText.setValue(arrPurposeValue[j+1]);
            $("#inline-full-purpose-others-text").css("display", "block");
            return;
          }
          break;
        }
      }
      if(!boolGetData){
        this.PurposeFormArray.push(new FormControl(false));
      }
    }
  }

  getTokenRaja(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.tokenRaja = this.responseApi.token;
              this.getProvince();
              resolve(true);
            }      
          },
          err => {
            console.log(err);
            resolve(false);
          }
        );
      },50);
    });
    
  }

  getProvince(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.dataProvince = this.responseApi.data;
              this.tempProvince = this.dataProvince.map(province => ({
                "id": province.code,
                "text": province.name,
                "additional": province
              }));
              // console.log(this.tempProvince);
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        );
      },50);
    });
    
  }

  onProvinceChange(event: any){
    this.provinceId = this.custForm.controls[event.source.ngControl.name].value;
    this.getCity(event.source.ngControl.name);
  }

  getCity(type:any){       
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;

          if(type == "custIdentityProvince"){
            this.tempCity = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custDomicileProvince"){
            this.tempCityDomicile = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custCompanyProvince"){
            this.tempCityCompany = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          if(type == "custGuardianProvince"){
            this.tempCityGuardian = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          //New
          if(type == "custCoupleProvince"){
            this.tempCityCouple = this.dataCity.map(city => ({
              "id": city.code,
              "text": city.name,
              "additional": city
            }));
          }
          //End
          
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  loadCity(type:any, id:any){       
    return new Promise((resolve) => {
      setTimeout(()=>{
        if (id == ""){
          resolve(true);
        } else {
          this.http.get(this.ApiConfig.API_URL + "/get-city/" + id, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataCity = this.responseApi.data;
  
                if(type == "custIdentityProvince"){
                  this.tempCity = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custDomicileProvince"){
                  this.tempCityDomicile = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custCompanyProvince"){
                  this.tempCityCompany = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "custGuardianProvince"){
                  this.tempCityGuardian = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                //New
                if(type == "custCoupleProvince"){
                  this.tempCityCouple = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                //End
                
              }
              resolve(true);
            },
            err => {
              console.log(err);
            }
          );
        }
        
      },50);
    })
  }

  onCityChange(event: any){
    this.cityId = this.custForm.controls[event.source.ngControl.name].value;
    if(event.source.ngControl.name == "custIdentityCity"){
      this.custDomicileCity.setValue(this.cityId);

      this.custIdentityDistricts.setValue('');
      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileDistricts.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');

      this.tempDistrict=[];
      this.tempDistrictDomicile=[];
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    }
    this.getDistrict(event.source.ngControl.name);
  }

  loadDistrict(type:any, id:any){
    return new Promise((resolve) => {
      setTimeout(()=>{
        if (id == ""){
          resolve(true);
        } else {
          this.http.get(this.ApiConfig.API_URL + "/get-districts/" + id, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataDistrict = this.responseApi.data;
  
                if(type == "custIdentityCity"){
                  this.tempDistrict = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custDomicileCity"){
                  this.tempDistrictDomicile = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custCompanyCity"){
                  this.tempDistrictCompany = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "custGuardianCity"){
                  this.tempDistrictGuardian = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                //New
                if(type == "custCoupleCity"){
                  this.tempDistrictCouple = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                //End
                resolve(true);
              }
              resolve(true);
            },
            err => {
              console.log(err);
            }
          );
        }
        
      },10);
    })
  }

  getDistrict(type:any){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;

          if(type == "custIdentityCity"){
            this.tempDistrict = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custDomicileCity"){
            this.tempDistrictDomicile = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custCompanyCity"){
            this.tempDistrictCompany = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          if(type == "custGuardianCity"){
            this.tempDistrictGuardian = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          //New
          if(type == "custCoupleCity"){
            this.tempDistrictCouple = this.dataDistrict.map(district => ({
              "id": district.code,
              "text": district.name,
              "additional": district
            }));
          }
          //End
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.districtId = this.custForm.controls[event.source.ngControl.name].value;

    if(event.source.ngControl.name == "custIdentityDistricts"){
      this.custDomicileDistricts.setValue(this.districtId);

      this.custIdentityWard.setValue('');
      this.custIdentityPostalCode.setValue('');
      this.custDomicileWard.setValue('');
      this.custDomicilePostalCode.setValue('');
  
      this.tempSubdistrict=[];
      this.tempSubdistrictDomicile=[];
    }
    
    this.getSubDistrict(event.source.ngControl.name);
  }

  loadSubDistrict(type:any, id:any){
    return new Promise((resolve) => {
      setTimeout(()=>{
        if (id == ""){
          resolve(true);
        } else {
          this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + id, this.httpOptions).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataSubdistrict = this.responseApi.data;
                if(type == "custIdentityDistricts"){
                  this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custDomicileDistricts"){
                  this.tempSubdistrictDomicile = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custCompanyDistricts"){
                  this.tempSubdistrictCompany = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                if(type == "custGuardianDistricts"){
                  this.tempSubdistrictGuardian = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                //New
                if(type == "custCoupleDistricts"){
                  this.tempSubdistrictCouple = this.dataSubdistrict.map(subdistrict => ({
                    "id": subdistrict.code,
                    "text": subdistrict.name,
                    "additional": subdistrict
                  }));
                }
                //End
                resolve(true);
              }     
              resolve(true); 
            },
            err => {
              console.log(err);
            }
          );
        }
      
      },50);
    })
  }

  getSubDistrict(type:any){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          if(type == "custIdentityDistricts"){
            this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custDomicileDistricts"){
            this.tempSubdistrictDomicile = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custCompanyDistricts"){
            this.tempSubdistrictCompany = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          if(type == "custGuardianDistricts"){
            this.tempSubdistrictGuardian = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          //New
          if(type == "custCoupleDistricts"){
            this.tempSubdistrictCouple = this.dataSubdistrict.map(subdistrict => ({
              "id": subdistrict.code,
              "text": subdistrict.name,
              "additional": subdistrict
            }));
          }
          //End
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  onSubdistrictChange(event: any){
    this.custDomicileWard.setValue(this.custForm.controls[event.source.ngControl.name].value)
    this.custIdentityPostalCode.setValue('');
    this.custDomicilePostalCode.setValue('');
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  getAVGTransaction(){
    this.http.get(this.ApiConfig.API_URL + "/add-cif-individual-average-transaction", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dtAVGTransaction = this.responseApi.data;
        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  validateForm() {
    this.SOFValue = "";
    this.PurposeValue = "";
    this.custForm.controls["custValid"].setValue("");
    let skipval_id = ["custIdentityProvince", "custIdentityCity", "custIdentityDistricts", "custIdentityWard", "custIdentityPostalCode"];
    let skipval_domicile = ["custDomicileProvince", "custDomicileCity", "custDomicileDistricts", "custDomicileWard", "custDomicilePostalCode"];
    let skipval_guardian = ["custGuardianProvince", "custGuardianCity", "custGuardianDistricts", "custGuardianWard", "custGuardianPostalCode"];
    const invalid = [];
    const controls = this.custForm.controls;

    for (const name in controls) {
        if (skipval_id.find(function(element){return element == name;}) == name && controls["custIdentityCountry"].value.toUpperCase() != "INDONESIA"){
          continue;
        }
        if (skipval_domicile.find(function(element){return element == name;}) == name && controls["custDomicileCountry"].value.toUpperCase() != "INDONESIA"){
          continue;
        }
        // if (skipval_guardian.find(function(element){return element == name;}) == name && controls["custGuardianCountry"].value.toUpperCase() != "INDONESIA"){
        //   continue;
        // }
        if (name == "custSourceOfFunds_OthersText"){
          if(this.custSOF.value.includes(true)){
            // Sumber Dana Value
            this.custSourceOfFunds_OthersText.markAsUntouched();
            // this.custSourceOfFunds_OthersText.setErrors(null);
            const selectedSOF = this.custForm.value.custSOF
              .map((checked, i) => checked ? this.arrSOF[i].text : null)
              .filter(v => v !== null);
            for(let x = 0; x < selectedSOF.length; x++){
              this.SOFValue = this.SOFValue + selectedSOF[x] + ";";
              if(selectedSOF[x] == "Lain-lain"){
                this.custSourceOfFunds_OthersText.markAsTouched();
                if(this.custSourceOfFunds_OthersText.value==""){
                  this.openAll();
                  controls["custValid"].setValue("TIDAK VALID");
                  return false;
                } else {
                  this.SOFValue = this.SOFValue + this.custSourceOfFunds_OthersText.value + ";";
                }
              }
            }
            if(this.SOFValue==""){
              this.openAll();
              controls["custValid"].setValue("TIDAK VALID");
              return false;
            }
            if(this.custSourceOfFunds_OthersText.untouched){
              continue;
            }
          } else{
            this.custSOF.markAsTouched();
            this.openAll();
            controls["custValid"].setValue("TIDAK VALID");
            return false;
          }
        }
        if (name == "custPurpose_OthersText"){
          if(this.custPurpose.value.includes(true)){
            // Tujuan Pembukaan Rekening Value
            this.custPurpose_OthersText.markAsUntouched();
            const selectedPurpose = this.custForm.value.custPurpose
              .map((checked, i) => checked ? this.arrPurpose[i].text : null)
              .filter(v => v !== null);
            for(let x = 0; x < selectedPurpose.length; x++){
              this.PurposeValue = this.PurposeValue + selectedPurpose[x] + ";";
              if(selectedPurpose[x]== "Lain-lain"){
                this.custPurpose_OthersText.markAsTouched();
                if(this.custPurpose_OthersText.value==""){
                  this.openAll();
                  controls["custValid"].setValue("TIDAK VALID");
                  return false;
                } else {
                  this.PurposeValue = this.PurposeValue + this.custPurpose_OthersText.value + ";";
                }
              }
            }
            if(this.PurposeValue==""){
              this.openAll();
              controls["custValid"].setValue("TIDAK VALID");
              return false;
            }
            if(this.custPurpose_OthersText.untouched){
              continue;
            }
          } else {
            this.custPurpose.markAsTouched();
            this.openAll();
            controls["custValid"].setValue("TIDAK VALID");
            return false;
          }
        }

        if (controls[name].invalid) {
            invalid.push(name);
            // Skip Required Value Berdasarkan Tanggal
            if(name == "custvpDate"){
              if(this.custvpText.value!='Berdasarkan Tanggal'){
                continue;
              }
            }
            // Skip Required Value Jenis Pekerjaan Lainnya
            if(name == "custTOWText"){
              if(this.custTOWDD.value!='Lainnya'){
                continue;
              }
            }
            // Skip Required Value Hubungan Lainnya
            // if(name == "custRelationshipText"){
            //   if(this.custRelationshipDD.value!='Lainnya'){
            //     continue;
            //   }
            // }
            controls["custValid"].setValue("TIDAK VALID");
            this.openAll();
            return false;
        }
    }
    this.custSOFValue.setValue(this.SOFValue);
    this.custPurposeValue.setValue(this.PurposeValue);
    return true;
  }

  updateCust(){
    if(this.validateForm()){
      let strHTML = "<style>td, th {border: 1px solid #dddddd;text-align: left;padding: 8px;} tr:nth-child(even) {background-color: #dddddd;}</style>";
      strHTML += "<table style='font-size: small;border-collapse: collapse;width: 100%;'><tr><th>Nama Field</th><th>Data Lama</th><th>Data Baru</th></tr>";
      strHTML+="<tbody style = 'overflow:scroll;'>";
      let strTableContent = "";
      // SET OLD VALUE INTO ARRAY
      // console.log(this.arrChanged);
      // console.log(isNaN(this.custFullName.value));
      Object.keys(this.custForm.controls).forEach((name) => {
        const currentControl = this.custForm.controls[name];
        if(currentControl.dirty || name == "custSOFValue" || name == "custPurposeValue"){
          // console.log(name);
          if(currentControl.value!=this.arrChanged[name].oldData && this.arrChanged[name].text != "" && this.arrChanged[name].text != null){
            if(((name == "custIdentityProvince" || name == "custIdentityCity" || name == "custIdentityDistricts" || name == "custIdentityWard" || name=="custDomicileProvince" || name == "custDomicileCity" || name == "custDomicileDistricts" || name == "custDomicileWard" || name=="custCompanyProvince" || name == "custCompanyCity" || name == "custCompanyDistricts" || name == "custCompanyWard" || name=="custGuardianProvince" || name == "custGuardianCity" || name == "custGuardianDistricts" || name == "custGuardianWard" || name=="custCoupleProvince" || name == "custCoupleCity" || name == "custCoupleDistricts" || name == "custCoupleWard") && ((!isNaN(currentControl.value) && isNaN(this.arrChanged[name].oldData)) || (isNaN(currentControl.value) && !isNaN(this.arrChanged[name].oldData) || (!isNaN(currentControl.value) && !isNaN(this.arrChanged[name].oldData)))))){
              if(name=="custIdentityProvince"){
                let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custIdentityCity"){
                let strIdx = this.tempCity.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempCity[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custIdentityDistricts"){
                let strIdx = this.tempDistrict.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempDistrict[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custIdentityWard"){
                let strIdx = this.tempSubdistrict.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempSubdistrict[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name=="custDomicileProvince"){
                let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custDomicileCity"){
                let strIdx = this.tempCityDomicile.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempCityDomicile[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custDomicileDistricts"){
                let strIdx = this.tempDistrictDomicile.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempDistrictDomicile[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custDomicileWard"){
                let strIdx = this.tempSubdistrictDomicile.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempSubdistrictDomicile[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name=="custCompanyProvince"){
                let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custCompanyCity"){
                let strIdx = this.tempCityCompany.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempCityCompany[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custCompanyDistricts"){
                let strIdx = this.tempDistrictCompany.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempDistrictCompany[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custCompanyWard"){
                let strIdx = this.tempSubdistrictCompany.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempSubdistrictCompany[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name=="custGuardianProvince"){
                let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custGuardianCity"){
                let strIdx = this.tempCityGuardian.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempCityGuardian[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custGuardianDistricts"){
                let strIdx = this.tempDistrictGuardian.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempDistrictGuardian[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custGuardianWard"){
                let strIdx = this.tempSubdistrictGuardian.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempSubdistrictGuardian[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }
              //New
              else if(name=="custCoupleProvince"){
                let strIdx = this.tempProvince.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempProvince[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custCoupleCity"){
                let strIdx = this.tempCityCouple.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempCityCouple[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custCoupleDistricts"){
                let strIdx = this.tempDistrictCouple.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempDistrictCouple[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else if(name == "custCoupleWard"){
                let strIdx = this.tempSubdistrictCouple.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.tempSubdistrictCouple[strIdx].text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }
              //End
              else if(name == "custMonthlyIncome" || name == "custMonthlyAverageTransaction"){
                let strIdx = this.dtAVGTransaction.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.dtAVGTransaction[strIdx].question_text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else {
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].oldData +"</td><td>"+ this.arrChanged[name].newData +"</td></tr>"
              }
            }

            if(((name != "custIdentityProvince" && name != "custIdentityCity" && name != "custIdentityDistricts" && name != "custIdentityWard" && name!="custDomicileProvince" && name != "custDomicileCity" && name != "custDomicileDistricts" && name != "custDomicileWard" && name!="custCompanyProvince" && name != "custCompanyCity" && name != "custCompanyDistricts" && name != "custCompanyWard" && name!="custGuardianProvince" && name != "custGuardianCity" && name != "custGuardianDistricts" && name != "custGuardianWard" && name!="custCoupleProvince" && name != "custCoupleCity" && name != "custCoupleDistricts" && name != "custCoupleWard"))){
              if(name == "custMonthlyIncome" || name == "custMonthlyAverageTransaction"){
                let strIdx = this.dtAVGTransaction.findIndex( x => x.id === currentControl.value);
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value, convertNewData: strIdx<0?currentControl.value:this.dtAVGTransaction[strIdx].question_text};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].convertOldData +"</td><td>"+ this.arrChanged[name].convertNewData +"</td></tr>"
              }else {
                let A = this.arrChanged[name];
                let B = {newData: currentControl.value};
                this.arrChanged[name] = {...A, ...B};
                strTableContent+="<tr><td>"+ this.arrChanged[name].text +"</td><td>"+ this.arrChanged[name].oldData +"</td><td>"+ this.arrChanged[name].newData +"</td></tr>"
              }
            } 
          }
        }
      });
      // console.log(this.custDomicileProvince);
      if(strTableContent!=""){
        strHTML+=strTableContent+"</tbody></table>";
      }else{
        strHTML= "<h1>Tidak Ada Perubahan Data</h1>";
      }
      Swal.fire(
        {
          title: 'Preview Data Yang Diubah',
          icon: 'info',
          html: strHTML,
          showCancelButton: true,
          confirmButtonText: 'Ubah',
          cancelButtonText: 'Batal'
        }).then((result) => {
          if (result.value) {
            this.getLastSequenceCIF(this.custCIF.value).then(res => this.callInsertTempCIF().then(res => this.callUpdtCust()));
          }
        }
      )      
    }
  }

  callUpdtCust(){
    // UPDATE DATA
    let arrTemp = [this.custID.value, "ACTIVE", "PENDING_EDIT"];
    this.http.post(this.ApiConfig.API_URL + "/update-pending-cif-individual/", arrTemp, this.httpOptions).subscribe(res => {
      this.responseApi = res;
      if (this.responseApi.status == "200") {
        Swal.fire("Success", "Your input has been saved.", "success").then(function() {
          window.location.href = "/admin/cif-individual";
        });
      } else {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      }
    }, err => {
      Swal.fire("Ooops", "There's something wrong happened", "error");
    });  
  }

  changeSOF(event:any){
    this.custSOF.markAsTouched();
    if(event.path[0].checked && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-sourceoffunds-other-text").css("display", "block");
    }
    if(!(event.path[0].checked) && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-sourceoffunds-other-text").css("display", "none");
      this.custSourceOfFunds_OthersText.markAsUntouched();
    }

  }

  changePurpose(event:any){
    this.custPurpose.markAsTouched();
    if(event.path[0].checked && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-purpose-others-text").css("display", "block");
    }
    if(!(event.path[0].checked) && event.path[1].innerText.trim() == 'Lain-lain'){
      $("#inline-full-purpose-others-text").css("display", "none");
      this.custPurpose_OthersText.markAsUntouched();
    }

  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  getLastSequenceCIF(cif:any){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.http.get(this.ApiConfig.API_URL + "/last-sequence-cif-individual/" + cif, this.httpOptions).subscribe(
          res => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              this.sequence = parseInt(this.responseApi.data[0].sequence) + 1;
              resolve(true);
            }
          }, 
          err => {
            console.log(err);
          }
        )
      }, 500);
    });
  }

  callInsertTempCIF(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
        this.custTemp.setValue([this.custCIF.value, this.sequence, "ACTIVE", "PENDING_EDIT", "1"]);
        this.http.post(this.ApiConfig.API_URL + "/insert-temp-cif-individual/", this.custForm.value, this.httpOptions).subscribe(res => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            resolve(true);
          } else {
            Swal.fire("Ooops", "There's something wrong happened", "error");
            resolve(false);
          }
        }, err => {
          Swal.fire("Ooops", "There's something wrong happened", "error");
          resolve(false);
        });
      }, 100);
    });
  }

  get custFullName(){return this.custForm.get("custFullName")}
  get custPlaceOfBirth(){return this.custForm.get("custPlaceOfBirth")}
  get custCountryOfBirth(){return this.custForm.get("custCountryOfBirth")}
  get custDateOfBirth(){return this.custForm.get("custDateOfBirth")}
  get custSex(){return this.custForm.get("custSex")}
  get custMothersName(){return this.custForm.get("custMothersName")}
  get custMaritalStatus(){return this.custForm.get("custMaritalStatus")}
  get custNationality(){return this.custForm.get("custNationality")}
  get custIdentityType(){return this.custForm.get("custIdentityType")}
  get custIdentityNumber(){return this.custForm.get("custIdentityNumber")}
  get custvpText(){return this.custForm.get("custvpText")}
  get custvpDate(){return this.custForm.get("custvpDate")}
  get custCountryOfIssue(){return this.custForm.get("custCountryOfIssue")}
  get custIdentityAddress(){return this.custForm.get("custIdentityAddress")}
  get custIdentityCountry(){return this.custForm.get("custIdentityCountry")}
  get custDomicileAddress(){return this.custForm.get("custDomicileAddress")}
  get custDomicileCountry(){return this.custForm.get("custDomicileCountry")}
  get custStatusOfResidence(){return this.custForm.get("custStatusOfResidence")}
  get custLongTimeOccupy(){return this.custForm.get("custLongTimeOccupy")}
  get custCellphone(){return this.custForm.get("custCellphone")}
  get custTOWText(){return this.custForm.get("custTOWText")}
  get custTOWDD(){return this.custForm.get("custTOWDD")}
  get custSourceOfFunds_income(){return this.custForm.get("custSourceOfFunds_income")}
  get custSourceOfFunds_Investment(){return this.custForm.get("custSourceOfFunds_Investment")}
  get custSourceOfFunds_Others(){return this.custForm.get("custSourceOfFunds_Others")}
  get custSourceOfFunds(){return this.custForm.get("custSourceOfFunds")}
  get custPurpose_Investment(){return this.custForm.get("custPurpose_Investment")}
  get custPurpose_Study(){return this.custForm.get("custPurpose_Study")}
  get custPurpose_Capital(){return this.custForm.get("custPurpose_Capital")}
  get custMonthlyIncome(){return this.custForm.get("custMonthlyIncome")}
  get custMonthlyAverageTransaction(){return this.custForm.get("custMonthlyAverageTransaction")}
  get custGuardianFullName(){return this.custForm.get("custGuardianFullName")}
  get custGuardianAddress(){return this.custForm.get("custGuardianAddress")}
  get custGuardianCountry(){return this.custForm.get("custGuardianCountry")}
  get custGuardianCellphone(){return this.custForm.get("custGuardianCellphone")}
  get custRelationshipDD(){return this.custForm.get("custRelationshipDD")}
  get custRelationshipText(){return this.custForm.get("custRelationshipText")}
  get custSourceOfFunds_Income(){return this.custForm.get("custSourceOfFunds_Income")}
  get custSourceOfFunds_Legacy(){return this.custForm.get("custSourceOfFunds_Legacy")}
  get custSourceOfFunds_Grant(){return this.custForm.get("custSourceOfFunds_Grant")}
  get custSourceOfFunds_Lottery(){return this.custForm.get("custSourceOfFunds_Lottery")}
  get custIdentityPostalCode(){return this.custForm.get("custIdentityPostalCode")}
  get custSourceOfFunds_Couple(){return this.custForm.get("custSourceOfFunds_Couple")}
  get custSourceOfFunds_Deposit(){return this.custForm.get("custSourceOfFunds_Deposit")}
  get custSourceOfFunds_Parent(){return this.custForm.get("custSourceOfFunds_Parent")}
  get custSourceOfFunds_OthersText(){return this.custForm.get("custSourceOfFunds_OthersText")}
  get custPurpose_Commerce(){return this.custForm.get("custPurpose_Commerce")}
  get custPurpose_Salary(){return this.custForm.get("custPurpose_Salary")}
  get custPurpose_Routine(){return this.custForm.get("custPurpose_Routine")}
  get custPurpose_Others(){return this.custForm.get("custPurpose_Others")}
  get custPurpose_OthersText(){return this.custForm.get("custPurpose_OthersText")}
  get custDomicileProvince(){return this.custForm.get("custDomicileProvince")}
  get custDomicileCity(){return this.custForm.get("custDomicileCity")}
  get custDomicileDistricts(){return this.custForm.get("custDomicileDistricts")}
  get custDomicileWard(){return this.custForm.get("custDomicileWard")}
  get custDomicilePostalCode(){return this.custForm.get("custDomicilePostalCode")}
  get custIdentityProvince(){return this.custForm.get("custIdentityProvince")}
  get custIdentityCity(){return this.custForm.get("custIdentityCity")}
  get custIdentityDistricts(){return this.custForm.get("custIdentityDistricts")}
  get custIdentityWard(){return this.custForm.get("custIdentityWard")}
  get custEmail(){return this.custForm.get("custEmail")}
  get custGuardianProvince(){return this.custForm.get("custGuardianProvince")}
  get custGuardianCity(){return this.custForm.get("custGuardianCity")}
  get custGuardianDistricts(){return this.custForm.get("custGuardianDistricts")}
  get custGuardianWard(){return this.custForm.get("custGuardianWard")}
  get custGuardianPostalCode(){return this.custForm.get("custGuardianPostalCode")}
  get custValid(){return this.custForm.get("custValid")}
  get custCompanyCountry(){return this.custForm.get("custCompanyCountry")}
  get custSOF(){return this.custForm.get("custSOF")}
  get custPurpose(){return this.custForm.get("custPurpose")}
  get custCompanyCity(){return this.custForm.get("custCompanyCity")}
  get custCompanyDistricts(){return this.custForm.get("custCompanyDistricts")}
  get custCompanyWard(){return this.custForm.get("custCompanyWard")}
  get custCompanyProvince(){return this.custForm.get("custCompanyProvince")}
  get custSOFValue(){return this.custForm.get("custSOFValue")}
  get custPurposeValue(){return this.custForm.get("custPurposeValue")}
  get custCIF(){return this.custForm.get("custCIF")}
  get custID(){return this.custForm.get("custID")}
  get custTemp(){return this.custForm.get("custTemp")}
  get custSubmissionStatus(){return this.custForm.get("custSubmissionStatus")}

  //New
  get custNumberOfDependents(){return this.custForm.get("custNumberOfDependents")}
  get custCoupleAddress(){return this.custForm.get("custCoupleAddress")}
  get custCoupleCountry(){return this.custForm.get("custCoupleCountry")}
  get custCoupleProvince(){return this.custForm.get("custCoupleProvince")}
  get custCoupleCity(){return this.custForm.get("custCoupleCity")}
  get custCoupleDistricts(){return this.custForm.get("custCoupleDistricts")}
  get custCoupleWard(){return this.custForm.get("custCoupleWard")}
  get custCouplePostalCode(){return this.custForm.get("custCouplePostalCode")}
  get custCoupleName(){return this.custForm.get("custCoupleName")}
  get custCouplePhone(){return this.custForm.get("custCouplePhone")}
  get custLongWorked(){return this.custForm.get("custLongWorked")}
  get custProductSpecification(){return this.custForm.get("custProductSpecification")}
  get custSystemSelling(){return this.custForm.get("custSystemSelling")}
  get custSystemSellingOthers(){return this.custForm.get("custSystemSellingOthers")}
  get custWorkingOwner(){return this.custForm.get("custWorkingOwner")}
  get custWorkingLandOwner(){return this.custForm.get("custWorkingLandOwner")}
  get custWorkingLandOwnerOthers(){return this.custForm.get("custWorkingLandOwnerOthers")}
  get custGrossIncome(){return this.custForm.get("custGrossIncome")}
  get custNetIncome(){return this.custForm.get("custNetIncome")}
  //End

}
