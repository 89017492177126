import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import { ServicesComponent } from 'src/app/services/services.component';

declare function setDatatableSearch(): any;

@Component({
  selector: 'app-cif-company-resubmission',
  templateUrl: './cif-company-resubmission.component.html',
  styleUrls: ['./cif-company-resubmission.component.sass']
})
export class CifCompanyResubmissionComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private globalService : ServicesComponent
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  ngOnInit(): void {
    this.globalService.checkRole("CIF Company");    
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/cif-company-resubmission",
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No. CIF',data:'cif_code'},
          {title: 'Nama Perusahaan',data:'company_name'},
          {title: 'No. Telpon',data:'phone_number'},
          {title: 'Email',data:'email'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                  return '<button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-info view-btn"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          $('.print2-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          $('.view-btn', row).bind('click', () => {
              self.viewData(data);
          });
          return row;
      }
    };
  }

  deleteData(data, row) {
    Swal.fire({
      title: 'Apakah anda yakin?',
      showDenyButton: true,
      confirmButtonText: 'Ya',
      denyButtonText: `Tidak`,
      icon: 'warning'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.http.post(this.ApiConfig.API_URL + "/delete-cif-company" , data,  this.httpOptions).subscribe((res) => {
          this.responseApi = res;
          if (this.responseApi.status == "200") {
            Swal.fire('Sukses membuang data CIF Perusahaan', '', 'success');
              row.remove();
          } else {
              Swal.fire("Sorry", "An error occured, please try again", "error");
          }
      }, (err) => {
          Swal.fire("Sorry", err.error.message, "error");
      });
        
      } else if (result.isDenied) {
        Swal.fire('Canceled', '', 'info');
      }
    })

  }
  
  editData(data) {
    this.router.navigate(['/admin/resubmission-cif-company-edit/' + data.id ]);
  }

  viewData(data){
      let obj = {
        createdBy : data.created_by,
        approve1 : data.first_approver,
        approve2 : data.second_approver
      };
      this.http.post(this.ApiConfig.API_URL + "/cif-company-view-approver", obj , this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            let createdBy = this.responseApi.data.created_by;
            let approve1 = this.responseApi.data.approver1;
            let approve2 = this.responseApi.data.approver2;
            let str = "";
            str += "<h1>Submitted by : "+createdBy+"</h1>";
            str += "<div><span>Rejected by : </span><span>"+approve2+"</span></div>"
            
            Swal.fire(
              {
                title: 'Transaksi Cross-Approval',
                icon: 'info',
                html: str,
                customClass: {container : 'table-modal'}
              }
            );
        } else {
            Swal.fire("Sorry", "An error occured, please try again", "error");
        }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
    
  }

}
