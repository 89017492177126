import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormArray, FormControl, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiConfig } from 'src/app/utility/ApiConfig';
import Swal from 'sweetalert2';
import {MatAccordion} from '@angular/material/expansion';
import { CurrencyPipe, DatePipe, Location, NgLocaleLocalization } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {identity, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ReplaySubject } from "rxjs"; 
import { DialogInsuranceComponent } from './dialog-insurance/dialog-insurance.component';


export interface User {name: string}
export interface arrCIF {cif_id: string}

@Component({
  selector: 'app-add-loan',
  templateUrl: './add-loan.component.html',
  styleUrls: ['./add-loan.component.css']
})

export class AddLoanComponent implements OnInit {
  insObj : any = {
    ins_name : "",
    ins_legality      : "",
    ins_relationship  : "",
    v_brand   : "",
    v_type    : "",
    v_year    : "",
    v_no      : "",
    v_stnk    : "",
    b_desc    : "",
    b_no      : "",
    b_name    : "",
    b_address : "",
    no_pk     : "",
    cover_note: "",
    no_apht   : "",
    kjpp      : "",
    harga_jaminan : "",
    tanggal_survey : ""
  }
  openDialogInsurance(){
    let dialogRef = this.dialog.open(DialogInsuranceComponent , {data:this.insObj});
    dialogRef.afterClosed().subscribe(res => {
      // console.log(this.insObj,res)
      const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
      if(res.ok){
        insurance.push(new FormGroup({
          ins_name: new FormControl(res.ins_name, []),
          ins_legality: new FormControl(res.ins_legality, []),
          ins_relationship: new FormControl(res.ins_relationship, []),
          v_brand: new FormControl(res.v_brand, []),
          v_type: new FormControl(res.v_type, []),
          v_year: new FormControl(res.v_year, []),
          v_no: new FormControl(res.v_no, []),
          b_desc: new FormControl(res.b_desc, []),
          b_name: new FormControl(res.b_name, []),
          b_no: new FormControl(res.b_no, []),
          b_address: new FormControl(res.b_address, []),
          no_pk: new FormControl(res.no_pk, []),
          cover_note: new FormControl(res.cover_note, []),
          no_apht: new FormControl(res.no_apht, []),
          kjpp: new FormControl(res.kjpp, []),
          harga_jaminan: new FormControl(res.harga_jaminan, []),
          tanggal_survey: new FormControl(res.tanggal_survey, []),
        }));
        
        if(res.ins_name == "Ruko" || res.ins_name == "Tanah & Bangunan") {
          this.loanForm.get('loanDocuments')['controls'][5].controls.docFileBuffer.setValidators();
          this.loanForm.get('loanDocuments')['controls'][5].controls.doc_name.setValidators();
          this.loanForm.get('loanDocuments')['controls'][5].patchValue({booValue : false});
          this.loanForm.get('loanDocuments')['controls'][5].controls.docFileBuffer.updateValueAndValidity();
          this.loanForm.get('loanDocuments')['controls'][5].controls.doc_name.updateValueAndValidity();
        } else if (res.ins_name == "Kendaraan Beroda Empat") {
          this.loanForm.get('loanDocuments')['controls'][6].controls.docFileBuffer.setValidators();
          this.loanForm.get('loanDocuments')['controls'][6].controls.doc_name.setValidators();
          this.loanForm.get('loanDocuments')['controls'][6].patchValue({booValue : false});
          this.loanForm.get('loanDocuments')['controls'][6].controls.docFileBuffer.updateValueAndValidity();
          this.loanForm.get('loanDocuments')['controls'][6].controls.doc_name.updateValueAndValidity();
        }
        this.loanForm.get('loanDocuments').updateValueAndValidity();
      }
    })
  }

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private route: ActivatedRoute, 
    private ApiConfig: ApiConfig,
    private location: Location,
    public dialog : MatDialog,
    private currencyPipe : CurrencyPipe
  ) { }

  loanForm: FormGroup = new FormGroup({
    fullname: new FormControl({value: '', disabled: true}),
    nickname: new FormControl({value: '', disabled: true}),
    sex: new FormControl({value: '', disabled: true}),
    ktp_no: new FormControl({value: '', disabled: true}),
    validity_identity: new FormControl({value: '', disabled: true}),
    place_of_birth: new FormControl({value: '', disabled: true}),
    date_of_birth: new FormControl({value: '', disabled: true}),
    identity_address: new FormControl({value: '', disabled: true}),
    identity_districts: new FormControl({value: '', disabled: true}),
    identity_ward: new FormControl({value: '', disabled: true}),
    identity_city: new FormControl({value: '', disabled: true}),
    identity_country: new FormControl({value: '', disabled: true}),
    identity_province: new FormControl({value: '', disabled: true}),
    identity_postalcode: new FormControl({value: '', disabled: true}),
    identity_phonenumber: new FormControl({value: '', disabled: true}),
    identity_smartphonenumber: new FormControl({value: '', disabled: true}),
    company_phone: new FormControl({value: '', disabled: true}),
    number_of_dependents: new FormControl({value: '', disabled: true}),
    last_study: new FormControl({value: '', disabled: true}),
    mothername: new FormControl({value: '', disabled: true}),
    marital_status: new FormControl({value: '', disabled: true}),
    couple_name: new FormControl({value: '', disabled: true}),
    couple_address: new FormControl({value: '', disabled: true}),
    couple_country: new FormControl({value: '', disabled: true}),
    couple_province: new FormControl({value: '', disabled: true}),
    couple_districts: new FormControl({value: '', disabled: true}),
    couple_ward: new FormControl({value: '', disabled: true}),
    couple_city: new FormControl({value: '', disabled: true}),
    couple_postalcode: new FormControl({value: '', disabled: true}),
    couple_phonenumber: new FormControl({value: '', disabled: true}),
    validity_identity_date: new FormControl({value: '', disabled: true}),

    guardian_name: new FormControl({value: '', disabled: true}),
    guardian_relationship: new FormControl({value: '', disabled: true}),
    guardian_address: new FormControl({value: '', disabled: true}),
    guardian_country: new FormControl({value: '', disabled: true}),
    guardian_province: new FormControl({value: '', disabled: true}),
    guardian_districts: new FormControl({value: '', disabled: true}),
    guardian_ward: new FormControl({value: '', disabled: true}),
    guardian_city: new FormControl({value: '', disabled: true}),
    guardian_postalcode: new FormControl({value: '', disabled: true}),
    guardian_phone: new FormControl({value: '', disabled: true}),
    guardian_mobilephone: new FormControl({value: '', disabled: true}),

    domicile_address: new FormControl({value: '', disabled: true}),
    domicile_country: new FormControl({value: '', disabled: true}),
    domicile_province: new FormControl({value: '', disabled: true}),
    domicile_districts: new FormControl({value: '', disabled: true}),
    domicile_ward: new FormControl({value: '', disabled: true}),
    domicile_city: new FormControl({value: '', disabled: true}),
    domicile_postalcode: new FormControl({value: '', disabled: true}),
    domicile_phone: new FormControl({value: '', disabled: true}),
    domicile_mobilephone: new FormControl({value: '', disabled: true}),
    domicile_status: new FormControl({value: '', disabled: true}),
    domicile_longtimeoccupy: new FormControl({value: '', disabled: true}),


    company_name: new FormControl({value: '', disabled: true}),
    company_address: new FormControl({value: '', disabled: true}),
    company_country: new FormControl({value: '', disabled: true}),
    company_province: new FormControl({value: '', disabled: true}),
    company_districts: new FormControl({value: '', disabled: true}),
    company_ward: new FormControl({value: '', disabled: true}),
    company_city: new FormControl({value: '', disabled: true}),
    company_worktype: new FormControl({value: '', disabled: true}),
    company_worktypetext: new FormControl({value: '', disabled: true}),
    company_position: new FormControl({value: '', disabled: true}),
    company_businessfield: new FormControl({value: '', disabled: true}),
    company_businessfieldtext: new FormControl({value: '', disabled: true}),
    net_income: new FormControl({value: '', disabled: true}),
    gross_income: new FormControl({value: '', disabled: true}),
    entre_sales_system_others: new FormControl({value: '', disabled: true}),
    entre_land_status_others: new FormControl({value: '', disabled: true}),
    company_longeffort: new FormControl({value: '', disabled: true}),

    entre_product_specification: new FormControl({value: '', disabled: true}),
    entre_sales_system: new FormControl({value: '', disabled: true}),
    entre_business_ownership: new FormControl({value: '', disabled: true}),
    entre_land_status: new FormControl({value: '', disabled: true}),
    entre_gross_profit: new FormControl({value: '', disabled: true}),
    entre_net_profit: new FormControl({value: '', disabled: true}),
    //SEND TO WS------------------------------------------------------------------------------------------------------------------------
    product: new FormControl('', [Validators.required]),
    branch_code: new FormControl('', [Validators.required]),
    officer_name: new FormControl('', [Validators.required]),
    officer_code: new FormControl('', [Validators.required]),
    cif_id: new FormControl('', [Validators.required]),

    is_debtor: new FormControl('', [Validators.required]),
    bank_name: new FormControl('', []),
    bank_product: new FormControl('', [Validators.required]),
    other_bank_loan: new FormArray([
      new FormGroup({
        name : new FormControl('', []),
        remaining_debt : new FormControl('', []),
        monthly_installment : new FormControl('', []),
        loan_term : new FormControl('', []),
      })
    ]),
    loan_amount: new FormControl('', [Validators.required]),
    loan_interest: new FormControl('', [Validators.required]),
    loan_duration_month: new FormControl('', [Validators.required]),
    loan_facility: new FormControl('', [Validators.required]),
    loan_purpose: new FormControl('', [Validators.required]),

    request_date: new FormControl(''),
    request_name: new FormControl(''),
    created_by: new FormControl(''),
    v_cmo_id: new FormControl('', [Validators.required]),
    // v_tl_id: new FormControl('', [Validators.required]),
    // v_bm_id: new FormControl('', [Validators.required]),
    // v_am_id: new FormControl('', [Validators.required]),
    v_tl_id: new FormControl('', []),
    v_bm_id: new FormControl('', []),
    v_am_id: new FormControl('', []),
    verification_date: new FormControl('', [Validators.required]),
    provision_fee: new FormControl('', [Validators.required]),
    admin_fee: new FormControl('', [Validators.required]),
    notary_fee: new FormControl('', [Validators.required]),
    insurance_fee: new FormControl('', [Validators.required]),
    denda_fee: new FormControl('', [Validators.required]),
    loanDocument: new FormArray([]),
    loanDocuments: new FormArray([]),
    custValid: new FormControl(''),
    other_products : new FormArray([
    ]),
    insurance : new FormArray([

    ])
  });

  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;
  tokenRaja : any;
  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  tempProvinceDomicile : any; tempProvinceCompany : any; tempProvinceGuardian : any;
  tempCityDomicile : any; tempCityCompany : any; tempCityGuardian : any;
  tempDistrictDomicile : any; tempDistrictCompany : any; tempDistrictGuardian : any;
  tempSubdistrictDomicile : any; tempSubdistrictCompany : any; tempSubdistrictGuardian : any;
  tempCityCouple:any; tempDistrictCouple:any; tempSubdistrictCouple:any; 

  arrDocument = 
  [
    {text: "Formulir Aplikasi Permohonan Pembiayaan"},
    {text: "Copy KTP Pemohon Beserta Pasangan & Pemilik Jabatan"},
    {text: "Copy Kartu Keluarga Suami, Istri dan Pemilik Jaminan"},
    {text: "Copy NPWP/SPT"},
    {text: "Copy SIUP/SKU/Surat Ijin Profesi Untuk Pengusaha/Profesional"},
  ]
  arrCIF:any;
  options: arrCIF[] = [
    {cif_id:"CIF00001"}
  ];
  filteredOptions: Observable<arrCIF[]>;
  dtLoanData:any;

  ngOnInit(): void {
    this.generateDocument();
    this.loanForm.patchValue({
      created_by : this.ApiConfig.USER_DATA.id_user,
    });
    this.filteredOptions = this.cif_id.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.cif_id),
      map(cif_id => cif_id ? this._filter(cif_id) : this.options.slice())
    );
    this.getCIF();
    this.getProvince();
  }

  formattedAmount:any;
  transformAmount(element){
    this.formattedAmount = this.currencyPipe.transform(element.target.value, 'Rp. ');    
    element.target.value = this.formattedAmount;
  }

  displayFn(user: arrCIF): string {
    return user && user.cif_id ? user.cif_id : '';
  }

  private _filter(cif_id: string): arrCIF[] {
    const filterValue = cif_id.toLowerCase();

    return this.options.filter(option => option.cif_id.toLowerCase().includes(filterValue));
  }

  get DocumentFormArray() {
    return this.loanForm.controls.loanDocument as FormArray;
  }

  private addCheckboxes() {
    this.arrDocument.forEach(() => this.DocumentFormArray.push(new FormControl(false)));
  }

  valNumber(event:any){
    var ASCIICode = (event.which) ? event.which : event.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
        return false;
    return true;
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  setData(){
    if(this.cif_id.value==""){
      Swal.fire("Ooops", "Nomor CIF Kosong. Harap Diisi Terlebih Dahulu", "error");
    } else {
      this.http.get(this.ApiConfig.API_URL + "/add-cif-individual-customer-data/" + this.cif_id.value.id, this.httpOptions).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200"){
            this.dtLoanData = this.responseApi.data;
            this.fullname.setValue(this.dtLoanData[0].full_name);
            this.nickname.setValue(this.dtLoanData[0].nickname);
            this.place_of_birth.setValue(this.dtLoanData[0].place_of_birth);
            this.date_of_birth.setValue(this.dtLoanData[0].dob);
            this.sex.setValue(this.dtLoanData[0].sex);
            this.mothername.setValue(this.dtLoanData[0].mothers_name);
            this.marital_status.setValue(this.dtLoanData[0].marital_status);
            this.last_study.setValue(this.dtLoanData[0].last_education);
            this.ktp_no.setValue(this.dtLoanData[0].identity_number);
            this.identity_address.setValue(this.dtLoanData[0].identity_address);
            this.identity_city.setValue(this.dtLoanData[0].identity_city);
            this.identity_districts.setValue(this.dtLoanData[0].identity_districts);
            this.identity_ward.setValue(this.dtLoanData[0].identity_ward);
            this.identity_country.setValue(this.dtLoanData[0].identity_country);
            this.identity_province.setValue(this.dtLoanData[0].identity_province);
            this.identity_postalcode.setValue(this.dtLoanData[0].identity_postcode);
            this.identity_phonenumber.setValue(this.dtLoanData[0].house_phone);
            this.identity_smartphonenumber.setValue(this.dtLoanData[0].cellphone);
            this.domicile_address.setValue(this.dtLoanData[0].domicile_address);
            this.domicile_country.setValue(this.dtLoanData[0].domicile_country);
            this.domicile_province.setValue(this.dtLoanData[0].domicile_province);
            this.domicile_city.setValue(this.dtLoanData[0].domicile_city);
            this.domicile_districts.setValue(this.dtLoanData[0].domicile_districts);
            this.domicile_ward.setValue(this.dtLoanData[0].domicile_ward);
            this.domicile_postalcode.setValue(this.dtLoanData[0].domicile_postcode);
            this.domicile_status.setValue(this.dtLoanData[0].status_of_residence);
            this.domicile_longtimeoccupy.setValue(this.dtLoanData[0].long_time_occupy);
            this.domicile_phone.setValue(this.dtLoanData[0].house_phone);
            this.company_phone.setValue(this.dtLoanData[0].company_phone);
            this.domicile_mobilephone.setValue(this.dtLoanData[0].cellphone);
            this.company_position.setValue(this.dtLoanData[0].position);
            this.company_name.setValue(this.dtLoanData[0].company_name);
            this.company_address.setValue(this.dtLoanData[0].company_address)
            this.company_country.setValue(this.dtLoanData[0].company_country)
            this.company_province.setValue(this.dtLoanData[0].company_province);
            this.company_city.setValue(this.dtLoanData[0].company_city);
            this.company_districts.setValue(this.dtLoanData[0].company_districts);
            this.company_ward.setValue(this.dtLoanData[0].company_ward);
            this.company_longeffort.setValue(this.dtLoanData[0].company_longworked);

            this.entre_product_specification.setValue(this.dtLoanData[0].entre_product_specification);
            this.entre_business_ownership.setValue(this.dtLoanData[0].entre_business_ownership);
            this.net_income.setValue(this.dtLoanData[0].net_income);
            this.gross_income.setValue(this.dtLoanData[0].gross_income);

            this.guardian_name.setValue(this.dtLoanData[0].guardian_full_name);
            this.guardian_address.setValue(this.dtLoanData[0].guardian_address);
            this.guardian_country.setValue(this.dtLoanData[0].guardian_country);
            this.guardian_province.setValue(this.dtLoanData[0].guardian_province);
            this.guardian_city.setValue(this.dtLoanData[0].guardian_city)
            this.guardian_districts.setValue(this.dtLoanData[0].guardian_districts);
            this.guardian_ward.setValue(this.dtLoanData[0].guardian_ward);
            this.guardian_postalcode.setValue(this.dtLoanData[0].guardian_postcode);
            this.guardian_mobilephone.setValue(this.dtLoanData[0].guardian_cellphone);
            this.guardian_phone.setValue(this.dtLoanData[0].guardian_house_phone);
            this.guardian_relationship.setValue(this.dtLoanData[0].guardian_relationship);
            this.number_of_dependents.setValue(this.dtLoanData[0].number_of_dependents);

            this.couple_name.setValue(this.dtLoanData[0].couple_name);
            this.couple_address.setValue(this.dtLoanData[0].couple_address);
            this.couple_country.setValue(this.dtLoanData[0].couple_country);
            this.couple_province.setValue(this.dtLoanData[0].couple_province);
            this.couple_city.setValue(this.dtLoanData[0].couple_city);
            this.couple_districts.setValue(this.dtLoanData[0].couple_districts);
            this.couple_ward.setValue(this.dtLoanData[0].couple_ward);
            this.couple_postalcode.setValue(this.dtLoanData[0].couple_postalcode);
            this.couple_phonenumber.setValue(this.dtLoanData[0].couple_phone);

            if(this.dtLoanData[0].validity_period == "Seumur Hidup"){
              this.validity_identity.setValue(this.dtLoanData[0].validity_period);
            }else{
              this.validity_identity.setValue("Berdasarkan Tanggal");
              this.validity_identity_date.setValue(this.dtLoanData[0].validity_period);
            }

            let datatop = "None;Pelajar/Mahasiswa;Pegawai Swasta;Pegawai Negeri;Ibu Rumah Tangga;Pensiunan;TNI/POLRI;Guru/Dosen;Wiraswasta;Profesional;";
            if(datatop.indexOf(this.dtLoanData[0].type_of_work) >= 0){
              this.company_worktype.setValue(this.dtLoanData[0].type_of_work);
            } else {
              this.company_worktype.setValue("Lainnya");
              this.company_worktypetext.setValue(this.dtLoanData[0].type_of_work);
            };

            let databf = "None;Pertanian;Industri/Manufaktur;Jasa;Perdagangan;"
            if(databf.indexOf(this.dtLoanData[0].business_fields) >= 0){
              this.company_businessfield.setValue(this.dtLoanData[0].business_fields);
            } else {
              this.company_businessfield.setValue("Lainnya");
              this.company_businessfieldtext.setValue(this.dtLoanData[0].business_fields);
            };

            let datass = "Konsinyasi/Bagi Hasil;Kredit dan Tunai;Kredit;Tunai;"
            let datawo = "Sewa/Kontrak;Milik Sendiri;"   

            if(datass.indexOf(this.dtLoanData[0].entre_sales_system) >= 0){
              this.entre_sales_system.setValue(this.dtLoanData[0].entre_sales_system);
            }else{
              this.entre_sales_system_others.setValue(this.dtLoanData[0].entre_sales_system);
              this.entre_sales_system.setValue("Lainnya");
            }

            if(datawo.indexOf(this.dtLoanData[0].entre_land_status) >= 0){
              this.entre_land_status.setValue(this.dtLoanData[0].entre_land_status);
            }else{
              this.entre_land_status_others.setValue(this.dtLoanData[0].entre_land_status);
              this.entre_land_status.setValue("Lainnya");
            }

            this.getAPIValue();
          }
        }, 
        err => {
          console.log(err);
        }
      )
    }
  }

  getCIF(){
    this.http.get(this.ApiConfig.API_URL + "/loan-cif-id", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.arrCIF = this.responseApi.data;
          this.filteredOptions =  new Observable<arrCIF[]>();
          this.options = this.arrCIF;
          this.filteredOptions = this.cif_id.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.cif_id),
            map(cif_id => cif_id ? this._filter(cif_id) : this.options.slice())
          );

        }
      }, 
      err => {
        console.log(err);
      }
    )
  }

  getProvince(){
    this.http.get(this.ApiConfig.API_URL + "/get-province").subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          })); 
        }  
      },
      err => {
        console.log(err);
      }
    );
  }

  loadCity(type:any, id:any) { 
    id = id ? id : '0'
      return new Promise((resolve) => {
        setTimeout(()=>{
          this.http.get(this.ApiConfig.API_URL + "/get-city/"+id).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataCity = this.responseApi.data;

                if(type == "identity_province"){
                  this.tempCity = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "domicile_province"){
                  this.tempCityDomicile = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "company_province"){
                  this.tempCityCompany = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "guardian_province"){
                  this.tempCityGuardian = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
                if(type == "couple_province"){
                  this.tempCityCouple = this.dataCity.map(city => ({
                    "id": city.code,
                    "text": city.name,
                    "additional": city
                  }));
                }
              }
              resolve(true);
            },
            err => {
              console.log(err);
            }
          );
        },50);
      })

  }

  loadDistrict(type:any, id:any) {
      id = id ? id : '0'
      return new Promise((resolve) => {
        setTimeout(()=>{
          if(id == ""){resolve(true)};
          this.http.get(this.ApiConfig.API_URL + "/get-districts/"+id).subscribe(
            res => {
              this.responseApi = res;
              if(this.responseApi.status == "200"){
                this.dataDistrict = this.responseApi.data;

                if(type == "identity_city"){
                  this.tempDistrict = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "domicile_city"){
                  this.tempDistrictDomicile = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "company_city"){
                  this.tempDistrictCompany = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "guardian_city"){
                  this.tempDistrictGuardian = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                if(type == "couple_city"){
                  this.tempDistrictCouple = this.dataDistrict.map(district => ({
                    "id": district.code,
                    "text": district.name,
                    "additional": district
                  }));
                }
                resolve(true);
              }
              resolve(true);
            },
            err => {
              console.log(err);
            }
          );
        },10);
      })
  }

  loadSubDistrict(type:any, id:any){
    id = id ? id : '0'
    return new Promise((resolve) => {
      setTimeout(()=>{
      this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/"+id).subscribe(
        res => {
          this.responseApi = res;
          if(this.responseApi.status == "200"){
            this.dataSubdistrict = this.responseApi.data;
            if(type == "identity_districts"){
              this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
                "id": subdistrict.code,
                "text": subdistrict.name,
                "additional": subdistrict
              }));
            }
            if(type == "domicile_districts"){
              this.tempSubdistrictDomicile = this.dataSubdistrict.map(subdistrict => ({
                "id": subdistrict.code,
                "text": subdistrict.name,
                "additional": subdistrict
              }));
            }
            if(type == "company_districts"){
              this.tempSubdistrictCompany = this.dataSubdistrict.map(subdistrict => ({
                "id": subdistrict.code,
                "text": subdistrict.name,
                "additional": subdistrict
              }));
            }
            if(type == "guardian_districts"){
              this.tempSubdistrictGuardian = this.dataSubdistrict.map(subdistrict => ({
                "id": subdistrict.code,
                "text": subdistrict.name,
                "additional": subdistrict
              }));
            }
            if(type == "couple_districts"){
              this.tempSubdistrictCouple = this.dataSubdistrict.map(subdistrict => ({
                "id": subdistrict.code,
                "text": subdistrict.name,
                "additional": subdistrict
              }));
            }
            resolve(true);
          }     
          resolve(true); 
        },
        err => {
          console.log(err);
        }
      );
      },50);
    })
  }

  getAPIValue(){
    return new Promise((resolve, reject) => {
      setTimeout(()=>{
                    this.loadCity("identity_province", this.identity_province.value)
        .then(res=> this.loadDistrict("identity_city", this.identity_city.value)
        .then(res=> this.loadSubDistrict("identity_districts", this.identity_districts.value)

        .then(res=> this.loadCity("couple_province", this.couple_province.value)
        .then(res=> this.loadDistrict("couple_city", this.couple_city.value)
        .then(res=> this.loadSubDistrict("couple_districts", this.couple_districts.value)

        .then(res=> this.loadCity("guardian_province", this.guardian_province.value)
        .then(res=> this.loadDistrict("guardian_city", this.guardian_city.value)
        .then(res=> this.loadSubDistrict("guardian_districts", this.guardian_districts.value)

        .then(res=> this.loadCity("domicile_province", this.domicile_province.value)
        .then(res=> this.loadDistrict("domicile_city", this.domicile_city.value)
        .then(res=> this.loadSubDistrict("domicile_districts", this.domicile_districts.value)

        .then(res=> this.loadCity("company_province", this.company_province.value)
        .then(res=> this.loadDistrict("company_city", this.company_city.value)
        .then(res=> this.loadSubDistrict("company_districts", this.company_districts.value)
        .then(res=>{resolve(true);
        })))))))))))))));
      },200);
    });
  }

  addLoan(){
    if(this.validationForm()) {
    // if(1==1){
      let pipe = new DatePipe('en-GB');
      this.loanForm.value.verification_date = pipe.transform(this.loanForm.value.verification_date,'yyyyMMdd');
      this.loanForm.value.request_date = pipe.transform(this.loanForm.value.request_date,'yyyyMMdd');
      this.loanForm.value.loan_interest = this.loanForm.value.loan_interest.replaceAll(",",".");
      this.loanForm.value.provision_fee = this.loanForm.value.provision_fee.replaceAll(",",".");
      this.loanForm.value.admin_fee = this.loanForm.value.admin_fee.replaceAll(",",".");
      this.http.post(this.ApiConfig.API_URL + "/loan", this.loanForm.value , this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.location.back();
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    }
  }

  deleteInsurance(idx){
    const insurance: FormArray = this.loanForm.get('insurance') as FormArray;
    insurance.removeAt(idx);
  }

  validationForm(){
    const invalid = [];
    const controls = this.loanForm.controls;
    // return true;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log(name);
        invalid.push(name);
        controls["custValid"].setValue("TIDAK VALID");
        this.openAll();
        return false;
      }
    }

    return true;
  }

  addRow() {
    const other_bank_loan: FormArray = this.loanForm.get('other_bank_loan') as FormArray;
    other_bank_loan.push(new FormGroup({
      name: new FormControl('', []),
      remaining_debt: new FormControl('', []),
      monthly_installment: new FormControl('', []),
      loan_term: new FormControl('', [])   
    }))
  }

  addRowUpload() {
    const loanDocuments: FormArray = this.loanForm.get('loanDocuments') as FormArray;
    loanDocuments.push(new FormGroup({
      type : new FormControl(''),
      doc_name : new FormControl('', []),
      file_path : new FormControl(''),
      file_extension : new FormControl(''),
      docFileBuffer: new FormControl('', []),
      booValue : new FormControl(false)       
    }))
  }

  addRowInsurance(){
    const loanDocuments: FormArray = this.loanForm.get('insurance') as FormArray;
    loanDocuments.push(new FormGroup({
      ins_name : new FormControl(''),
      ins_legality : new FormControl(''),
      ins_relationship : new FormControl(''),
      ins_vehicle_brand : new FormControl(''),
      ins_vehicle_type : new FormControl(''),
      ins_vehicle_year : new FormControl(''),
      ins_vehicle_no : new FormControl(''),
      ins_vehicle_stnk_name : new FormControl(''),
      ins_land_description : new FormControl(''),
      ins_land_cert_no : new FormControl(''),
      ins_land_cert_name : new FormControl(''),
      ins_land_address : new FormControl(''),      
    }));
    
    console.log(this.loanForm.get('insurance'))
  }

  removeRow(index) {
    const other_bank_loan: FormArray = this.loanForm.get('other_bank_loan') as FormArray;
    other_bank_loan.removeAt(index);
  }

  removeRowUpload(index) {
    const loanDocuments: FormArray = this.loanForm.get('loanDocuments') as FormArray;
    loanDocuments.removeAt(index);
  }

  changeOtherProduct(){
    if(this.bank_product.value=="Lainnya"){
      this.loanForm.get('bank_product_others').setValidators([Validators.required]);
      this.loanForm.get('bank_product_others').updateValueAndValidity();
    }else{
      this.loanForm.get('bank_product_others').clearValidators();
      this.loanForm.get('bank_product_others').updateValueAndValidity();
      this.bank_product_others.setValue('');
    }
  }

  changeIns(){
    if(this.ins.value == "Kendaraan Bermotor Roda Empat"){
      this.loanForm.get('ins_vehicle_brand').setValidators([Validators.required]);
      this.loanForm.get('ins_vehicle_type').setValidators([Validators.required]);
      this.loanForm.get('ins_vehicle_year').setValidators([Validators.required]);
      this.loanForm.get('ins_vehicle_no').setValidators([Validators.required]);
      this.loanForm.get('ins_vehicle_stnk_name').setValidators([Validators.required]);
      this.loanForm.get('ins_vehicle_brand').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_type').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_year').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_no').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_stnk_name').updateValueAndValidity();

      this.loanForm.get('ins_land_description').clearValidators();
      this.loanForm.get('ins_land_cert_no').clearValidators();
      this.loanForm.get('ins_land_cert_name').clearValidators();
      this.loanForm.get('ins_land_address').clearValidators();
      this.loanForm.get('ins_land_description').updateValueAndValidity();
      this.loanForm.get('ins_land_cert_no').updateValueAndValidity();
      this.loanForm.get('ins_land_address').updateValueAndValidity();
      this.loanForm.get('ins_land_cert_name').updateValueAndValidity();
      this.loanForm.get('ins_land_description').setValue('');
      this.loanForm.get('ins_land_cert_no').setValue('');
      this.loanForm.get('ins_land_address').setValue('');
      this.loanForm.get('ins_land_cert_name').setValue('');
    }else if(this.ins.value == "Tanah & Bangunan" || this.ins.value == "Ruko"){
      this.loanForm.get('ins_land_description').setValidators([Validators.required]);
      this.loanForm.get('ins_land_cert_no').setValidators([Validators.required]);
      this.loanForm.get('ins_land_cert_name').setValidators([Validators.required]);
      this.loanForm.get('ins_land_address').setValidators([Validators.required]);
      this.loanForm.get('ins_land_description').updateValueAndValidity();
      this.loanForm.get('ins_land_cert_no').updateValueAndValidity();
      this.loanForm.get('ins_land_address').updateValueAndValidity();
      this.loanForm.get('ins_land_cert_name').updateValueAndValidity();

      this.loanForm.get('ins_vehicle_brand').clearValidators();
      this.loanForm.get('ins_vehicle_type').clearValidators();
      this.loanForm.get('ins_vehicle_year').clearValidators();
      this.loanForm.get('ins_vehicle_no').clearValidators();
      this.loanForm.get('ins_vehicle_stnk_name').clearValidators();
      this.loanForm.get('ins_vehicle_brand').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_type').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_year').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_no').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_stnk_name').updateValueAndValidity();
      this.loanForm.get('ins_vehicle_brand').setValue('');
      this.loanForm.get('ins_vehicle_type').setValue('');
      this.loanForm.get('ins_vehicle_year').setValue('');
      this.loanForm.get('ins_vehicle_no').setValue('');
      this.loanForm.get('ins_vehicle_stnk_name').setValue('');
    }
  }

  generateDocument(){
    const isTanah = "Copy Sertifikat SHM/SHGB, IMB, PBB, AJB, Akta Hibah, Akta Waris (untuk jaminan tanah & Bangunan)";
    const isVehicle = "Copy BPKB & STNK (untuk jaminan kendaraan)";
    const loanDocuments: FormArray = this.loanForm.get('loanDocuments') as FormArray;
    this.arrDocument.forEach((e,i,a) => {
      if(e.text == isTanah || e.text == isVehicle){
        loanDocuments.push(new FormGroup({
          type : new FormControl(this.arrDocument[i].text),
          doc_name : new FormControl('', []),
          file_path : new FormControl(''),
          file_extension : new FormControl(''),
          docFileBuffer: new FormControl('',[]),
          booValue : new FormControl(true)        
        }))
      } else {
        loanDocuments.push(new FormGroup({
          type : new FormControl(this.arrDocument[i].text),
          doc_name : new FormControl('', []),
          file_path : new FormControl(''),
          file_extension : new FormControl(''),
          docFileBuffer: new FormControl('',[]),
          booValue : new FormControl(true)        
        }))
      }
    });
  }

  feFileName : any;
  uploadFile(event,i) {
    let fileName = event.target.files[0].name;
    this.feFileName = fileName;
    let extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let name = fileName.substring(0,fileName.indexOf("."));
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.loanForm.get('loanDocuments')['controls'][i].patchValue({
        doc_name : name,
        file_extension : extension,
        docFileBuffer : base64
      });
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  setInsuranceJSON(){
    // console.log(this.insurance['controls'][0].ins_vehicle_brand);
    this.insurance['controls'][0].controls.ins_vehicle_brand.setValue(this.ins_vehicle_brand.value);
    this.insurance['controls'][0].controls.ins_vehicle_type.setValue(this.ins_vehicle_type.value);
    this.insurance['controls'][0].controls.ins_vehicle_year.setValue(this.ins_vehicle_year.value);
    this.insurance['controls'][0].controls.ins_vechile_no.setValue(this.ins_vehicle_no.value);
    this.insurance['controls'][0].controls.ins_vechile_stnk_name.setValue(this.ins_vehicle_stnk_name.value);

    this.insurance['controls'][1].controls.ins_name.setValue(this.ins.value);
    this.insurance['controls'][1].controls.ins_land_description.setValue(this.ins_land_description.value);
    this.insurance['controls'][1].controls.ins_land_cert_no.setValue(this.ins_land_cert_no.value);
    this.insurance['controls'][1].controls.ins_land_cert_name.setValue(this.ins_land_cert_name.value);
    this.insurance['controls'][1].controls.ins_land_address.setValue(this.ins_land_address.value);
  }

  insChange(idx:string){
    if(idx == "1" || idx == "2"){
      if(this.loanForm.controls.insurance['controls'][idx].controls.ins_value.value){
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_description.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_no.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_name.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_address.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_description.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_no.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_name.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_address.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.updateValueAndValidity();
      }else{
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_description.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_no.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_name.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_address.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_description.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_no.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_name.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_address.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_description.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_no.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_cert_name.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_land_address.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.setValue('');
      }
    }else if(idx == "0"){
      if(this.loanForm.controls.insurance['controls'][idx].controls.ins_value.value){
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_brand.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_type.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_year.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_no.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_stnk_name.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.setValidators([Validators.required]);
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_brand.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_type.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_year.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_no.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_stnk_name.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.updateValueAndValidity();
      }else{
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_brand.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_type.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_year.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_no.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_stnk_name.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.clearValidators();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_brand.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_type.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_year.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_no.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_stnk_name.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.updateValueAndValidity();
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_brand.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_type.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_year.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_no.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_vehicle_stnk_name.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_legality.setValue('');
        this.loanForm.controls.insurance['controls'][idx].controls.ins_relationship.setValue('');
      }
    }

    if(!this.loanForm.controls.insurance['controls'][0].controls.ins_value.value && !this.loanForm.controls.insurance['controls'][1].controls.ins_value.value && !this.loanForm.controls.insurance['controls'][2].controls.ins_value.value){
      this.ins.setValue("");
    } else {
      this.ins.setValue("VALID");
    }
  }

  get loanDocument(){return this.loanForm.get("loanDocument")}
  get loanDocuments(){return this.loanForm.get("loanDocuments")}
  // get ins_name(){return this.loanForm.get("ins_name")}
  get insurance(){return this.loanForm.get("insurance")}
  get fullname(){return this.loanForm.get("fullname")}
  get nickname(){return this.loanForm.get("nickname")}
  get sex(){return this.loanForm.get("sex")}
  get ktp_no(){return this.loanForm.get("ktp_no")}
  get validity_identity(){return this.loanForm.get("validity_identity")}
  get place_of_birth(){return this.loanForm.get("place_of_birth")}
  get date_of_birth(){return this.loanForm.get("date_of_birth")}
  get identity_address(){return this.loanForm.get("identity_address")}
  get identity_districts(){return this.loanForm.get("identity_districts")}
  get identity_ward(){return this.loanForm.get("identity_ward")}
  get identity_city(){return this.loanForm.get("identity_city")}
  get identity_postalcode(){return this.loanForm.get("identity_postalcode")}
  get identity_country(){return this.loanForm.get("identity_country")}
  get identity_province(){return this.loanForm.get("identity_province")}
  get identity_phonenumber(){return this.loanForm.get("identity_phonenumber")}
  get identity_smartphonenumber(){return this.loanForm.get("identity_smartphonenumber")}
  get company_phone(){return this.loanForm.get("company_phone")}
  get number_of_dependents(){return this.loanForm.get("number_of_dependents")}
  get last_study(){return this.loanForm.get("last_study")}
  get mothername(){return this.loanForm.get("mothername")}
  get marital_status(){return this.loanForm.get("marital_status")}
  get couple_name(){return this.loanForm.get("couple_name")}
  get couple_address(){return this.loanForm.get("couple_address")}
  get couple_country(){return this.loanForm.get("couple_country")}
  get couple_province(){return this.loanForm.get("couple_province")}
  get couple_districts(){return this.loanForm.get("couple_districts")}
  get couple_ward(){return this.loanForm.get("couple_ward")}
  get couple_city(){return this.loanForm.get("couple_city")}
  get couple_postalcode(){return this.loanForm.get("couple_postalcode")}
  get couple_phonenumber(){return this.loanForm.get("couple_phonenumber")}
  get domicile_address(){return this.loanForm.get("domicile_address")}
  get domicile_country(){return this.loanForm.get("domicile_country")}
  get domicile_province(){return this.loanForm.get("domicile_province")}
  get domicile_districts(){return this.loanForm.get("domicile_districts")}
  get domicile_ward(){return this.loanForm.get("domicile_ward")}
  get domicile_city(){return this.loanForm.get("domicile_city")}
  get domicile_postalcode(){return this.loanForm.get("domicile_postalcode")}
  get domicile_phone(){return this.loanForm.get("domicile_phone")}
  get domicile_mobilephone(){return this.loanForm.get("domicile_mobilephone")}
  get domicile_status(){return this.loanForm.get("domicile_status")}
  get domicile_longtimeoccupy(){return this.loanForm.get("domicile_longtimeoccupy")}
  get guardian_name(){return this.loanForm.get("guardian_name")}
  get guardian_relationship(){return this.loanForm.get("guardian_relationship")}
  get guardian_address(){return this.loanForm.get("guardian_address")}
  get guardian_country(){return this.loanForm.get("guardian_country")}
  get guardian_province(){return this.loanForm.get("guardian_province")}
  get guardian_districts(){return this.loanForm.get("guardian_districts")}
  get guardian_ward(){return this.loanForm.get("guardian_ward")}
  get guardian_city(){return this.loanForm.get("guardian_city")}
  get guardian_postalcode(){return this.loanForm.get("guardian_postalcode")}
  get guardian_phone(){return this.loanForm.get("guardian_phone")}
  get guardian_mobilephone(){return this.loanForm.get("guardian_mobilephone")}
  get company_name(){return this.loanForm.get("company_name")}
  get company_address(){return this.loanForm.get("company_address")}
  get company_country(){return this.loanForm.get("company_country")}
  get company_province(){return this.loanForm.get("company_province")}
  get company_districts(){return this.loanForm.get("company_districts")}
  get company_ward(){return this.loanForm.get("company_ward")}
  get company_city(){return this.loanForm.get("company_city")}
  // get company_postalcode(){return this.loanForm.get("company_postalcode")}
  get company_worktype(){return this.loanForm.get("company_worktype")}
  get company_worktypetext(){return this.loanForm.get("company_worktypetext")}
  get company_position(){return this.loanForm.get("company_position")}
  get company_businessfield(){return this.loanForm.get("company_businessfield")}
  get company_businessfieldtext(){return this.loanForm.get("company_businessfieldtext")}
  get company_longeffort(){return this.loanForm.get("company_longeffort")}
  get net_income(){return this.loanForm.get("net_income")}
  get gross_income(){return this.loanForm.get("gross_income")}
  get entre_sales_system_others(){return this.loanForm.get("entre_sales_system_others")}
  get entre_land_status_others(){return this.loanForm.get("entre_land_status_others")}
  get product(){return this.loanForm.get("product")}
  get branch_code(){return this.loanForm.get("branch_code")}
  get officer_name(){return this.loanForm.get("officer_name")}
  get officer_code(){return this.loanForm.get("officer_code")}
  get cif_id(){return this.loanForm.get("cif_id")}
  get entre_product_specification(){return this.loanForm.get("entre_product_specification")}
  get entre_sales_system(){return this.loanForm.get("entre_sales_system")}
  get entre_business_ownership(){return this.loanForm.get("entre_business_ownership")}
  get entre_land_status(){return this.loanForm.get("entre_land_status")}
  get entre_gross_profit(){return this.loanForm.get("entre_gross_profit")}
  get entre_net_profit(){return this.loanForm.get("entre_net_profit")}
  get is_debtor(){return this.loanForm.get("is_debtor")}
  get bank_name(){return this.loanForm.get("bank_name")}
  get bank_product(){return this.loanForm.get("bank_product")}
  get other_bank_loan(){return this.loanForm.get("other_bank_loan")}
  get loan_amount(){return this.loanForm.get("loan_amount")}
  get loan_interest(){return this.loanForm.get("loan_interest")}
  get loan_duration_year(){return this.loanForm.get("loan_duration_year")}
  get loan_duration_month(){return this.loanForm.get("loan_duration_month")}
  get loan_facility(){return this.loanForm.get("loan_facility")}
  get loan_purpose(){return this.loanForm.get("loan_purpose")}
  get ins(){return this.loanForm.get("ins")}
  get ins_legality(){return this.loanForm.get("ins_legality")}
  get ins_relationship(){return this.loanForm.get("ins_relationship")}
  get ins_vehicle_brand(){return this.loanForm.get("ins_vehicle_brand")}
  get ins_vehicle_type(){return this.loanForm.get("ins_vehicle_type")}
  get ins_vehicle_year(){return this.loanForm.get("ins_vehicle_year")}
  get ins_vehicle_no(){return this.loanForm.get("ins_vehicle_no")}
  get ins_vehicle_stnk_name(){return this.loanForm.get("ins_vehicle_stnk_name")}
  get ins_land_description(){return this.loanForm.get("ins_land_description")}
  get ins_land_cert_no(){return this.loanForm.get("ins_land_cert_no")}
  get ins_land_cert_name(){return this.loanForm.get("ins_land_cert_name")}
  get ins_land_address(){return this.loanForm.get("ins_land_address")}
  get request_date(){return this.loanForm.get("request_date")}
  get request_name(){return this.loanForm.get("request_name")}
  get created_by(){return this.loanForm.get("created_by")}
  get created_date(){return this.loanForm.get("created_date")}
  get last_updated(){return this.loanForm.get("last_updated")}
  get is_deleted(){return this.loanForm.get("is_deleted")}
  get validity_identity_date(){return this.loanForm.get("validity_identity_date")}
  get custValid(){return this.loanForm.get("custValid")}
  get debt_name(){return this.loanForm.get("debt_name")}
  get v_cmo_id(){return this.loanForm.get("v_cmo_id")}
  get v_tl_id(){return this.loanForm.get("v_tl_id")}
  get v_bm_id(){return this.loanForm.get("v_bm_id")}
  get v_am_id(){return this.loanForm.get("v_am_id")}
  get verification_date(){return this.loanForm.get("verification_date")}
  get bank_product_others(){return this.loanForm.get("bank_product_others")}
  get other_products(){return this.loanForm.get("other_products")}
  get provision_fee(){return this.loanForm.get("provision_fee")}
  get admin_fee(){return this.loanForm.get("admin_fee")}
  get notary_fee(){return this.loanForm.get("notary_fee")}
  get insurance_fee(){return this.loanForm.get("insurance_fee")}
  get denda_fee(){return this.loanForm.get("denda_fee")}
}
