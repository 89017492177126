import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { Route, Router, ActivatedRoute } from '@angular/router';
declare function setDatatableSearch(): any;


@Component({
  selector: 'app-attachment-cif-company',
  templateUrl: './attachment-cif-company.component.html',
  styleUrls: ['./attachment-cif-company.component.css']
})
export class AttachmentCifCompanyComponent implements OnInit {

  constructor(
    private ApiConfig: ApiConfig,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  dtOptions: any = {};
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  responseApi: any;

  idParam: any; companyName: any;

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getCompanyName(this.idParam);
    }
    this.dtOptions = {
      dom: 'rtpl',
      ajax: {
          url: this.ApiConfig.API_URL + "/cif-company-attachment/"+ this.idParam,
          headers: { 'token': this.ApiConfig.USER_DATA.token },
          data: function (data) {
              setDatatableSearch();
              return data;
          }
      },
      columns: [
          {title: 'No.',data:'id'},
          {title: 'Jenis Dokumen',data:'document_name'},
          {title: 'Keterangan',data:'document_description'},
          {title: 'Tanggal Berlaku',data:'document_date'},
          {
              data: null, title: 'Action', defaultContent: "",
              render: function (data, type, row) {
                return '<button class="btn btn-info print2-btn"><img src="../../../assets/icons/document.svg"><button class="btn btn-info edit-btn"><img src="../../../assets/icons/edit.svg"></button><button class="btn btn-danger delete-btn"><img src="../../../assets/icons/backspace.svg"></button>';
              }
          }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
          const self = this;
          $('.delete-btn', row).unbind('click');
          $('.edit-btn', row).unbind('click');
          $('.print2-btn', row).unbind('click');
          $('.delete-btn', row).bind('click', () => {
              self.deleteData(data, row);
          });
          $('.edit-btn', row).bind('click', () => {
              self.editData(data);
          });
          $('.print2-btn', row).bind('click', () => {
              self.downloadData(data);
          });
          return row;
      }
    };
  } //onint

  deleteData(data, row) {
    this.http.delete(this.ApiConfig.API_URL + "/cif-company/" + data.id,  this.httpOptions).subscribe((res) => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
            Swal.fire("Success", this.responseApi.message, "success");
            row.remove();
        } else {
            Swal.fire("Sorry", "An error occured, please try again", "error");
        }
    }, (err) => {
        Swal.fire("Sorry", err.error.message, "error");
    });
  }
  
  addData(){
    this.router.navigate(['/admin/add-attachment/' + this.idParam ]);
  };

  editData(data) {
    this.router.navigate(['/admin/edit-cif-company/' + data.id ]);
  }

  downloadData(data) {
    this.router.navigate(['/admin/attachment-cif-company/' + data.id ]);
  }

  getCompanyName(id){
    this.http.get(this.ApiConfig.API_URL + "/cif-company-attachment-name/"+id, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.companyName = this.responseApi.data.company_name;
        }        
      },
      err => {
        console.log(err);
      }
    );
  }
}
