import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { CurrencyPipe, Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-approve-menu-loan',
  templateUrl: './approve-menu-loan.component.html',
  styleUrls: ['./approve-menu-loan.component.css']
})
export class ApproveMenuLoanComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    private ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private currencyPipe : CurrencyPipe
  ) { }

  idParam : any;
  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};
  loanData : any; cifData : any; statusApproval : any; submittedBy : any; regionData : any; approvedBy : any; cannotApprove : any;
  jumlahPinjaman : any;
  loanAmount: any; notaryFee: any; insuranceFee: any;
  documentData: any;
  coaId: any = '';
  tempCoa: any;
  firstCoa: boolean = true;

  ngOnInit(): void {
    // this.spinner.show();
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
      this.getApprover(this.idParam)
      this.getCoa();
    }
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  getApprover(id){
    this.http.get(this.ApiConfig.API_URL + '/loan-first-approver/' + id , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.approvedBy = 
          {
            username : this.responseApi.data.username,
            id : this.responseApi.data.id,
          }
          if(this.ApiConfig.USER_DATA.id_user == this.approvedBy.id) {
            this.cannotApprove = true;
          } else if (this.ApiConfig.USER_DATA.id_role == '1') {
            this.cannotApprove = false;
          }
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }
  getData(id){
    this.http.get(this.ApiConfig.API_URL + '/loan/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){

        this.loanData = this.responseApi.data.loanData;
        this.cifData = this.responseApi.data.cifData;
        this.statusApproval = this.loanData.status_approval;
        this.submittedBy = this.responseApi.data.submittedBy
        this.loanData.v_date = moment(this.loanData.v_date).format('DD-MM-YYYY');
        this.loanData.request_date = moment(this.loanData.request_date).format('DD-MM-YYYY');
        
        // this.cifData.date_of_birth = moment(this.cifData.date_of_birth).format('DD-MM-YYYY');
        this.loanAmount = this.loanData.loan_amount;
        this.notaryFee = this.loanData.notary_fee;
        this.insuranceFee = this.loanData.insurance_fee;
        this.jumlahPinjaman = this.loanData.loan_amount;
        this.loanData.loan_amount = this.currencyPipe.transform(this.loanData.loan_amount, 'Rp. ');
        this.loanData.notary_fee = this.currencyPipe.transform(this.loanData.notary_fee, 'Rp. ');
        this.loanData.insurance_fee = this.currencyPipe.transform(this.loanData.insurance_fee, 'Rp. ');
        this.parseRegionData();
        this.getRegionData(this.cifData.cif_id);
        this.getUploadDocument(this.loanData.id)
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getUploadDocument(id) {
    this.http.get(this.ApiConfig.API_URL + "/loan-document/" + id, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200") {
          this.documentData = this.responseApi.data;
        }
      },
      (err) => {
        console.log(err);
      }
    )
  }

  viewDocument(i) {
    let filepath = this.documentData[i].file_path +"."+ this.documentData[i].file_extension

    window.open(
      this.ApiConfig.API_URL + "/openPDF?pdfName=" + filepath
    );
  }

  
  parseRegionData(){
    this.cifData.identity_province = this.cifData.identity_province ? this.cifData.identity_province : '0';
    this.cifData.couple_province = this.cifData.couple_province ? this.cifData.couple_province : '0';
    this.cifData.guardian_province = this.cifData.guardian_province ? this.cifData.guardian_province : '0';
    this.cifData.company_province = this.cifData.company_province ? this.cifData.company_province : '0';
    this.cifData.domicile_province = this.cifData.domicile_province ? this.cifData.domicile_province : '0';

    this.cifData.identity_city = this.cifData.identity_city ? this.cifData.identity_city : '0';
    this.cifData.couple_city = this.cifData.couple_city ? this.cifData.couple_city : '0';
    this.cifData.guardian_city = this.cifData.guardian_city ? this.cifData.guardian_city : '0';
    this.cifData.company_city = this.cifData.company_city ? this.cifData.company_city : '0';
    this.cifData.domicile_city = this.cifData.domicile_city ? this.cifData.domicile_city : '0';

    this.cifData.identity_districts = this.cifData.identity_districts ? this.cifData.identity_districts : '0';
    this.cifData.couple_districts = this.cifData.couple_districts ? this.cifData.couple_districts : '0';
    this.cifData.guardian_districts = this.cifData.guardian_districts ? this.cifData.guardian_districts : '0';
    this.cifData.company_districts = this.cifData.company_districts ? this.cifData.company_districts : '0';
    this.cifData.domicile_districts = this.cifData.domicile_districts ? this.cifData.domicile_districts : '0';

    this.cifData.identity_ward = this.cifData.identity_ward ? this.cifData.identity_ward : '0';
    this.cifData.couple_ward = this.cifData.couple_ward ? this.cifData.couple_ward : '0';
    this.cifData.guardian_ward = this.cifData.guardian_ward ? this.cifData.guardian_ward : '0';
    this.cifData.company_ward = this.cifData.company_ward ? this.cifData.company_ward : '0';
    this.cifData.domicile_ward = this.cifData.domicile_ward ? this.cifData.domicile_ward : '0';
  }

  getRegionData(cif_id){
    this.http.get(this.ApiConfig.API_URL + '/loan-region-api/' + cif_id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200") {
          this.regionData = this.responseApi.data;          
          this.cifData.identity_province = this.regionData.province.find(item => item.code == this.cifData.identity_province).name;          
          this.cifData.couple_province = this.regionData.province.find(item => item.code == this.cifData.couple_province).name;
          this.cifData.guardian_province = this.regionData.province.find(item => item.code == this.cifData.guardian_province).name;
          this.cifData.company_province = this.regionData.province.find(item => item.code == this.cifData.company_province).name;
          this.cifData.domicile_province = this.regionData.province.find(item => item.code == this.cifData.domicile_province).name;

          this.cifData.identity_city = this.regionData.identity_city.find(item => item.code == this.cifData.identity_city).name;
          this.cifData.couple_city = this.regionData.couple_city.find(item => item.code == this.cifData.couple_city).name;
          this.cifData.guardian_city = this.regionData.guardian_city.find(item => item.code == this.cifData.guardian_city).name;
          this.cifData.company_city = this.regionData.company_city.find(item => item.code == this.cifData.company_city).name;
          this.cifData.domicile_city = this.regionData.domicile_city.find(item => item.code == this.cifData.domicile_city).name;

          this.cifData.identity_districts = this.regionData.identity_district.find(item => item.code == this.cifData.identity_districts).name;
          this.cifData.couple_districts = this.regionData.couple_district.find(item => item.code == this.cifData.couple_districts).name;
          this.cifData.guardian_districts = this.regionData.guardian_district.find(item => item.code == this.cifData.guardian_districts).name;
          this.cifData.company_districts = this.regionData.company_district.find(item => item.code == this.cifData.company_districts).name;
          this.cifData.domicile_districts = this.regionData.domicile_district.find(item => item.code == this.cifData.domicile_districts).name;

          this.cifData.identity_ward = this.regionData.identity_ward.find(item => item.code == this.cifData.identity_ward).name;
          this.cifData.couple_ward = this.regionData.couple_ward.find(item => item.code == this.cifData.couple_ward).name;
          this.cifData.guardian_ward = this.regionData.guardian_ward.find(item => item.code == this.cifData.guardian_ward).name;
          this.cifData.company_ward = this.regionData.company_ward.find(item => item.code == this.cifData.company_ward).name;
          this.cifData.domicile_ward = this.regionData.domicile_ward.find(item => item.code == this.cifData.domicile_ward).name;

          
          this.spinner.hide();

                 
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  approve(){
    if (this.coaId != ''){
      let data = {
        data_type: "loan",
        approval_type: "PENDING_ADD",
        data_identifier: this.loanData.id,
        approved_by: this.ApiConfig.USER_DATA.id_user,
        action_type: "approve",
        coaId : this.coaId,
        idStore : this.ApiConfig.USER_DATA.id_store
      }
  
      let isTerm = false;
      if(this.loanData.loan_facility == "TL") {
        isTerm = true;
      }
  
      this.http.post(this.ApiConfig.API_URL + '/loan-approval' , data , this.httpOptions).subscribe(
        (res) => {
          this.responseApi = res;
          if(this.responseApi.status == "200"){
            let isProcess = this.responseApi.data;
            if(!isTerm || (isTerm && !isProcess)) {
              Swal.fire("Success", "Your input has been saved.", "success");
              this.location.back();
            }
            else if(isTerm && isProcess) {
              this.initLoan();
            }
            else if (!isTerm && isProcess) {
              this.registrationFeePayment();
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      Swal.fire("Error", "Mohon Isi COA Untuk Bank (Peminjaman)", "error");
    }

    
  }

  reject(){
    let data = {
      data_type: "loan",
      approval_type: "PENDING_ADD",
      data_identifier: this.loanData.id,
      approved_by: this.ApiConfig.USER_DATA.id_user,
      action_type: "reject",
      coaId      : this.coaId
    }
    this.http.post(this.ApiConfig.API_URL + '/loan-rejection' , data , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
            Swal.fire("Success", "Your input has been saved.", "success");
            this.location.back();
        }
      },
      (err) => {
        console.log(err);
      }
    );

  }

  initLoan(){
    this.http.get(this.ApiConfig.API_URL + '/term-loan-first-setup-v2/' + this.loanData.id + '/' + this.ApiConfig.USER_DATA.id_user , this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.registrationFeePayment();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //Potong Rekening Daily Saving untuk Biaya Registrasi Term Loan, Demand Loan tdk d proses disini
  registrationFeePayment() {
    let loanAmount = this.loanAmount;
    let provisionFee = (this.loanData.provision_fee / 100) * loanAmount;
    let adminFee = (this.loanData.admin_fee / 100) * loanAmount
    let notaryFee = parseFloat(this.notaryFee);
    let insuranceFee = parseFloat(this.insuranceFee);
    let totalFee = provisionFee + adminFee + notaryFee + insuranceFee

    let provisi = {
      cif_id : this.loanData.cif_id,
      amount : provisionFee,
      type : "D",
      note : "Biaya Provisi Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let admin = {
      cif_id : this.loanData.cif_id,
      amount : adminFee,
      type : "D",
      note : "Biaya Admin Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let notary = {
      cif_id : this.loanData.cif_id,
      amount : notaryFee,
      type : "D",
      note : "Biaya Notaris Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let insurance = {
      cif_id : this.loanData.cif_id,
      amount : insuranceFee,
      type : "D",
      note : "Biaya Asuransi Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }

    let paymentArr = [provisi,admin,notary,insurance];

    paymentArr.forEach((e,i,a) => {
      setTimeout(() => {

        this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', e, this.httpOptions).subscribe(
          (res) => {
            this.responseApi = res;
            if(this.responseApi.status == "200"){
              // this.sendLoan();
            }
          },
          (err) => {
            console.log(err);
          }
        );

      },500)

      if(i == paymentArr.length - 1) {
        this.sendLoan();
      }
    })
  }

  sendLoan() {
    let obj_cair = {
      cif_id : this.loanData.cif_id,
      amount : this.jumlahPinjaman,
      type : "K",
      note : "Pencairan Dana Pinjaman Term Loan. Kode Pinjaman " + this.loanData.id,
      create_operator : this.ApiConfig.USER_DATA.id_user
    }
    console.log(obj_cair)
    this.http.post(this.ApiConfig.API_URL + '/insert-log-daily-savings-transactions', obj_cair, this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          Swal.fire("Success", "Your input has been saved. Term Loan Successfully Processed", "success");
          this.location.back();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  onCoaIdChange(event: any){
    if (this.firstCoa){
      this.firstCoa = false;
    } else {
      this.coaId = event;
    }
  };

  getCoa(){
    this.http.get(this.ApiConfig.API_URL + "/coa/", this.httpOptions).subscribe(res => {
      this.responseApi  = res;
      this.tempCoa      = this.responseApi.data.map(coa => ({
        "id"          : coa.id_coa,
        "text"        : coa.coa_name,
        "additional"  : coa
      }));
    });
  }
}
