import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '../../../../utility/ApiConfig';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-cif-company',
  templateUrl: './view-cif-company.component.html',
  styleUrls: ['./view-cif-company.component.css']
})
export class ViewCifCompanyComponent implements OnInit {

  @ViewChild('panels',{static:false}) myPanels: MatAccordion;
  @ViewChild('panels',{static:false}) myPanels2: MatAccordion;

  constructor(
    private http: HttpClient,
    private location: Location,
    public ApiConfig: ApiConfig,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  idParam: any;
  responseApi: any;
  httpOptions: any = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': this.ApiConfig.get("user-data").token })};

  companyForm: FormGroup = new FormGroup({
    cpCif: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpName: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpFieldOfWork: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpPlaceOfEstablishment: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDeedNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDateOfEstablisment: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDeedIssuedPlace: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDeedIssuedDate: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpNPWP: new FormControl({value : '', disabled: true}, [Validators.required]),

    cpAddress: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpNationality: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpProvince: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDistrict: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpSubDistrict: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpCity: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpPostalCode: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpOccupiedSince: new FormControl({value : '', disabled: true}, [Validators.required]),

    cpPhoneNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpFaxNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpEmail: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpPicNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpHandphoneNumber: new FormControl({value : '', disabled: true}, [Validators.required]),

    picName: new FormControl({value : '', disabled: true}, [Validators.required]),
    picPosition: new FormControl({value : '', disabled: true}, [Validators.required]),
    picIdentityType: new FormControl({value : '', disabled: true}, [Validators.required]),
    picIdentityNumber: new FormControl({value : '', disabled: true}, [Validators.required]),

    cpFundSource: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpAccountPurpose: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpMonthlyIncome: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpAvgTransaction: new FormControl({value : '', disabled: true}, [Validators.required]),
  });
  companyFormNew: FormGroup = new FormGroup({
    cpCif: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpName: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpFieldOfWork: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpPlaceOfEstablishment: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDeedNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDateOfEstablisment: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDeedIssuedPlace: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDeedIssuedDate: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpNPWP: new FormControl({value : '', disabled: true}, [Validators.required]),

    cpAddress: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpNationality: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpProvince: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpDistrict: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpSubDistrict: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpCity: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpPostalCode: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpOccupiedSince: new FormControl({value : '', disabled: true}, [Validators.required]),

    cpPhoneNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpFaxNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpEmail: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpPicNumber: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpHandphoneNumber: new FormControl({value : '', disabled: true}, [Validators.required]),

    picName: new FormControl({value : '', disabled: true}, [Validators.required]),
    picPosition: new FormControl({value : '', disabled: true}, [Validators.required]),
    picIdentityType: new FormControl({value : '', disabled: true}, [Validators.required]),
    picIdentityNumber: new FormControl({value : '', disabled: true}, [Validators.required]),

    cpFundSource: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpAccountPurpose: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpMonthlyIncome: new FormControl({value : '', disabled: true}, [Validators.required]),
    cpAvgTransaction: new FormControl({value : '', disabled: true}, [Validators.required]),
  });

  statusApproval : any;
  dataIdentifier : any;
  errArr : any;

  tokenRaja : any;
  dataQuestion : any; tempQuestion : any;
  dataProvince : any; tempProvince : any; provinceId : any;
  dataCity : any; tempCity : any; cityId : any;
  dataDistrict : any; tempDistrict : any; districtId : any;
  dataSubdistrict : any; tempSubdistrict : any; subdistrictId : any;
  etcFund : any; etcPurpose : any;

  fundSource : any = 
  [
    {
      name : "Penghasilan",
      checked : false
    },
    {
      name : "Warisan",
      checked : false
    },
    {
      name : "Hibah",
      checked : false
    },
    {
      name : "Undian",
      checked : false
    },
    {
      name : "Dari Suami / Istri",
      checked : false
    },
    {
      name : "Bunga Simpanan",
      checked : false
    },
    {
      name : "Dari Orang Tua",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];
  accountPurpose : any = 
  [
    {
      name : "Investasi",
      checked : false
    },
    {
      name : "Pendidikan",
      checked : false
    },
    {
      name : "Niaga",
      checked : false
    },
    {
      name : "Modal",
      checked : false
    },
    {
      name : "Gaji",
      checked : false
    },
    {
      name : "Biaya Rutin",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];

  tokenRajaNew : any;
  dataQuestionNew : any; tempQuestionNew : any;
  dataProvinceNew : any; tempProvinceNew : any; provinceIdNew : any;
  dataCityNew : any; tempCityNew : any; cityIdNew : any;
  dataDistrictNew : any; tempDistrictNew : any; districtIdNew : any;
  dataSubdistrictNew : any; tempSubdistrictNew : any; subdistrictIdNew : any;
  etcFundNew : any; etcPurposeNew : any;

  submittedBy : any;
  approvedBy : any = "None";
  fundSourceNew : any = 
  [
    {
      name : "Penghasilan",
      checked : false
    },
    {
      name : "Warisan",
      checked : false
    },
    {
      name : "Hibah",
      checked : false
    },
    {
      name : "Undian",
      checked : false
    },
    {
      name : "Dari Suami / Istri",
      checked : false
    },
    {
      name : "Bunga Simpanan",
      checked : false
    },
    {
      name : "Dari Orang Tua",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];
  accountPurposeNew : any = 
  [
    {
      name : "Investasi",
      checked : false
    },
    {
      name : "Pendidikan",
      checked : false
    },
    {
      name : "Niaga",
      checked : false
    },
    {
      name : "Modal",
      checked : false
    },
    {
      name : "Gaji",
      checked : false
    },
    {
      name : "Biaya Rutin",
      checked : false
    },
    {
      name : "Lain-Lain",
      checked : false,
      value : ""
    },
  ];

  compareObj : any = {
    cpCif: 0,
    cpName: 0,
    cpFieldOfWork: 0,
    cpPlaceOfEstablishment: 0,
    cpDeedNumber: 0,
    cpDateOfEstablisment: 0,
    cpDeedIssuedPlace: 0,
    cpDeedIssuedDate: 0,
    cpNPWP: 0,
    cpAddress: 0,
    cpNationality: 0,
    cpProvince: 0,
    cpDistrict: 0,
    cpSubDistrict: 0,
    cpCity: 0,
    cpPostalCode: 0,
    cpOccupiedSince: 0,
    cpPhoneNumber: 0,
    cpFaxNumber: 0,
    cpEmail: 0,
    cpPicNumber: 0,
    cpHandphoneNumber: 0,
    picName: 0,
    picPosition: 0,
    picIdentityType: 0,
    picIdentityNumber: 0,
    cpFundSource: 0,
    cpAccountPurpose: 0,
    cpMonthlyIncome: 0,
    cpAvgTransaction: 0,
  };;

  ngOnInit(): void {
    if (this.route.snapshot.params.id) {
      this.idParam = this.route.snapshot.params.id;
      this.getData(this.idParam);
    }
  }

 

  getData(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.companyForm.patchValue({
            cpCif: this.responseApi.data.cif_code,
            cpName: this.responseApi.data.company_name,
            cpFieldOfWork: this.responseApi.data.field_of_work,
            cpPlaceOfEstablishment: this.responseApi.data.place_of_establishment,
            cpDeedNumber: this.responseApi.data.deed_number,
            cpDateOfEstablisment: this.responseApi.data.date_of_establishment,
            cpDeedIssuedPlace: this.responseApi.data.deed_issued_place,
            cpDeedIssuedDate: this.responseApi.data.deed_issued_date,
            cpNPWP: this.responseApi.data.npwp,
        
            cpAddress: this.responseApi.data.address,
            cpNationality: this.responseApi.data.nationality,
            cpProvince: this.responseApi.data.province,
            cpDistrict: this.responseApi.data.district,
            cpSubDistrict: this.responseApi.data.sub_district,
            cpCity: this.responseApi.data.city,
            cpPostalCode: this.responseApi.data.postal_code,
            cpOccupiedSince: this.responseApi.data.occupied_since,
        
            cpPhoneNumber: this.responseApi.data.phone_number,
            cpFaxNumber: this.responseApi.data.fax_number,
            cpEmail: this.responseApi.data.email,
            cpPicNumber: this.responseApi.data.pic_number,
            cpHandphoneNumber: this.responseApi.data.handphone_number,
        
            picName: this.responseApi.data.pic_name,
            picPosition: this.responseApi.data.pic_position,
            picIdentityType: this.responseApi.data.pic_identity_type,
            picIdentityNumber: this.responseApi.data.pic_identity_number,
        
            cpFundSource: this.responseApi.data.fund_source,
            cpAccountPurpose: this.responseApi.data.account_purpose,
            cpMonthlyIncome: this.responseApi.data.monthly_income,
            cpAvgTransaction: this.responseApi.data.average_transaction,
          });
        this.statusApproval = this.responseApi.data.status_approval;
        this.dataIdentifier = this.responseApi.data.cif_code;
        this.submittedBy = this.responseApi.data.created_by;
        this.getQuestion();
        this.getProvince();
        this.parseCheckbox();
        this.getLogStatus(this.dataIdentifier,this.submittedBy);
        if(this.statusApproval == "PENDING_EDIT"){
          this.getNewData(this.dataIdentifier);
        }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getNewData(id){
    this.http.get(this.ApiConfig.API_URL + '/cif-company-edit-new/' + id,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.companyFormNew.patchValue({
            cpCif: this.responseApi.data.cif_code,
            cpName: this.responseApi.data.company_name,
            cpFieldOfWork: this.responseApi.data.field_of_work,
            cpPlaceOfEstablishment: this.responseApi.data.place_of_establishment,
            cpDeedNumber: this.responseApi.data.deed_number,
            cpDateOfEstablisment: this.responseApi.data.date_of_establishment,
            cpDeedIssuedPlace: this.responseApi.data.deed_issued_place,
            cpDeedIssuedDate: this.responseApi.data.deed_issued_date,
            cpNPWP: this.responseApi.data.npwp,
        
            cpAddress: this.responseApi.data.address,
            cpNationality: this.responseApi.data.nationality,
            cpProvince: this.responseApi.data.province,
            cpDistrict: this.responseApi.data.district,
            cpSubDistrict: this.responseApi.data.sub_district,
            cpCity: this.responseApi.data.city,
            cpPostalCode: this.responseApi.data.postal_code,
            cpOccupiedSince: this.responseApi.data.occupied_since,
        
            cpPhoneNumber: this.responseApi.data.phone_number,
            cpFaxNumber: this.responseApi.data.fax_number,
            cpEmail: this.responseApi.data.email,
            cpPicNumber: this.responseApi.data.pic_number,
            cpHandphoneNumber: this.responseApi.data.handphone_number,
        
            picName: this.responseApi.data.pic_name,
            picPosition: this.responseApi.data.pic_position,
            picIdentityType: this.responseApi.data.pic_identity_type,
            picIdentityNumber: this.responseApi.data.pic_identity_number,
        
            cpFundSource: this.responseApi.data.fund_source,
            cpAccountPurpose: this.responseApi.data.account_purpose,
            cpMonthlyIncome: this.responseApi.data.monthly_income,
            cpAvgTransaction: this.responseApi.data.average_transaction,
          });
        this.getQuestionNewData();
        this.getProvinceNewData();
        this.parseCheckboxNewData();
        this.compareEdit();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  compareEdit(){
    var newData = this.companyFormNew.value;
    var oldData = this.companyForm.value;
    const newEntries = Object.entries(newData);
    const oldEntries = Object.entries(oldData);
    let object = [];
    newEntries.forEach((e,i) => {
      if(e[1]!=oldEntries[i][1] && e[0]!="documentForm"){
        for (let key in this.compareObj){
          if(e[0]==key)
          this.compareObj[key] = 1;
        }
      }
    });
  }

  parseCheckbox(){
    //Fund Source
    let foundBoolean = false; 
    let fund = this.companyForm.value.cpFundSource.split(';');
    fund.splice(-1);
    fund.forEach((elementFund, indexFund) => {
      foundBoolean = false;
      this.fundSource.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          foundBoolean = true;
        }

        if (this.fundSource.length == (indexSource + 1) && !foundBoolean){
          // etcBoolean = true;
          elementSource.checked = true;
          this.etcFund = elementFund;
        }
      });
    });

    //Account Purpose
    let purposeBoolean = false;
    let purpose = this.companyForm.value.cpAccountPurpose.split(';');
    purpose.splice(-1);
    purpose.forEach((elementFund, indexFund) => {
      purposeBoolean = false;
      this.accountPurpose.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          purposeBoolean = true;
        }

        if (this.accountPurpose.length == (indexSource + 1) && !purposeBoolean){
          elementSource.checked = true;
          this.etcPurpose = elementFund;
        }
      });
    });
  }

  getTokenRaja(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.code == "200"){
          this.tokenRaja = this.responseApi.token;
          this.getProvince();
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvince(){
    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvince = this.responseApi.data;
          this.tempProvince = this.dataProvince.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
          // console.log(this.tempProvince);
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

  getCity(){
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCity = this.responseApi.data;
          // console.log(this.dataCity)
          this.tempCity = this.dataCity.map(city => ({
            "id": city.code,
            "text": city.name,
            "additional": city
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getDistrict(){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrict = this.responseApi.data;
          this.tempDistrict = this.dataDistrict.map(district => ({
            "id": district.code,
            "text": district.name,
            "additional": district
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getSubDistrict(){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrict = this.responseApi.data;
          this.tempSubdistrict = this.dataSubdistrict.map(subdistrict => ({
            "id": subdistrict.code,
            "text": subdistrict.name,
            "additional": subdistrict
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getQuestion(){
    this.http.get(this.ApiConfig.API_URL + "/cif-company-question", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.dataQuestion = this.responseApi.data;
        }        
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChange(event: any){
    this.companyForm.patchValue({
      cpDistrict: event
    });
    this.districtId = this.companyForm.controls['cpDistrict'].value;
    this.getSubDistrict();
  }

  onSubdistrictChange(event: any){
    this.companyForm.patchValue({
      cpSubDistrict: event
    });
  }

  onProvinceChange(event: any){
    this.companyForm.patchValue({
      cpProvince: event
    });
    this.provinceId = this.companyForm.controls['cpProvince'].value;
    this.getCity();
  }

  onCityChange(event: any){
    this.companyForm.patchValue({
      cpCity: event
    });
    this.cityId = this.companyForm.controls['cpCity'].value;
    this.getDistrict();
  }

  closeAll(){
    this.myPanels.closeAll();
  }

  openAll(){
    this.myPanels.openAll();
  }

  changeFund(e){
    this.fundSource[7].value = this.etcFund;
  }

  changeAccPurpose(e){
    this.accountPurpose[6].value = this.etcPurpose;
  }

  getLogStatus(approve,submit){
    this.http.get(this.ApiConfig.API_URL + '/log-status-cif-company/' + approve + '/' + submit,this.httpOptions).subscribe(
      (res) => {
        this.responseApi = res;
        if (this.responseApi.status == '200') {
          this.approvedBy = this.responseApi.data.approve ? this.responseApi.data.approve : this.approvedBy
          this.submittedBy = this.responseApi.data.submit ? this.responseApi.data.submit : this.submittedBy
        }
      },
      (err) => {
        console.log(err);
      }
    );   
  }



  //------------------------------------------------EDIT NEW DATA---------------------------------------------------------------------------
  parseCheckboxNewData(){
    //Fund Source
    let foundBoolean = false; 
    let fund = this.companyFormNew.value.cpFundSource.split(';');
    fund.splice(-1);
    fund.forEach((elementFund, indexFund) => {
      foundBoolean = false;
      this.fundSourceNew.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          foundBoolean = true;
        }

        if (this.fundSourceNew.length == (indexSource + 1) && !foundBoolean){
          // etcBoolean = true;
          elementSource.checked = true;
          this.etcFundNew = elementFund;
        }
      });
    });

    //Account Purpose
    let purposeBoolean = false;
    let purpose = this.companyFormNew.value.cpAccountPurpose.split(';');
    purpose.splice(-1);
    purpose.forEach((elementFund, indexFund) => {
      purposeBoolean = false;
      this.accountPurposeNew.forEach((elementSource, indexSource) => {
        
        if (elementFund == elementSource.name){
          elementSource.checked = true;
          purposeBoolean = true;
        }

        if (this.accountPurpose.length == (indexSource + 1) && !purposeBoolean){
          elementSource.checked = true;
          this.etcPurposeNew = elementFund;
        }
      });
    });
  }

  getTokenRajaNewData(){
    this.http.get(this.ApiConfig.API_URL + "/region-token", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.code == "200"){
          this.tokenRajaNew = this.responseApi.token;
          this.getProvinceNewData();
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getProvinceNewData(){
    this.http.get(this.ApiConfig.API_URL + "/get-province", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataProvinceNew = this.responseApi.data;
          this.tempProvinceNew = this.dataProvinceNew.map(province => ({
            "id": province.code,
            "text": province.name,
            "additional": province
          }));
        }
      }, 
      err => {
        console.log(err);
      }
    );
  }

  getCityNewData(){
    this.http.get(this.ApiConfig.API_URL + "/get-city/" + this.provinceId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataCityNew = this.responseApi.data;
          // console.log(this.dataCity)
          this.tempCityNew = this.dataCityNew.map(city => ({
            "id": city.code,
            "text": city.name,
            "additional": city
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getDistrictNewData(){
    this.http.get(this.ApiConfig.API_URL + "/get-districts/" + this.cityId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataDistrictNew = this.responseApi.data;
          this.tempDistrictNew = this.dataDistrictNew.map(district => ({
            "id": district.code,
            "text": district.name,
            "additional": district
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getSubDistrictNewData(){
    this.http.get(this.ApiConfig.API_URL + "/get-subdistricts/" + this.districtId, this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if(this.responseApi.status == "200"){
          this.dataSubdistrictNew = this.responseApi.data;
          this.tempSubdistrictNew = this.dataSubdistrictNew.map(subdistrict => ({
            "id": subdistrict.code,
            "text": subdistrict.name,
            "additional": subdistrict
          }));
        }      
      },
      err => {
        console.log(err);
      }
    );
  }

  getQuestionNewData(){
    this.http.get(this.ApiConfig.API_URL + "/cif-company-question", this.httpOptions).subscribe(
      res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          this.dataQuestionNew = this.responseApi.data;
        }        
      },
      err => {
        console.log(err);
      }
    );
  }

  onDistrictChangeNewData(event: any){
    this.companyFormNew.patchValue({
      cpDistrict: event
    });
    this.districtId = this.companyFormNew.controls['cpDistrict'].value;
    this.getSubDistrictNewData();
  }

  onSubdistrictChangeNewData(event: any){
    this.companyFormNew.patchValue({
      cpSubDistrict: event
    });
  }

  onProvinceChangeNewData(event: any){
    this.companyFormNew.patchValue({
      cpProvince: event
    });
    this.provinceId = this.companyFormNew.controls['cpProvince'].value;
    this.getCityNewData();
  }

  onCityChangeNewData(event: any){
    this.companyFormNew.patchValue({
      cpCity: event
    });
    this.cityId = this.companyFormNew.controls['cpCity'].value;
    this.getDistrictNewData();
  }

  approve(){
    if(this.statusApproval=="PENDING_ADD" || this.statusApproval=="PENDING_DELETE"){
      var obj = {
        data_type: "cif_company",
        approval_type: this.statusApproval,
        data_identifier: this.dataIdentifier,
        id_user: this.ApiConfig.USER_DATA.id_user,
        action_type: "approve",
        log_status: "01",
        data: this.companyForm.value
      };
    } else if (this.statusApproval=="PENDING_EDIT"){
      var obj = {
        data_type: "cif_company",
        approval_type: this.statusApproval,
        data_identifier: this.dataIdentifier,
        id_user: this.ApiConfig.USER_DATA.id_user,
        action_type: "approve",
        log_status: "01",
        data: this.companyFormNew.value
      };
    }
    if(obj.approval_type == "PENDING_ADD"){
      this.http.post(this.ApiConfig.API_URL + "/log-status-cif-company-add/", obj, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/pending-cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else if(obj.approval_type == "PENDING_EDIT"){
      this.http.post(this.ApiConfig.API_URL + "/log-status-cif-company-edit/", obj, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/pending-cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else if(obj.approval_type == "PENDING_DELETE"){
      this.http.post(this.ApiConfig.API_URL + "/log-status-cif-company-delete/", obj, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/pending-cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    }
  }

  reject(){
    if(this.statusApproval=="PENDING_ADD" || this.statusApproval=="PENDING_DELETE"){
      var obj = {
        data_type: "cif_company",
        approval_type: this.statusApproval,
        data_identifier: this.dataIdentifier,
        id_user: this.ApiConfig.USER_DATA.id_user,
        action_type: "reject",
        log_status: "01",
        data: this.companyForm.value
      };
    } else if (this.statusApproval=="PENDING_EDIT"){
      var obj = {
        data_type: "cif_company",
        approval_type: this.statusApproval,
        data_identifier: this.dataIdentifier,
        id_user: this.ApiConfig.USER_DATA.id_user,
        action_type: "reject",
        log_status: "01",
        data: this.companyFormNew.value
      };
    }
    if(obj.approval_type == "PENDING_ADD"){
      this.http.post(this.ApiConfig.API_URL + "/log-status-cif-company-add/", obj, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/pending-cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else if(obj.approval_type == "PENDING_EDIT"){
      this.http.post(this.ApiConfig.API_URL + "/log-status-cif-company-edit/", obj, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/pending-cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    } else if(obj.approval_type == "PENDING_DELETE"){
      this.http.post(this.ApiConfig.API_URL + "/log-status-cif-company-delete/", obj, this.httpOptions).subscribe(res => {
        this.responseApi = res;
        if (this.responseApi.status == "200") {
          Swal.fire("Success", "Your input has been saved.", "success");
          this.router.navigate(['/admin/pending-cif-company']);
        } else {
          Swal.fire("Ooops", "There's something wrong happened", "error");
        }
      }, err => {
        Swal.fire("Ooops", "There's something wrong happened", "error");
      });
    }
  }
}
